import cx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Card, Flex, Heading, Icon, TextInput } from '../../../../components/common';
import { IconStyled } from '../../../../components/common/icon/IconStyled';
import LicenseValidationGuide from './license-validation-guide';
import { Typography } from 'antd';
import tenantTheme from '@theme';
const { Text } = Typography;

const AdLicenseForm = ({ formik, handleAdLicenseValidation, loading }) => {
  const [licenseModalVisible, setLicenseModalVisible] = useState(false);
  const { t } = useTranslation();
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  return (
    <Card bodyStyle={{ padding: isMobile ? 16 : 40 }}>
      <Flex
        className="w-100 m-auto"
        vertical
        align="center"
        justify={!isMobile && 'center'}
        style={{ minHeight: '410px', maxWidth: '560px' }}
      >
        <div className={cx(isMobile ? 'mb-20' : 'mb-32', 'text-center')}>
          <IconStyled
            className={cx(isMobile ? 'mb-20' : 'mb-24', 'm-auto')}
            style={{
              '--icon-styled-width': isMobile ? '94px' : '124px',
              background: tenantTheme['primary-color-1'],
            }}
          >
            <Icon icon="LicenseIcon" size={isMobile ? '54px' : '70px'} />
          </IconStyled>

          <Heading as={isMobile ? 'h6' : 'h2'} className={isMobile ? 'mb-0' : 'mb-8'} style={{ fontWeight: '700' }}>
            {t('Provide Your Ad License Number')}
          </Heading>
          <Text className={isMobile ? 'fz-12' : 'fz-16'} type="secondary">
            {t('You need a license to advertise your property.')}
          </Text>
          <Button
            type={'link'}
            style={{ padding: '4px', textDecoration: 'underline' }}
            onClick={() => setLicenseModalVisible(true)}
          >
            {t('Learn More')}
          </Button>
        </div>
        <Flex vertical={isMobile && true} className="mb-24 w-100" gap="20px">
          <TextInput
            key="adLicense"
            name="adLicense"
            value={formik.values.adLicense}
            type="input"
            onChange={(e) => formik.setFieldValue('adLicense', e?.target?.value)}
            onBlur={() => formik.setFieldTouched('adLicense', true)}
            errorMsg={formik?.errors['adLicense'] && formik?.touched['adLicense'] && formik?.errors['adLicense']}
            placeholder={'e.g 7200000001'}
            maxLength={10}
            className="w-100"
          />
        </Flex>

        <Button
          className="w-100"
          onClick={() => {
            formik.setFieldTouched('adLicense', true);
            handleAdLicenseValidation();
          }}
          loading={loading}
          type={'primary'}
          size={'large'}
        >
          {t('Continue')}
        </Button>
        {licenseModalVisible && (
          <LicenseValidationGuide
            licenseModalVisible={licenseModalVisible}
            setLicenseModalVisible={setLicenseModalVisible}
          />
        )}
      </Flex>
    </Card>
  );
};
export default AdLicenseForm;
