import { Form, Input, Radio } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Alert, Button, Heading } from '../../../components/common';
import { RadioButtonStyled } from '../../../components/common/radio-button/styled';
import { useAppAuthentication } from '../../../hooks';
import { getAppLanguage } from '../../../hooks/useAppInit';
import { AuthWrapper } from './style';
import tenantConstants from '@constants';

const SignIn = () => {
  const { t } = useTranslation();
  const locale = useSelector((state) => state.AppConfig.locale);

  const isLoading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);
  const [form] = Form.useForm();

  const { onLogin } = useAppAuthentication();

  const handleSubmit = (values) => {
    onLogin(values);
  };

  const onChangeLanguage = (event) => {
    const lang = tenantConstants?.languages?.find((e) => e.key == event.target.value);
    const currentLanguage = getAppLanguage();
    const pathDirectories = window.location.pathname.split('/');
    let newPath;
    if (pathDirectories[1] !== lang.key) {
      if (window.location.pathname.includes(`/${currentLanguage?.key}`)) {
        newPath = [window.location.pathname.replace(`/${currentLanguage?.key}`, `/${lang?.key}`)];
      } else {
        newPath = [pathDirectories[0], lang?.key, pathDirectories.slice(1)];
      }
    }
    window.location.pathname = newPath.join('/');
  };

  return (
    <AuthWrapper>
      <div className="auth-contents" style={{ height: 'inherit' }}>
        <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
          <Heading as="h3">
            {t('Sign in to')} <span className="text-primary">{t('Profolio')}</span>
          </Heading>
          <Form.Item
            name="email"
            rules={[
              { message: t('Please input email'), required: true },
              { message: t('Please enter valid email'), type: 'email' },
            ]}
            initialValue=""
            label={t('Email Address')}
          >
            <Input placeholder={t('Email')} />
          </Form.Item>
          <Form.Item
            name="password"
            initialValue=""
            label={t('Password')}
            rules={[{ message: t('Please enter password'), required: true }]}
          >
            <Input.Password placeholder={t('Password')} />
          </Form.Item>
          {!isLoading && <Alert message={error} style={{ marginBottom: 16 }} />}
          <Form.Item>
            <Button
              className="btn-signin"
              htmlType="submit"
              type="primary"
              size="large"
              loading={isLoading}
              disabled={isLoading}
            >
              {t('Sign In')}
            </Button>
          </Form.Item>

          <Radio.Group value={locale} style={{ width: '100%' }} size="small" onChange={onChangeLanguage}>
            {tenantConstants?.languages?.length > 1 &&
              tenantConstants?.languages?.map((e) => (
                <RadioButtonStyled key={e.label} value={e.key}>
                  {e.label}
                </RadioButtonStyled>
              ))}
          </Radio.Group>
        </Form>
      </div>
    </AuthWrapper>
  );
};

export default SignIn;
