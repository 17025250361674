import tenantData from '@data';
import store from '../../../../redux/store';
import { NetworkService } from '../../../../services/networkService';
import { creditsWidgetMapper, quotaCreditsWidgetMapper } from './quota-credits';

export const agencyQuotaCredit = async (id) => {
  const user = store.getState().loginUser.user;
  let response;
  if (user?.isCurrencyUser) {
    response = await NetworkService.get(`api/credits/summary`, {
      ...(id != -1 && { [`subject_id`]: id }),
    });
  } else {
    response = await NetworkService.get(`/api/dashboard/qc_summary`, {
      ...(id != -1 && { [`q[user_id_eq]`]: id }),
    });
  }

  if (response) {
    if (response.error) {
      return response;
    } else {
      let quotaCredits = {};
      tenantData.platformList.forEach((e) => {
        if (user?.isCurrencyUser) {
          quotaCredits[e.slug] = creditsWidgetMapper(e, response?.data?.credits_summary);
        } else if (Object?.keys(response?.data?.[e?.slug])) {
          quotaCredits[e.slug] = quotaCreditsWidgetMapper(e, response?.data, user);
        }
      });
      return quotaCredits;
    }
  }
};
