import tenantApi from '@api';
import tenantConstants from '@constants';
import Cookies from 'js-cookie';
import { notification } from '../../components/common';
import { cookieDomain } from '../../utility/env';
import actions from './actions';

const { loginBegin, loginErr, logoutBegin, logoutSuccess, logoutErr, setAuthTokens } = actions;

const login = (data, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(loginBegin());
      const response = await tenantApi.loginApi(data);
      if (response) {
        if (response.error) {
          dispatch(loginErr(response.error));
          notification.error(response.error);
        } else {
          dispatch(setAuthTokens({token: response.data?.token, authenticated: !!response.data?.token, initialized: true }));
          response.data.user &&
            Cookies.set(
              tenantConstants.AUTH_TOKEN_COOKIE_KEY,
              response.data.token,
              cookieDomain && { domain: cookieDomain },
            );
          onSuccess(response.data);
        }
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (cb) => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      dispatch(logoutSuccess(null));
      cb();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { logOut, login, setAuthTokens };

