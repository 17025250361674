import { NetworkService } from '../../../../services/networkService';
import { getLoggedInUserValues } from './userSettingsForm/getLoggedInUserValues';

const fetchUserSettingsDetail = async id => {
  let response = await NetworkService.get(`/api/users/${id}`);
  if (response) {
    if (response.error) {
      return response;
    } else {
      return {
        data: getLoggedInUserValues(response.data?.user),
      };
    }
  }
};
export default fetchUserSettingsDetail;
