import tenantUtils from '@utils';
import tenantData from '@data';
import { t } from 'i18next';
import { Icon } from '../../../components/common';
import { getTimeDateString } from '../../../utility/date';
import { listingTypes } from '../../data/bayut/listingTypes';

const getMyListingsFilters = (user, agencyUsersList, locale) => [
  {
    label: 'Bayut Listing ID',
    type: 'input',
    key: 'listing_id',
    queryParamKey: 'q[listing_id_eq]',
    placeholder: 'Enter Bayut Listing ID',
    labelProps: { muted: true },
    inputType: 'number',
    limit: 8,
  },
  {
    label: 'REGA Ad License Number',
    type: 'input',
    key: 'ad_license',
    queryParamKey: 'q[ad_license_eq]',
    placeholder: 'Enter REGA Ad License Number',
    labelProps: { muted: true },
    inputType: 'number',
    limit: 9,
  },
  {
    label: 'Purpose',
    list: tenantData.purposeList,
    type: 'select',
    key: 'purpose_id',
    queryParamKey: 'q[purpose_id_eq]',
    placeholder: 'Select Purpose',
    labelProps: { muted: false },
    getOptionValue: (e) => e?.id?.toString(),
    getOptionLabel: (e) => (
      <div>
        <Icon icon={e.icon} />
        {t(e.title)}
      </div>
    ),
  },
  {
    label: 'Property Type',
    key: 'type_id',
    queryParamKey: 'q[type_id_in][]',
    type: 'select',
    mode: 'multiple',
    placeholder: 'Select Property Types',
    allowClear: true,
    labelProps: { muted: true },
    list: listingTypes([], locale)?.map((e) => ({
      label: t(e?.title?.[locale]),
      options: e.sub_types.map((it) => ({
        label: t(it?.title?.[locale]),
        value: it.id?.toString(),
        icon: it?.icon,
      })),
    })),
    getOptionLabel: (e) => <div>{t(e.label)}</div>,
  },
  {
    label: 'Posted On',
    key: 'date_between',
    queryParamKey: ['q[created_at_gteq]', 'q[created_at_lteq]'],
    placeholder: 'Select Date Range',
    type: 'dateRange',
    labelProps: { muted: true },
    getOptionLabel: (value) => {
      return value
        .split(',')
        .map((e) => getTimeDateString(e, false, true, false, false))
        .join(' - ');
    },
  },
  {
    type: 'locationFilter',
    mode: 'multiple',
    subList: [
      {
        key: 'city_id',
        queryParamKey: 'q[location_city_id_in][]',
        getOptionValue: (item) => item.location_id.toString(),
      },
      {
        key: 'location_id',
        queryParamKey: 'q[location_id_in][]',
        getOptionValue: (item) => item.location_id.toString(),
      },
    ],
  },
  ...(!!(agencyUsersList?.length > 1)
    ? [
        {
          label: 'Posted By',
          list: agencyUsersList,
          type: 'select',
          key: 'user_id',
          queryParamKey: 'q[user_id_eq]',
          placeholder: 'Select Users',
          box_title: 'All Users',
          labelProps: { muted: true },
          singleValue: true,
          getOptionValue: (e) => e?.id?.toString(),
          getOptionLabel: (e) => `${tenantUtils.getLocalisedString(e, 'name')} ${e.id === user?.id ? t('(Me)') : ''}`,
        },
      ]
    : []),
  {
    list: [0, 1000000000],
    type: 'rangeSlider',
    isCurrency: true,
    defaultValues: [0, 1],
    label: 'Price Range',
    key: 'price_between',
    queryParamKey: ['q[price_gteq]', 'q[price_lteq]'],
    labelProps: { muted: true },
  },
  {
    list: tenantData?.areaUnitList?.map((e) => ({ ...e, key: e?.slug, value: [0, 10000] })),
    defaultUnitKey: 'square_meters',
    label: 'Area Range',
    type: 'unitRangeSlider',
    key: 'area_between',
    unitKey: 'areaUnitKey',
    queryParamKey: ['q[area_unit_value_gteq]', 'q[area_unit_value_lteq]', 'areaUnitKey'],
    labelProps: { muted: true },
    unitList: tenantData?.areaUnitList.map((e) => ({ ...e, key: e?.slug })),
    disabledUnit: !tenantData?.areaUnitList?.length || tenantData?.areaUnitList?.length == 1,
  },
];

export default getMyListingsFilters;
