import tenantData from '@data';
import { TENANT_KEY } from '../../utility/env';
import { getKeyValueFromObject } from '../../utility/utility';
import actions from './actions';

const {
  SET_LISTINGS_DATA,
  SET_LISTINGS_LOADING,
  SET_QUOTA_CREDITS_ERROR_LOADING,
  SET_QUOTA_CREDITS_DATA,
  SET_EXPIRY_DATA,
  SET_CURRENT_USER,
  SET_LISTINGS_TABLE_DATA,
  SET_ANALYTICS_DATA,
  UPDATE_LISTINGS_DATA,
  UPDATE_LIST_DASHBOARD,
  SET_ANALYTICS_FILTER_OBJECT,
  SET_UPDATED_CREDITS,
  SET_UPDATED_QUOTA,
  SET_SELECTED_PLATFORM,
  SET_LEADS_FILTER_DASHBOARD,
  LEADS_DATA_BEGIN_DASHBOARD,
  LEADS_DATA_ERROR_DASHBOARD,
  LEADS_DATA_SUCCESS_DASHBOARD,
  SET_LEADS_SUMMARY_PLATFORM_FILTER_DASHBOARD,
  SET_LISTING_ID_FOR_BULK_REFRESH_DASHBOARD,
  SET_SELECTED_USER,
} = actions;

const initialState = {
  listings: {},
  quotaCreditsData: { data: null, loading: true, error: null, selectedUser: {} },
  analyticsData: { loading: true },
  listingsTable: {
    data: null,
    loading: true,
    error: null,
  },
  selectedListings: [],
  selectedPlatform: TENANT_KEY == 'bayut' ? 'ksa' : TENANT_KEY,
  leadsData: {},
  selectedUser: {},
};

const handleLeadsFilterObj = (state, payload) => {
  if (!!payload?.platformKey) {
    return {
      [payload?.platformKey]: {
        ...state.leadsData[payload.platformKey],
        filterObj: { ...state.leadsData[payload.platformKey]?.filterObj, ...payload.data },
      },
    };
  }
  const platformObj = {};
  tenantData.platformList.forEach((platform) => {
    platformObj[platform.slug] = {
      ...state.leadsData[platform.slug],
      filterObj: { ...state.leadsData[platform.slug]?.filterObj, ...payload.data },
    };
  });

  return platformObj;
};

const DashboardReducer = (state = initialState, action) => {
  const { type, payload, data } = action;
  switch (type) {
    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: { ...payload },
      };
    case SET_LISTINGS_DATA:
      return { ...state, listings: { ...state.listings, ...payload } };
    case SET_LISTINGS_LOADING:
      const { platformKey } = payload;
      return {
        ...state,
        listings: { ...state.listings, [platformKey]: { ...state.listings[platformKey], loading: true, error: null } },
      };
    case SET_LISTINGS_TABLE_DATA:
      return { ...state, listingsTable: { ...state.listingsTable, ...payload } };
    case SET_ANALYTICS_DATA:
      return {
        ...state,
        analyticsData: {
          ...state.analyticsData,
          [payload.platformKey]: { ...state.analyticsData[payload.platformKey], ...payload.data },
          ...(payload.hasOwnProperty('loading') && { loading: payload.loading }),
        },
      };

    case UPDATE_LISTINGS_DATA:
      return {
        ...state,
        listingsTable: { ...state.listingsTable, data: { ...state.listingsTable.data, list: payload.list } },
      };
    case SET_ANALYTICS_FILTER_OBJECT:
      return {
        ...state,
        analyticsData: {
          ...state.analyticsData,
          [payload.platformKey]: {
            ...state.analyticsData[payload.platformKey],
            filterObj: {
              ...state.analyticsData[payload.platformKey].filterObj,
              ...payload.data,
            },
          },
        },
      };
    case UPDATE_LIST_DASHBOARD: {
      const list = [...state.listingsTable.data.list];
      const { replaceKey, valueExists = false, ...rest } = action.data;
      const index = list.findIndex((e) => e.id === (replaceKey ? action.data.listing_id : action.data.id));
      list.splice(index, 1, {
        ...list[index],
        ...(replaceKey ? getKeyValueFromObject(list[index], replaceKey, rest, valueExists) : action.data),
      });
      return {
        ...state,
        listingsTable: {
          ...state.listingsTable,
          data: {
            ...state.listingsTable.data,
            list: list,
          },
        },
      };
    }
    case SET_QUOTA_CREDITS_DATA: {
      const { data, loading, error } = payload;
      return {
        ...state,
        quotaCreditsData: {
          ...state.quotaCreditsData,
          ...(data && { data: { ...state.quotaCreditsData.data, ...data } }),
          ...{ loading },
          ...{ error },
        },
      };
    }

    case SET_EXPIRY_DATA: {
      const objToSpread = payload.platformKey
        ? { [payload.platformKey]: payload.data[payload.platformKey] }
        : { ...payload.data };
      return {
        ...state,
        quotaCreditsData: {
          ...state.quotaCreditsData,
          productExpiryData: { ...state.quotaCreditsData.productExpiryData, ...objToSpread },
        },
      };
    }

    case SET_QUOTA_CREDITS_ERROR_LOADING: {
      return {
        ...state,
        quotaCreditsData: {
          ...state.quotaCreditsData,
          ...payload,
        },
      };
    }

    case SET_CURRENT_USER: {
      return {
        ...state,
        quotaCreditsData: {
          ...state.quotaCreditsData,
          selectedUser: { ...state.quotaCreditsData.selectedUser, ...{ [payload.platformKey]: payload.userId } },
        },
      };
    }

    case SET_UPDATED_CREDITS: {
      let prevCredits = state.quotaCreditsData.data[action.data.platform].products.all[action.data.productTitle];
      prevCredits[1] = prevCredits[1] - action.data.creditDeduct <= 0 ? 0 : prevCredits[1] - action.data.creditDeduct;
      prevCredits[0] = prevCredits[0] + action.data.creditDeduct;
      return {
        ...state,
        quotaCreditsData: {
          ...state.quotaCreditsData,
          data: {
            ...state.quotaCreditsData.data,
            [action.data.platform]: {
              ...state.quotaCreditsData.data[action.data.platform],
              products: {
                ...state.quotaCreditsData.data[action.data.platform].products,
                all: {
                  ...state.quotaCreditsData.data[action.data.platform].products.all,
                  [action.data.productTitle]: prevCredits,
                },
              },
            },
          },
        },
      };
    }
    case SET_UPDATED_QUOTA: {
      const productTitle = action.data.platform === 'zameen' ? 'Premium Listing' : 'Listing';
      let prevQuota = state.quotaCreditsData.data[action.data.platform].products.all[productTitle];
      if (!!action?.data?.addQuota) {
        prevQuota[1] = prevQuota[1] + action.data.quotaDeduct;
        prevQuota[0] = prevQuota[0] - action.data.quotaDeduct <= 0 ? 0 : prevQuota[0] - action.data.quotaDeduct;
      } else {
        prevQuota[1] = prevQuota[1] - action.data.quotaDeduct <= 0 ? 0 : prevQuota[1] - action.data.quotaDeduct;
        prevQuota[0] = prevQuota[0] + action.data.quotaDeduct;
      }
      return {
        ...state,
        quotaCreditsData: {
          ...state.quotaCreditsData,
          data: {
            ...state.quotaCreditsData.data,
            [action.data.platform]: {
              ...state.quotaCreditsData.data[action.data.platform],
              products: {
                ...state.quotaCreditsData.data[action.data.platform].products,
                all: {
                  ...state.quotaCreditsData.data[action.data.platform].products.all,
                  [productTitle]: prevQuota,
                },
              },
            },
          },
        },
      };
    }
    case SET_SELECTED_PLATFORM:
      return { ...state, selectedPlatform: data?.platform };
    case SET_LEADS_FILTER_DASHBOARD:
      const leadsFilterObj = state.leadsData?.[payload?.platformKey]?.filterObj;
      return {
        ...state,
        leadsData: {
          ...state.leadsData,
          [payload.platformKey]: {
            ...state.leadsData?.[payload.platformKey],
            filterObj: {
              ...leadsFilterObj,
              date_between: payload.dateRange,
            },
          },
        },
      };
    case LEADS_DATA_BEGIN_DASHBOARD:
      return {
        ...state,
        leadsData: {
          ...state.leadsData,
          [payload.platformKey]: {
            ...state.leadsData?.[payload.platformKey],
            ...payload.data,
          },
        },
      };
    case LEADS_DATA_ERROR_DASHBOARD:
      return {
        ...state,
        leadsData: {
          ...state.leadsData,
          [payload.platformKey]: {
            ...state.leadsData[payload.platformKey],
            ...payload.data,
          },
        },
      };
    case LEADS_DATA_SUCCESS_DASHBOARD:
      return {
        ...state,
        leadsData: {
          ...state.leadsData,
          [payload.platformKey]: {
            ...state.leadsData[payload.platformKey],
            ...payload.data,
          },
        },
      };
    case SET_LEADS_SUMMARY_PLATFORM_FILTER_DASHBOARD:
      return {
        ...state,
        leadsData: {
          ...state.leadsData,
          ...handleLeadsFilterObj(state, payload),
        },
      };
    default:
      return state;
  }
};

export { DashboardReducer };
