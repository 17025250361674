import { NetworkService } from '../../../../services/networkService';
import tenantUtils from '@utils';

export const applyUtilizationPlan = async (data) => {
  const response = await NetworkService.post(`/api/credit_utilisation_plans`, JSON.stringify(data));
  if (response) {
    return response;
  }
};

export const getAppliedAutoPlan = async (userId) => {
  const response = await NetworkService.get(`/api/credit_utilisation_plans`);
  if (response) {
    if (response?.error) {
      return { data: { plans: [] } };
    }
  }
  return response?.data;
};

export const getPlans = async (userId, params) => {
  const response = await NetworkService.get(
    `/api/users/${userId}/plans?plan_type=manual${!!params ? '&' + params.replace('?', '') : ''}`,
  );
  if (response) {
    if (response.error) {
      if (response?.response?.status === 404) {
        return {
          data: { plans: [] },
          pagination: tenantUtils.getPaginationObject({}),
        };
      }
      return response;
    } else {
      return {
        data: {
          plans: response.data.data.plans,
          pagination: tenantUtils.getPaginationObject(response.data.data?.meta),
        },
      };
    }
  }
  return response;
};

export const stopAutoPlan = async (planId) => {
  const response = await NetworkService.delete(`/api/credit_utilisation_plans/${planId}`);
  if (response) {
    if (response.error) {
      return response;
    } else {
      return {
        data: {
          ...response.data.data,
        },
      };
    }
  }
};
