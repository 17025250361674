import tenantApi from '@api';
import tenantRoutes from '@routes';
import { Col, Row, Typography, Badge } from 'antd';
import cx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Card, Flex, Heading, Icon } from '../../../../components/common';
import { useRouteNavigate } from '../../../../hooks';
import LicenseValidation from './license-validation';
import { PropertyCard } from '../../../../container/pages/post-listing/styled';
const { Text, Title } = Typography;
import { notification } from '../../../../components/common';

const cardList = [
  {
    id: 1,
    icon: 'PropertyIcon',
    propertyType: 'Sell or Rent Property',
    description: 'Find buyers and tenants for your property',
  },
  {
    id: 2,
    icon: 'RentalIcon',
    propertyType: 'Daily Rentals',
    description: 'Host guests at your place for short stays',
  },
];

const PostListingWithPropertyType = () => {
  const { t } = useTranslation();
  const [selectedCardId, setSelectedCardId] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showAdLicenseField, setShowAdLicenseField] = useState(false);
  const navigate = useRouteNavigate();
  const isMobile = useSelector((state) => state.AppConfig.isMobile);

  const handleCardClick = (id) => {
    setSelectedCardId(id);
  };
  const postNewListing = async (values, onResponseSuccess = () => {}) => {
    setLoading(true);
    const response = await tenantApi.postNewListing(values);
    if (response) {
      onResponseSuccess();
      setLoading(false);
      if (response?.error) {
        notification.error(response?.error);
      } else {
        const listingId = response?.data?.listing?.id;
        navigate(`${tenantRoutes.app().post_listing.path}/${listingId}`);
      }
    }
  };
  const handleClickOnListingSelection = () => {
    if (selectedCardId == 2) {
      postNewListing({ is_daily_rental: true });
    } else {
      setShowAdLicenseField(true);
    }
  };
  const renderPropertyCards = (selectedCard) => {
    return (
      <PropertyCard
        style={{ borderWidth: 1, '--ant-padding-lg': isMobile ? '12px' : '24px' }}
        key={selectedCard.id}
        onClick={() => handleCardClick(selectedCard.id)}
        className={cx('pointer ', selectedCard.id === selectedCardId && 'active-card')}
      >
        <Flex align="start" vertical={!isMobile && true} gap="8px">
          <Icon icon={selectedCard.icon} iconProps={{ size: isMobile ? '36px' : '52px' }} />
          <div>
            <Title
              className="mb-0"
              level={5}
              style={{ '--ant-font-weight-strong': '700', fontSize: isMobile ? '14px' : '18px' }}
            >
              {t(selectedCard.propertyType)}
            </Title>
            <Text className={!isMobile && 'fz-16'} type="secondary">
              {t(selectedCard.description)}
            </Text>
          </div>
        </Flex>
      </PropertyCard>
    );
  };
  
  return (
    <>
      {/* ToDo as discussed */}
      {!showAdLicenseField ? (
        <>
          <Row style={{ marginBlockStart: '20px' }}>
            <Col xs={24} lg={18} xxl={15} style={{ margin: 'auto' }}>
              <Button
                size="small"
                className="mb-8"
                style={{ border: 0 }}
                type="primaryOutlined"
                icon="IoMdArrowRoundBack"
                onClick={() => navigate(-1)}
                iconSize="14px"
              >
                {' '}
                {t('Back')}
              </Button>
              <Card bodyStyle={{ padding: isMobile ? 16 : 40 }}>
                <Flex
                  className="w-100 m-auto"
                  vertical
                  align="center"
                  justify={!isMobile && 'center'}
                  style={{ minHeight: '410px', maxWidth: '560px' }}
                >
                  <div className={cx(isMobile ? 'mb-20' : 'mb-40', 'text-center')}>
                    <Heading as={isMobile && 'h6'} className={isMobile ? 'mb-0' : 'mb-8'} style={{ fontWeight: '700' }}>
                      {t('Post a Listing')}
                    </Heading>
                    <Text className={isMobile ? 'fz-12' : 'fz-16'} type="secondary">
                      {t('Choose the type of listing you want to publish')}
                    </Text>
                  </div>
                  {/* Make a common component for type selection */}
                  <Flex vertical={isMobile && true} className="mb-16" gap="20px">
                    {cardList.map((item) =>
                      item?.id == 2 ? (
                        <Badge.Ribbon text={t('New')} color="red" placement="end">
                          {renderPropertyCards(item)}
                        </Badge.Ribbon>
                      ) : (
                        renderPropertyCards(item)
                      ),
                    )}
                  </Flex>

                  <Button
                    className="w-100"
                    onClick={handleClickOnListingSelection}
                    loading={loading}
                    type={'primary'}
                    size={'large'}
                  >
                    {t('Continue')}
                  </Button>
                </Flex>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <LicenseValidation postNewListing={postNewListing} setShowAdLicenseField={setShowAdLicenseField} />
      )}
    </>
  );
};
export default PostListingWithPropertyType;
