import { Menu } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DropdownStyled } from './dropdown-style';

import tenantTheme from '@theme';
import { Icon } from '..';
import Group from '../group/group';
import Label from '../Label/Label';

const Dropdown = (props) => {
  const {
    content,
    placement,
    title,
    labelProps,
    action,
    children,
    style,
    iconSize,
    rootClassName,
    className,
    options,
    getOptionLabel,
    getOptionValue,
    getOptionLink,
    getOptionIcon,
    renderChildren,
    useAsLink,
    onChange,
    placeholder,
    defaultValue,
    suffixIcon,
    prefixIcon,
    box_title,
    linkTo,
    ...rest
  } = props;

  const [value, setValue] = useState(options.filter((item) => item?.id == defaultValue)[0]?.name);

  const renderChild = () => {
    return children || <span>{placeholder}</span>;
  };

  const renderLabel = (item, onClick) => (
    <Group template="max-content auto" gap={rest.labelGap || '8px'} style={{ alignItems: 'center' }} onClick={onClick}>
      {getOptionIcon ? (
        <Icon icon={getOptionIcon(item)} size="1.6em" />
      ) : (
        item?.icon && <Icon icon={item.icon} size="1.6em" />
      )}
      {getOptionLabel(item)}
    </Group>
  );

  const renderDropdownItem = (item) => {
    return renderChildren ? (
      renderChildren(item)
    ) : (
      <Menu.Item onClick={() => onChange(item)} key={getOptionValue(item)}>
        {useAsLink ? (
          <a target="_blank" rel="noopener noreferrer" href={linkTo || getOptionLink(item)}>
            {renderLabel(item)}
          </a>
        ) : (
          renderLabel(item, item?.onClick)
        )}
      </Menu.Item>
    );
  };

  return (
    <Group gap="8px">
      {title && (
        <Label htmlFor="name" {...labelProps}>
          {title}
        </Label>
      )}
      <DropdownStyled
        className={rootClassName}
        overlayClassName={className}
        style={style}
        placement={placement}
        trigger={['click']}
        value={value}
        overlay={
          options && !!options.length ? (
            <Menu>
              {box_title && <Menu.Item>{box_title}</Menu.Item>}
              {content || options.map((e) => renderDropdownItem(e))}
            </Menu>
          ) : (
            <Menu>{renderDropdownItem()}</Menu>
          )
        }
        {...rest}
      >
        <div style={{ gap: 4, ...style }}>
          {prefixIcon && (
            <Icon
              icon={prefixIcon}
              size={iconSize || rest?.prefixIconProps?.size}
              color={rest?.prefixIconProps?.color || tenantTheme['extra-light-color']}
              {...rest?.prefixIconProps}
            />
          )}
          {renderChild()}
          {suffixIcon && (
            <Icon
              icon={suffixIcon}
              size={iconSize || rest?.suffixIconProps?.size}
              color={rest?.suffixIconProps?.color || tenantTheme['extra-light-color']}
              {...rest?.suffixIconProps}
            />
          )}
        </div>
      </DropdownStyled>
    </Group>
  );
};

Dropdown.defaultProps = {
  action: ['click'],
  placement: 'bottomRight',
  style: {},
  className: '',
  options: [],
  getOptionLabel: (e) => e?.name || e?.title,
  getOptionValue: (e) => e?.id,
  getOptionLink: (e) => e?.href,
  suffixIcon: 'MdKeyboardArrowDown',
  onChange: () => {},
};

Dropdown.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string,
  labelProps: PropTypes.object,
  action: PropTypes.array,
  content: PropTypes.node,
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
  rootClassName: PropTypes.string,
  options: PropTypes.array,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  getOptionIcon: PropTypes.func,
  renderChildren: PropTypes.func,
  useAsLink: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  suffixIcon: PropTypes.string,
  prefixIcon: PropTypes.string,
  box_title: PropTypes.string,
  linkTo: PropTypes.string,
};

export { Dropdown };
