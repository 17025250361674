export const addQueryFilterArray = (filters, searchKey, value, splitString, cb) => {
  const prevVal = filters[searchKey];
  const prevValArr = prevVal ? prevVal.toString().split(splitString) : [];
  if (value && !prevValArr.includes(value?.toString())) {
    const filtersObj = { ...filters };
    filtersObj[searchKey] = [...prevValArr, value].join(splitString);
    filtersObj.page && delete filtersObj.page;
    cb(filtersObj);
  }
};

export const addQueryFilterArrayPreSelected = (filters, searchKey, value, splitString, cb) => {
  const obj = { ...filters };
  if (obj[searchKey]) {
    const array = obj[searchKey].split(splitString);
    const index = array.indexOf(value.toString());
    if (array.length > 1) {
      array.splice(index, 1);
      obj[searchKey] = array.join(splitString);
    } else {
      delete obj[searchKey];
    }
  }
  obj.page && delete obj.page;
  cb(obj);
};

export const convertQueryObjToString = (obj) => {
  if (!obj) return '';
  const keys = Object.keys(obj);
  return keys
    .map((key) => (obj[key] ? `${key}=${obj[key]}` : ''))
    .filter((e) => !!e)
    .join('&');
};

const removeCommas = (filters) => {
  if (!filters) return '';
  const keys = Object.keys(filters);
  const params = keys
    ?.map((e) => {
      const valuesArray = filters[e] && filters[e] != 'null' ? filters[e]?.split(',') : '';
      return (
        valuesArray &&
        valuesArray
          ?.map((val) => {
            return `${e}=${val}`;
          })
          .join('&')
      );
    })
    .join('&');
  return params;
};

export const getQueryString = (filters, removeCommaSeperated = false) => {
  const queryString =
    typeof filters === 'string'
      ? filters
      : removeCommaSeperated
        ? removeCommas(filters)
        : convertQueryObjToString(filters);
  return queryString;
};

export const mapQueryStringToFilterObject = (search, cb = () => {}) => {
  const queryObj = {};
  const queryString = search?.split('?')[1];

  if (queryString) {
    const items = decodeURI(queryString)?.split('&');
    for (const item of items) {
      const key = item?.split('=')[0];
      const value = item?.split('=')[1];
      cb(key, value);
      if (key && value) {
        if (queryObj[key]) {
          queryObj[key] += `,${value}`;
        } else {
          queryObj[key] = value;
        }
      }
    }
  }

  return { queryObj };
};

export const getValuesFromQuery = (search, linkedArray) => {
  const values = [];

  const queryString = search?.split('?')[1];
  if (queryString) {
    const items = queryString?.split('&');
    for (const item of items) {
      const key = item?.split('=')[0];
      const value = item?.split('=')[1];
      if (key && value) {
        if (linkedArray.includes(key)) {
          values.push(value);
        }
      }
    }
  }
  return values;
};
