import tenantData from '@data';
import { NetworkService } from '../../../../services/networkService';

export const fetchUserDetailApi = async (userId) => {
  const formFields = {
    list: {
      name: {
        type: 'input',
        validation: () => ({}),
        initialValue: '',
        props: {
          label: 'Name',
          placeholder: 'Enter Name',
          maxLength: 80,
        },
      },
      email: {
        type: 'input',
        validation: () => ({}),
        value: '',
        props: {
          label: 'Email',
          placeholder: 'Enter Email',
          maxLength: 300,
        },
      },
      mobile: {
        type: 'input-unit',
        validation: () => ({}),
        initialValue: '',
        value: '',
        props: {
          label: 'Mobile',
          prefixIcon: 'user',
          labelIcon: 'map',
          placeholder: 'Enter Mobile Number',
          unitList: tenantData.phoneCodeList,
          unitPosition: 'left',
        },
      },

      landline: {
        type: 'input-unit',
        validation: () => ({}),
        initialValue: '',
        value: '',
        props: {
          label: 'Landline',
          prefixIcon: 'user',
          labelIcon: 'map',
          placeholder: 'Enter Landline',
          unitList: tenantData.phoneCodeList,
          unitPosition: 'left',
        },
      },
      address: {
        type: 'input',
        validation: () => ({}),
        initialValue: '',
        value: '',
        props: {
          label: 'Address',
          placeholder: 'Enter Address',
          maxLength: 250,
        },
      },
      country: {
        type: 'select',
        validation: () => ({}),
        initialValue: '',
        value: '',
        props: {
          label: 'Country',
          prefixIcon: 'user',
          labelIcon: 'map',
          placeholder: 'Select Country',
          options: [
            { id: 1, name: 'Marvel' },
            { id: 2, name: 'Kevlar' },
          ],
        },
      },
      city: {
        type: 'select',
        validation: () => ({}),
        initialValue: '',
        value: '',
        props: {
          label: 'City',
          prefixIcon: 'user',
          labelIcon: 'map',
          placeholder: 'Select City',
          options: [
            { id: 1, name: 'Marvel' },
            { id: 2, name: 'Kevlar' },
          ],
        },
      },
      about: {
        type: 'input',
        validation: () => ({}),
        initialValue: '',
        value: '',
        props: {
          label: 'About',
          placeholder: 'A little about yourself',
          maxLength: 250,
        },
      },
      image: {
        type: 'image-select',
        validation: () => ({}),
        initialValue: '',
        value: '',
        props: {
          label: 'Upload a picture',
          placeholder: 'Browse and Upload',
          attachmentType: 'user_profile',
        },
      },
    },
  };

  if (userId) {
    let response = await NetworkService.get(`/api/users/${userId}`);
    if (response.status && response.data.data) {
      return {
        ...response.data.data,
        formFields,
      };
    } else
      return {
        error: response.error,
        formFields,
      };
  }
  return { formFields };
};
