import { NetworkService } from '../../../../services/networkService';
import tenantData from '@data';

export const makePriceForPackage = (platform, product) => {
  const dependents = tenantData.productDependents?.[platform]?.[product?.key]?.split(',');
  let dependentsTotalPrice = 0;
  dependents?.forEach((dep) => {
    dependentsTotalPrice =
      parseInt(tenantData.quotaCreditProducts?.find((e) => e?.slug == dep)?.price) + dependentsTotalPrice;
  });
  const totalPackagePrice = dependentsTotalPrice
    ? dependentsTotalPrice + parseInt(product?.product_price)
    : parseInt(product?.product_price);
  return totalPackagePrice;
};

const activeProducts = async (headers) => {
  const response = await NetworkService.get(`/api/public/products`, null, headers);
  const productsByPlatform = { zameen: [], olx: [] };
  if (response) {
    if (response.error) {
      return response;
    } else if (!response?.data?.products || response?.data?.products?.length === 0) {
      return {
        platformProducts: productsByPlatform,
        products: [],
      };
    } else if (response?.data?.products) {
      const productList = response?.data?.products;
      productList &&
        productList?.length > 0 &&
        productList?.forEach((product) => {
          const { product_id, key: slug } = product;
          if (slug && slug.includes('olx')) {
            productsByPlatform.olx.push({
              ...tenantData.getListingActions(slug),
              ...product,
              product_id,
              price: makePriceForPackage('olx', product),
            });
          } else {
            productsByPlatform.zameen.push({
              ...tenantData.getListingActions(slug),
              ...product,
              product_id,
              price: makePriceForPackage('zameen', product),
            });
          }
        });
      return {
        platformProducts: productsByPlatform,
        products: productList.map((e) => ({ ...tenantData.getListingActions(e.key), ...e })),
      };
    }
  }
};

export default activeProducts;
