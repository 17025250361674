import { NetworkService } from "../../../../services/networkService";

export const createUpsellCart = async payload => {
  const response = await NetworkService.post(`/api/carts`, payload);

  if (response) {
    if (response.error) {
      return response;
    } else if (response?.data) {
      return response?.data;
    } else {
    }
  }
};
