import tenantData from '@data';
import { NetworkService } from '../../../../services/networkService';
import { mapQueryParams } from '../../../../utility/utility';

const dataMapper = (obj) => {
  const productsHash = {};
  tenantData.products.forEach((e) => {
    productsHash[e.slug] = e;
  });
  return obj.map((item) => {
    return {
      ...item,
      icon: productsHash[item?.product?.slug]?.icon,
      iconColor: productsHash[item?.product?.slug]?.iconColor,
      iconProps: productsHash[item?.product?.slug]?.iconProps,
      appliedDate: item?.performed_at,
      expiryDate: item?.listing?.expiry_date,
    };
  });
};

export const getConsumptionHistoryData = async (page, platforms, params, filtersList) => {
  const mappedParams = mapQueryParams(params, filtersList);
  const response = await NetworkService.get(`/api/credits/consumption_history?page=${page || 1}&${mappedParams}`);
  let data = {};

  if (response) {
    if (response.error) {
      return response;
    }
    platforms?.map((platform) => {
      if (response?.data?.credits_consumption_history?.[platform?.slug]) {
        data[platform?.slug] = {
          data: dataMapper(response?.data?.credits_consumption_history?.[platform?.slug]),
          pagination: response?.data?.pagination,
        };
      }
    });
  }
  return data;
};
