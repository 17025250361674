import tenantConstants from '@constants';
import tenantUtils from '@utils';
import moment from 'moment';
import { NetworkService } from '../../../../services/networkService';
import { getDateDiffInDays, getDateLabels, getVariousDates } from '../../../../utility/date';
import { getQueryString } from '../../../../utility/urlQuery';
import { getErrorAllResponse, getResultGroup } from '../../../../utility/utility';
const limit = 10;

const getPageData = (pageNumber, last_page, data) => {
  let start_index = (pageNumber - 1) * limit;
  let end_index = pageNumber == last_page ? data?.length : pageNumber * limit;

  return data.slice(start_index, end_index);
};

const findProductSum = (grouped_stats, dateKey, filteredProducts) => {
  const sum = filteredProducts.reduce((acc, item) => {
    if (grouped_stats?.[dateKey]?.[item]) {
      return acc + grouped_stats[dateKey][item];
    } else {
      return acc;
    }
  }, 0);

  return sum;
};
const setLeadsData = (key, allStats, productWise) => {
  let leadsData;
  if (productWise && key != 'all') {
    leadsData =
      productWise?.sum_phone_view_count + productWise?.sum_sms_view_count + productWise?.sum_chat_lead_count || 0;
  } else if (!productWise && key != 'all') {
    leadsData = 0;
  } else if (key == 'all') {
    leadsData = allStats?.sum_phone_view_count + allStats?.sum_sms_view_count + allStats?.sum_chat_lead_count || 0;
  }
  return leadsData;
};

const widgetParser = (responseData, dataSummary, params) => {
  const data = {
    id: 'zameen',
    slug: 'zameen',
    icon: 'IconZameen',
    title: 'Analytics',
    data_summary: tenantUtils.generateDataSummaryFor('zameen', ['calls', 'whatsapp', 'sms', 'emails']),
    data_breakdown: tenantUtils.generateSecondChartDataBreakdownFor('zameen', ['views', 'clicks', 'leads']),
    reach_data: [
      {
        key: 'views',
        value: dataSummary?.views,
        percentage: dataSummary?.leads_data?.views,
        label: 'Views',
        title: 'Views',
        icon: 'IoMdEye',
        iconProps: { color: '#28b16d' },
        hit: true,
        id: 0,
        growth: dataSummary?.leads_data?.views > 0,
        since_when: '',
      },
      {
        key: 'clicks',
        value: dataSummary?.clicks,
        percentage: dataSummary?.leads_data?.clicks,
        label: 'Clicks',
        title: 'Clicks',
        icon: 'HiCursorClick',
        iconProps: { color: '#f0a742' },
        hit: true,
        id: 0,
        growth: dataSummary?.leads_data?.clicks > 0,
        since_when: '',
      },
      {
        key: 'leads',
        value: dataSummary?.leads,
        percentage: dataSummary?.leads_data?.leads,
        label: 'Leads',
        title: 'Leads',
        icon: 'MdPhone',
        iconProps: { color: '#479eeb' },
        hit: true,
        growth: dataSummary?.leads_data?.leads > 0,
        since_when: '',
      },
    ],
  };

  if (!responseData && !dataSummary) {
    return data;
  }

  const { result_group, purpose } = params;
  let grouped_stats = responseData;
  const summarydata = data.data_summary;
  const breakdowndata = data.data_breakdown;
  summarydata.forEach((performanceMetric, i) => {
    summarydata[i].value = dataSummary?.[performanceMetric?.key] || 0;
    summarydata[i].percentage = dataSummary?.leads_data?.[performanceMetric?.key];
    summarydata[i].growth = dataSummary?.leads_data?.[performanceMetric.key] > 0;
  });
  const purposeTypes = breakdowndata.purposes.map((e) => e.key);
  const performanceBy = breakdowndata.performanceBy.map((e) => {
    return e.key;
  });
  const marked = breakdowndata.types.map((e) => e.key);
  const dateKeys = Object.keys(grouped_stats);
  const labels = dateKeys.map((e) => {
    if (result_group === 'dd') {
      return moment(e).format('MMM-DD');
    }
    if (result_group === 'ww' || result_group === 'mm') {
      return moment(grouped_stats[e].start_date).format('MMM-DD');
    }
    if (result_group === 'yy') {
      return moment(grouped_stats[e].start_date).format('MMM-DD, YY');
    }
  });
  breakdowndata['labels'] = labels;
  const currentPurposes = !purpose ? 'all' : purpose === 1 ? 'sale' : 'rent';
  const filteredProducts = marked.filter((item) => item != 'all');
  const dataKeysInitialData = {};
  marked.forEach((tab) => {
    dataKeysInitialData[tab] = [];
  });
  // purposeTypes.forEach(purposeKey => {
  performanceBy.forEach((performanceMetricKey) => {
    if (performanceMetricKey == 'leads') {
      let tabularData = {};
      summarydata.forEach((e) => {
        tabularData[e?.key] = { ...dataKeysInitialData };
      });
      dateKeys.forEach((dateKey) => {
        Object.keys(tabularData)?.forEach((tabKey) => {
          Object.keys(tabularData[tabKey]).forEach((key) => {
            if (key != 'all') {
              tabularData[tabKey][key] = [...(tabularData[tabKey][key] || []), grouped_stats?.[dateKey]?.[key] || 0];
            } else if (key == 'all') {
              tabularData[tabKey]['all'] = [
                ...(tabularData[tabKey]['all'] || []),
                findProductSum(grouped_stats, dateKey, filteredProducts) || 0,
              ];
            }
          });
        });
      });
      breakdowndata.data[performanceMetricKey] = tabularData;
    } else {
      marked.forEach((productKey) => {
        dateKeys.forEach((dateKey) => {
          breakdowndata.data[performanceMetricKey][productKey].push(
            productKey == 'all'
              ? findProductSum(grouped_stats, dateKey, filteredProducts) || 0
              : grouped_stats?.[dateKey]?.[productKey] || 0,
          );
        });
      });
    }
  });
  // });

  return data;
};

const tableParser = (responseData, params) => {
  const tableData = tenantUtils.reportsData['zameen'].leadTable();

  const dateKeys = Object.keys(responseData);

  const list = dateKeys.map((dateKey, i) => {
    const { leads, calls, emails, whatsapp, sms } = responseData?.[dateKey];
    return {
      key: `leads-${i}`,
      date: { value: dateKey },
      leads: { value: leads || 0 },
      calls: { value: calls || 0 },
      emails: { value: emails || 0 },
      whatsapp: { value: whatsapp || 0 },
      sms: { value: sms || 0 },
    };
  });
  const pagination = {
    from: params?.page ? (params?.page - 1) * limit + 1 : 1,
    to: params?.page ? params?.page * limit : 10,
    current_page: parseInt(params?.page ? params?.page : 1),
    per_page: limit,
    last_page: parseInt(list?.length / limit) + 1,
    total_pages: parseInt(list?.length / limit) + 1,
    total_records: list?.length,
  };

  return {
    list: getPageData(pagination?.current_page, pagination?.last_page, list?.reverse()),
    ...tableData,
    pagination: tenantUtils.getPaginationObject(pagination),
  };
};

const widgetParserOlx = (responseData, summaryData, params) => {
  const olxData = {
    id: 'olx',
    slug: 'olx',
    icon: 'IconOLX',
    title: 'Analytics',
    reach_data: [
      {
        key: 'views',
        value: summaryData?.aggregates?.sum_search_count,
        percentage: summaryData?.trends?.sum_search_count,
        label: 'Views',
        title: 'Views',
        icon: 'IoMdEye',
        iconProps: { color: '#28b16d' },
        hit: true,
        id: 0,
        growth: summaryData?.trends?.sum_search_count > 0,
        since_when: '',
      },
      {
        key: 'clicks',
        value: summaryData?.aggregates?.sum_view_count,
        percentage: summaryData?.trends?.sum_view_count,
        label: 'Clicks',
        title: 'Clicks',
        icon: 'HiCursorClick',
        iconProps: { color: '#f0a742' },
        hit: true,
        id: 0,
        growth: summaryData?.trends?.sum_view_count > 0,
        since_when: '',
      },
      {
        key: 'leads',
        value: summaryData?.aggregates?.sum_lead_count,
        // percentage: summaryData?.trends?.sum_lead_count,
        label: 'Leads',
        title: 'Leads',
        icon: 'MdPhone',
        iconProps: { color: '#479eeb' },
        hit: true,
        //growth: summaryData?.trends?.sum_lead_count > 0,
        since_when: '',
      },
    ],
    data_summary: tenantUtils.generateDataSummaryFor('olx', [
      'leads',
      'sum_phone_view_count',
      'sum_sms_view_count',
      'sum_chat_lead_count',
    ]),
    data_breakdown: tenantUtils.generateSecondChartDataBreakdownFor('olx', ['leads', 'views', 'clicks']),
  };

  if (!summaryData || !responseData) {
    return olxData;
  }

  const lead_data = olxData.data_summary;
  const lead_breakdown_data = olxData.data_breakdown;
  const { items: grouped_stats, total } = responseData;
  const purposeTypes = lead_breakdown_data.purposes.map((e) => e.key);
  const performanceBy = lead_breakdown_data.performanceBy.map((e) => e.key);
  const marked = lead_breakdown_data.types.map((e) => e.key);
  const dataKeysInitialData = {};
  marked.forEach((tab) => {
    dataKeysInitialData[tab] = [];
  });
  let labels;
  if (total === 0) {
    labels = getDateLabels(params?.start_date, params?.end_date).map((date) => moment(date).format('MMM-DD'));
    lead_breakdown_data['labels'] = labels;
    // purposeTypes.forEach(purposeType => {
    performanceBy.forEach((performanceMetricKey) => {
      if (performanceMetricKey == 'leads') {
        let tabularData = {};
        lead_data.forEach((e) => {
          tabularData[e?.key] = { ...dataKeysInitialData };
        });
        labels.forEach((dateKey) => {
          Object.keys(tabularData)?.forEach((tabKey) => {
            Object.keys(tabularData[tabKey]).forEach((key) => {
              tabularData[tabKey][key] = [...tabularData[tabKey][key], 0];
            });
          });
        });
        lead_breakdown_data.data[performanceMetricKey] = tabularData;
      } else {
        marked.forEach((product) => {
          labels.forEach((dateKey) => {
            lead_breakdown_data.data[performanceMetricKey][product].push(0);
          });
        });
      }
    });
    // });
    return olxData;
  }

  const dateKeys = Object.keys(grouped_stats);
  labels = dateKeys.map((e) => {
    return moment(e).format('MMM-DD');
  });
  lead_data.forEach((performanceMetric, i) => {
    let totalValue = 0;
    Object.keys(summaryData)
      .filter((key) => key !== 'trends')
      .forEach((productId) => {
        totalValue += summaryData?.[productId]?.[performanceMetric?.key] || 0;
      });

    lead_data[i].value = totalValue || 0;
    lead_data[i].percentage = summaryData?.['trends']?.[performanceMetric?.key];
    lead_data[i].growth = summaryData?.['trends']?.[performanceMetric?.key] > 0;
  });

  //TODO - Hard code set total lead key to sum to all leads values sum
  lead_data.forEach((performanceMetric, i) => {
    lead_data[0].value = Number(lead_data[0].value) + Number(lead_data[i].value) || 0;
  });

  //Setting Leads percentage as it will not be available in API
  let leadsPercentage = tenantUtils.calculateTotalChangePercentageLeads(
    lead_data[1].value,
    lead_data[2].value,
    lead_data[3].value,
    lead_data[1].percentage,
    lead_data[2].percentage,
    lead_data[3].percentage,
  );
  lead_data[0].percentage = leadsPercentage;
  lead_data[0].growth = leadsPercentage > 0;

  lead_breakdown_data['labels'] = labels;
  const currentPurposes =
    params.category_id.length === 2
      ? ['all']
      : params.category_id.map((e) => {
          return e === 1 ? 'sale' : 'rent';
        });

  // purposeTypes.forEach(purposeType => {
  performanceBy.forEach((performanceMetricKey) => {
    if (performanceMetricKey == 'leads') {
      let tabularData = {};
      lead_data.forEach((e) => {
        tabularData[e?.key] = { ...dataKeysInitialData };
      });
      Object.values(grouped_stats)?.forEach((value) => {
        Object.keys(tabularData)?.forEach((tabKey) => {
          Object.keys(tabularData[tabKey]).forEach((key) => {
            const obj = value?.product_wise?.find((productType) => {
              return productType?.ad_product === key;
            });
            if (tabKey == 'leads') {
              tabularData[tabKey][key] = [...(tabularData[tabKey][key] || []), setLeadsData(key, value, obj)];
            } else {
              const leadStatType = lead_data.find((e) => e.key == tabKey);
              if (obj && key != 'all') {
                tabularData[tabKey][key] = [...(tabularData[tabKey][key] || []), obj?.[leadStatType?.responseKey] || 0];
              } else if (!obj && key != 'all') {
                tabularData[tabKey][key] = [...(tabularData[tabKey][key] || []), 0];
              } else if (key == 'all') {
                tabularData[tabKey]['all'] = [
                  ...(tabularData[tabKey]['all'] || []),
                  value?.[leadStatType?.responseKey] || 0,
                ];
              }
            }
          });
        });
      });
      lead_breakdown_data.data[performanceMetricKey] = tabularData;
    } else {
      marked.forEach((product) => {
        dateKeys.forEach((dateKey) => {
          const statType = lead_breakdown_data.performanceBy.find((e) => e.key == performanceMetricKey);
          const olxProduct = grouped_stats[dateKey]?.product_wise?.find((item) => item.ad_product === product);
          lead_breakdown_data.data[performanceMetricKey][product].push(
            product == 'all'
              ? grouped_stats[dateKey]?.[statType?.responseKey] || 0
              : olxProduct?.[statType?.responseKey] || 0,
          );
        });
      });
    }
  });
  // });
  return olxData;
};

const tableParserOlx = (responseData, params) => {
  const tableDataOlx = tenantUtils.reportsData['olx'].leadTable();
  if (!responseData) {
    return { list: [], ...tableDataOlx };
  }
  const { items: grouped_stats, total } = responseData;
  if (!grouped_stats || grouped_stats?.length == 0) {
    const labels = getDateLabels(params?.start_date, params?.end_date);
    const list = labels.map((dateKey) => {
      return {
        key: `traffic-${dateKey}`,
        date: { value: dateKey },
        calls: { value: 0 },
        chats: { value: 0 },
        sms: { value: 0 },
        leads: { value: 0 },
      };
    });
    return { list, ...tableDataOlx };
  }
  const dateKeys = Object.keys(grouped_stats);
  const list = dateKeys.map((dateKey) => {
    const { sum_chat_lead_count, sum_phone_view_count, sum_sms_view_count } = grouped_stats[dateKey];
    return {
      key: `traffic-${dateKey}`,
      date: { value: dateKey },
      calls: { value: sum_phone_view_count || 0 },
      chats: { value: sum_chat_lead_count || 0 },
      sms: { value: sum_sms_view_count || 0 },
      leads: { value: sum_sms_view_count + sum_chat_lead_count + sum_phone_view_count || 0 },
    };
  });
  const pagination = {
    from: params?.page ? (params?.page - 1) * limit + 1 : 1,
    to: params?.page ? params?.page * limit : 10,
    current_page: parseInt(params.page ? params?.page : 1),
    per_page: limit,
    last_page: list?.length % limit > 0 ? parseInt(list?.length / limit) + 1 : parseInt(list?.length / limit),
    total_pages: list?.length % limit > 0 ? parseInt(list?.length / limit) + 1 : parseInt(list?.length / limit),
    total_records: list?.length,
  };

  return {
    list: getPageData(pagination?.current_page, pagination?.last_page, list.reverse()),
    ...tableDataOlx,
    pagination: tenantUtils.getPaginationObject(pagination),
  };
};

const leadsQueryObj = {
  include: ['sms', 'whatsappStats', 'emails', 'calls'],
  date_between: getVariousDates(29),
  trend: 1,
  without_pagination: 1,
  result_group: 'dd',
};
const makeZameenParams = (params, parseFor) => {
  if (parseFor === 'widget') {
    const paramsObj = {
      ...leadsQueryObj,
      ...(!!params?.date_between && {
        date_between: params.date_between,
        ...getResultGroup(getDateDiffInDays(params.date_between)),
      }),
      ...(!!params?.purpose && params.purpose !== 'all' && { purpose: params?.purpose === 'sale' ? 1 : 2 }),
      ...(!!params?.stat && { include: params.stat }),
    };
    return paramsObj;
  }

  return {
    ...leadsQueryObj,
    ...params,
    without_pagination: 0,
    trend: 0,
  };
};

const makeOlxParams = (params, parseFor) => {
  const dates = getVariousDates(29);
  const leadsQueryObjOlx = {
    start_date: dates[0],
    end_date: dates[1],
    category_id: [1, 2],
  };

  if (parseFor === 'widget') {
    const paramsObj = {
      ...leadsQueryObjOlx,
      ...(!!params?.date_between?.[0] && { start_date: params?.date_between[0] }),
      ...(!!params?.date_between?.[1] && { end_date: params?.date_between[1] }),
      ...(!!params?.purpose && params.purpose !== 'all' && { category_id: params?.purpose === 'sale' ? [1] : [2] }),
    };
    return paramsObj;
  }

  const paramsObj = {
    ...leadsQueryObjOlx,
    ...(!!params?.date_between && {
      start_date: params?.date_between.split(',')[0],
      end_date: params?.date_between.split(',')[1],
    }),
    ...(!!params?.[tenantConstants.VALUE_IN_ARRAY_KEY('purpose')] && {
      category_id: [params?.[tenantConstants.VALUE_IN_ARRAY_KEY('purpose')]],
    }),
    ...(!!params?.[tenantConstants.VALUE_IN_ARRAY_KEY('user_id')] && {
      user_ids: [...params?.[tenantConstants.VALUE_IN_ARRAY_KEY('user_id')].split(',')],
    }),
    limit,
    offset: 0,
    ...(!!params?.page && {
      offset: params.page === 1 ? 0 : (params.page - 1) * limit,
    }),
  };
  return paramsObj;
};

export const leadsSummaryApi = async (paramObj, parseFor, platformKey, user) => {
  const params = paramObj ? paramObj : tenantUtils.reportsData[platformKey].initialFilter.lead;
  const { user_id: userId, page, date_between = getVariousDates(30), stat: include = 'table_view', purpose } = params;

  const paramsObj = {
    start_date: typeof date_between === 'string' ? date_between?.split(',')?.[0] : date_between?.[0],
    end_date: typeof date_between === 'string' ? date_between?.split(',')?.[1] : date_between?.[1],
    group_by: getResultGroup(
      getDateDiffInDays(typeof date_between === 'string' ? date_between?.split(',') : date_between),
      true,
    )?.result_group,
    include: include === 'leads' ? 'views' : include,
    ...(page && { page: page }),
    ...(userId && { [`q[user_id_eq]`]: userId }),
    // ...(purpose && purpose != 'all' ? { [`purpose_id`]: purpose } : { [`purpose_id`]: JSON.stringify([1, 2]) }),
  };

  const olxParmasObj = {
    start_date: typeof date_between === 'string' ? date_between?.split(',')?.[0] : date_between?.[0],
    end_date: typeof date_between === 'string' ? date_between?.split(',')?.[1] : date_between?.[1],
    ...(userId && { [`q[user_id_eq]`]: userId }),
    // ...(purpose && purpose != 'all' ? { [`purpose_id`]: purpose } : { [`purpose_id`]: JSON.stringify([1, 2]) }),
  };

  const zameenParams = makeZameenParams(params, parseFor);

  if (platformKey === 'zameen') {
    if (parseFor === 'widget') {
      const widgetData = [
        NetworkService.get(
          `/api/dashboard/summary_by_date?${getQueryString(paramsObj)}&${
            !purpose || purpose == 'all'
              ? 'purpose_id[]=1&purpose_id[]=2'
              : `purpose_id[]=${purpose == 'sale' ? 1 : purpose == 'rent' ? 2 : purpose}`
          }&${!user?.is_agency_admin ? `q[user_id_eq]=${user?.id}` : ``}`,
        ),
        NetworkService.get(
          `/api/dashboard/zameen_analytics?start_date=${paramsObj?.start_date}&end_date=${paramsObj?.end_date}&${
            !purpose || purpose == 'all'
              ? 'purpose_id[]=1&purpose_id[]=2'
              : `purpose_id[]=${purpose == 'sale' ? 1 : purpose == 'rent' ? 2 : purpose}`
          }&${!user?.is_agency_admin ? `q[user_id_eq]=${user?.id}` : ``}`,
        ),
      ];
      const widgetDataResponse = await Promise.all(widgetData);
      const widgetDataError = getErrorAllResponse(widgetDataResponse);
      if (widgetDataError || !widgetDataResponse?.[1]?.data?.stats || !widgetDataResponse?.[0]?.data?.stats_summary) {
        return { error: widgetDataError || 'Network Error' };
      } else {
        let data = widgetDataResponse?.[0]?.data?.stats_summary;
        const summaryData = widgetDataResponse?.[1]?.data?.stats;
        return widgetParser(data, summaryData, zameenParams);
      }
    } else {
      const tableData = await NetworkService.get(
        `/api/dashboard/summary_by_date?${getQueryString({ ...paramsObj })}&${
          !purpose || purpose == 'all'
            ? 'purpose_id[]=1&purpose_id[]=2'
            : `purpose_id[]=${purpose == 'sale' ? 1 : purpose == 'rent' ? 2 : purpose}`
        }&${!user?.is_agency_admin ? `q[user_id_eq]=${user?.id}` : ``}`,
      );
      if (tableData) {
        if (tableData?.error) {
          return tableData;
        } else {
          return tableParser(tableData?.data?.stats_summary, paramsObj);
        }
      }
    }
  } else if (platformKey === 'olx') {
    const olxParams = makeOlxParams(params, parseFor);
    if (parseFor === 'widget') {
      const widgetData = [
        NetworkService.get(
          `/api/dashboard/olx_product_stats?${getQueryString(olxParmasObj)}&${
            !purpose || purpose == 'all'
              ? 'category_ids[]=1&category_ids[]=2'
              : `category_ids[]=${purpose == 'sale' ? 1 : purpose == 'rent' ? 2 : purpose}`
          }&${!user?.is_agency_admin ? `q[user_id_eq]=${user?.id}` : ``}`,
        ),
        NetworkService.get(
          `/api/dashboard/olx_analytics?${getQueryString({
            ...olxParmasObj,
            trends: true,
          })}&${
            !purpose || purpose == 'all'
              ? 'category_ids[]=1&category_ids[]=2'
              : `category_ids[]=${purpose == 'sale' ? 1 : purpose == 'rent' ? 2 : purpose}`
          }&${!user?.is_agency_admin ? `q[user_id_eq]=${user?.id}` : ``}`,
        ),
      ];

      const widgetDataResponse = await Promise.all(widgetData);
      const widgetDataError = getErrorAllResponse(widgetDataResponse);
      if (!!widgetDataError) {
        return {
          error: widgetDataError,
        };
      } else if (widgetDataResponse) {
        const data = widgetDataResponse?.[0]?.data?.stats;
        const stats = widgetDataResponse?.[1]?.data?.stats;
        const sum_lead_count =
          stats?.aggregates?.sum_phone_view_count +
          stats?.aggregates?.sum_sms_view_count +
          stats?.aggregates?.sum_chat_lead_count;
        const summaryData = { ...stats, aggregates: { ...stats?.aggregates, sum_lead_count } };
        return widgetParserOlx(data, summaryData, olxParams);
      }
    } else {
      const tableData = await NetworkService.get(
        `/api/dashboard/olx_product_stats?${getQueryString(olxParmasObj)}&${
          !purpose || purpose == 'all'
            ? 'category_ids[]=1&category_ids[]=2'
            : `category_ids[]=${purpose == 'sale' ? 1 : purpose == 'rent' ? 2 : purpose}`
        }&${!user?.is_agency_admin ? `q[user_id_eq]=${user?.id}` : ``}`,
      );
      if (tableData) {
        if (tableData?.error) {
          return tableData;
        } else {
          return tableParserOlx(tableData?.data?.stats, { ...olxParmasObj, page: params.page });
        }
      }
    }
  }
};
