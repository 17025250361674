import tenantApi from '@api';
import { getErrorString } from '../../utility/utility';
import store from '../store';

const setListingPerformanceTableData = (userId, params, currentState) => async (dispatch) => {
  dispatch({
    type: 'SET_LISTING_PERFORMANCE_TABLE_DATA',
    payload: { ...currentState, loading: true, error: null },
  });
  const response = await tenantApi.fetchListingPeformanceData(userId, params);
  if (response.error) {
    dispatch({
      type: 'SET_LISTING_PERFORMANCE_TABLE_DATA',
      payload: { error: getErrorString(response.error), loading: false },
    });
  } else {
    dispatch({
      type: 'SET_LISTING_PERFORMANCE_TABLE_DATA',
      payload: {
        list: response.list,
        pagination: response.pagination,
        loading: false,
        error: null,
        table: response?.table,
      },
    });
    const user = store.getState().loginUser?.user;
    if (!!user?.products?.platforms['olx'] && !!response?.list) {
      const olxStatsData = await tenantApi.olxListingPerformanceApi(userId, response.list,user);
      if (olxStatsData) {
        dispatch({ type: 'SET_OLX_LISTING_PERFORMANCE_TABLE_DATA', payload: { list: olxStatsData.list } });
      }
    }
  }
};

export { setListingPerformanceTableData };
