import { useEffect, useState } from 'react';
import { Input } from 'antd';
import styles from './index.module.less';
import DateFilter from '../dateFilter/DateFilter';
import TextInput from '../textInput/textInput';
import { DATE_TIME_FORMAT } from '../../../constants/formats';
import { Select } from '..';

export default function UnitSelect(props) {
  const {
    label,
    options,
    value,
    formItem,
    placeholder,
    getOptionLabel = (option) => option.label,
    getOptionValue = (option) => option.key,
    defaultOption,
    handleChange,
    useInternalState = true,
    componentType,
    inputType,
    disabled,
    loading,
    preventOnChange = true,
    className,
    getValues,
    splitBy = ',',
    selectMode,
    allowInput,
    dateEnabledKey,
    onPressEnter,
  } = props;
  const [option, setOption] = useState(options?.[0] || null);
  const [futureDateEnabled, setFutureDateEnabled] = useState();

  useEffect(() => {
    defaultOption && setOption(defaultOption);
  }, [defaultOption]);

  useEffect(() => {
    !defaultOption && options && setOption(options?.[0]);
  }, [options]);

  const disabledDate = (current) => {
    if (futureDateEnabled) {
      return null;
    }
    const minDate = new Date();
    const maxDate = new Date();
    minDate.setDate(minDate.getDate() - 500);
    return current && current > maxDate;
  };

  const getConvertedDateValue = (dates) => {
    return `${dates[0].startOf('day').format(DATE_TIME_FORMAT)},${dates[1].endOf('day').format(DATE_TIME_FORMAT)}`;
  };

  const getDateValueFromString = (value) => {
    if (value) {
      return value?.split(splitBy);
    }
    return undefined;
  };

  const getSelectedValue = () => {
    let currentSelectType = option;
    let selectedOption = value?.[currentSelectType?.key];
    return !!selectedOption ? selectedOption?.split(',') : [];
  };

  const getComponent = () => {
    switch (componentType) {
      case 'date':
        return (
          <DateFilter
            onChange={(dates) => {
              dates && handleChange(option.key, getConvertedDateValue(dates));
            }}
            disabledDate={disabledDate}
            value={getDateValueFromString(value?.[option?.value])}
            noDefault
            inputReadOnly
            {...option?.componentProps}
          />
        );
      case 'select':
        return (
          <Select
            value={getSelectedValue()}
            className="selectSearch togglesearch"
            style={{
              width: '100%',
            }}
            placeholder={placeholder ? placeholder : `Select ${option?.label}`}
            disabled={disabled}
            mode={selectMode}
            options={option?.list}
            loading={loading}
            allowClear
            onChange={(value, item) => {
              handleChange(option.key, value);
            }}
          />
        );
      default:
        return (
          <TextInput
            type={inputType || 'text'}
            value={formItem ? value.value : value?.[option?.key]}
            placeholder={placeholder ? placeholder : `Search By ${option?.label}`}
            useInternalState={useInternalState}
            disabled={disabled}
            loading={loading}
            maxLength={100}
            {...(preventOnChange && {
              ...(useInternalState
                ? {
                    handleBlur(e) {
                      handleChange(option.key, e.target.value);
                    },
                  }
                : {
                    handleChange(e) {
                      if (getValues) {
                        getValues({ key: option.value, value: e.target.value });
                      } else {
                        handleChange(option.key, e.target.value);
                      }
                    },
                  }),
            })}
            onPressEnter={(e) => {
              handleChange(option.key, e.target.value, true);
              onPressEnter();
            }}
            allowInput={allowInput}
            allowClear
          />
        );
    }
  };

  return (
    <div className={className}>
      <label>{label}</label>
      <Input.Group className={styles.unitSelect} compact>
        {getComponent()}
        <Select
          placeholder=""
          value={formItem && value.unit ? value.unit : option}
          options={options}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          disabled={disabled}
          onChange={(value, item) => {
            if (value === dateEnabledKey) {
              setFutureDateEnabled(true);
            } else {
              setFutureDateEnabled(false);
            }
            setOption(item);
            getValues && getValues((prev) => ({ ...prev, key: value }));
            handleChange(item.key, value.value);
          }}
          dropdownMatchSelectWidth={false}
        />
      </Input.Group>
    </div>
  );
}
