import { NetworkService } from "../../../../services/networkService";
import { getErrorString } from '../../../../utility/utility';

const checkout = async cart_id => {
  try {
    const response = await NetworkService.post(`/api/orders/checkout`, { cart_id });
    const checkoutData = response.data?.data;
    if (response.error) {
      return {
        error: response.error,
      };
    } else {
      return { data: checkoutData };
    }
  } catch (e) {
    return {
      error: getErrorString(e),
    };
  }
};

export default checkout;
