import { NetworkService } from '../../../../services/networkService';
const getLicense = async id => {
  let response = await NetworkService.get(`api/users/${id}/licenses`);
  if (response.error) {
    return {
      error: response.error,
    };
  } else {
    return response;
  }
};
export default getLicense;
