import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, TextInput, TextWithIcon, notification } from '../../../../components/common';
import tenantTheme from '@theme';
import tenantApi from '@api';
import { GenerateTitle, TextInputRTL } from './styled';
import { generateTitleClickEvent, generateDescriptionClickEvent } from '../../../../services/analyticsService';
const GenerateContentField = (props) => {
  const {
    formik,
    setSubmitDisable,
    lineCount,
    limit,
    name,
    label,
    labelIcon,
    placeholder,
    dir,
    payloadKey,
    desc,
    skipField,
  } = props;

  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(null);
  const { [name]: field, [skipField]: fieldToSkip, ...rest } = formik.values;
  const { user } = useSelector((state) => state.loginUser);
  useEffect(() => {
    isChanged != null && setIsChanged(true);
  }, [JSON.stringify(rest)]);

  useEffect(() => {
    isChanged != null && setIsChanged(false);
  }, [field]);

  const fetchAutoGeneratedContent = async () => {
    setLoading(true);
    setSubmitDisable(true);
    const payload = await tenantApi.autoGeneratedContentPayload(formik?.values, payloadKey);
    const response = await tenantApi.fetchAutoGeneratedContent(formik?.values?.id, payload);
    if (response) {
      desc === 'title'
        ? generateTitleClickEvent(user, response, formik.values)
        : generateDescriptionClickEvent(user, response, formik.values);
      setLoading(false);
      setSubmitDisable(false);
      if (response?.error) {
        notification.error(response?.error);
      } else {
        formik.setFieldValue(name, response?.content, true);
        setIsChanged(false);
      }
    }
  };

  const renderReviewMessage = () => {
    return (
      isChanged && (
        <TextWithIcon
          gap="4px"
          className={isMobile ? 'fz-10' : 'fz-14'}
          align={isMobile && 'baseline'}
          textColor={tenantTheme['warning-color']}
          icon="RiErrorWarningFill"
          iconProps={{ color: tenantTheme['warning-color'], size: isMobile && '10px' }}
          title={t(`Please review the ${desc} after editing the listing`)}
        />
      )
    );
  };

  const renderGenerateButton = () => {
    return (
      <GenerateTitle align="center" justify="space-between" gap={isMobile && '2px'}>
        <Button
          // className="generate-btn"
          shape="round"
          icon="BsStars"
          iconProps={{ size: isMobile ? '10px' : '12px' }}
          type="primaryOutlined"
          size="small"
          style={{
            fontSize: isMobile && '10px',

            border: 0,
            marginInlineStart: 'auto',
          }}
          disabled={loading}
          loading={loading}
          onClick={fetchAutoGeneratedContent}
        >
          {t(`Generate`) + ` ${label}`}
        </Button>
      </GenerateTitle>
    );
  };

  const renderField = () => (
    <TextInput
      dir={dir}
      key={name}
      name={name}
      label={label}
      labelIcon={labelIcon}
      placeholder={placeholder}
      useInternalState
      handleChange={formik?.handleChange}
      handleBlur={(event) => {
        formik.setFieldTouched(name, true);
        formik.setFieldValue(name, event.target.value, true);
      }}
      value={formik?.values[name]}
      disabled={loading}
      errorMsg={formik?.errors[name] && formik?.touched[name] && formik?.errors[name]}
      style={{ borderColor: isChanged && tenantTheme['warning-color'] }}
      lineCount={lineCount}
      limit={limit}
      extra={!formik?.errors[name] && renderReviewMessage}
      button={renderGenerateButton}
    />
  );

  return dir == 'rtl' ? <TextInputRTL>{renderField()}</TextInputRTL> : renderField();
};

export default GenerateContentField;
