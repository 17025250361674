import tenantConstants from '@constants';
import tenantData from '@data';
import tenantUtils from '@utils';
import { Typography } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AddAmenities, ImageSelect } from '..';
import TenantComponents from '../../../tenant/components';
import GenerateContentField from '../../../tenant/components/bayut/post-listing/GenerateContentField';
import { skipFieldsForField } from '../../../tenant/components/bayut/post-listing/listing-form-data';
import { PriceCheckValue } from '../../../tenant/components/bayut/post-listing/styled';
import { Flex, Group, Icon, Number, Popover, RadioButtons, Select, TextInput } from '../../common';
import { IconStyled } from '../../common/icon/IconStyled';
import CreditInfo from '../../credits-info/credits-info';
import MobileVerification from '../../mobile-number-verification/mobile-verification';
import PostListingSkeleton from './post-listing-from-skeleton';

const { Text } = Typography;

const PostListingForm = (props) => {
  const {
    loading,
    formik,
    isMobile,
    setLoading,
    users = [],
    listingPurposeId,
    areaMaxLength,
    tooltipText,
    listingType,
    bedroomList,
    bathRoomsList,
    isCreditsAvailable,
    clearUserSelections = () => {},
    getLocationsLatLong = () => {},
    nationalAddressLoading,
    alreadyPosted,
  } = props;
  const { user } = useSelector((state) => state.loginUser);
  const { locale } = useSelector((state) => state?.AppConfig);

  const { t } = useTranslation();

  const propertyTypeButtonList = useMemo(() => {
    const propertyTypeobject = tenantData.listingTypes().reduce((acc, data) => {
      acc[data.id] = data.sub_types.map((subType) => ({
        ...subType,
        key: subType.id,
        label: tenantUtils.getLocalisedString(subType, 'title'),
      }));
      return acc;
    }, {});

    return propertyTypeobject;
  }, []);

  const renderInputPopOver = () => {
    return (
      <Popover
        placement="top"
        content={<div style={{ maxWidth: '300px', width: '100%' }}>{tooltipText}</div>}
        action="hover"
      >
        <>
          <Icon icon="AiOutlineInfoCircle" iconProps={{ color: '#222' }} />
        </>
      </Popover>
    );
  };

  return (
    <>
      <PostListingSkeleton icon="PropertyInformationIcon" title={t('Property Information')}>
        <Group gap="32px" style={{ maxWidth: 640 }}>
          <RadioButtons
            name="property_type"
            key="property_type"
            shape="round"
            label={t('Select Property Type')}
            labelIcon="BiBuildingHouse"
            value={formik?.values['property_type']}
            handleChange={(e) => {
              clearUserSelections();
              formik.setFieldValue('property_type', e?.target?.value, true);
              const selectedPropertyType = propertyTypeButtonList[listingPurposeId]?.find(
                (data) => data?.id == e?.target?.value,
              );
              formik.setFieldValue('property_type_label', selectedPropertyType?.title?.en);
            }}
            errorMsg={
              formik.errors['property_type'] && formik.touched['property_type'] && formik.errors['property_type']
            }
            buttonList={propertyTypeButtonList[listingPurposeId]}
            disabled={alreadyPosted}
          />

          {!skipFieldsForField?.['bedrooms'].property_type[
            formik.values['sub_listing_type']?.id || formik.values['property_type']
          ]?.skipField && (
            <RadioButtons
              name="bedrooms"
              shape="round"
              key="bedrooms"
              label={t('Number of Bedrooms')}
              inputLeftIcon="user"
              labelIcon="LuBedDouble"
              value={formik?.values['bedrooms']}
              handleChange={(e) => {
                formik.setFieldValue('bedrooms', e?.target?.value, true);
              }}
              buttonList={bedroomList}
              errorMsg={formik.errors['bedrooms'] && formik?.touched['bedrooms'] && formik.errors['bedrooms']}
            />
          )}
          {!skipFieldsForField?.['bathrooms'].property_type[
            formik.values['sub_listing_type']?.id || formik.values['property_type']
          ]?.skipField && (
            <RadioButtons
              name="bathrooms"
              shape="round"
              key="bathrooms"
              label={t('Number of Bathrooms')}
              inputLeftIcon="user"
              labelIcon="BiBath"
              value={formik?.values['bathrooms']}
              handleChange={(e) => {
                formik.setFieldValue('bathrooms', e?.target?.value, true);
              }}
              buttonList={bathRoomsList}
              errorMsg={formik.errors['bathrooms'] && formik?.touched['bathrooms'] && formik.errors['bathrooms']}
            />
          )}
          <TextInput //ToDo use TextInput With Unit
            key="area"
            name="area"
            label={t('Area Size')}
            labelIcon="IconAreaSize"
            placeholder={t('e.g., 800')}
            useInternalState
            handleChange={(event) => {
              formik.setFieldValue('area', event.target.value, true);
            }}
            suffix={<span className="color-gray-dark">{t(tenantData.areaUnitList[0]?.title_short)}</span>}
            handleBlur={(event) => {
              formik.setFieldTouched('area', true);
              formik.setFieldValue('area', event.target.value, true);
            }}
            maxLength={areaMaxLength}
            value={formik?.values['area']}
            skeletonLoading={loading}
            errorMsg={formik?.errors['area'] && formik?.touched['area'] && formik?.errors['area']}
          />
        </Group>
      </PostListingSkeleton>
      <PostListingSkeleton icon="IconLocationPurpose" title={t('Property Location')}>
        <Group gap="32px" style={{ maxWidth: 640 }}>
          {tenantConstants.SHOW_NATIONAL_ADDRESS && (
            <TextInput
              key="national_address"
              name="national_address"
              label={t('National Address')}
              maxLength={8}
              labelIcon="IconNavigate"
              placeholder={t('e.g.,RRRR2929')}
              handleChange={(event) => {
                formik.setFieldValue('national_address', event.target.value, true);
              }}
              handleBlur={() => {
                formik.setFieldTouched('national_address', true);
                if (formik?.values['national_address'] && !formik?.errors['national_address']) {
                  getLocationsLatLong(formik?.values['national_address']);
                }
              }}
              renderPopover={() => renderInputPopOver()}
              value={formik?.values['national_address']}
              skeletonLoading={loading}
              errorMsg={
                formik?.errors['national_address'] &&
                formik?.touched['national_address'] &&
                formik?.errors['national_address']
              }
              loading={nationalAddressLoading}
            />
          )}
          <TenantComponents.LocationSelect
            name="Location_select"
            formik={formik}
            setFieldValue={formik.setFieldValue}
            setFieldTouched={formik.setFieldTouched}
            value={formik?.values['location-info']}
            error={formik.errors?.['location-info']}
            touched={formik.touched?.['location-info']}
            onBlur={formik.handleBlur}
            item={{ key: 'location-info' }}
            onCityChange={(option) => {}}
            disabled={{
              city: formik?.values?.location?.city_id,
            }}
            renderCrossCityAlerts={() => {}}
            hideLocation={false}
            onLocationSelect={() => {}}
            user={user}
            showPlot={true}
          />
        </Group>
      </PostListingSkeleton>

      <PostListingSkeleton icon="IconImagesPost" title={t('Property Images')}>
        <Group gap="32px" style={{ maxWidth: 640 }}>
          <ImageSelect
            labelIcon="LuImage"
            label={t('Images')}
            values={formik.values}
            setFieldValue={formik.setFieldValue}
            errorMsg={
              formik.errors['property_images'] && formik.touched['property_images'] && formik.errors['property_images']
            }
            valueKey={'property_images'}
            filesAllowed={tenantData.allowedFilesType}
            imageBankIcon={false}
            showImageBank={false}
          />
        </Group>
      </PostListingSkeleton>

      <PostListingSkeleton icon="IconAdInformation" title={t('Price & Features')}>
        <Group gap="32px" style={{ maxWidth: 640 }}>
          <TextInput
            key="price"
            name="price"
            label={t('Price') + ' ' + `(${listingType})`}
            labelIcon="RiPriceTag3Line"
            useInternalState
            placeholder={t('Enter Price')}
            handleChange={(event) => {
              formik.setFieldValue('price', event.target.value, true);
            }}
            suffix={<span className="color-gray-dark">{t(tenantConstants.currency)}</span>}
            handleBlur={(event) => {
              formik.setFieldTouched('price', true);
              formik.setFieldValue('price', event.target.value, true);
            }}
            value={formik?.values['price']}
            skeletonLoading={loading}
            errorMsg={formik?.errors['price'] && formik?.touched['price'] && formik?.errors['price']}
            extra={() => {
              return formik?.values['price'] && !formik?.errors['price'] ? (
                <PriceCheckValue>
                  <Number
                    type="price"
                    className={'text-muted'}
                    value={formik?.values['price']}
                    tooltip={false}
                    compact={false}
                  />
                </PriceCheckValue>
              ) : null;
            }}
          />
          <AddAmenities
            name="feature_and_amenities"
            handleChange={(values) => {
              formik.setFieldValue('feature_and_amenities', values);
            }}
            handleBlur={formik?.handleBlur}
            value={formik?.values['feature_and_amenities']}
            skeletonLoading={loading}
            key="feature_and_amenities"
            labelIcon="MdOutlineOtherHouses"
            bodyStyle={{ paddingBlockStart: 'initial' }}
            payloadKey={formik.values?.['property_type']}
            purposeId={listingPurposeId}
          />
          <GenerateContentField
            formik={formik}
            setSubmitDisable={setLoading}
            labelIcon="PiTextT"
            desc={'title'}
            skipField={locale == 'en' ? 'property_description_en' : 'property_description_ar'}
            name={locale == 'ar' ? 'property_title_ar' : 'property_title_en'}
            dir={locale == 'ar' ? 'rtl' : ''}
            label={locale == 'ar' ? 'عنوان' : 'Title'}
            placeholder={
              locale == 'ar'
                ? 'أدخل العنوان، على سبيل المثال، منزل جديد جميل...'
                : 'Enter title e.g Beautiful new house...'
            }
            payloadKey={locale == 'en' ? 'title' : 'title_ar'}
          />

          <GenerateContentField
            formik={formik}
            setSubmitDisable={setLoading}
            labelIcon="BiDetail"
            lineCount={isMobile ? 3 : 5}
            limit={2500}
            desc={'description'}
            skipField={locale == 'en' ? 'property_title_en' : 'property_title_ar'}
            payloadKey={locale == 'en' ? 'description' : 'description_ar'}
            name={locale == 'ar' ? 'property_description_ar' : 'property_description_en'}
            dir={locale == 'ar' ? 'rtl' : ''}
            label={locale == 'ar' ? 'وصف' : 'Description'}
            placeholder={locale == 'ar' ? 'صف عقارك بالتفصيل' : 'Describe your property in detail'}
          />
        </Group>
      </PostListingSkeleton>

      <PostListingSkeleton icon="IconContactInfo" title={t('Contact Information')}>
        <Group gap="32px" style={{ maxWidth: 640 }}>
          {user?.is_agency_admin && (
            <Select
              name="posting_as"
              key="posting_as"
              label={t('Posting As')}
              labelIcon="FiUser"
              placeholder={t('Select User')}
              value={tenantUtils.getLocalisedString(formik?.values['posting_as'], 'name')}
              onBlur={() => formik?.setFieldTouched('posting_as', true)}
              options={users}
              getOptionLabel={(op) => tenantUtils.getLocalisedString(op, 'name')}
              getOptionValue={(op) => op.id}
              disabled={true}
            />
          )}

          <Group template="max-content auto" gap="16px">
            <MobileVerification
              name={`mobile`}
              placeholder={t('Enter Phone Number')}
              defaultCountry="SA"
              value={formik?.values['mobile']}
              onChange={(value) => {
                formik.setFieldValue(`mobile`, !!value ? value : '', true);
              }}
              errorMsg={formik.errors['mobile'] && formik.touched['mobile'] && formik.errors['mobile']}
              touched={formik.touched.mobile}
              onBlur={() => formik.setFieldTouched(`mobile`, true)}
              label={t('Phone Number')}
              labelIcon={'MdSmartphone'}
              isMobile={isMobile}
              userType={'User'}
              isUserVerified={!!user?.is_mobile_verified}
              disabled={user?.mobile && !!user?.is_mobile_verified}
              containerClassName={'pos-rel'}
              userId={user?.id}
              countrySelectProps={{ disabled: true }}
            />
          </Group>
        </Group>
      </PostListingSkeleton>
      {isCreditsAvailable && (
        <PostListingSkeleton icon="IconPackageCredit" title={t('Package & Credits Info')}>
          {isMobile && (
            <>
              <Flex gap="16px" align="start">
                <IconStyled>
                  <Icon icon="IconCredit" />
                </IconStyled>
                <Flex vertical gap="8px" className="w-100">
                  <Text strong>{t('Package & Credits Info')}</Text>
                </Flex>
              </Flex>
            </>
          )}
          <CreditInfo
            isMobile={isMobile}
            loading={loading}
            cardStyle={{ marginInlineStart: '48px', width: !isMobile && '594px', borderWidth: 1 }}
          />
        </PostListingSkeleton>
      )}
    </>
  );
};

export default PostListingForm;
