import tenantApi from '@api';
import tenantUtils from '@utils';
import { Card, Carousel, Typography } from 'antd';
import cx from 'clsx';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Flex,
  Group,
  Heading,
  Segmented,
  Skeleton,
  notification,
} from '../../../../../components/common/index.js';
import { BannerContainer } from '../../../../../components/styled.js';
import { useGetLocation, useRouteNavigate } from '../../../../../hooks';
import { packageMonthClickEvent } from '../../../../../services/analyticsService/index.js';
import { convertQueryObjToString, mapQueryStringToFilterObject } from '../../../../../utility/urlQuery.js';
import { CreditTopUps } from '../credit-top-ups/creditTopUps.js';
import { CardPackages } from '../styled.js';
import { PackageCard } from './packageCard.js';
import { PackageCardMobile } from './packageCardMobile.js';
import { PackageCardList } from './styled.js';

const { Text } = Typography;

const packageDurations = () => [
  { key: 12, label: t('1 Year'), value: 12 },
  { key: 6, label: t('6 Months'), value: 6 },
];

const PackagesCardSkeleton = () => {
  const { isMemberArea } = useSelector((state) => state.AppConfig);
  const items = isMemberArea ? [1, 2, 3, 4] : [1, 2, 3, 4, 5];
  return (
    <Group template={`repeat(${items?.length}, 1fr)`}>
      {items.map(() => (
        <Skeleton style={{ height: '600px', width: '230px' }} />
      ))}
    </Group>
  );
};

const PackageCardSkeletonMobile = () => {
  return <Skeleton style={{ height: '500px', width: '100%' }} />;
};

const OfferedPackages = (props) => {
  const carouselRef = useRef(null);
  const containerRef = useRef(null);
  const navigate = useRouteNavigate();
  const location = useGetLocation();

  const { redirectUrl = '' } = mapQueryStringToFilterObject(location.search)?.queryObj;

  const { isMobile, rtl, isMemberArea } = useSelector((state) => state.AppConfig);

  const [currentSlide, setCurrentSlide] = useState(rtl ? 3.1 : 0);
  const [activePackageDuration, setActivePackageDuration] = useState(packageDurations()[0]);
  const [packageData, setPackageData] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isError, setIsError] = useState();
  const { user } = useSelector((state) => state.loginUser);
  const { quotaCreditsData } = useSelector((state) => state.Dashboard);

  const getPackagesData = async () => {
    setIsLoading(true);
    const response = await tenantApi.getPackagesData({ rtl: rtl });
    if (response) {
      setIsLoading(false);
      if (response.error) {
        setIsError(response.error);
      } else {
        setPackageData(response);
      }
    }
  };

  const go = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.goTo(3.1, true);
    }
  }, []);
  const goToSlide = useCallback(
    (slideNumber, dontAnimate = false) => {
      const maxSlides = 7;
      let newSlideNumber = currentSlide + slideNumber;
      setCurrentSlide(newSlideNumber);
      // Ensure the new slide number stays within the bounds
      if (newSlideNumber < 0) {
        newSlideNumber = 0;
      } else if (newSlideNumber > maxSlides) {
        newSlideNumber = maxSlides;
      }
      setCurrentSlide(newSlideNumber);
      // if (carouselRef.current) {
      carouselRef?.current?.goTo(newSlideNumber, dontAnimate);
      // }
    },
    [currentSlide, packageData, activePackageDuration, carouselRef],
  );

  useEffect(() => {
    getPackagesData();
    if (!rtl) {
      go();
    }
  }, [rtl, go]);

  const handlePackageDurationChange = (value) => {
    rtl && goToSlide(3.1);
    !rtl && setCurrentSlide(0);
    setActivePackageDuration(packageDurations().find((e) => e.key == value));
    packageMonthClickEvent(user, value);
  };

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: isMemberArea ? 4 : 5,
      slidesToScroll: 1,
      rtl: rtl,
      currentSlide: 8,
      responsive: [
        { breakpoint: 1366, settings: { slidesToShow: 4, slidesToScroll: 1, dots: true } },
        { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1, dots: true } },
        { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      ],
    }),
    [containerRef?.current],
  );

  const onGetPackageClick = async (id, onSuccess = () => {}) => {
    let cart;
    if (id) {
      cart = {
        source: 'profolio',
        cart_details_attributes: [{ item_id: id, source: 'profolio', item_type: 'Package' }],
      };
    }
    const response = await tenantApi.createCart(cart);
    if (response) {
      onSuccess();
      if (response.error) {
        notification.error(response.error);
      } else {
        navigate(
          `/checkout?${convertQueryObjToString({
            cart_id: response?.id,
            ...(redirectUrl && { redirectUrl }),
          })}`,
        );
      }
    }
  };

  const renderDesktop = () => (
    <CardPackages template="200px minmax(0, 1fr)" className="mb-0" isRtl={rtl}>
      <div>
        <Text type="secondary" className="d-block fz-14 mb-4">
          {t('Package Duration')}
        </Text>
        <Segmented
          size="small"
          key="packageDurations"
          name="packageDurations"
          value={activePackageDuration.key}
          options={packageDurations()}
          onChange={handlePackageDurationChange}
        />
        <div style={{ marginBlockStart: '152px' }}>
          <Heading as="h5" className="mb-32">
            {t('Benefits')}
            {''}
            <span className="color-gray-dark fz-12 fw-400"> /{t(' month')}</span>
          </Heading>
        </div>
        {packageData?.benefits?.map((benefit, index) => (
          <div key={index} style={{ height: '33px' }}>
            {tenantUtils.getLocalisedString(benefit, 'name')}
          </div>
        ))}
      </div>
      <div style={{ position: 'relative' }}>
        <div className="card-container">
          <Carousel ref={carouselRef} dots={false} {...settings} rtl={rtl}>
            {isLoading ? (
              <PackagesCardSkeleton />
            ) : (
              packageData?.packageData[activePackageDuration.key]?.map((pkg, index) => (
                <PackageCard
                  key={pkg.id}
                  packageData={pkg}
                  onGetPackageClick={onGetPackageClick}
                  refundable_amount={packageData?.refundable_amount}
                  packageDuration={activePackageDuration?.label}
                />
              ))
            )}
          </Carousel>
        </div>
        <Button
          type="primaryOutlined"
          icon="IoIosArrowForward"
          className="next-btn flipX"
          disabled={rtl ? (currentSlide > 0 ? false : true) : currentSlide > 0 ? true : false}
          onClick={() => goToSlide(rtl ? -3.1 : 3.1)}
          style={{ border: 0, borderRadius: '50%' }}
        />
        <Button
          style={{ borderRadius: '50%', border: 0 }}
          type="primaryOutlined"
          icon="IoIosArrowBack"
          className="prev-btn flipX"
          disabled={rtl ? (currentSlide > 0 ? true : false) : currentSlide > 0 ? false : true}
          onClick={() => goToSlide(rtl ? 3.1 : -3.1)}
        />
      </div>
    </CardPackages>
  );

  const renderMobile = () => (
    <CardPackages isRtl={rtl}>
      <Flex justify="space-between" align="center" className="mb-12">
        <div>
          <Text type="secondary" className="d-block fz-14 mb-4">
            {t('Package Duration')}
          </Text>

          <Segmented
            size="small"
            key="packageDurations"
            name="packageDurations"
            value={activePackageDuration.key}
            options={packageDurations()}
            onChange={handlePackageDurationChange}
          />
        </div>

        <Flex gap="12px">
          <Button
            className="flipX"
            type="primaryOutlined"
            icon="IoIosArrowBack"
            onClick={() => goToSlide(rtl ? 1 : -1)}
            style={{ border: 0, borderRadius: '50%' }}
          />
          <Button
            className="flipX"
            type="primaryOutlined"
            icon="IoIosArrowForward"
            onClick={() => goToSlide(rtl ? -1 : 1)}
            style={{ border: 0, borderRadius: '50%' }}
          />
        </Flex>
      </Flex>
      {!packageData ? (
        <PackageCardSkeletonMobile />
      ) : (
        <Carousel ref={carouselRef} slidesToShow={1.5} slidesToScroll={1} {...settings}>
          {packageData?.packageData[activePackageDuration.key]?.map((pkg) => (
            <PackageCardMobile
              key={pkg.id}
              packageData={pkg}
              onGetPackageClick={onGetPackageClick}
              refundable_amount={packageData?.refundable_amount}
            />
          ))}
        </Carousel>
      )}
    </CardPackages>
  );

  return (
    <PackageCardList>
      <Card className="packages-card mb-8" style={{ borderWidth: 0 }} bodyStyle={{ padding: 16 }}>
        {(isMemberArea || !user?.package) && (
          <BannerContainer
            color="#71A7AC"
            breakTag={false}
            className={'package-banner'}
            title={t('Get a business package and enjoy exclusive benefits')}
            listColor="#fff"
            titleColor="#fff"
            titleFontWeight="500"
            listItems={false}
            subtitle={!isMobile && t('Access Profolio, manage agency staff, view lead reports and much more')}
            titleAsH1
          />
        )}
        {!isMemberArea && user?.package && (
          <Heading
            className={cx('text-primary ', isMobile ? 'mb-8' : 'mb-20')}
            style={{ fontWeight: '800' }}
            as={isMobile ? 'h5' : 'h3'}
          >
            {quotaCreditsData?.data?.ksa?.current_package_details?.next_disbursement_date
              ? t('Upgrade your Package to get More Benefits')
              : t('Get a Package According to Your Business Needs')}
          </Heading>
        )}
        {isMobile ? renderMobile() : renderDesktop()}
      </Card>

      <CreditTopUps redirectUrl={redirectUrl} />
    </PackageCardList>
  );
};
export default OfferedPackages;
