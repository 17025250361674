import { NetworkService } from '../../../../services/networkService';
import tenantUtils from '@utils';

export const getMailbox = async (type, params, userId) => {
  const response = await NetworkService.get(`/api/conversations`, {
    ...(type == 'deleted' && { is_trashed: true }),
    ...params,
    ...(userId && userId != -1 && { [`q[user_id_eq]`]: userId }),
  });
  if (response?.error) {
    return response;
  } else {
    return {
      items: response?.data?.conversations,
      pagination: {
        ...tenantUtils.getPaginationObject(response?.data?.pagination),
      },
    };
  }
};

export const deleteMailThread = async (userId, ids) => {
  const response = await NetworkService.delete(`/api/conversations/${ids}`);
  return response;
};

export const UntrashThead = async (id) => {
  const response = await NetworkService.patch(`/api/conversations/${id}/untrash`);
  return response;
};

export const getMessageDetail = async (userId, messageId) => {
  const response = await NetworkService.get(`/api/conversations/${messageId}`);
  if (response) {
    if (response?.error) {
      return response;
    } else {
      return response?.data;
    }
  }
};

export const postReply = async (message, messageId) => {
  const response = await NetworkService.patch(`/api/conversations/${messageId}/send_message`, {
    message: { body: message },
  });
  if (response) {
    if (response?.error) {
      return response;
    } else {
      return response?.data?.conversation;
    }
  }
};

export const getMailCount = async (userId) => {
  const response = await NetworkService.get(`/api/conversations/unread_conversations_count`, {
    ...(userId && userId != -1 && { [`q[user_id_eq]`]: userId }),
  });
  if (response) {
    if (response.error) {
      return response;
    } else {
    }
  }
  return response;
};
