import tenantApi from '@api';
import { NetworkService } from '../../../../services/networkService';

export const changePassword = async (user, values) => {
  const response = await NetworkService.post(`/api/users/${user?.id}/change_password`, tenantApi.userPassword(values));
  if (response) {
    if (response.error) {
      return response;
    } else {
      return response.data;
    }
  }
};
