import { formatMobile } from "../../../../../utility/utility";
import { imageStateObject } from '../../../../../helpers/imageHelpers/imageStateObject';

export const getAgencyStaffUserValues = values => {
  if (!values) return null;
  return {
    name: values?.name,
    email: values?.email,
    mobile: values?.mobile ? formatMobile(values?.mobile, 'singleNumber') : '',
    landline: values?.phone ? formatMobile(values?.phone, 'singleNumber') : '',
    address: values?.address,
    city: values?.city_id,
    image: values?.profile_image && [{ gallerythumb: values?.profile_image, ...imageStateObject() }],
  };
};
