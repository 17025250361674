import React from 'react';
import { FooterComponent } from './footer';
import { NavBar } from './navbar';

const Header = () => {
  return <NavBar />;
};

const Footer = () => {
  return <FooterComponent />;
};

const Layout = {
  Header,
  Footer,
};

export { Layout };
