import { PERMISSIONS_TYPE } from '../../../constants/permissions';
import { getBaseURL } from '../../../utility/env';

const getUserSettingsRoutes = (path, user) => [
  {
    path: `${path}/user-profile`,
    Component: 'UserProfilePage',
    name: 'User Settings',
    icon: 'FiUser',
    key: 'user-profile',
  },
  ...(user && user.is_agency_admin && user.is_package_user
    ? [
        {
          path: `${path}/agency-profile`,
          Component: 'AgencyPage',
          name: 'Agency Settings',
          icon: 'SideMenuSetting',
          key: 'agency-profile',
          isAgencyPage: true,
        },
      ]
    : []),
  ...(user && !user.is_agency_admin && user.is_package_user
    ? [
        {
          path: `${path}/licenses`,
          route: '/licenses',
          Component: 'LicensesPage',
          name: 'Licenses',
          icon: 'PiIdentificationCard',
          badge: '',
          key: 'licenses',
        },
      ]
    : []),
  ...(user && user.is_agency_admin && user.is_package_user
    ? [
        {
          path: `${path}/licenses`,
          Component: 'AgencyLicensesPage',
          name: 'Licenses',
          icon: 'PiIdentificationCard',
          badge: '',
          key: 'licenses',
          isAgencyPage: true,
        },
      ]
    : []),
  ...(user && user.is_package_user
    ? [
        {
          path: `${path}/preferences`,
          Component: 'UserPreferencePage',
          name: 'Preferences',
          icon: 'VscSettings',
          key: 'preferences',
        },
      ]
    : []),

  {
    path: `${path}/change-password`,
    Component: 'ChangePasswordPage',
    name: 'Change Password',
    icon: 'MdPassword',
    key: 'change-password',
  },
];

const getInboxSubRoutes = (path, countDetails) => [];

const app = (path = '', asArray, user = {}) => {
  const isPremiumUser = user && user.is_package_user;
  const permissions = user && user.permissions;
  const isAgency = user && user.agency;
  const isMemberArea = user && !user.is_package_user;

  const routes = {
    dashboard: {
      path: `${path}/dashboard`,
      Component: 'Dashboard',
    },
    listings: {
      path: `${path}/listings`,
      Component: 'ListingsPage',
      isMemberArea: true,
    },
    post_listing: {
      path: `${path}/post-listing`,
      Component: 'PostListingNafazPage',
      isMemberArea: true,
    },
    update_listing: {
      path: `${path}/post-listing/:id`,
      Component: 'PostListingPage',
      isMemberArea: true,
    },
    upgrade_listing: {
      path: `${path}/post-listing/:id/upgrade`,
      Component: 'UpgradeListingPage',
      isMemberArea: true,
    },
    credits_usage: {
      path: `${path}/credits-usage`,
      Component: 'CreditsUsage',
    },
    ...(user &&
      !!user.is_agency_admin && {
        agency_staff: {
          path: `${path}/agency-staff`,
          Component: 'AgencyStaffPage',
          permission: PERMISSIONS_TYPE.STAFF,
          isAgencyPage: true,
        },
      }),
    invite_user: {
      path: `${path}/invite`,
      Component: 'InviteUserPage',
      isMemberArea: true,
    },
    inbox: {
      path: `${path}/inbox/*`,
      Component: 'InboxPage',
      exact: false,
      subRoutes: getInboxSubRoutes(path),
    },
    reports_summary: {
      path: `${path}/reports/summary`,
      Component: 'ReportsSummaryPage',
      isPremiumUserPage: true,
    },
    listing_report: {
      path: `${path}/reports/listing-report`,
      Component: 'ListingReportsPage',
      isPremiumUserPage: true,
    },
    leads_reports: {
      path: `${path}/reports/leads-reports`,
      Component: 'LeadsReports',
      isPremiumUserPage: true,
    },
    ...(user &&
      user.isCurrencyUser && {
        prop_shop: {
          path: `${path}/packages`,
          Component: 'PropShopPage',
          isMemberArea: true,
        },
      }),
    settings: {
      path: `${path}/user-settings/*`,
      route: `${path}/user-settings`,
      Component: 'UserSettingsPage',
      exact: false,
      isMemberArea: true,
      subRoutes: getUserSettingsRoutes(path, user),
    },
    agency_user_update: {
      path: `${path}/agency-staff/:id`,
      Component: 'AgencyUserFormMobile',
    },
    agency_quota_transer: {
      path: `${path}/agency-staff/:id/transfer`,
      Component: 'AgencyUserFormMobile',
    },
    others: [
      {
        path: `${path}/checkout`,
        Component: 'CheckoutPage',
        isMemberArea: true,
      },
    ],
  };

  if (asArray) {
    const list = [
      ...Object.keys(routes)
        .filter((e) => e !== 'others')
        .map((e) => routes[e]),
      ...routes.others,
    ];
    return list.filter((e) => {
      if (isMemberArea) {
        return !!e.isMemberArea;
      } else if (!isAgency && e.isAgencyPage) {
        return false;
      } else if (!isPremiumUser && e.isPremiumUserPage) {
        return false;
      } else return permissions && e.permission ? !!permissions[e.permission] : true;
    });
  }
  return routes;
};

const auth = (path = '', asArray) => {
  const routes = {
    signin: { path: `${path}/signin`, Component: 'SignInPage' },
  };
  if (asArray) {
    return [
      ...Object.keys(routes)
        .filter((e) => e !== 'others')
        .map((e) => routes[e]),
    ];
  }
  return routes;
};

const redirectToAppPath = (path, cb = () => {}) => {
  const pathsToRedirect = ['/profolio', '/user-settings/profile', '/agency-settings'];
  let language = path.startsWith('/en/') ? '/en' : '';
  const hasPathToRedirect = pathsToRedirect.find((e) => path.includes(e));
  if (hasPathToRedirect) {
    console.log('=> hasPathToRedirect: ', hasPathToRedirect, path);
    const settingPaths = getUserSettingsRoutes(getBaseURL() + language + '/user-settings');
    if (hasPathToRedirect === '/user-settings/profile') {
      if (path.includes('/profile')) {
        window.location.replace(getBaseURL() + language + '/user-settings/user-profile');
      }
    } else if (hasPathToRedirect === '/user-settings') {
      // if (path.includes('/preferences')) {
      //   window.location.replace(settingPaths.find(e => e.key === 'preferences').path);
      // } else if (path.includes('/change-password')) {
      //   window.location.replace(settingPaths.find(e => e.key === 'change-password').path);
      // } else if (path.includes('/licenses')) {
      //   window.location.replace(settingPaths.find(e => e.key === 'licenses').path);
      // } else {
      //   window.location.replace(settingPaths[0].path);
      // }
    } else if (hasPathToRedirect === '/agency-settings') {
      if (path.includes('/licenses')) {
        window.location.replace(getBaseURL() + language + '/user-settings/licenses');
      } else {
        window.location.replace(getBaseURL() + language + '/user-settings/agency-profile');
      }
    } else {
      window.location.replace(app(getBaseURL() + language).dashboard.path);
    }
  } else {
    cb();
  }
};

export default { app, auth, redirectToAppPath };
