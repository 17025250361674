import tenantConstants from '@constants';
import moment from 'moment/moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeLayoutMode, changeRtlMode, setLocale } from '../redux/themeLayout/actionCreator';
import { getBaseURL, getClassifiedBaseURL, isProduction, TENANT_KEY } from '../utility/env';
import * as Sentry from '@sentry/react';

// TODO: need to add this to utils and constants
export const getAppLanguage = () => {
  const pathDirectories = window.location.pathname.split('/');
  if (pathDirectories.length > 1) {
    const pathLocale = pathDirectories[1];
    const lang = tenantConstants.languages.find((e) => e.key == pathLocale) || tenantConstants.languages[0];
    return lang;
  }
};

export const getLangBasePath = () => {
  const pathDirectories = window.location.pathname.split('/');
  if (pathDirectories.length > 1) {
    const pathLocale = pathDirectories[1];
    const lang = tenantConstants.languages.find((e) => e.key == pathLocale) || {};
    return lang.key || '';
  }
};

const useAppInit = () => {
  const lang = getAppLanguage();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.loginUser);
  const { isMemberArea, locale } = useSelector((state) => state.AppConfig);

  // Sentry User
  useEffect(() => {
    user?.id && isProduction && Sentry.setUser({ id: user.id, email: user?.email });
    return () => {
      isProduction && Sentry.setUser(null);
    };
  }, [user?.id]);

  // Zendesk
  useEffect(() => {
    if (isProduction && user && !isMemberArea && tenantConstants.ZENDESK) {
      const loadZendesk = () => {
        if (window.zE) {
          window.zE(() => {
            window.zE.setLocale(locale);
            window.zE('webWidget', 'updateSettings', {
              webWidget: {
                // color: { theme: tenantTheme['primary-color'], launcher: tenantTheme['primary-color'] },
                launcher: { mobile: { labelVisible: true } },
                chat: { connectOnPageLoad: false },
                position: { horizontal: 'left', vertical: 'bottom' },
              },
            });
            window.zE('webWidget', 'prefill', {
              name: { value: user.name, readOnly: true },
              email: { value: user.email, readOnly: !!user.email },
            });
          });
        }
      };
      // Zendesk
      const zendeskScript = document.createElement('script');
      zendeskScript.id = 'ze-snippet';
      zendeskScript.src = `${process.env.REACT_APP_ZENDESK}`;
      document.head.appendChild(zendeskScript);

      if (document.readyState === 'complete') {
        setTimeout(() => {
          loadZendesk();
        }, 1500);
      }
    }
  }, [user?.id, isMemberArea, tenantConstants.ZENDESK]);

  useEffect(() => {
    // Title
    document.title = t('Profolio');

    // Meta Description
    const description = document.createElement('meta');
    description.name = 'description';
    description.content = t(`Property Management Software By ${tenantConstants.title}`);
    document.head.appendChild(description);
  }, []);

  useEffect(() => {
    // Fonts CSS
    if (!isMemberArea) {
      const fontsapis = document.createElement('link');
      const fonts = document.createElement('link');
      const fontsStyleSheet = document.createElement('link');
      fontsapis.rel = 'preconnect';
      fontsapis.href = 'https://fonts.googleapis.com';

      fonts.rel = 'preconnect';
      fonts.href = 'https://fonts.gstatic.com';
      fonts.crossOrigin = true;

      fontsStyleSheet.rel = 'stylesheet';
      fontsStyleSheet.href =
        'https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Mukta:wght@200;300;400;500;600;700;800&display=swap';
      document.head.appendChild(fontsapis);
      document.head.appendChild(fonts);
      document.head.appendChild(fontsStyleSheet);
    }

    // Favicon
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = `/profolio-assets/${process.env.REACT_APP_TENANT}${isMemberArea ? '/lite' : ''}/favicon.png`;
    document.head.appendChild(favicon);

    // Favicon Shortcut (SVG)
    const faviconSC = document.createElement('link');
    faviconSC.rel = 'shortcut icon';
    faviconSC.type = 'image/svg+xml';
    faviconSC.href = `/profolio-assets/${process.env.REACT_APP_TENANT}${isMemberArea ? '/lite' : ''}/favicon.svg`;
    document.head.appendChild(faviconSC);

    // Touch Icon
    const touchIcon = document.createElement('link');
    touchIcon.rel = 'apple-touch-icon';
    touchIcon.href = `/profolio-assets/${process.env.REACT_APP_TENANT}${isMemberArea ? '/lite' : ''}/logo192.png`;
    document.head.appendChild(touchIcon);

    // Manifest
    const manifest = document.createElement('link');
    manifest.rel = 'manifest';
    manifest.href = `/profolio-assets/${process.env.REACT_APP_TENANT}${isMemberArea ? '/lite' : ''}/manifest.json`;
    document.head.appendChild(manifest);
  }, [isMemberArea]);

  useEffect(() => {
    // import fonts
    if (TENANT_KEY === 'bayut') {
      // import('./fonts/fonts.less');
      let style = document.createElement('style');
      style.type = 'text/css';
      let fontFace = `
        @font-face {
          font-family: "Droid Arabic Kufi";
          src: url('//${window.location.host}/profolio-assets/bayut/fonts/DroidArabicKufi.woff2') format("woff2");
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }`;
      style.appendChild(document.createTextNode(fontFace));
      document.head.appendChild(style);
    }
  }, []);

  useEffect(() => {
    // Moment Locale
    moment.locale(lang.key);
    const htmlElement = document.getElementsByTagName('html')[0];
    htmlElement.lang = lang.key;
    htmlElement.dir = lang.rtl ? 'rtl' : 'ltr';
  }, [lang.key, lang.rtl]);

  useEffect(() => {
    i18n.changeLanguage(lang.key);
    dispatch(setLocale(lang.key));
    dispatch(changeRtlMode(lang.rtl));
    dispatch(changeLayoutMode(lang.darkMode));
  }, [lang, window.location.pathname]);

  const onLanding = (user, isMemberArea, _isAgencyAdmin) => {
    return new Promise((resolve) => {
      if (tenantConstants.HAS_MEMBER_AREA) {
        if (user) {
          const { id, is_package_user, is_suspended } = user;
          const localUserInfo = localStorage.getItem(tenantConstants.USER_LOCAL_STORAGE_KEY);
          !localUserInfo &&
            localStorage.setItem(
              tenantConstants.USER_LOCAL_STORAGE_KEY,
              JSON.stringify({ id, is_package_user, is_suspended }),
            );
          if (isMemberArea && window.location.origin != getClassifiedBaseURL()) {
            // Redirect to lite experience
            window.location.replace(
              `${getClassifiedBaseURL()}${
                window.location.pathname.length > 1 ? window.location.pathname + window.location.search : '/listings'
              }`,
            );
            setTimeout(() => {
              resolve();
            }, 100);
          } else if (!isMemberArea && window.location.origin != getBaseURL()) {
            // Redirect to pro experience
            window.location.replace(getBaseURL() + (window.location.pathname + window.location.search));
            setTimeout(() => {
              resolve();
            }, 100);
          } else {
            resolve();
          }
        }
      } else {
        resolve();
      }
    });
  };

  return { locale: lang.key, basePath: getLangBasePath(), onLanding, ...lang };
};
export default useAppInit;
