import actions from './actions';

const { SET_LICENSE_LIST } = actions;

const initialState = {
  licenseData: null,
  loading: true,
  error: null,
};

const UserLicenseReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LICENSE_LIST:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export { UserLicenseReducer };
