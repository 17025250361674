import { NetworkService } from '../../../../services/networkService';
import { getLoggedInUserValues } from './userSettingsForm/getLoggedInUserValues';
import tenantApi from '@api';

const updateUserSetting = async (userId, values) => {
  let response = await NetworkService.put(`/api/users/${userId}`, tenantApi.loggedInUser(values));
  if (response.error) {
    return { error: response.error };
  } else if (response?.data) return { data: { ...getLoggedInUserValues(response?.data?.user) } };
};
export default updateUserSetting;
