import { checkRequiredKeys } from '../../tenant/checkRequiredKeys';
import { TENANT_KEY } from '../../utility/env';
import BayutApis from './bayut';
import ZameenApis from './zameen';

const configKeys = [];

export const tenants = Object.freeze({
  ['zameen']: checkRequiredKeys(ZameenApis, configKeys),
  ['bayut']: checkRequiredKeys(BayutApis, configKeys),
});

export default tenants[TENANT_KEY];
