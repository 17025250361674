import { getToken } from '../authentication/reducers';
import actions from './actions';

const {
  FETCH_USER_BEGIN,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERR,
  FETCH_USER_PERMISSIONS_SUCCESS,
  UPDATE_USER_SETTING_SUCCESS,
  UPDATE_USER_PRODUCTS,
  SET_USER_SETTINGS,
  SET_USER_LOADING,
  SET_USER_AGENCY_SETTINGS,
  SET_USER_NEW_AVAILABLE_CREDITS,
  SET_USER_NEW_AVAILABLE_QUOTA,
  SET_UPDATE_USER_DATA,
  SET_CONTRACTS_DETAILS,
  SET_PAYMENT_DETAILS,
} = actions;

const initialState = {
  user: null,
  loading: getToken() ? true : false,
  error: null,
};

const userReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FETCH_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FETCH_USER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FETCH_USER_SUCCESS:
      return {
        ...state,
        error: null,
        user: {
          ...state?.user,
          ...data,
        },
        loading: false,
      };

    case SET_CONTRACTS_DETAILS:
      return {
        ...state,
        user: {
          ...state.user,
          ...data,
        },
      };

    case FETCH_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: data,
      };

    case UPDATE_USER_SETTING_SUCCESS:
      const { settings } = state.user;
      const updateSettings = settings.map(item => {
        item.value = action?.data?.[item?.name];
        return item;
      });
      return {
        ...state,
        user: {
          ...state.user,
          settings: updateSettings,
        },
      };

    case UPDATE_USER_PRODUCTS:
      return {
        ...state,
        user: {
          ...state.user,
          ...data,
        },
      };

    case SET_USER_LOADING:
      return {
        ...state,
        loading: action.data,
      };

    case SET_USER_SETTINGS:
      return {
        ...state,
        user: {
          ...state.user,
          profileData: {
            ...state?.user?.profileData,
            ...(!!action.data.list && { formFields: action.data.list }),
            ...(!!action.data.data && { formValues: action.data.data }),
          },
        },
      };

    case SET_USER_AGENCY_SETTINGS:
      return {
        ...state,
        user: {
          ...state.user,
          agencyUserSettings: {
            ...state?.user?.agencyUserSettings,
            ...(!!action?.data?.fields && { fields: action?.data?.fields }),
            ...(!!action?.data?.data && { data: action?.data?.data }),
          },
        },
      };

    case SET_USER_NEW_AVAILABLE_CREDITS:
      let prevcredits = state.user.credits.products[action.data.platform];
      let newcredits = prevcredits.map(item => {
        if (item.product_id === action.data.productId) {
          let newItem = {
            ...item,
            available_credits:
              item.available_credits - action.data.creditDeduct <= 0
                ? 0
                : item.available_credits - action.data.creditDeduct,
            consumed: item.consumed + action.data.creditDeduct,
          };
          return newItem;
        } else {
          return item;
        }
      });
      return {
        ...state,
        user: {
          ...state.user,
          credits: {
            ...state.user.credits,
            products: {
              ...state.user.credits.products,
              [action?.data?.platform]: newcredits,
            },
          },
        },
      };

    case SET_USER_NEW_AVAILABLE_QUOTA: {
      let prevQuota = state.user.quota.products[action.data.platform];
      const newQuota = !!action.data?.addQuota
        ? {
            ...prevQuota,
            available: prevQuota.available + action.data.quotaDeduct,
            consumed:
              prevQuota.consumed - action.data.quotaDeduct <= 0 ? 0 : prevQuota.consumed - action.data.quotaDeduct,
          }
        : {
            ...prevQuota,
            available:
              prevQuota.available - action.data.quotaDeduct <= 0 ? 0 : prevQuota.available - action.data.quotaDeduct,
            consumed: prevQuota.consumed + action.data.quotaDeduct,
          };
      return {
        ...state,
        user: {
          ...state.user,
          quota: {
            ...state.user.quota,
            products: {
              ...state.user.credits.products,
              [action?.data?.platform]: newQuota,
            },
          },
        },
      };
    }

    case SET_UPDATE_USER_DATA: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.data.data,
        },
      };
    }

    default:
      return state;
  }
};

export { userReducer };
