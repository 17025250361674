import actions from './actions';
const { SET_APPLICABLE_LICENSE_LIST } = actions;
const initialState = {
  applicableLicenses: null,
  licenseLoading: false,
  error: null,
};

const ApplicableLicenseReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_APPLICABLE_LICENSE_LIST:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
export { ApplicableLicenseReducer };
