import tenantData from '@data';
import tenantRoutes from '@routes';
import tenantUtils from '@utils';
import { List, Typography } from 'antd';
import cx from 'clsx';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Group, Heading } from '../../../../../components/common';
import { useApplyProductModalData } from '../../../../../hooks';
import { QuotaCreditModal } from '../listing-platform-actions/quotaCreditModal';
import { HealthTag, LegendHorizontal, PopoverContainer, QualityPopover } from './styled';
import { actionButtonQualityWidgetClickEvent, hoverListingQualityDonut} from '../../../../../services/analyticsService';
const { Title, Text } = Typography;

const getTagColor = (color) => {
  switch (color) {
    case 'high':
      return 'success';
    case 'low':
      return 'error';
    case 'medium':
      return 'warning';
    default:
      return 'warning';
  }
};

const getScoreIcon = (color, percentage) => (
  <HealthTag
    className={cx('fw-800 py-2 fz-14 text-center')}
    style={{ margin: 0, width: '50px' }}
    color={getTagColor(color)}
  >
    {percentage}%
  </HealthTag>
);

const Health = (props) => {
  const { listingsData } = props;
  const { t } = useTranslation();
  const deductionModalRef = useRef();
  const navigate = useNavigate();
  const { isMobile } = useSelector((state) => state.AppConfig);
  const { user } = useSelector((state) => state.loginUser);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const query = new URLSearchParams(location.search);
  const statusId = query.get('q[status_id_eq]');
  const {
    confirmationLoading,
    selectedPaymentOption,
    paymentOptions,
    action,
    onSuccessQuotaCreditModal,
    getDeduction,
    onChangePaymentOption,
    onCancelModal,
  } = useApplyProductModalData(user, deductionModalRef);

  const healthData = [
    {
      title: t('FRESHNESS'),
      subTitle: tenantUtils.getLocalisedString(props, 'age_title'),
      message: tenantUtils.getLocalisedString(props, 'age_message'),
      status: props?.age_percentage_score,
      color: props?.age_classification,
      button: t('Refresh'),
      showButton: listingsData?.productsInfo?.refresh?.is_applicable && props?.age > 3,
      onClick: () => {
        const action = tenantData.getListingActions('refresh');
        getDeduction(action?.actionType, listingsData.platforms.data[0], action);
      },
    },
    {
      title: t('UNIQUE IMAGES'),
      subTitle: tenantUtils.getLocalisedString(props, 'unique_images_title'),
      message: tenantUtils.getLocalisedString(props, 'unique_images_message'),
      status: props?.unique_images_percentage_score,
      color: props?.unique_images_classification,
      button: t('Add Images'),
      showButton: props?.unique_images_percentage_score === 100 ? false : true,
      onClick: () => {
        actionButtonQualityWidgetClickEvent(user, statusId, "add-images", props )
        const path = `${tenantRoutes.app().post_listing.path}/${listingsData.id}`;
        navigate(path);
      },
    },
    {
      title: t('FEATURES'),
      subTitle: tenantUtils.getLocalisedString(props, 'features_title'),
      message: tenantUtils.getLocalisedString(props, 'features_message'),
      status: props?.features_percentage_score,
      color: props?.features_classification,
      button: t('Add Amenities'),
      showButton: props?.features_percentage_score === 100 ? false : true,
      onClick: () => {
        actionButtonQualityWidgetClickEvent(user, statusId, "add-amenities", props )
        const path = `${tenantRoutes.app().post_listing.path}/${listingsData.id}`;
        navigate(path);
      },
    },
  ];

  const renderPopOverHeader = () => {
    return (
      <Group template="auto auto" className={cx('py-16 px-24 qualityCard')} gap="10px" style={{ alignItems: 'center' }}>
        <div>
          <Title level={5} className="fz-16 mb-4" style={{ fontWeight: 700 }}>
            {t('Overall Quality')}
          </Title>
          <Text type="secondary">{t('Higher quality means more listing visibility & leads')}</Text>
        </div>

        <Flex align="center" justify="end" gap="4px">
          <LegendHorizontal>
            {getScoreIcon(props?.overall_classification, props?.overall_percentage_score)}
          </LegendHorizontal>{' '}
        </Flex>
      </Group>
    );
  };

  const renderItem = (e) => {
    return (
      <List.Item className="py-16">
        <Flex justify="space-between" align={isMobile ? 'start' : 'center'} gap={isMobile ? '12px' : '24px'}>
          <div>
            <Heading
              className="color-gray-lighter mb-12"
              style={{ textTransform: 'uppercase', fontSize: '12px' }}
              as="h6"
            >
              {e.title}
            </Heading>

            <Flex gap="16px" align="center">
              {getScoreIcon(e.color, e.status)}

              <div>
                <Title className="mb-0" level={5} style={{ fontSize: '14px' }}>
                  {e.subTitle}
                </Title>
                <Text type="secondary" className="fz-12">
                  {e.message}
                </Text>
              </div>
            </Flex>
          </div>

          {!!e.showButton && (
            <Button
              style={{ borderRadius: '6px' }}
              type="primaryOutlined"
              size={isMobile && 'small'}
              onClick={() => {
                setPopoverVisible(false);
                e.onClick();
              }}
            >
              {e.button}
            </Button>
          )}
        </Flex>
      </List.Item>
    );
  };

  return (
    <>
      <PopoverContainer
        placement="right"
        getTooltipContainer={null}
        getPopupContainer={null}
        overlayClassName="quality-popupContainer"
        onClick={() => setPopoverVisible(true)}
        visible={popoverVisible}
        onVisibleChange={(visible) => {
          setPopoverVisible(visible);
        }}
        onMouseEnter={() => hoverListingQualityDonut(user, statusId, props?.overall_percentage_score)}
        content={
          <QualityPopover
            header={renderPopOverHeader()}
            itemLayout="vertical"
            dataSource={healthData}
            renderItem={(e) => (e.subTitle || e.message ? renderItem(e) : null)}
          />
        }
      >
        {getScoreIcon(props?.overall_classification, props?.overall_percentage_score)}
      </PopoverContainer>

      <QuotaCreditModal
        ref={deductionModalRef}
        title={action?.applicableProduct?.requestTitle}
        action={action}
        okText={action?.isSufficient ? 'Submit' : 'Continue'}
        onSuccess={onSuccessQuotaCreditModal}
        onCancel={onCancelModal}
        loading={confirmationLoading}
        selectedPaymentOption={selectedPaymentOption}
        paymentOptions={paymentOptions}
        onChangePaymentOption={onChangePaymentOption}
        showInsufficientCreditsAlert
        showInfoMessage
        hideDataTable
      />
    </>
  );
};

export default Health;
