import { NetworkService } from "../../../../services/networkService";
export const applyProducts = async (listingId, body) => {
  
  const response = await NetworkService.put(`/api/listings/${listingId}/apply_products`, body);
  if (response) {
    if (response.error) {
      return response;
    } else {
      return response;
    }
  }
};
