import tenantData from '@data';
import tenantTheme from '@theme';
import tenantUtils from '@utils';
import { Card, Typography } from 'antd';
import cx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IconStyled } from '../../../../../components/common/icon/IconStyled.js';
import { Button, Flex, Heading, Icon, IconWithSubtext, Tag } from '../../../../../components/common/index.js';
import RenderTextLtr from '../../../../../components/render-text/render-text.js';
import { packageSelectEvent } from '../../../../../services/analyticsService/index.js';
import { formatPrice } from '../../../../../utility/utility.js';
import UpgradePackageModal from './package-upgrade-modal.js';
const { Text } = Typography;

export const PackageCard = ({ packageData, onGetPackageClick, refundable_amount, packageDuration }) => {
  const { rtl, isMemberArea } = useSelector(state => state.AppConfig);
  const [buttonLoading, setButtonLoading] = useState();
  const { t } = useTranslation();
  const type = tenantData.slugToType?.[packageData?.slug];
  const { user } = useSelector(state => state.loginUser);
  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false);

  const handleGetPackage = packageData => {
    if (isMemberArea || !user?.package) {
      setButtonLoading(true);
      onGetPackageClick(packageData?.id, () => setButtonLoading(false));
    } else {
      setUpgradeModalVisible(true);
    }
  };

  return (
    <Card
      className={cx('pos-rel  w-100 text-center', type === 'platinum-plus' ? 'platinum-card' : 'package-list')}
      bodyStyle={{ paddingTop: 0 }}
      style={{ borderWidth: 1 }}
      disabled={user?.package?.slug == packageData.slug || !packageData?.is_applicable}
    >
      <Flex vertical gap="16px" className="mb-24">
        <div>
          <IconStyled
            className="mb-8"
            style={{
              '--icon-bg-color': tenantData.packages?.[type]?.packageColor || 'default-color',
              margin: 'auto',
              marginBlockStart: '28px',
              '--icon-styled-width': '48px',
            }}
          >
            <Icon
              size={28}
              icon={tenantData.packages?.[type]?.icon}
              iconProps={tenantData.packages?.[type]?.iconProps}
            />
          </IconStyled>
          <Heading as="h5" className="mb-0">
            <RenderTextLtr text={packageData.name} />
          </Heading>
        </div>
        <div>
          <IconWithSubtext
            className="mb-8"
            title={formatPrice(packageData.credits_per_month)}
            subText={`${t('credits')}/${t('mo')}`}
            textSize="20px"
            gap={'4px'}
            justify="center"
          />

          <div style={!isMemberArea && user?.package && packageData?.is_applicable ? { fontStyle: 'italic' } : {}}>
            <Text type="secondary" className="fz-12">
              {isMemberArea ||
              !user?.package ||
              user?.package?.slug == packageData?.slug ||
              !packageData?.is_applicable ? (
                t('SAR')
              ) : (
                <>
                  <span className="fw-500" style={{ color: '#222' }}>
                    {t('Pay')}
                  </span>{' '}
                  <span className="fw-800 base-color">{t('SAR')}</span>
                </>
              )}
            </Text>
            <span className="fw-800 fz-16" style={{ marginInlineStart: '4px' }}>
              {isMemberArea ||
              !user?.package ||
              user?.package?.slug === packageData?.slug ||
              !packageData?.is_applicable ? (
                formatPrice(packageData.net_amount)
              ) : (
                <>
                  {refundable_amount
                    ? formatPrice(packageData.net_amount - refundable_amount)
                    : formatPrice(packageData.net_amount)}{' '}
                  <span className="fw-400">{t(' to Upgrade')}</span>
                </>
              )}
            </span>
          </div>
        </div>
      </Flex>
      <Button
        className="mb-24"
        style={{ paddingInline: 12, width: '100%' }}
        onClick={() => {
          handleGetPackage(packageData);
          packageSelectEvent(user, packageDuration, packageData?.name);
        }}
        type={type == 'platinum-plus' ? 'primary' : 'primaryOutlined'}
        loading={buttonLoading}
      >
        {user?.package?.slug == packageData.slug ? (
          t('Current Package')
        ) : !packageData?.is_applicable ? (
          t('Downgrade Unavailable')
        ) : (
          <RenderTextLtr text={`${t('Get')}  ${packageData.name}`} />
        )}
        
      </Button>
      <UpgradePackageModal
        upgradeModalVisible={upgradeModalVisible}
        setUpgradeModalVisible={setUpgradeModalVisible}
        packageData={packageData}
        onGetPackageClick={onGetPackageClick}
        refundable_amount={refundable_amount}
        packageDuration={packageDuration}
      />
      <div className="additional-info">
        {Object.entries(packageData.additional_info.options).map(([key, value]) => (
          <div key={key}>
            <div style={{ height: '33px', color: tenantTheme['gray700'] }}>
              {value.value == 'no' ? (
                <Icon icon="RxCross2" size="18px" color="#F73131" />
              ) : value.value == 'yes' ? (
                <Icon icon="GiCheckMark" className="text-primary" />
              ) : (
                tenantUtils.getLocalisedString(value, 'value')
              )}
            </div>
          </div>
        ))}
      </div>
      <Tag
        color={type == 'platinum-plus' ? '#FFCC3333' : '#F2FAFA'}
        style={{ border: 'none', color: type == 'platinum-plus' ? '#BE6E00' : tenantTheme['primary-color'] }}
        className={cx('listing-tag')}
        size="12px"
      >
        {t('Save')} {rtl && '%'}
        {packageData.additional_info?.discount}
        {!rtl && '%'}
      </Tag>
    </Card>
  );
};
