import tenantTheme from '@theme';
import tenantUtils from '@utils';
import { NetworkService } from '../../../../services/networkService';
import { getQueryString } from '../../../../utility/urlQuery';

export const dashboardListingWidgetApi = async (user, params, platform) => {
  const productsToShow = user?.isCurrencyUser
    ? [{ slug: 'signature-listing' }, { slug: 'hot-listing' }, { slug: 'basic-listing' }]
    : [{ slug: 'hot-listing' }, { slug: 'basic-listing' }];
  const data = {
    ksa: {
      title: 'Listings',
      icon_data: {},
      link_data: {
        text: 'View All Listings',
        to: '/listings',
      },
      total_title: 'Active',
      total_title_icon: 'MdCircle',
      total_value: '',
      purposes: [
        {
          id: 1,
          icon: 'IconForSale',
          iconProps: { color: tenantTheme['color-for-sale'], hasBackground: true },
          title: 'For Sale',
          value: '',
        },
        {
          id: 2,
          icon: 'IconForRent',
          iconProps: { color: tenantTheme['color-for-rent'], hasBackground: true },
          title: 'To Rent',
          value: '',
        },
        {
          id: 4,
          icon: 'IconRental',
          iconProps: { color: tenantTheme['color-for-rent'], hasBackground: true },
          title: 'Daily Rentals',
          value: '',
        },
      ],
      products: tenantUtils.generateProductData(productsToShow),
    },
  };

  const widgetParser = (listings) => {
    const currentPlatform = data[platform];
    currentPlatform.total_value = listings[platform].active;
    currentPlatform.purposes[0].value = listings[platform].sale;
    currentPlatform.purposes[1].value = listings[platform].rent;
    currentPlatform.purposes[2].value = listings[platform].daily_rental;
    currentPlatform.products.forEach((item, i) => {
      currentPlatform.products[i].value = listings[platform][item.slug_alt];
    });
    return data?.[platform];
  };

  let response = await NetworkService.get(`/api/dashboard/listing_stats?${getQueryString(params)}`);
  if (response) {
    if (response.error) {
      return response;
    } else {
      return widgetParser(response?.data?.stats);
    }
  }
};
