import actions from './actions';

const { SET_RULES } = actions;

const initialState = {
  monthRules: null,
  loading: true,
  error: null,
};

const MonthReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_RULES:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export { MonthReducer };
