import { strings } from '../../../../constants/strings';

import tenantApi from '@api';
import { NetworkService } from '../../../../services/networkService';

const fetchAgencyStaffList = async (user, agencyId, params) => {
  const response = await NetworkService.get(`/api/agencies/${agencyId}`);
  if (response) {
    if (response.error) {
      return response;
    }
    let listingsPosted = 0;
    return {
      list: response?.data?.agency?.users?.map((e) => {
        let activeListings = 0;
        // Object.keys(e?.listings)?.map(it => {
        //   activeListings += e?.listings?.[it]?.[0]?.total;
        // });
        // listingsPosted += activeListings;
        return {
          ...e,
          user_name: e?.name,
          user_email: e?.email,
          user_phone: e?.mobile,
          active_listings: { value: activeListings },
          leads: { value: null },
          zameenQuota:
            !!e?.quotas?.zameen?.available_quota >= 1
              ? `${e?.quotas?.zameen?.available_quota.toFixed(1)}/${e?.quotas?.zameen?.total_quota.toFixed(1)}`
              : '-',
          olxQuota: !!e?.mapped_on_olx
            ? !!e?.quotas?.olx?.available_quota >= 1
              ? `${e?.quotas?.olx?.available_quota.toFixed(1)}/${e?.quotas?.olx?.total_quota.toFixed(1)}`
              : '-'
            : 'Not mapped',
          rowActions: {
            ...e,
            agencyId: agencyId,
            userId: e?.id,
            isAdmin: e?.is_admin,
            name: e?.name,
            deleteModalData: {
              table: [
                {
                  title: 'Name',
                  dataIndex: 'name',
                  key: 'name',
                  component: 'String',
                },
                {
                  title: 'Email',
                  dataIndex: 'email',
                  key: 'email',
                  component: 'String',
                },
                // {
                //   title: 'Active Listings',
                //   dataIndex: 'active_listings',
                //   key: 'active_listings',
                //   component: 'Number',
                // },
              ],
              list: [{ ...e, active_listings: { value: activeListings } }],
              onDelete: async () => {
                const response = await tenantApi.deleteAgencyUser(agencyId, e?.id);
                if (response) {
                  if (response?.error) {
                    return { error: response.error };
                  } else {
                    return { id: e?.id };
                  }
                }
              },
            },
          },
        };
      }),
      headerCards: [
        {
          icon: 'MdPersonPinCircle',
          iconColor: '',
          iconSize: '1em',
          title: strings.total_agents,
          value: response.data.data?.users?.length,
        },
        {
          icon: 'IoMdPin',
          iconColor: '#00a651',
          iconSize: '1em',
          title: strings.listings_posted,
          value: listingsPosted,
        },
      ],
      table: [
        {
          title: 'Name',
          dataIndex: 'user_name',
          key: 'user_name',
          component: 'String',
        },
        {
          title: 'Mobile',
          dataIndex: 'user_phone',
          key: 'user_phone',
          component: 'String',
        },
        {
          title: 'Email',
          dataIndex: 'user_email',
          key: 'user_email',
          component: 'String',
        },
        // ...(user?.products?.platforms?.zameen
        //   ? [
        //       {
        //         title: 'Zameen Listings',
        //         dataIndex: 'quota.zameen.available',
        //         key: 'quota.zameen.available',
        //         component: 'String',
        //       },
        //     ]
        //   : []),
        // ...(user?.products?.platforms?.olx
        //   ? [
        //       {
        //         title: 'OLX Listings',
        //         dataIndex: 'quota.olx.available',
        //         key: 'quota.olx.available',
        //         component: 'String',
        //       },
        //     ]
        //   : []),
        {
          title: 'Zameen Quota',
          dataIndex: 'zameenQuota',
          key: 'zameenQuota',
          component: 'String',
        },
        {
          title: 'Olx Quota',
          dataIndex: 'olxQuota',
          key: 'olxQuota',
          component: 'String',
        },
        // {
        //   title: 'Active Listings',
        //   dataIndex: 'active_listings',
        //   key: 'active_listings',
        //   component: 'Number',
        // },
        {
          title: 'Controls',
          dataIndex: 'rowActions',
          key: 'rowActions',
          component: 'UserRowActions',
          align: 'right',
        },
      ],
    };
  }
};

export default fetchAgencyStaffList;
