import tenantData from '@data';
import store from '../../../../redux/store';
import { NetworkService } from '../../../../services/networkService';
import { quotaCreditsWidgetMapper } from './quota-credits';

export const agencyQuotaCredit = async (id) => {
  const user = store.getState().loginUser.user;
  const response = await NetworkService.get(`/api/dashboard/qc_summary`, {
    ...(id !== -1 && { [`q[user_id_eq]`]: id }),
  });
  if (response) {
    if (response.error) {
      return response;
    } else {
      let quotaCredits = {};
      tenantData.platformList.forEach((e) => {
        if (Object?.keys(response?.data?.[e?.slug])?.length) {
          quotaCredits[e.slug] = quotaCreditsWidgetMapper(e, response?.data, user);
        }
      });
      return quotaCredits;
    }
  }
};
