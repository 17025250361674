import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Group } from '../../../../components/common';
import { Main } from '../../../../container/styled';
import { useGetMatch, usePageTitle } from '../../../../hooks';
import OfferedPackages from './offered-packages/offeredPackages';
import { PackageCardList } from './offered-packages/styled';
import PackageHeader from './package-header';

function PropShop(props) {
  const { t } = useTranslation();
  const { isExact } = useGetMatch();

  const { isMobile, isMemberArea } = useSelector(state => state.AppConfig);
  const { user } = useSelector(state => state.loginUser);
  usePageTitle(
    t('Advertise Your Property | Bayut KSA'),
    t(
      "Find the perfect package to boost your property's visibility among potential buyers and renters across KSA. Simple, effective, and designed to suit various budgets and goals.",
    ),
  );

  return (
    <Main isMemberArea={isMemberArea}>
      <PackageCardList className={isExact ? 'cartWraper' : 'checkoutWraper'}>
        <Row gutter={isMobile ? 0 : 32} justify="center" style={{ rowGap: 16 }}>
          <Col xxl={30} lg={28} xs={34} width>
            <Group gap="8px">
              {!isMemberArea && user?.package && <PackageHeader />}
              <OfferedPackages {...props} />
            </Group>
          </Col>
        </Row>
      </PackageCardList>
    </Main>
  );
}

export default PropShop;
