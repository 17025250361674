import { imageStateObject } from "../../../../helpers/imageHelpers/imageStateObject";
import { NetworkService } from "../../../../services/networkService";

export const fetchListingDetail = async (userId, listingId) => {
  const response = await NetworkService.get(
    `/api/users/${userId}/listings/${listingId}?include=active_images`,
  );
  if (response) {
    if (response.error) {
      return response;
    } else {
      const active_images = response?.data?.data?.active_images;
      const listingImages = active_images.map((e) => ({
        ...e,
        ...imageStateObject('select'),
      }));
      return { active_images: listingImages };
    }
  }
};
