const actions = {
  FETCH_USER_BEGIN: 'FETCH_USER_BEGIN',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_PERMISSIONS_SUCCESS: 'FETCH_USER_PERMISSIONS_SUCCESS',
  FETCH_USER_ERR: 'FETCH_USER_ERR',
  UPDATE_USER_SETTING_SUCCESS: 'UPDATE_USER_SETTING_SUCCESS',
  UPDATE_USER_PRODUCTS: 'UPDATE_USER_PRODUCTS',
  SET_USER_SETTINGS: 'SET_USER_SETTINGS',
  SET_USER_LOADING: 'SET_USER_LOADING',
  SET_USER_AGENCY_SETTINGS: 'SET_USER_AGENCY_SETTINGS',
  SET_USER_NEW_AVAILABLE_CREDITS: 'SET_USER_NEW_AVAILABLE_CREDITS',
  SET_USER_NEW_AVAILABLE_QUOTA: 'SET_USER_NEW_AVAILABLE_QUOTA',
  SET_UPDATE_USER_DATA: 'SET_UPDATE_USER_DATA',
  SET_CONTRACTS_DETAILS: 'SET_CONTRACTS_DETAILS',

  fetchUserBegin: () => {
    return {
      type: actions.FETCH_USER_BEGIN,
    };
  },

  fetchUserSuccess: data => {
    return {
      type: actions.FETCH_USER_SUCCESS,
      data,
    };
  },

  setContractsDetails: data => {
    return {
      type: actions.SET_CONTRACTS_DETAILS,
      data,
    };
  },

  fetchUserPermissionsSuccess: data => {
    return {
      type: actions.FETCH_USER_PERMISSIONS_SUCCESS,
      data,
    };
  },

  fetchUserErr: err => {
    return {
      type: actions.FETCH_USER_ERR,
      err,
    };
  },

  updateUserSetting: data => {
    return {
      type: actions.UPDATE_USER_SETTING_SUCCESS,
      data,
    };
  },

  updateUserProducts: data => {
    return {
      type: actions.UPDATE_USER_PRODUCTS,
      data,
    };
  },

  setUserLoading: data => {
    return {
      type: actions.SET_USER_LOADING,
      data,
    };
  },

  setUserSettings: data => {
    return {
      type: actions.SET_USER_SETTINGS,
      data,
    };
  },
  setUserAgencySettings: data => {
    return {
      type: actions.SET_USER_AGENCY_SETTINGS,
      data,
    };
  },

  setUserNewAvailableCredis: data => {
    return {
      type: actions.SET_USER_NEW_AVAILABLE_CREDITS,
      data,
    };
  },

  setUserNewAvailableQuota: data => {
    return {
      type: actions.SET_USER_NEW_AVAILABLE_QUOTA,
      data,
    };
  },

  setUpdateUserData: data => {
    return {
      type: actions.SET_UPDATE_USER_DATA,
      data,
    };
  },
};

export default actions;
