import { AgencyStaffReducer } from './agencyStaff/reducer';
import AppConfig from './themeLayout/reducers';
import { DashboardReducer } from './dashboard/reducer';
import { LeadsSummaryReducer } from './leadsSummary/reducer';
import { ListingPerformanceReducer } from './listingPerformance/reducer';
import { ListingsSummaryReducer } from './listingsSummary/reducer';
import { ReportsSummaryReducer } from './reportsSummary/reducer';
import { TrafficSummaryReducer } from './trafficSummary/reducer';
import { UsersReducer } from './users/reducers';
import authActions from './authentication/actions';
import authReducer from './authentication/reducers';
import cartData from './cart/reducers';
import { combineReducers } from 'redux';
import generalReducer from './general/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import { listingReducer } from './listings/reducers';
import { userReducer } from './user/reducers';
import { QuotaCreditsReducer } from './quotaCredits/reducer';
import { LicenseReducer } from './license/reducer';
import { UserLicenseReducer } from './user-license/reducer';
import { ApplicableLicenseReducer } from './applicable-license/reducer';
import { MonthReducer } from './monthRules/reducer';
const appReducers = combineReducers({
  headerSearchData: headerSearchReducer,
  general: generalReducer,
  loginUser: userReducer,
  listings: listingReducer,
  userGroup: UsersReducer,
  auth: authReducer,
  AppConfig,
  cart: cartData,
  ReportSummary: ReportsSummaryReducer,
  ListingsSummary: ListingsSummaryReducer,
  LeadsSummary: LeadsSummaryReducer,
  TrafficSummary: TrafficSummaryReducer,
  AgencyStaff: AgencyStaffReducer,
  Dashboard: DashboardReducer,
  ListingPerformance: ListingPerformanceReducer,
  QuotaCredits: QuotaCreditsReducer,
  licenseData: LicenseReducer,
  userLicenseData: UserLicenseReducer,
  applicableLicenseData: ApplicableLicenseReducer,
  monthRules: MonthReducer,
});

const rootReducers = (state, action) => {
  if (action.type === authActions.LOGOUT_SUCCESS) {
    return appReducers({ AppConfig: state.AppConfig }, action);
  }
  return appReducers(state, action);
};
export default rootReducers;
