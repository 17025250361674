import { checkRequiredKeys } from '../../tenant/checkRequiredKeys';
import BayutUtilities, { BAYUT_KEY } from './bayut';
import ZameenUtilities, { ZAMEEN_KEY } from './zameen';
import { TENANT_KEY } from '../../utility/env';

const configKeys = [];

export const tenants = Object.freeze({
  [ZAMEEN_KEY]: checkRequiredKeys(ZameenUtilities, configKeys),
  [BAYUT_KEY]: checkRequiredKeys(BayutUtilities, configKeys),
});

export default tenants[TENANT_KEY];
