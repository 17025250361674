import { NetworkService } from "../../../../services/networkService";
const manageAgencyInvite=async (values)=>{
    let response=await NetworkService.put('/api/invitations',values);
    if (response.error) {
      return {
        error: response.error,
      };
    } else {
      return response;
    }
}
export default manageAgencyInvite;