import { getErrorString, getKeyValueFromObject } from '../../utility/utility';

import actions from './actions';

const {
  FILTER_LISTING_BEGIN,
  FILTER_LISTING_SUCCESS,
  FILTER_LISTING_ERR,

  SORTING_LISTING_BEGIN,
  SORTING_LISTING_SUCCESS,
  SORTING_LISTING_ERR,

  FETCH_LISTING_BEGIN,
  FETCH_LISTING_SUCCESS,
  FETCH_LISTING_ERR,
  UPDATE_LIST,
  SET_UNIT,
  SET_LISTING_ID_FOR_BULK_REFRESH,
  FETCH_DELETE_REASONS_FAILURE,
  FETCH_DELETE_REASONS_BEGIN,
  FETCH_DELETE_REASONS_SUCCESS,
} = actions;

const initialState = {
  list: null,
  loading: true,
  error: null,
  filters: [],
  selectedListings: [],
  pagination: { current: 0, pageSize: 0 },
  deleteReasons: {
    list: [],
    loading: false,
    error: '',
  },
};

const listingReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FILTER_LISTING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FILTER_LISTING_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case FILTER_LISTING_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case FETCH_DELETE_REASONS_BEGIN:
      return {
        ...state,
        deleteReasons: { ...state.deleteReasons, loading: true },
      };
    case FETCH_DELETE_REASONS_SUCCESS:
      return {
        ...state,
        deleteReasons: { loading: false, error: '', list: data },
      };
    case FETCH_DELETE_REASONS_FAILURE:
      return {
        ...state,
        deleteReasons: { ...state.deleteReasons, loading: false, error: err },
      };
    case SORTING_LISTING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SORTING_LISTING_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case SORTING_LISTING_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FETCH_LISTING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LISTING_SUCCESS:
      const { viewType, ...rest } = data;
      return {
        ...state,
        ...rest,
        ...(viewType === 'mobile' && { list: [...(state.list ? state.list : []), ...rest?.list] }),
        error: null,
        loading: false,
      };

    case FETCH_LISTING_ERR:
      return {
        ...state,
        error: getErrorString(err),
        loading: false,
      };

    case UPDATE_LIST: {
      const list = [...state.list];
      const { replaceKey, valueExists = false, ...rest } = action.data;
      const index = list.findIndex(e => e.id === (replaceKey ? action.data.listing_id : action.data.id));
      list.splice(index, 1, {
        ...list[index],
        ...(replaceKey ? getKeyValueFromObject(list[index], replaceKey, rest, valueExists) : action.data),
      });
      return { ...state, list };
    }

    case SET_UNIT:
      return {
        ...state,
        units: {
          ...state.units,
          ...data,
        },
      };

    case SET_LISTING_ID_FOR_BULK_REFRESH:
      const { listings, selected, multiple, clearSelectedItems = false } = data;
      let newSelectedListings = [...state?.selectedListings];
      if (!clearSelectedItems) {
        if (selected) {
          if (multiple) {
            listings?.forEach(e => {
              newSelectedListings = [...newSelectedListings, e];
            });
          } else {
            newSelectedListings = [...newSelectedListings, listings];
          }
        } else {
          if (multiple) {
            listings?.forEach(e => {
              const index = newSelectedListings?.findIndex(it => it?.id === e?.id);
              if (index != -1) {
                newSelectedListings?.splice(index, 1);
              }
            });
          } else {
            const listingIndex = state.selectedListings?.findIndex(e => e?.id === listings?.id);
            if (listingIndex != -1) {
              newSelectedListings.splice(listingIndex, 1);
            }
          }
        }
      } else {
        newSelectedListings = [];
      }
      return {
        ...state,
        selectedListings: newSelectedListings,
      };

    default:
      return state;
  }
};

export { listingReducer };
