import { NetworkService } from "../../../../services/networkService";
const getAgencyLicense = async agencyId => {
  let response = await NetworkService.get(`api/agencies/${agencyId}/licenses`);
  if (response.error) {
    return {
      error: response.error,
    };
  } else {
    return response;
  }
};
export default getAgencyLicense;
