// eslint-disable-next-line max-classes-per-file

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import tenantTheme from '@theme';
import { addDays, endOfDay, startOfDay, subDays } from 'date-fns';
import React, { useState } from 'react';
import { createStaticRanges, DateRangePicker } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import { getStartEndValue } from '../../../utility/date';
import { Button } from '../button/button';
import { ButtonGroup, ItemWraper } from './style';

const defineds = {
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
};

const getStaticRanges = (reportsStaticRanges = false, t) => {
  return [
    {
      label: t('Today'),
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: t('Yesterday'),
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },

    {
      label: t('Last 7 Days'),
      range: () => ({
        startDate: addDays(new Date(), -6),
        endDate: startOfDay(new Date()),
      }),
    },
    {
      label: t('Last 15 Days'),
      range: () => ({
        startDate: addDays(new Date(), -14),
        endDate: startOfDay(new Date()),
      }),
    },
    {
      label: t('Last 30 Days'),
      range: () => ({
        startDate: addDays(new Date(), -29),
        endDate: startOfDay(new Date()),
      }),
    },
    ...(reportsStaticRanges
      ? [
          {
            label: t('Last 3 Months'),
            range: () => ({
              startDate: addDays(new Date(), -89),
              endDate: startOfDay(new Date()),
            }),
          },
          {
            label: t('Last 6 Months'),
            range: () => ({
              startDate: addDays(new Date(), -179),
              endDate: startOfDay(new Date()),
            }),
          },
          {
            label: t('Last Year'),
            range: () => ({
              startDate: addDays(new Date(), -364),
              endDate: startOfDay(new Date()),
            }),
          },
          {
            label: t('Last 2 Years'),
            range: () => ({
              startDate: addDays(new Date(), -729),
              endDate: startOfDay(new Date()),
            }),
          },
        ]
      : []),
  ];
};

const DateRangePickerOne = (props) => {
  const {
    setIsOpen,
    setDateRange,
    dateRange,
    onSelect,
    type = 'past',
    accentColor = tenantTheme['primary-color'],
    minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    reportsStaticRanges = false,
  } = props;
  const { t } = useTranslation();

  const [state, setState] = useState({
    datePickerInternational: null,
    dateRangePicker: {
      selection: {
        startDate: props.queryStartDate ? new Date(props.queryStartDate) : subDays(new Date(), 29),
        endDate: props.queryEndDate ? new Date(props.queryEndDate) : new Date(),
        key: 'selection',
      },
    },
  });

  const handleRangeChange = (which) => {
    setState({
      ...state,
      dateRangePicker: {
        ...state.dateRangePicker,
        ...which,
      },
    });
    setDateRange({
      ...state,
      dateRangePicker: {
        ...state.dateRangePicker,
        ...which,
      },
    });
  };

  const { dateRangePicker } = state;
  const start =
    dateRange && dateRange?.length != 0
      ? dateRange?.dateRangePicker?.selection.startDate.toString().split(' ')
      : dateRangePicker.selection.startDate.toString().split(' ');

  const end =
    dateRange && dateRange?.length != 0
      ? dateRange?.dateRangePicker?.selection.endDate.toString().split(' ')
      : dateRangePicker.selection.endDate.toString().split(' ');

  return (
    <ItemWraper accentColor={accentColor}>
      <DateRangePicker
        onChange={handleRangeChange}
        showSelectionPreview
        moveRangeOnFirstSelection={false}
        className="PreviewArea"
        months={2}
        {...props}
        {...(type === 'past' && {
          maxDate: new Date(),
          minDate: minDate,
        })}
        {...(type === 'future' && { minDate: new Date() })}
        ranges={
          dateRange && dateRange?.length != 0 ? [dateRange?.dateRangePicker?.selection] : [dateRangePicker?.selection]
        }
        direction="horizontal"
        inputRanges={[]}
        staticRanges={createStaticRanges(getStaticRanges(reportsStaticRanges, t))}
        rangeColors={[`${accentColor}`]}
        readOnly={true}
        onFocus={(e) => e.target.blur()}
      />

      <ButtonGroup>
        <p>{getStartEndValue(start, end)}</p>
        <div>
          <Button
            size="small"
            type="primary"
            onClick={() => {
              setIsOpen(false);
              setDateRange(state);
              onSelect(start, end);
            }}
            style={{ backgroundColor: accentColor || tenantTheme['primary-color'], border: 0 }}
          >
            {t('Search')}
          </Button>
          <Button size="small" type="white" outlined onClick={() => setIsOpen(false)}>
            {t('Cancel')}
          </Button>
        </div>
      </ButtonGroup>
    </ItemWraper>
  );
};

export { DateRangePickerOne };
