import tenantConstants from '@constants';

import actions from './actions';
import { strings } from '../../constants/strings';
const { SET_LISTING_PERFORMANCE_TABLE_DATA, SET_OLX_LISTING_PERFORMANCE_TABLE_DATA } = actions;

const initialState = {
  list: null,
  loading: true,
  error: null,
  filtersData: {
    dependentFields: {
      [tenantConstants.VALUE_IN_ARRAY_KEY('location')]: {
        [tenantConstants.VALUE_CONTAINS_KEY('city_name')]: true,
      },
      [tenantConstants.VALUE_IN_ARRAY_KEY('category')]: {
        [tenantConstants.VALUE_IN_ARRAY_KEY('platform')]: true,
      },
    },
    list: [
      {
        label: 'ID',
        type: 'input',
        key: tenantConstants.VALUE_EQUALS_KEY('id'),
        placeholder: strings.placeholders.property_id,
        labelProps: {
          muted: true,
        },
        inputType: 'number',
        limit: 30,
      },
    ],
  },
  table: [],
  pagination: {
    current: 0,
    pageSize: 0,
  },
};

const ListingPerformanceReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LISTING_PERFORMANCE_TABLE_DATA:
      return {
        ...initialState,
        ...payload,
      };
    case SET_OLX_LISTING_PERFORMANCE_TABLE_DATA:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export { ListingPerformanceReducer };
