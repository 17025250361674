import { getPermissionsObj } from '../user/userDetail';
import tenantData from '@data';
import store from '../../../../redux/store';
import { NetworkService } from '../../../../services/networkService';

const fetchAgencyUsers = async (agencyId) => {
  const loggedInUser = store.getState().loginUser.user;

  let response = await NetworkService.get(`/api/agencies/${agencyId}`);
  if (response) {
    if (response?.error) {
      return response;
    } else if (response?.data)
      return {
        data: response?.data?.agency?.users.map((e) => {
          const isOnOlx = e?.mapped_on_olx;
          return {
            ...e,
            id: e?.id,
            name: e?.name,
            email: e?.email,
            mobile: e?.mobile,
            landline: e?.phone,
            ...(!!e?.quota && { quota: { products: e?.quota } }),
            ...(!!e?.credits && { credits: { products: e?.credits } }),
            type: 'premium',
            permissions: e?.is_admin ? getPermissionsObj(true) : getPermissionsObj(false),
            is_owner: e?.id == response?.data?.creator_id,
            is_single_platform: !isOnOlx,
            can_post_free: { zameen: loggedInUser?.can_post_limit_listings, olx: false },
            listing_posted: e?.listing_posted > 0,
            platform_user: isOnOlx ? [{ id: 2, slug: 'olx', title: 'OLX' }] : [],
            platforms: isOnOlx ? tenantData.platformList : tenantData.platformList?.filter((e) => e?.slug != 'olx'),
            products: {
              platforms: {
                zameen: tenantData.platformList?.[0],
                ...(isOnOlx && { olx: tenantData.platformList?.[1] }),
              },
            },
          };
        }),
      };
  }
};

export default fetchAgencyUsers;
