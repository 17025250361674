import tenantApi from '@api';
import tenantConstants from '@constants';
import InputOtp from '@onefifteen-z/react-input-otp';
import tenantTheme from '@theme';
import { Space } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { KC_REQUESTS } from '../../keycloak/requests';
import { getRequestHeaders, NetworkService } from '../../services/networkService';
import { Button, Modal, notification, TextWithIcon } from '../common';
import { fetchUserDetail } from '../../redux/user/actionCreator';

const OtpVerificationModal = forwardRef(
  (
    { value, onCancelModal = () => {}, onSuccess = () => {}, onFail = () => {}, otpFor = 'mobile', okText, width },
    ref,
  ) => {
    const { t } = useTranslation();

    const [otpCode, setOtpCode] = useState('');
    const [timer, setTimer] = useState(60);
    const [otpModalShow, setOtpModalShow] = useState(false);
    const [otpVerifyLoading, setVerifyOtpLoading] = useState(false);
    const [onSendOtpLoading, setOnSendOtpLoading] = useState(false);
    const [otpError, setOtpError] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [verificationData, setVerificationData] = useState(false);
    const [otpForItem, setOtpFor] = useState(otpFor);

    const { user } = useSelector((state) => state.loginUser);
    const auth = useSelector((state) => state.auth.login);
    const dispatch = useDispatch();

    useEffect(() => {
      let countdown;
      if (otpModalShow) {
        if (timer > 0) {
          countdown = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
          }, 1000);
        }
      }
      return () => clearInterval(countdown);
    }, [timer, otpModalShow]);

    useEffect(() => {
      otpFor && setOtpFor(otpFor);
    }, [otpFor]);

    useImperativeHandle(ref, () => ({
      onVerify: onSendOtp,
    }));

    const mediums = { email: 'email', mobile: 'sms' };

    const onSendOtp = async (val, otpF = 'mobile') => {
      setOtpCode('');
      otpF && setOtpFor(otpF);
      const endpoint = KC_REQUESTS?.[otpF];
      setOnSendOtpLoading(true);
      const response = await (tenantConstants.KC_ENABLED
        ? NetworkService.post(
            endpoint?.url,
            endpoint?.body(val, mediums[otpF]),
            getRequestHeaders(auth, {}, endpoint?.url),
          )
        : tenantApi.getOtp(user?.id, val, otpF));
      if (response?.error) {
        setOnSendOtpLoading(false);
        notification.error(t(response.error));
        onFail();
      } else {
        setOnSendOtpLoading(false);
        setOtpModalShow(true);
        tenantConstants.KC_ENABLED && setVerificationData(response?.data);
      }
    };

    const onResendOtp = async () => {
      const endpoint = KC_REQUESTS?.[`resend_verify_${otpForItem}`];
      setResendLoading(true);
      const response = await (tenantConstants.KC_ENABLED
        ? NetworkService.post(
            endpoint?.url,
            endpoint?.body(verificationData, mediums[otpForItem]),
            getRequestHeaders(auth, {}, endpoint?.url),
          )
        : tenantApi.getOtp(user?.id, value, otpForItem));
      if (response?.error) {
        setResendLoading(false);
        notification.error(t(response.error));
      } else {
        setResendLoading(false);
        setOtpCode('');
        setTimer(60);
      }
    };

    const onVerifyOtp = async () => {
      if (!otpCode) {
        notification.error('Please enter OTP to verify!');
      } else {
        const verifyEndpoint = KC_REQUESTS?.[`verify_${otpForItem}`];
        const data = { [otpForItem]: value, otp: otpCode };
        setVerifyOtpLoading(true);
        setOtpError(false);
        const response = await (tenantConstants.KC_ENABLED
          ? NetworkService.post(
              verifyEndpoint?.url,
              verifyEndpoint?.body(verificationData, otpCode),
              getRequestHeaders(auth, {}, verifyEndpoint?.url),
            )
          : tenantApi.verifyOTP(user?.id, data, otpForItem));
        if (response?.error) {
          setVerifyOtpLoading(false);
          notification.error(t(response.error));
          setOtpError(true);
          onFail();
          // notification.error(response?.response?.data?.error_description);
        } else {
          setVerifyOtpLoading(false);
          setOtpModalShow(false);
          setOtpCode('');
          notification.success(t(`${otpForItem === 'email' ? 'Email' : 'Phone Number'} Verified Successfully`));
          dispatch(fetchUserDetail(user?.id));
          onSuccess();
        }
      }
    };

    const onCloseOtpModal = () => {
      setOtpError(false);
      setOnSendOtpLoading(false);
      setOtpModalShow(false);
      onCancelModal();
    };

    return (
      <Modal
        type="primary"
        title={t('OTP Verification')}
        visible={otpModalShow}
        onCancel={onCloseOtpModal}
        footer={null}
        width={width}
      >
        <Space size={20} direction="vertical" className="text-center w-100">
          <div className="text-muted fs16">
            {t(`You've received a 4-digit code on: `)}
            <strong className="base-color">{value}</strong>
          </div>
          <InputOtp
            error={otpError}
            errorMessage={t('Incorrect OTP')}
            otpLength={4}
            onChange={(value) => setOtpCode(value)}
            value={otpCode}
            wrapperClass="flex justify-content-center dir-ltr"
          />
          <Space size={4} align="center" className="text-muted fs16 semiBold">
            {!!timer && (
              <TextWithIcon
                icon="RxCounterClockwiseClock"
                iconProps={{ size: 18, color: tenantTheme['primary-light-1'] }}
                textColor={tenantTheme['primary-light-1']}
                gap={6}
                title={timer}
              />
            )}
          </Space>
          <div>
            <div className="text-muted">{t(`I didn’t receive any code`)}</div>
            <Button type="link" disabled={!!timer} onClick={onResendOtp} loading={resendLoading}>
              {t('Resend OTP')}
            </Button>
          </div>
          <Button type="primary" size="large" onClick={onVerifyOtp} block loading={otpVerifyLoading}>
            {okText || t('Continue')}
          </Button>
        </Space>
      </Modal>
    );
  },
);

export default OtpVerificationModal;
