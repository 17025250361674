import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import Flex from '../../../../components/common/flex';
import Image from '../../../../components/common/image/image';
import { Modal } from '../../../../components/common/modals/antd-modals';
import { useSelector } from 'react-redux';
import { BayutAppStoreArabicIcon, BayutPlayStoreArabicIcon } from '../../../../components/svg';
import { BayutAppStoreIcon, PlayStoreBadgeIcon } from '../../../../components/utilities/icons';
import { getBaseURL } from '../../../../utility/env';
import { downloadAppActionEvent } from '../../../../services/analyticsService';

const DownloadBayutModal = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const locale = useSelector((state) => state.AppConfig.locale);
  const { user } = useSelector((state) => state.loginUser);

  return (
    <Modal
      width={360}
      title={<span className="fw-600 fs20">{t('Get the Bayut KSA App')}</span>}
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <div>
        <Flex vertical align="center" gap="8px">
          <div className="fz-16 fw-600">{t('Scan the QR code to download the app')}</div>
          <Image
            width={166}
            src={`${getBaseURL()}/profolio-assets/images/downloadAppQRCode.svg`}
            size={166}
            preview={false}
            onClick={() => downloadAppActionEvent(user, 'qr_code')}
          />
        </Flex>

        <Divider type="horizontal">{t('OR')}</Divider>

        <Flex className="badges" align="center" justify="center" gap="10px">
          {locale === 'en' ? (
            <>
              <a
                href="https://apps.apple.com/app/apple-store/id1504036401?pt=121218764&ct=app_adoption&mt=8"
                target="_blank"
                rel="noreferrer"
                onClick={() => downloadAppActionEvent(user, 'app_store')}
              >
                <BayutAppStoreIcon width="auto" height="33" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.bayut.bayutsaapp&referrer=utm_source%3Dprofolio%26utm_medium%3Dqr%26utm_campaign%3Dapp_adoption"
                target="_blank"
                rel="noreferrer"
                onClick={() => downloadAppActionEvent(user, 'play_store')}
              >
                <PlayStoreBadgeIcon width="auto" height="34.8" />
              </a>
            </>
          ) : (
            <>
              <a
                href="https://apps.apple.com/us/app/bayut-saudi-arabia/id1504036401"
                target="_blank"
                rel="noreferrer"
                onClick={() => downloadAppActionEvent(user, 'app_store')}
              >
                <BayutAppStoreArabicIcon width="auto" height="33" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.bayut.bayutsaapp&hl=ar&gl=US"
                target="_blank"
                rel="noreferrer"
                onClick={() => downloadAppActionEvent(user, 'play_store')}
              >
                <BayutPlayStoreArabicIcon width="auto" height="34.8" />
              </a>
            </>
          )}
        </Flex>
      </div>
    </Modal>
  );
};

export default DownloadBayutModal;
