import tenantTheme from '@theme';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationModal, DataTable } from '../../../../../components/common';
import { UserForm } from '../../../../../components/forms/AgencyUserForm';
import TableActions from '../../../../../components/table/table-actions/table-actions';
import { useTableData } from '../../../../../hooks';
import { deleteAgencyUser, setAgencyStaffList } from '../../../../../redux/agencyStaff/actionCreator';
import { setUsersList } from '../../../../../redux/users/actionCreator';
import { selectUser } from '../../../../../redux/dashboard/actionCreators';

export const AgencyUserRowActions = (props) => {
  const { t } = useTranslation();
  const { userId, agencyId, isAdmin, isOwner, name, deleteModalData, quotaCredit, creditsAvailable } = props;
  const { user } = useSelector((state) => state.loginUser);
  const [showUserForm, setShowUserForm] = useState(false);
  const [showManageQuota, setShowManageQuota] = useState(false);
  const confirmationModalRef = useRef();
  const dispatch = useDispatch();
  const [data, columns] = useTableData(deleteModalData?.list, deleteModalData?.table || [], []);
  const [showAssignCredits, setShowAssignCredits] = useState(false);

  return (
    !isOwner &&
    userId != user.id && (
      <>
        <TableActions
          id={`${agencyId}-${userId}`}
          actionsList={[
            {
              iconType: 'edit',
              onClick: () => {
                setShowUserForm(true);
              },
              color: tenantTheme['primary-color'],
            },
            {
              iconType: 'delete',
              onClick: () => {
                confirmationModalRef?.current && confirmationModalRef.current.showModal();
              },
              color: tenantTheme['primary-color'],
            },
          ]}
        />

        <UserForm
          isVisible={showUserForm}
          setIsVisible={setShowUserForm}
          agencyId={agencyId}
          userId={userId}
          isAdmin={isAdmin}
        />
        <ConfirmationModal
          ref={confirmationModalRef}
          title={t('Delete User')}
          onSuccess={async () => {
            confirmationModalRef.current.setLoading(true);
            const deletedUser = await deleteModalData?.onDelete();
            confirmationModalRef.current.setLoading(false);
            if (deletedUser) {
              if (deletedUser.error) {
                confirmationModalRef.current.setError(deletedUser.error);
              } else {
                dispatch(deleteAgencyUser(deletedUser));
                dispatch(setAgencyStaffList(user, agencyId));
                dispatch(setUsersList(agencyId));
                dispatch(selectUser(user));
                confirmationModalRef?.current && confirmationModalRef.current.hideModal();
              }
            }
          }}
        >
          <p>{t('The following User will be deleted')}</p>
          <DataTable data={data} columns={columns} tabs={false} />
        </ConfirmationModal>
      </>
    )
  );
};
