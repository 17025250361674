import { checkRequiredKeys } from '../../tenant/checkRequiredKeys';
import { TENANT_KEY } from '../../utility/env';
import BayutData, { BAYUT_KEY } from './bayut';
import ZameenData, { ZAMEEN_KEY } from './zameen';

const configKeys = [];

export const tenants = Object.freeze({
  [ZAMEEN_KEY]: checkRequiredKeys(ZameenData, configKeys),
  [BAYUT_KEY]: checkRequiredKeys(BayutData, configKeys),
});

export default tenants[TENANT_KEY];
