import tenantApi from '@api';
import tenantData from '@data';
import tenantUtils from '@utils';
import { Col, Row, Tooltip } from 'antd';
import Countdown from 'antd/lib/statistic/Countdown';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ConfirmationModal,
  DataTable,
  Group,
  Heading,
  Icon,
  notification,
} from '../../../../../components/common';
import { IconZoneFactor } from '../../../../../components/svg';
import { PERMISSIONS_TYPE } from '../../../../../constants/permissions';
import { useGetLocation } from '../../../../../hooks';
import {
  fetchExpiryData,
  fetchQuotaCreditsData,
  updateListForDashboard,
} from '../../../../../redux/dashboard/actionCreators';
import { updateList } from '../../../../../redux/listings/actionCreator';
import { fetchUserProducts } from '../../../../../redux/user/actionCreator';
import { getTimeDateString } from '../../../../../utility/date';
import { formatNumberString } from '../../../../../utility/utility';
import { PostStoryModal } from '../post-story/post-story';
import { JeffiDetail } from './JeffiDetail';
import { TIME_DATE_FORMAT } from '../../../../../constants/formats';
const quotaColumns = (shifted) => {
  return [
    { title: shifted ? 'Quota Available' : 'Credits Available', dataIndex: 'quota_available', key: 'quota_available' },
    { title: shifted ? 'Quota Required' : 'Credits Required', dataIndex: 'quota_used', key: 'quota_used' },
  ];
};

const creditColumns = [
  { title: 'Product', dataIndex: 'product', key: 'product' },
  { title: 'Credits Available', dataIndex: 'credits_available', key: 'credits_available' },
  { title: 'Credits Required', dataIndex: 'credits_used', key: 'credits_used' },
];

const removeStoryColumns = [
  { title: 'ID', dataIndex: 'id', key: 'id' },
  { title: 'Purpose', dataIndex: 'purpose', key: 'purpose' },
  { title: 'Location', dataIndex: 'location', key: 'location' },
  { title: 'Price', dataIndex: 'price', key: 'price' },
  { title: 'Listed Date', dataIndex: 'date', key: 'date' },
];

const platformProducts = {
  zameen: ['shot_listing', 'hot_listing', 'refresh_listing', 'story_ad', 'post', 'repost', 'upgrade', 'publish'],
  olx: ['olx_feature', 'olx_refresh_listing', 'post', 'repost', 'upgrade'],
};

const canPostProduct = (status, slug) => {
  const index = tenantUtils.statusDependentPlatformActions[slug].split(',').findIndex((e) => e == status);
  return index != -1;
};

function PlatformAction(props) {
  const { t } = useTranslation();
  const {
    property_id,
    purpose,
    location,
    item,
    platform,
    isMobile,
    status,
    actionType,
    stories,
    storyViews,
    price,
    posted_on,
    listingOwner,
  } = props;
  const [quotaApplied, setQuotaApplied] = useState(null);
  const [creditsApplied, setCreditApplied] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [imagesToPost, setImagesToPost] = useState([]);
  const [postStoryModal, setPostStoryModal] = useState(false);
  const postConfirmRef = useRef();
  const postStoryRef = useRef();
  const applyProductModalRef = useRef();
  const removeStoryRef = useRef();
  const jeffiRef = useRef();
  const dispatch = useDispatch();
  const { pathname } = useGetLocation();

  const user = useSelector((state) => state.loginUser.user);
  const reduxQuotaCredit = useSelector((state) => state.Dashboard?.quotaCreditsData);

  const selectedUser = reduxQuotaCredit?.selectedUser?.[platform.slug]
    ? reduxQuotaCredit?.selectedUser[platform.slug]
    : user?.permissions?.[PERMISSIONS_TYPE.PROFILE]
      ? -1
      : user?.id;

  const getQuotaDeduction = async () => {
    setLoading(true);
    const response = await tenantApi.getQuotaDeductionApi({
      subject_id: listingOwner?.id,
      location_id: location?.id,
    });
    if (response) {
      if (response.error) {
        setLoading(false);
        notification.error(response.error);
      } else {
        if (response?.[platform.slug]?.available < response?.[platform.slug]?.required) {
          setLoadingProduct(false);
          setLoading(false);
          notification.error(
            listingOwner?.id === user?.id
              ? t('Insufficient Quota')
              : `${listingOwner?.name} ${t('has insufficient quota')}`,
          );
        } else {
          setQuotaApplied(response?.[platform.slug]);
          postConfirmRef?.current && postConfirmRef.current.showModal();
        }
      }
    }
  };

  const getCreditDeduction = async (productType) => {
    setLoadingProduct(true);
    const response = await tenantApi.getCreditDeductionApi(listingOwner?.id, {
      listing_id: property_id,
      product_id: user?.products?.platforms?.[platform.slug]?.all
        ? user.products.platforms[platform.slug].all.find((e) => e.slug == item.slug)?.id
        : tenantData.products?.find((e) => e.slug === item?.slug)?.id,
    });
    if (response) {
      setLoadingProduct(false);
      setLoading(false);
      if (response.error) {
        notification.error(response.error);
      } else if (response) {
        if (response?.availableCredits < response?.creditToDeduct) {
          notification.error(
            listingOwner?.id === user?.id
              ? t('Insufficient Credits')
              : `${listingOwner?.name} ${t('has insufficient credits')}`,
          );
        } else {
          setCreditApplied(response);
          if (productType === 'story') {
            postStoryRef?.current && postStoryRef.current.showPostStoryModal();
          } else {
            applyProductModalRef?.current && applyProductModalRef.current.showModal();
          }
        }
      }
    }
  };

  const setStoryCreditDeduction = useCallback(
    (creditToDeduct, selectedImages, totalCredits) => {
      setCreditApplied({
        creditToDeduct,
        availableCredits: totalCredits,
      });

      setImagesToPost(selectedImages);
      applyProductModalRef?.current && applyProductModalRef.current.showModal();
    },
    [listingOwner?.id],
  );

  const onPost = async () => {
    postConfirmRef?.current && postConfirmRef.current.setLoading(true);
    const response = await tenantApi.postSavedListing(listingOwner, property_id, platform.slug, status.slug);
    if (response) {
      setLoading(false);
      postConfirmRef?.current && postConfirmRef.current.setLoading(false);
      if (response.error) {
        notification.error(response.error);
      } else {
        dispatch(fetchUserProducts());
        if (response.data) {
          if (pathname === '/dashboard') {
            dispatch(updateListForDashboard(response.data.data));
            dispatch(fetchQuotaCreditsData(selectedUser, platform.slug, user));
            dispatch(fetchExpiryData(selectedUser.id, platform.slug, user));
          } else {
            dispatch(updateList(response.data.data));
          }
        }
        postConfirmRef.current && postConfirmRef.current.hideModal();
      }
    }
  };

  const onApplyProduct = async () => {
    setLoadingProduct(true);
    applyProductModalRef?.current && applyProductModalRef.current.setLoading(true);
    const response = await tenantApi.postPackageOnListing(listingOwner?.id, property_id, platform.slug, {
      [platform.slug]: {
        product_slug: item.slug,
        ...(item.slug === 'story_ad' && { no_of_days: creditsApplied?.creditToDeduct, images_ids: imagesToPost }),
      },
    });
    if (response) {
      setLoadingProduct(false);
      applyProductModalRef?.current && applyProductModalRef.current.setLoading(false);
      if (response.error) {
        notification.error(response.error);
      } else {
        if (response?.storySuccess) {
          applyProductModalRef?.current && applyProductModalRef.current.hideModal();
          notification.success(response.message);
        } else {
          dispatch(fetchUserProducts());
          if (!!response.data && !response?.dontUpdate) {
            if (pathname === '/dashboard') {
              dispatch(updateListForDashboard(response.data));
              dispatch(fetchQuotaCreditsData(selectedUser, platform.slug, user));
              dispatch(fetchExpiryData(selectedUser.id, platform.slug, user));
            } else {
              dispatch(updateList(response.data));
            }
          }
          if (pathname === '/dashboard') {
            dispatch(fetchQuotaCreditsData(selectedUser, platform.slug, user));
            dispatch(fetchExpiryData(selectedUser.id, platform.slug, user));
          }
          !!applyProductModalRef?.current && applyProductModalRef.current.hideModal();
        }
      }
    }
  };

  const onDelete = async () => {
    const response = await tenantApi.deleteStory(listingOwner?.id, stories?.story_id, property_id);
    if (response) {
      setLoadingProduct(false);
      removeStoryRef?.current && removeStoryRef.current.hideModal();
      if (response?.error) {
        notification.error(response.error);
      } else {
        if (pathname === '/dashboard') {
          dispatch(updateListForDashboard(response.data));
        } else {
          dispatch(updateList(response.data));
        }
      }
    }
  };

  const renderDataTable = (label, tableClassName, data, columns) => {
    return (
      <>
        <div className="mb-8">{label}</div>
        <DataTable className={tableClassName} columns={columns} data={data} />
      </>
    );
  };

  const stroyAdContent = (color) => {
    return (
      <>
        <Heading as="h6" className="mb-12">
          Zameen Stories
        </Heading>
        <Row gutter={12} align="top" style={{ minWidth: 200 }} className="mb-12">
          <Col xs={12}>
            <div style={{ fontSize: 12 }}>
              <strong>Posted On</strong>
              <div>{getTimeDateString(stories?.published_at, TIME_DATE_FORMAT) || '-'}</div>
            </div>
          </Col>
          <Col xs={12}>
            <div style={{ fontSize: 12 }}>
              <strong>Posted Count</strong>
              <div>{stories?.posted_count || '-'}</div>
            </div>
          </Col>

          <Col xs={12}>
            <div style={{ fontSize: 12 }}>
              <strong>Story Views</strong>
              <div>{storyViews || '-'} </div>
            </div>
          </Col>
          <Col xs={12}>
            <div style={{ fontSize: 12 }}>
              <strong>Time Remaining</strong>
              <Countdown
                value={getTimeDateString(stories?.expired_at, TIME_DATE_FORMAT)}
                format={'HH [Hours]'}
                valueStyle={{ fontSize: 12 }}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={12} align="middle">
          {/* <Col xs={12}>
            <Button type="primary" block>
              Preview
            </Button>
          </Col> */}
          <Button
            onClick={() => {
              setLoadingProduct(true);
              removeStoryRef?.current && removeStoryRef.current.showModal();
            }}
            transparented
            block
            style={{ borderColor: '#f5222d', '--btn-content-color': '#f5222d' }}
          >
            Remove
          </Button>
        </Row>
      </>
    );
  };

  const content = useCallback(
    (title, time, color, isApplied) =>
      !isApplied ? (
        <Heading as="h6" style={{ color }}>
          {title}
        </Heading>
      ) : title === 'Apply Story Ad' ? (
        <div>{stroyAdContent(color)}</div>
      ) : (
        <>
          <div>
            <Heading as="h6" style={{ color }}>
              {item?.appliedTitle}
            </Heading>
            {time && (
              <div style={{ fontSize: 12 }}>
                Expiring in
                <div>
                  <strong>{time.text}</strong>
                </div>
              </div>
            )}
          </div>
        </>
      ),
    [(item, item?.title, item?.color, item?.applied)],
  );

  const renderPostButton = (title) => {
    return (
      <div className="span-all">
        <Button
          onClick={() => {
            getQuotaDeduction();
          }}
          block
          style={{ padding: '4px 8px' }}
          loading={loading}
          disabled={loading}
        >
          {title}
          <Icon icon={platform.icon} size="1.4em" style={{ verticalAlign: 'text-bottom' }} />
        </Button>
      </div>
    );
  };

  return (
    <>
      <ConfirmationModal
        ref={postConfirmRef}
        onSuccess={onPost}
        onCancel={() => {
          setLoading(false);
          setLoadingProduct(false);
          setQuotaApplied(null);
        }}
      >
        {renderDataTable(
          user?.is_shifted_to_olx_quota ? 'Following quota will be utilized' : 'Following credits will be utilized',
          'mb-16',
          [
            {
              quota_available: quotaApplied?.available && quotaApplied?.available,
              quota_used: quotaApplied?.required && quotaApplied?.required,
            },
          ],
          quotaColumns(user?.is_shifted_to_olx_quota),
        )}
        {!!quotaApplied?.isZoneArea && (
          <Row className="mb-20">
            <Col md={1} xs={2}>
              <IconZoneFactor />
            </Col>
            <Col md={23} xs={22} className="fs12">
              <strong>Zone Factor Alert</strong>
              <p className="color-gray-dark">{`You are posting the listing to a premium location, ${formatNumberString(
                quotaApplied?.zoneFactor,
              )} extra ${
                platform?.slug === 'olx' && !user.is_shifted_to_olx_quota ? 'credit' : 'quota'
              } will be deducted because of zone factor`}</p>
            </Col>
          </Row>
        )}
      </ConfirmationModal>
      {!item?.applied && (
        <ConfirmationModal
          width={'708px'}
          ref={applyProductModalRef}
          onSuccess={onApplyProduct}
          onCancel={() => {
            setCreditApplied(null);
            setLoadingProduct(false);
            setLoading(false);
          }}
        >
          <>
            {renderDataTable(
              'Following credits will be utilized',
              'mb-16',
              [
                {
                  product: <>{item?.name}</>,
                  credits_available:
                    creditsApplied?.availableCredits && Number(creditsApplied?.availableCredits)?.toFixed(1),
                  credits_used: creditsApplied?.creditToDeduct && Number(creditsApplied?.creditToDeduct)?.toFixed(1),
                },
              ],
              creditColumns,
            )}
            {creditsApplied?.zoneFactor > 1 && (
              <Row className="mb-20">
                <Col md={1} xs={2}>
                  <IconZoneFactor />
                </Col>
                <Col md={23} xs={22} className="fs12">
                  <strong>Zone Factor Alert</strong>
                  <p className="color-gray-dark">{`You are upgrading the listing on a premium location, ${formatNumberString(
                    creditsApplied?.zoneFactor && Number(creditsApplied?.zoneFactor - 1).toFixed(1),
                  )} extra credit will be deducted because of zone factor`}</p>
                </Col>
              </Row>
            )}
          </>

          {item?.slug === 'shot_listing' && <JeffiDetail listingId={property_id} ref={jeffiRef} isMobile={isMobile} />}
        </ConfirmationModal>
      )}
      <ConfirmationModal
        width={'708px'}
        ref={removeStoryRef}
        okText={'Delete Story'}
        onSuccess={onDelete}
        onCancel={() => {
          setLoading(false);
          setLoadingProduct(false);
        }}
      >
        <>
          {renderDataTable(
            <strong> Delete Stories</strong>,
            'mb-16',
            [
              {
                id: <>{property_id}</>,
                purpose: purpose?.alternate_title,
                location: location?.long_title,
                price: price?.value,
                date: posted_on,
              },
            ],
            removeStoryColumns,
          )}
          <p className="color-gray-dark">
            The story of the following property will be destroyed. Are you sure you want to delete?
          </p>
        </>
      </ConfirmationModal>
      <PostStoryModal
        ref={postStoryRef}
        isModalOpen={postStoryModal}
        setIsModalOpen={setPostStoryModal}
        userId={listingOwner?.id}
        {...props}
        setStoryCreditDeduction={setStoryCreditDeduction}
        totalCredits={creditsApplied?.availableCredits && Number(creditsApplied?.availableCredits)?.toFixed(1)}
        isMobile={isMobile}
      />
      {actionType === 'repost' ? (
        renderPostButton('Re-post to')
      ) : actionType === 'upgrade' ? (
        renderPostButton('Upgrade')
      ) : actionType === 'post' ? (
        renderPostButton('Post to')
      ) : actionType === 'publish' ? (
        renderPostButton('Publish Now')
      ) : item?.canApply ? (
        <Tooltip placement="top" title={content(`${item.title}`, item.description, item.color, item?.applied)}>
          <Button
            onClick={() => {
              if (item.slug === 'story_ad') {
                getCreditDeduction('story');
              } else getCreditDeduction();
            }}
            shape="circle"
            icon={item.icon}
            iconSize={item.iconSize}
            loading={loadingProduct}
            enableOnHover
          />
        </Tooltip>
      ) : item?.applied ? (
        <Tooltip
          placement="top"
          title={content(item.title, item.description, item?.color || item?.iconProps?.color, item?.applied)}
        >
          <Button
            shape="circle"
            icon={item.icon}
            iconSize={item.iconSize}
            opaque={item.opaque}
            color={item?.color || item?.iconProps?.color}
            iconColor={item.iconColor}
            loading={loadingProduct}
          />
        </Tooltip>
      ) : (
        // <Tooltip placement="top" title={content(item?.title, item?.description, item?.color, item?.applied)}>
        <div>
          <Button shape="circle" icon={item?.icon} iconSize={item?.iconSize} loading={loadingProduct} disabled />
        </div>
        //  </Tooltip>
      )}
    </>
  );
}

const PlatformActions = (props) => {
  const { data = [], stories = {}, stats, ...rest } = props;
  return (
    <Group className={rest.className} gap="8px" template="repeat(5, minmax(0, 32px))" style={rest.style}>
      {data &&
        data?.map((item) => {
          const actions = platformProducts[item.slug]?.filter((e) => canPostProduct(item.status.slug, e));
          return !actions?.length ? (
            <div style={{ height: '32px' }} class="span-all"></div>
          ) : (
            actions?.map((e) => {
              return (
                <PlatformAction
                  item={tenantData.getListingActions(e, item.item)}
                  posted_on={item?.posted_on}
                  listingOwner={item?.listingOwner}
                  stories={stories?.active}
                  storyViews={stats?.story_vws_total}
                  platform={{ slug: item.slug, icon: item.icon }}
                  key={e}
                  actionType={e}
                  status={item?.status}
                  {...rest}
                />
              );
            })
          );
        })}
    </Group>
  );
};

export default PlatformActions;
