import { NetworkService } from '../../../../services/networkService';
import { getClassifiedBaseURL } from '../../../../utility/env';

export const fetchFavoriteProperties = async () => {
  const response = await NetworkService.get(`${getClassifiedBaseURL()}/api/user/favorites`);
  return response;
};

export const saveUserSettings = async payload => {
  const response = await NetworkService.post(`${getClassifiedBaseURL()}/api/saveSettings`, payload);
  return response;
};

export const logout = async payload => {
  const response = await NetworkService.post(`${getClassifiedBaseURL()}/api/logout`, payload);
  return response;
};
