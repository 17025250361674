import tenantConstants from '@constants';
import { BroadcastChannel } from 'broadcast-channel';
import { jwtDecode } from 'jwt-decode';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import keycloak from '../keycloak';
import { KC_REQUESTS } from '../keycloak/requests';
import { logOut, login, setAuthTokens } from '../redux/authentication/actionCreator';
import { NetworkClient, NetworkService, getRequestHeaders } from '../services/networkService';
import { logout } from '../tenant/components/bayut/layout/apis';
import { removeCookies } from '../utility/cookies';
import { broadCastChannelName, isDevelopment } from '../utility/env';

const useAppAuthentication = (initialize) => {
  const REFRESH_TIME_GAP = 15000; // Time to refresh before expiry (00:00:15)

  const isMemberArea = useSelector((state) => state.AppConfig.isMemberArea);
  const auth = useSelector((state) => state.auth.login);
  const dispatch = useDispatch();
  const authRef = useRef(auth);
  const channel = initialize && tenantConstants.KC_ENABLED && new BroadcastChannel(broadCastChannelName);

  const awaitForUserToken = auth.authenticated || !auth.initialized;

  const getMessage = (k) => ({ idToken: k.idToken, refreshToken: k.refreshToken, token: k.accessToken });

  const getAuthObj = useCallback(() => auth, [auth.token]);

  useEffect(() => {
    if (initialize && tenantConstants.KC_ENABLED) {
      authRef.current = auth;
    }
  }, [auth.token]);

  useEffect(() => {
    if (initialize && auth.token) {
      setRequestConfigs();
      checkErrorStatus();
    }
  }, [auth.token]);

  useEffect(() => {
    if (initialize && tenantConstants.KC_ENABLED) {
      initializeKeycloak();
      document.addEventListener('visibilitychange', refreshTokenAfterTabChange);
      channel.onmessage = (message) => onMessage(message);
    }
  }, []);

  useEffect(() => {
    // To Check
    if (initialize && tenantConstants.KC_ENABLED && auth.token) {
      try {
        const decodedToken = jwtDecode(auth.token);
        const expiryTime = decodedToken.exp * 1000;
        const now = Date.now();
        const delay = expiryTime - now - REFRESH_TIME_GAP;
        if (delay > 0) {
          const timer = setInterval(() => {
            if (document.visibilityState === 'visible') {
              handleRefreshToken(auth.refreshToken);
            }
          }, delay);
          return () => clearTimeout(timer);
        }
      } catch (e) {
        console.log('=> Error in initalize: ', e);
      }
    }
  }, [auth.authenticated]);

  const setStoreTokens = (obj, rest) => {
    const authObj = {
      token: obj.token,
      idToken: obj.idToken,
      refreshToken: obj.refreshToken,
      initialized: true,
      authenticated: true,
      ...rest,
    };
    dispatch(setAuthTokens(authObj));
  };

  const setRequestConfigs = () => {
    NetworkClient.interceptors.request.use(config => {
      const requestConfig = config;
      const latestAuthObj = getAuthObj();
      const { headers } = config;
      if (config.url.includes('/realms/')) {
        requestConfig.withCredentials = false;
     } else {
        requestConfig.headers = { ...headers, ...getRequestHeaders(latestAuthObj, headers, config.url) };
        requestConfig.withCredentials = true;
      }
      return requestConfig;
    });
  };

  const checkErrorStatus = () => {
    NetworkClient.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          console.log('=> 401: ', error, auth);
        }
        return Promise.reject(error);
      },
    );
  };

  const initializeKeycloak = async () => {
    if (isDevelopment) {
      try {
        const kc = await keycloak;
        const authenticated = await kc.init({ onLoad: 'login-required' });
        setStoreTokens(kc, { authenticated });
        if (!authenticated) {
          kc.login();
        }
      } catch (error) {
        console.error('=> KC Init error: ', error);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } else {
      keycloak
        .init()
        .then((authenticated) => {
          if (!authenticated) {
            keycloak.login();
            return;
          } else {
            setStoreTokens(keycloak, { token: keycloak.accessToken });
          }
        })
        .catch(error => {
          console.error('=> KC Init error:', error);
          onLogout();
        });
    }
  };

  const handleRefreshToken = async (rT, onSuccess = () => {}) => {
    if (tenantConstants.KC_ENABLED) {
      if (isDevelopment) {
        const kc = await keycloak;
        kc.updateToken();
      } else {
        keycloak
          .refresh(rT || auth.refreshToken)
          .then(() => {
            setStoreTokens(keycloak, { token: keycloak.accessToken });
            channel.postMessage(JSON.stringify(getMessage(keycloak)));
            onSuccess();
          })
          .catch(e => {
            console.error('=> Refresh Error: ', e);
            onLogout();
          });
      }
    } else {
      return;
    }
  };

  const refreshTokenAfterTabChange = () => {
    if (!document.hidden) {
      try {
        const currentAuth = authRef.current;
        const decodedToken = jwtDecode(currentAuth?.token);
        const expiryTime = decodedToken.exp * 1000;
        const now = Date.now();
        const delay = expiryTime - now - REFRESH_TIME_GAP;
        if (delay <= 0) {
          handleRefreshToken(currentAuth.refreshToken);
        }
      } catch (e) {
        console.log('Error in refresh: ', e);
      }
    }
  };

  const onMessage = (message) => {
    const data = JSON.parse(message);
    setStoreTokens(data);
  };

  const redirectToLogin = () => {
    window.location.replace(tenantConstants.GET_LOGIN_PATH(window.location.href));
  };

  const onLogin = (values) => {
    if (!tenantConstants.KC_ENABLED) {
      dispatch(
        login(values, (response) => {
          // navigate(tenantRoutes.app('', true, response.user)[0].path);
        }),
      );
    }
  };

  const onLogout = useCallback(async () => {
    if (tenantConstants.KC_ENABLED) {
      if (isDevelopment) {
        const kc = await keycloak;
        kc.logout();
      } else {
        if (auth.refreshToken) {
          try {
            keycloak.logout();
            const logoutEndpoint = KC_REQUESTS.logout;
            const response = await NetworkService.post(logoutEndpoint.url, logoutEndpoint.body(auth.refreshToken), {
              ...getRequestHeaders(auth, {}, logoutEndpoint.url),
              'Content-Type': 'application/x-www-form-urlencoded',
            });
            if (response.data === '') {
              redirectToLogin();
            }
          } catch (e) {
            redirectToLogin();
          }
        } else {
          redirectToLogin();
        }
      }
    } else {
      if (isMemberArea) {
        const response = await logout();
        if (response) {
          removeCookies();
        }
      } else {
        if (isDevelopment) {
          dispatch(
            logOut(() => {
              removeCookies();
              // navigate(tenantConstants.GET_LOGIN_PATH(''));
            }),
          );
        } else {
          removeCookies();
        }
      }
    }
  }, [auth.token]);

  return { auth, awaitForUserToken, redirectToLogin, onLogin, onLogout, handleRefreshToken };
};
export default useAppAuthentication;
