import tenantTheme from '@theme';
import Cookies from 'js-cookie';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../../../../components/common';
import { getClassifiedBaseURL } from '../../../../utility/env';

export const currencyArabicConversionUnitList = [
  { slug: 'AED', label: 'AED', alt_label: 'United Arab Emirates (AED)' },
  { slug: 'EUR', label: 'EUR', alt_label: 'European Union (EUR)' },
  { slug: 'GBP', label: 'GBP', alt_label: 'United Kingdom (GBP)' },
  { slug: 'INR', label: 'INR', alt_label: 'India (INR)' },
  { slug: 'PKR', label: 'PKR', alt_label: 'Pakistan (PKR)' },
  { slug: 'RUB', label: 'RUB', alt_label: 'Russian Federation (RUB)' },
  { slug: 'SAR', label: 'SAR', alt_label: 'Saudi Arabia (SAR)' },
  { slug: 'USD', label: 'USD', alt_label: 'United States of America (USD)' },
];
export const areaRangeUnitList = [
  { slug: 'SQFT', alt_label: 'Square Feet', label: 'Sq. ft.' },
  { slug: 'SQYD', alt_label: 'Square Yards', label: 'Sq. yd.' },
  { slug: 'SQM', alt_label: 'Square Meters', label: 'Sq. m.' },
];

export const getCookieUnits = (t) => {
  const settingsCookie = Cookies.get('settings');
  const settings = settingsCookie && JSON.parse(settingsCookie);
  return { currency: settings?.currency || 'SAR', area: settings?.area || 'SQM' };
};

export const setCookieUnits = (obj) => {
  const settingsCookie = Cookies.get('settings');
  const settingsObj = settingsCookie ? JSON.parse(decodeURIComponent(settingsCookie)) : {};
  const newCookie = JSON.stringify({ ...settingsObj, ...obj });
  Cookies.set('settings', newCookie);
};

export const getLink = (title, path, external, icon, locale, isNotClassified) => {
  const pathLocale = locale ? (locale == 'ar' ? '' : `/${locale}`) : '';
  const p = external ? `${!isNotClassified ? getClassifiedBaseURL() : ''}${pathLocale}${path}` : path;
  return external ? (
    <a style={{ color: tenantTheme['dark-color'] }} href={p}>
      {title}
    </a>
  ) : (
    <Link style={{ color: tenantTheme['dark-color'] }} to={p}>
      {title} {icon && <Icon icon={icon} />}
    </Link>
  );
};
