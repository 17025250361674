import tenantRoutes from '@routes';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetLocation, useRouteNavigate } from '../../hooks';
import SuccessModal from '../success-modal/success-modal';

const PitchPackageModal = ({ visible, setIsVisible, onContinue }) => {
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const navigate = useRouteNavigate();
  const location = useGetLocation();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.loginUser);

  const modalData = useMemo(
    () => ({
      title: t('Save Up to 44% with a Package!'),
      description: t(
        'Get a package to get credits at a discounted price along with access to Profolio, agency staff, lead reports, and much more',
      ),
      buttons: [
        {
          btnText: t('Continue'),
          type: 'default',
          href: null,
          onClick: onContinue,
        },
        {
          btnText: t('Get a Package'),
          type: 'primary',
          href: null,
          onClick: () =>
            navigate(`${tenantRoutes.app('', false, user).prop_shop.path}?redirectUrl=${location.pathname}`),
        },
      ],
    }),
    [onContinue],
  );

  return (
    <SuccessModal
      successImage="PackageSuccessIcon"
      handleCancel={() => setIsVisible(false)}
      visible={visible}
      iconStyle={{ marginBottom: isMobile ? '20px' : '30px' }}
      title={modalData?.title}
      description={modalData?.description}
      buttons={modalData?.buttons}
    />
  );
};

export default PitchPackageModal;
