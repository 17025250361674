import tenantUtils from '@utils';
import { imageStateObject } from '../../../../../helpers/imageHelpers/imageStateObject';
import { formatMobile } from '../../../../../utility/utility';

export const getAgencyStaffUserValues = values => {
  if (!values) return null;
  return {
    name: values?.name,
    email: values?.email,
    mobile: values?.mobile ? formatMobile(values?.mobile, 'singleNumber') : '',
    whatsapp: values?.whatsapp ? formatMobile(values?.whatsapp, 'singleNumber') : '',
    landline: values?.phone ? formatMobile(values?.phone, 'singleNumber') : '',
    address: values?.address,
    country: values?.country_id || 155,
    city: {
      city: values?.city_id && { location_id: values?.city_id, name: tenantUtils.getLocalisedString(values, 'city') },
    },
    ...(values?.profile_image && {
      image: [{ gallerythumb: values?.profile_image, ...imageStateObject() }],
    }),
    nameArabic: values?.name_l1,
    fALLicenseNumber: values?.license?.number,
    fALLicenseExpiryDate: values?.license?.end_date,
    falLicenseURL: values?.license?.certificate_link,
    nationalShortAddress: values?.national_short_address,
  };
};
