import { NetworkService } from "../../../../services/networkService";

export const requestPayment = async data => {
  const response = await NetworkService.post(`/api/checkout/request_payment`, {
    ...data,
    success_url: `${window.location.origin}/content/process-payment?status=success`,
    failure_url: `${window.location.origin}/content/process-payment?status=failed`,
  });
  if (response) {
    return response;
  }
};

export const updatePayment = async (sessionId, orderId) => {
  const response = await NetworkService.post(`/api/checkout/update_payment`, {
    'cko-session-id': sessionId,
  });
  if (response) {
    return response;
  }
};
