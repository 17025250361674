import { NetworkService } from '../../../../services/networkService';

export const inviteAgencyUser = async (values) => {
  let response = await NetworkService.get(`/api/invitations/new?invitee_type=User&invitee_phone=${values}`);
  if (response.error) {
    return {
      error: response.error,
    };
  } else {
    return response;
  }
};
export const checkInviteOtp = async (values) => {
  let response = await NetworkService.post('/api/invitations', values);
  if (response.error) {
    return {
      error: response.error,
    };
  } else {
    return response;
  }
};
