import { NetworkService } from '../../../../services/networkService';
import { agencyFormFields } from './agencyForm/agencyFormFields';
import { getAgencyStaffUserValues } from './agencyForm/getAgencyUserValues';

const fetchAgencyUserDetail = async userId => {
  if (!userId)
    return {
      formFields: await agencyFormFields(),
    };
  let response = await NetworkService.get(`/api/users/${userId}`);

  if (response?.error || !response?.data?.user) {
    return {
      error: response?.error || 'Network Error',
      formFields: await agencyFormFields(userId),
    };
  } else {
    return {
      agencyUserData: getAgencyStaffUserValues(response?.data?.user),
      formFields: await agencyFormFields(userId),
    };
  }
};

export default fetchAgencyUserDetail;
