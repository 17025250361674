import tenantData from '@data';
import tenantUtils from '@utils';
import store from '../../../../redux/store';
import { NetworkService } from '../../../../services/networkService';
import { getVariousDates } from '../../../../utility/date';
import { mapQueryParams } from '../../../../utility/utility';

export const defaultDataForWidget = {
  zameen: [
    {
      id: 2,
      name: 'Listing',
    },
    {
      id: 1,
      name: 'Refresh',
    },
    {
      id: 4,
      name: 'Hot',
    },
    {
      id: 5,
      name: 'Super Hot',
    },
    {
      id: 12,
      name: 'Story',
    },
    {
      id: 13,
      name: 'Photography',
    },
    {
      id: 14,
      name: 'Videography',
    },
  ],
  olx: [
    {
      id: 9,
      name: 'Listing',
    },
    {
      id: 10,
      name: 'Boost',
    },
    {
      id: 11,
      name: 'Feature',
    },
  ],
};

const getDataSets = (data, platform) => {
  let productData;
  if (Array.isArray(data)) {
    productData = data?.map((e) => {
      return {
        ...e,
        dataSet: [e?.used, e?.available],
        ...tenantData.products?.find((it) => e?.id == it?.id),
        name: defaultDataForWidget?.[platform]?.find((item) => item?.id == e?.id)?.name,
      };
    });
  } else {
    productData = {
      ...data,
      dataSet: [data?.used, data?.available],
      ...tenantData.products?.find((it) => it?.id == data?.id),
      name: defaultDataForWidget?.[platform]?.find((item) => item?.id == data?.id)?.name,
    };
  }
  return productData;
};

export const quotaCreditsWidgetMapper = (platform, productsByPlatform, user) => {
  return {
    key: platform?.slug,
    platform: platform,
    title: platform?.slug === 'olx' && !user?.is_shifted_to_olx_quota ? 'Listing and Credits' : 'Quota and Credits',
    titleIcon: platform?.slug === 'olx' ? 'IconOLX' : 'IconZameen',
    cardTitle: platform?.slug === 'olx' && !user?.is_shifted_to_olx_quota ? 'Listing and Credits' : 'Quota and Credits',
    assignToLink: '/agency-staff',
    buyMoreLink: !!platform?.slug === 'zameen' ? '/prop-shop' : undefined,
    products: Object?.keys(productsByPlatform?.[platform?.slug])?.length
      ? [
          {
            ...getDataSets(productsByPlatform?.[platform?.slug]?.quota, platform?.slug),
            ...productsByPlatform?.[platform?.slug]?.quota,
            name: defaultDataForWidget?.[platform?.slug]?.find(
              (item) => item?.id == productsByPlatform?.[platform?.slug]?.quota?.id,
            )?.name,
          },
          ...getDataSets(productsByPlatform?.[platform?.slug]?.credits, platform?.slug),
        ]
      : defaultDataForWidget?.[platform?.slug],
    labels: [
      {
        label: 'Available',
        icon: 'NotOpen',
        type: 'primary',
        slug: 'available',
      },
      {
        label: 'Used',
        icon: 'Opened',
        type: 'success',
        slug: 'used',
      },
    ],
    links: [
      {
        link: '/quota-and-credits',
        link_title: 'View Purchase Logs',
      },
    ],
    colors: ['#9299B8', tenantData.platformList.find((item) => item.slug === platform?.slug).brandColor],
  };
};

export const creditsWidgetMapper = (platform, productsByPlatform, user) => {
  return {
    key: platform?.slug,
    platform,
    title: 'Credits Balance',
    // titleIcon: 'IconZameen',
    cardTitle: 'Credits',
    assignToLink: '/agency-staff',
    buyMoreLink: '/prop-shop',
    products: getDataSetsForCredits([productsByPlatform?.[platform?.slug]], platform?.slug),
    labels: [
      {
        label: 'Available',
        icon: 'NotOpen',
        type: 'primary',
        slug: 'available',
      },
      {
        label: 'Used',
        icon: 'Opened',
        type: 'success',
        slug: 'used',
      },
    ],
    links: [
      {
        link: '/credits-usage',
        link_title: 'About Bayut Credits',
      },
    ],
    colors: ['#9299B8', tenantData.platformList.find((item) => item.slug === platform?.slug).brandColor],
  };
};

export const chartDataMapper = (e, productsByZones, responseData, products, zb) => {
  return {
    key: e,
    platform: e,
    title: e === 'olx' && !responseData?.is_shifted_to_olx_quota ? 'Listing and Credits' : 'Quota and Credits',
    titleIcon: e === 'olx' ? 'IconOLX' : 'IconZameen',
    cardTitle: e === 'olx' && !responseData?.is_shifted_to_olx_quota ? 'Listing and Credits' : 'Quota and Credits',
    assignToLink: '/agency-staff',
    buyMoreLink: !!e === 'zameen' ? '/prop-shop' : undefined,
    products,
    zones: Object.keys(productsByZones).map((e) => ({ value: e, label: e.replace('_', ' ').toUpperCase() })),
    types: productsByZones,
    labels: [
      {
        label: 'Available',
        icon: 'NotOpen',
        type: 'primary',
      },
      {
        label: 'Used',
        icon: 'Opened',
        type: 'success',
      },
    ],
    zonal_breakdown: zb,
    links: [
      {
        link: '/quota-and-credits',
        link_title: 'View Purchase Logs',
      },
    ],
    colors: ['#9299B8', tenantData.platformList.find((item) => item.slug === e).brandColor],
  };
};

export const getQuotaCreditsDetail = async (userId, platformOnlyKey) => {
  const loggedInUser = store.getState().loginUser.user;
  const agencyUsers = store.getState().userGroup.list;
  const isAgencyAdmin = agencyUsers?.find((e) => e?.id == loggedInUser?.id)?.is_agency_admin;

  const response = await NetworkService.get(`/api/dashboard/qc_summary`, {
    ...(isAgencyAdmin && { [`q[user_id_eq]`]: userId }),
  });
  let quotaCredits = {};
  if (response) {
    if (response.error) {
      return response;
    } else if (response?.data) {
      tenantData.platformList.forEach((e) => {
        if (Object?.keys(response?.data?.[e?.slug])?.length) {
          quotaCredits[e.slug] = quotaCreditsWidgetMapper(e, response?.data, loggedInUser);
        }
      });
      return quotaCredits;
    }
  }
};

export const fetchUserQuotaCredits = async (userId, agencyId) => {
  const response = await NetworkService.get(`/api/dashboard/qc_summary`);
  if (response) {
    if (response?.error) {
      return response;
    } else {
      const data = response?.data;
      const quotaCredits = { zameen: {}, olx: {} };
      tenantData.platformList?.forEach((e) => {
        quotaCredits[e?.slug]['credits'] = data?.[e?.slug]?.credits?.length
          ? data?.[e?.slug]?.credits?.map((e) => {
              return {
                ...tenantData.getListingActions(tenantData.quotaCreditProducts?.find((pr) => pr?.id === e?.id)?.slug),
                ...e,
              };
            })
          : [];
        quotaCredits[e?.slug]['quota'] = data?.[e?.slug]?.quota
          ? {
              ...tenantData.getListingActions(
                tenantData.quotaCreditProducts?.find((pr) => pr?.id === data?.[e?.slug]?.quota?.id)?.slug,
              ),
              ...data?.[e?.slug]?.quota,
            }
          : {};
      });

      return quotaCredits;
    }
  }
};

const getTranscationParams = (paramObj) => {
  const defaultParams = !paramObj?.['filter[date_between]']
    ? { ...paramObj, ['filter[date_between]']: getVariousDates(365)?.join(',') }
    : paramObj;
  const filters = Object?.keys(defaultParams);
  let params = [];
  filters.forEach((e) => {
    if (e === 'filter[date_between]') {
      params?.push(
        `q[transaction_date_gteq]=${defaultParams?.[e]?.split(',')?.[0]}&q[transaction_date_lteq]=${
          defaultParams?.[e]?.split(',')?.[1]
        }`,
      );
    } else {
      params.push(`${e}=${defaultParams?.[e]}`);
    }
  });
  return params?.join('&');
};

export const getPurchaseLogs = async (userId, params = '', products, filtersList) => {
  const mappedParams = mapQueryParams(params, filtersList);
  const response = await NetworkService.get(`/api/users/${userId}/purchase_logs?${mappedParams}`);
  if (response) {
    if (response.error) {
      return response;
    }
    return {
      data: {
        list: response?.data?.purchase_logs?.map((e) => {
          let total = 0;
          const valueObj = {};
          Object.keys(e.transactions).forEach((it) => {
            total += e.transactions[it].quantity;
            valueObj[it + ''] = { value: e.transactions[it].quantity };
          });
          return {
            ...valueObj,
            total: { value: total },
            date: e.date ? { value: e.date } : '-',
            expiry_date: e.expire_date ? { value: e.expire_date } : '-',
            quota_expiry_date: e.quota_expire_date ? { value: e.quota_expire_date } : '-',
          };
        }),
        pagination: tenantUtils.getPaginationObject(response?.data?.pagination),
        filtersData: {
          list: filtersList,
        },

        table: [
          {
            title: 'Purchase Date',
            dataIndex: 'date',
            key: 'date',
            component: 'Date',
          },
          {
            title: 'Quota',
            children: [
              ...(products?.length &&
                products
                  .filter((e) => e.product_usage_type === 'quota')
                  .map((e) => ({
                    title: e.product_title,
                    dataIndex: e.product_id + '',
                    key: e.product_id + '',
                    component: 'Number',
                  }))),
              {
                title: 'Expiry Date',
                dataIndex: 'quota_expiry_date',
                key: 'quota_expiry_date',
                component: 'Date',
              },
            ],
            className: 'cellBorderRight',
          },
          {
            title: 'Credit',
            children: [
              ...(products &&
                products
                  .filter((e) => e.actionType === 'credit')
                  .map((e) => ({
                    title: e.product_title,
                    dataIndex: e.product_id + '',
                    key: e.product_id + '',
                    component: 'Number',
                  }))),
              {
                title: 'Expiry Date',
                dataIndex: 'expiry_date',
                key: 'expiry_date',
                component: 'Date',
              },
            ],
          },
          {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            component: 'Number',
          },
        ],
      },
    };
  }
  return response;
};
