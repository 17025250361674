import tenantApi from '@api';
import { NetworkService } from '../../../../services/networkService';

const userPreferenceApi = async (id, values) => {
  let response = await NetworkService.post(`/api/users/${id}/preferences`, tenantApi.preferencesUpdate(values));
  if (response.error) {
    return response;
  } else if (response) {
    return response.data;
  }
};

export default userPreferenceApi;
