import { NetworkService } from "../../../../services/networkService";

export const fetchAccountManager = async user => {
  let type = user?.agency ? 'agency' : 'user';
  let resource_id = user?.agency ? user.agency.id : user.id;

  let response = await NetworkService.get(`/api/dashboard/account_manager?type=${type}&resource_id=${resource_id}`);

  if (response.error) {
    return response;
  } else {
    if (response?.data) {
      response.data = {
        ...response.data,
        account_manager: {
          ...response.data.account_manager,
          assignee: {
            ...response.data.account_manager.assignee,
            phone: response.data.account_manager.assignee?.phone
              ? `+${response.data.account_manager.assignee.phone}`
              : null,
            name_l1: response.data.account_manager.assignee.name_tld,
          },
        },
      };
      return response.data;
    }
  }
};
