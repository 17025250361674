import tenantApi from '@api';
import { NetworkService } from '../../../../services/networkService';

const updateAgencyUser = async (agencyId, userId, values) => {
  let response = await NetworkService.put(`/api/users/${userId}`, tenantApi.agencyUser(values));
  if (response.error) {
    return {
      error: response.error,
    };
  } else if (response?.data)
    return {
      user: response.data?.user,
    };
};
export default updateAgencyUser;
