import { NetworkService } from '../../../../services/networkService';
const getMonthOptions = async () => {
  let response = await NetworkService.get(`api/packages/custom_package_rules`);
  if (response.error) {
    return {
      error: response.error,
    };
  } else {
    return response;
  }
};
export default getMonthOptions;
