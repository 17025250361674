import ZameenRoutes, { ZAMEEN_KEY } from './zameen';
import BayutRoutes, { BAYUT_KEY } from './bayut';
import { TENANT_KEY } from '../../utility/env';
import { checkRequiredKeys } from '../../tenant/checkRequiredKeys';

const configKeys = ['key', 'routes.app', 'routes.auth'];

export const tenants = Object.freeze({
  [ZAMEEN_KEY]: checkRequiredKeys(ZameenRoutes, configKeys)?.routes,
  [BAYUT_KEY]: checkRequiredKeys(BayutRoutes, configKeys)?.routes,
});

export default tenants[TENANT_KEY];
