import { NetworkService } from '../../../../services/networkService';
const verifyOTP = async (id, values) => {
  let response = await NetworkService.patch(`api/users/${id}/verify_mobile_number`, values);
  if (response.error) {
    return {
      error: response.error,
    };
  } else {
    return response;
  }
};
export default verifyOTP;
