import { message as msg } from 'antd';

const getNotificationArgs = (message, title) => ({
  title: title,
  description: message,
});

const notify = {
  error: (message, title) => {
    msg.error({
      content: message,
      className: 'errorMessage',
    });
    // msg.error({ ...getNotificationArgs(message, title || 'Error') });
  },
  success: (message, title) => {
    msg.success({ content: message, className: 'successMessage' });
    // msg.success({ ...getNotificationArgs(message, title || 'Success') });
  },
  //   ...notification,
};

export default notify;
