import actions from './actions';

const { PRODUCTS_BEGIN, PRODUCTS_SUCCESS, PRODUCTS_ERR } = actions;

const initialState = {
  products: {
    data: [],
    loading: true,
    error: null,
  },
};

const generalReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case PRODUCTS_BEGIN:
      return { ...state, products: { ...state.products, loading: true } };
    case PRODUCTS_SUCCESS:
      return { ...state, products: { ...state.products, data } };
    case PRODUCTS_ERR:
      return { ...state, products: { error: err, loading: false } };
    default:
      return state;
  }
};

export default generalReducer;
