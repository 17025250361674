import tenantApi from '@api';
import tenantConstants from '@constants';
import { t } from 'i18next';
import { KC_REQUESTS } from '../../../../keycloak/requests';
import { getRequestHeaders, NetworkService } from '../../../../services/networkService';

export const changePassword = async (user, values, auth) => {
  const response = await (tenantConstants.KC_ENABLED
    ? NetworkService.post(
        KC_REQUESTS.change_password.url,
        {
          old_password: values?.oldPassword,
          new_password: values?.newPassword,
        },
        getRequestHeaders(auth, {}, KC_REQUESTS.change_password.url),
      )
    : NetworkService.post(`/api/users/${user?.id}/change_password`, tenantApi.userPassword(values)));

  if (response.error) {
    return response;
  } else {
    return response?.data || { message: t('Password Updated Successfully') };
  }
};
