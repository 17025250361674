import tenantTheme from '@theme';
import React from 'react';
import styled from 'styled-components';
import { Tag } from '..';
import { IconStyled } from '../icon/IconStyled';

export const TagStyled = styled((props) => <Tag {...props} />)`
  &.ant-tag {
    --tag-font-size: 10px;
    font-weight: 800;
    --tag-color: #fff;
    display: inline-flex;
    align-items: center;
    gap: 4px;
    padding: 5px;
    background-color: ${(props) => props.tagBackgroundColor || tenantTheme.gray100};
    border: 0;
    line-height: 1;
    vertical-align: text-bottom;
    border-radius: 4px;

    ${IconStyled} {
      --icon-styled-width: ${(props) => props.iconContainerSize};
    }
  }
`;
TagStyled.displayName = 'ProductTag';
