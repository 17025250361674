import { NetworkService } from "../../../../services/networkService";

export const loginApi = async data => {
  const response = await NetworkService.post(`/auth/login`, {
    email: data.email,
    pass: data.password,
  });
  if (response) {
    if (response.error) return response;
    else {
      return {
        data: {
          ...response?.data?.data,
          token: response?.data?.data?.access_token,
        },
      };
    }
  }
};
