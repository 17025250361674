import { NetworkService } from '../../../../services/networkService';
const getLocationsBasedOnNational = async (nationalAddress) => {
  let response = await NetworkService.get(`/api/locations/google_location?address=${nationalAddress}`);
  if (response?.error) {
    return {
      error: response.error,
    };
  } else {
    return response;
  }
};
export default getLocationsBasedOnNational;
