import { Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Flex, Group, Heading, Icon, Modal } from '../common';
const { Text } = Typography;

const SuccessModal = ({
  handleButtonClick = () => {},
  handleCancel = () => {},
  onCloseModal = () => {},
  successImage,
  iconStyle,
  title,
  description,
  buttons,
  renderContent = () => {},
  visible,
}) => {
  const isMobile = useSelector((state) => state.AppConfig.isMobile);

  const onClickButton = (item) => {
    if (item?.onClick) {
      item?.onClick?.();
    }
    handleButtonClick(item);
    handleCancel();
  };

  return (
    <Modal
      type="primary"
      visible={visible}
      okButtonProps={{ type: 'primary' }}
      onCancel={handleCancel}
      width={510}
      footer={null}
    >
      <Group>
        <Flex className="text-center" vertical align="center" style={{ maxWidth: 300, marginInline: 'auto' }}>
          <Icon icon={successImage || 'IllustrationHourglass'} size={96} style={{ ...iconStyle }} />
          <Heading as={isMobile ? 'h5' : 'h4'} className="fw-700">
            {title}
          </Heading>
          <Text type="secondary">{description}</Text>
        </Flex>

        {renderContent()}

        <Group template="repeat(auto-fit, minmax(0, 1fr))" gap="16px">
          {buttons?.length > 0 &&
            buttons?.map((item) => (
              <Button
                type={item?.type}
                size={!isMobile && 'large'}
                block
                style={{ '--btn-padding-y': '0.6217em' }}
                key={item?.btnText}
                onClick={() => onClickButton(item)}
                {...(item?.href && { href: item?.href })}
              >
                {item?.btnText}
              </Button>
            ))}
        </Group>
      </Group>
    </Modal>
  );
};

export default SuccessModal;
