import React, { useEffect } from 'react';
import { Spinner } from '../../../components/common';
import { useGetLocation } from '../../../hooks';
import { mapQueryStringToFilterObject } from '../../../utility/urlQuery';
import { Main } from '../../styled';

function PaymentProcess(props) {
  const { search } = useGetLocation();
  const { 'cko-session-id': sessionId, status } = mapQueryStringToFilterObject(search)?.queryObj;

  useEffect(() => {
    sessionId && postSessionId();
  }, []);

  const postSessionId = async () => {
    window.parent.postMessage({ type: status === 'success' ? 'paymentSuccess' : 'paymentFailed', sessionId }, '*');
  };

  return (
    <Main>
      <Spinner type="full" />
    </Main>
  );
}

export default PaymentProcess;
