import tenantApi from '@api';
import tenantConstants from '@constants';
import tenantData from '@data';
import tenantTheme from '@theme';
import tenantUtils from '@utils';
import { Divider, Row, Space, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { t } from 'i18next';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TIME_DATE_FORMAT } from '../../constants/formats';
import { useGetLocation } from '../../hooks';
import { updateListForDashboard } from '../../redux/dashboard/actionCreators';
import { updateList } from '../../redux/listings/actionCreator';
import TenantComponents from '../../tenant/components';
import { getTimeDateString } from '../../utility/date';
import { getBaseURL } from '../../utility/env';
import {
  Button,
  Flex,
  Icon,
  Image,
  Label,
  Modal,
  notification,
  Number,
  Popover,
  ProductTag,
  Skeleton,
  Switch,
  Tag,
  TextWithIcon,
} from '../common';
import Group from '../common/group/group';
import { IconStyled } from '../common/icon/IconStyled';
import ListingDrawer from '../listing-drawer/listingDrawer';
import RenderTextLtr from '../render-text/render-text';
import { SwitchWrapped, Thumbnail } from '../styled';

const { Text } = Typography;

const String = (props) => {
  const { text = '' } = props;
  return (
    <Text maxLength={15} ellipsis style={{ width: 50 }}>
      {text}
    </Text>
  );
};

const AvatarName = (props) => {
  const { name, image } = props;
  return (
    <Space>
      {image ? (
        <Avatar size={34} src={image} style={{ margin: 0, padding: 1 }} />
      ) : (
        <IconStyled>
          <Icon size={16} icon="FiUser" />
        </IconStyled>
      )}
      {name}
    </Space>
  );
};
const Img = (props) => {
  const { imageUrl, subKey } = props;
  return <img src={imageUrl} style={{ width: '80px', height: 'auto' }} />;
};

const TableNumber = (props) => {
  return <Number compact={false} dashForNone {...props} />;
};

const PurposeTypeArea = (props) => {
  const getTooltipText = () => {
    let locationTitle = '';
    if (props?.location?.breadcrumb?.length > 3) {
      props?.location?.breadcrumb?.forEach((item, index) => {
        if (index > 2) {
          locationTitle = `${item?.title} ${locationTitle}`;
        }
      });
      locationTitle = `${locationTitle} ${props?.location?.title}, ${
        props?.location?.breadcrumb?.find((e) => e.level === 3)?.title
      }`;
    } else {
      locationTitle = `${props?.location?.title}, ${props?.location?.breadcrumb?.find((e) => e.level === 3)?.title}`;
    }
    return locationTitle;
  };
  return (
    <Group template="auto" gap="8px">
      {/* <Tooltip placement="top" title={<span>{getTooltipText() ? getTooltipText() : ''}</span>}> */}
      <div>
        <Number {...props.area} compact={false} /> {props?.type} for {props.purpose}
      </div>
      <span>
        in {props?.location?.title}, {props?.location?.breadcrumb?.find((e) => e.level === 3)?.title}
      </span>
      {/* </Tooltip> */}
    </Group>
  );
};

const Price = (props) => {
  return <Number {...props} />;
};

const Area = (props) => {
  return <Number {...props} compact={false} />;
};

const Date = (props) => {
  const { user } = useSelector((state) => state.loginUser);
  const { data = [] } = props;
  return (
    <Group template="auto" gap="8px">
      {props?.value ? (
        <div> {getTimeDateString(props?.value, props?.format)} </div>
      ) : (
        data?.map((item) => (
          <div key={item.slug}>
            {item?.posted_on ? getTimeDateString(item?.posted_on) : '-'}
            {!user?.isCurrencyUser && item?.expiry_date && (
              <Popover
                content={
                  <>
                    {t('Expiring on')}: <strong>{getTimeDateString(item?.expiry_date)}</strong>
                  </>
                }
                action="hover"
              >
                <>
                  <Icon
                    icon="AiOutlineInfoCircle"
                    size={16}
                    style={{ marginInlineStart: 5 }}
                    color={tenantTheme['primary-color']}
                  />
                </>
              </Popover>
            )}
          </div>
        ))
      )}
    </Group>
  );
};

const Health = (props) => {
  return <TenantComponents.Health {...props} />;
};

const Products = (props) => {
  const { products = [] } = props;
  return (
    <>
      <Row>
        {products.map((item, index) => {
          return (
            <Flex align="center" gap="4px" style={{ marginInlineEnd: 4 }} key={item.id}>
              <Icon
                icon={item?.icon || 'MdOutlineCircle'}
                styled
                iconProps={{ color: item?.iconProps?.color, iconContainerSize: '1.6em' }}
              />
              <div>
                {item?.name} ({item?.quantity}){index !== products.length - 1 && ', '}
              </div>
            </Flex>
          );
        })}
      </Row>
    </>
  );
};

const OrderStatus = (props) => {
  const { state } = props;
  return (
    <Group gap="8px" template="auto">
      <div>
        <Tag color={state?.color} shape="round">
          {state?.label}
        </Tag>
      </div>
    </Group>
  );
};

const UserDetail = (props) => {
  const { name, email, phone } = props;
  return (
    <div>
      <div className="fw-700 mb-4">
        <Text>{name}</Text>
      </div>
      <TextWithIcon
        icon="MdMail"
        iconProps={{ size: '1.2em', color: tenantTheme.gray500 }}
        textColor={tenantTheme.gray700}
        value={email}
      />
      <TextWithIcon
        icon="MdPhone"
        iconProps={{ size: '1.2em', color: tenantTheme.gray500 }}
        textColor={tenantTheme.gray700}
        value={<RenderTextLtr text={phone} />}
      />
    </div>
  );
};

export const ExpandableMessage = (props) => {
  const [showFullMessage, setShowFullMessage] = useState(false);
  const { message, isExpandable } = props;
  const messageLength = 100;

  return (
    <div style={{ whiteSpace: 'normal' }}>
      {isExpandable ? (
        <>
          {!showFullMessage && message?.length > messageLength ? (
            <>{message?.slice(0, messageLength)}...</>
          ) : (
            <>{message}</>
          )}
          {message?.length > messageLength && (
            <Button
              className="p-0"
              type="link"
              onClick={() => {
                setShowFullMessage(!showFullMessage);
              }}
            >
              {!showFullMessage ? t('View More') : t('View Less')}
            </Button>
          )}
        </>
      ) : (
        message
      )}
    </div>
  );
};

export const ListingPurpose = (props) => {
  const { productsInfo = {} } = props;
  const listingDrawerRef = useRef();

  const getTagProps = () => {
    if (productsInfo?.['signature-listing']?.is_applied) {
      return tenantData.getListingActions('signature-listing');
    }
    if (productsInfo?.['hot-listing']?.is_applied) {
      return tenantData.getListingActions('hot-listing');
    }
    return tenantData.getListingActions('basic-listing');
  };

  const handleClick = () => {
    if (tenantConstants?.SHOW_LISTING_DETAIL) {
      listingDrawerRef?.current && listingDrawerRef.current.open(props?.id);
    }
  };
  return (
    <Group template="106px 1fr" gap=".571rem">
      <Thumbnail style={props.thumbnailStyles}>
        <Image
          onClick={handleClick}
          style={{ cursor: 'pointer' }}
          src={props?.image?.thumbnail}
          fallback={`${getBaseURL()}/profolio-assets/images/ph-listings.svg`}
        />
      </Thumbnail>
      <Group className="fz-12 py-2" template="initial" gap="4px">
        <Space className="fz-14 fw-800" style={{ lineHeight: 1.2 }}>
          <div>
            <Number className="text-primary" compact={false} type="price" {...props?.price} />
            {props?.isDailyRental && (
              <span
                className="fw-500"
                style={{ marginInlineStart: '4px', fontSize: '11px', color: tenantTheme['primary-color'] }}
              >
                {t('Night')}
              </span>
            )}
          </div>

          {productsInfo?.['basic-listing']?.is_applied && (
            <>
              <ProductTag {...getTagProps()} iconProps={{ ...getTagProps().iconProps, size: '1.2em' }} />
            </>
          )}
          {props.health && <TenantComponents.Health {...props.health} listingsData={props} />}
        </Space>
        <span>
          {props?.type?.title && props?.purpose?.title && (
            <Tag
              color={tenantTheme['primary-light-4']}
              style={{ '--tag-color': tenantTheme['primary-color'], fontWeight: '700' }}
            >
              {`${tenantUtils.getLocalisedString(props?.type, 'title')} ${t(`for ${props?.purpose?.title}`)}`}
            </Tag>
          )}
        </span>
        <Space size="large">
          {props?.details?.beds > 0 && (
            <TextWithIcon
              icon="IconBedroom"
              iconProps={{ size: '1.2em', color: tenantTheme.gray500 }}
              textColor={tenantTheme.gray700}
              value={`${props?.details?.beds} ${t('Rooms')}`}
            />
          )}
          {props?.details?.baths > 0 && (
            <TextWithIcon
              icon="IconBathroom"
              iconProps={{ size: '1.2em', color: tenantTheme.gray500 }}
              value={`${props?.details?.baths} ${t('Baths')}`}
            />
          )}
          <TextWithIcon
            icon="IconAreaSize"
            iconProps={{ size: '1.4em', color: tenantTheme.gray500 }}
            textColor={tenantTheme.gray700}
            value={`${props?.area?.value} ${t('Sq. M.')}`}
          />
        </Space>
        <div>
          <div>
            {props?.location?.breadcrumb
              ?.filter((e) => e?.level > 1)
              ?.map((e) => tenantUtils.getLocalisedString(e, 'title'))
              ?.join(', ')}
          </div>
        </div>
        <span className="fz-10">
          <Text className="text-muted fz-10">{t('Bayut ID:')}</Text>{' '}
          <Text className="text-primary fz-10">{props?.id}</Text>
          {props?.details?.regaId ? (
            <>
              <Divider type="vertical" />
              <Flex wrap={false} gap="2px" style={{ display: 'inline-flex' }}>
                <Text className="text-muted fz-10">{t('REGA ID:')} </Text>
                <Text className="text-primary inline-flex align-items-center fz-10" style={{ gap: 2 }}>
                  {props?.details?.regaId}
                  {props?.details?.regaExpiryDate && (
                    <Popover
                      content={
                        <>
                          {t('Expiring on')}: <strong>{getTimeDateString(props?.details?.regaExpiryDate)}</strong>
                        </>
                      }
                      action="hover"
                    >
                      <>
                        <Icon icon="AiOutlineInfoCircle" color={tenantTheme.gray700} />
                      </>
                    </Popover>
                  )}
                </Text>
              </Flex>
            </>
          ) : (
            <>
              <Divider type="vertical" />
              <Tag shape="round" style={{ alignSelf: 'center', fontSize: 12, lineHeight: 'normal' }}>
                {t('Unlicensed')}
              </Tag>
            </>
          )}
        </span>
      </Group>
      {tenantConstants.SHOW_LISTING_DETAIL && <ListingDrawer ref={listingDrawerRef} />}
    </Group>
  );
};

const ExpiryRenewal = (props) => {
  const location = useGetLocation();
  const { data = [], auto_renewable_item = {}, property_id } = props;
  const user = useSelector((state) => state.loginUser);
  const { expiry_date, posted_on } = data?.[0] || {};

  const [loading, setLoading] = useState(false);
  const [modalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();

  const dateLabelMapping = tenantUtils.getDateFieldsByStatus(data?.[0]?.status?.slug, auto_renewable_item?.isApplied);
  const dateLabels = Object.keys(dateLabelMapping);

  const updateExpiryRenewal = async (productId) => {
    const response = await tenantApi.updateAutoRenew(auto_renewable_item?.id, property_id, productId, user);
    if (response) {
      setLoading(false);
      if (response?.error) {
        notification.error(response?.error);
      } else {
        setIsModalVisible(false);
        if (location.pathname === '/dashboard') {
          dispatch(updateListForDashboard(response));
        } else {
          dispatch(updateList(response));
        }
      }
    }
  };
  const onChange = () => {
    setIsModalVisible(true);
  };

  return (
    <div>
      <div className="mb-8">
        {dateLabels[0] && (
          <Flex gap="4px">
            <div className="text-muted">{t(dateLabels[0])}</div>

            <div className="text-default fw-600">
              {getTimeDateString(data?.[0]?.[dateLabelMapping[dateLabels[0]]]) || '-'}
            </div>
            <Popover
              content={<div>{getTimeDateString(data?.[0]?.[dateLabelMapping[dateLabels[0]]], TIME_DATE_FORMAT)}</div>}
              action="hover"
            >
              <>
                <Icon
                  icon="AiOutlineInfoCircle"
                  size="1em"
                  style={{ marginBlockStart: '3px' }}
                  color={tenantTheme['primary-color']}
                />
              </>
            </Popover>
          </Flex>
        )}

        {dateLabels[1] && (
          <Flex gap="4px">
            <div className="text-muted">
              <div className="text-muted">{t(dateLabels[1])}</div>
            </div>
            <div className="text-default fw-600">
              {getTimeDateString(data?.[0]?.[dateLabelMapping[dateLabels[1]]]) || '-'}
            </div>
          </Flex>
        )}
      </div>
      <SwitchWrapped align="center">
        <Label htmlFor="auto-renew" className="fz-12" color={tenantTheme['primary-color']}>
          {t('AUTO-RENEW')}
        </Label>
        <Switch
          switchOnly
          name="auto-renew"
          value={auto_renewable_item?.isApplied}
          onChange={onChange}
          loading={loading}
        />
      </SwitchWrapped>
      <Modal
        title={`${auto_renewable_item?.isApplied ? t('Disable') : t('Enable')} ${t('Auto Renew')}`}
        visible={modalVisible}
        onOk={() => {
          setLoading(true);
          updateExpiryRenewal(tenantData.getListingActions('basic-listing')?.id);
        }}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        loading={loading}
      >
        {!auto_renewable_item?.isApplied
          ? t(
              "By turning the 'Auto-Renew' feature ON. The system will automatically renew the listing when it reaches its expiry, if you have enough credits available",
            )
          : t('Are you sure you want to disable auto renew for this listing')}
      </Modal>
    </div>
  );
};

const PlatformStatus = (props) => {
  const { data = [] } = props;
  return (
    <Group gap="8px" template="auto">
      {data?.map((item) => (
        <div key={item.slug}>
          <Tag color={item.status.color} shape="round">
            {item.status.label}
            {item?.status?.comments && (
              <Popover
                placement="right"
                title="Comments"
                content={<p style={{ paddingInline: 10 }}>{item?.status?.comments}</p>}
                action="hover"
              >
                <>
                  <Icon icon="AiOutlineInfoCircle" style={{ marginInlineStart: 5 }} color="currentColor" />
                </>
              </Popover>
            )}
          </Tag>
        </div>
      ))}
    </Group>
  );
};

const PlatformDisposition = (props) => {
  const { data = [] } = props;
  return (
    <Group gap="8px" template="auto">
      {data?.map((item) => (
        <div key={item.slug}>
          <Tag color={item.disposition?.color} shape="round">
            {item.disposition?.label}
            {item?.disposition?.comments && (
              <Popover
                placement="right"
                title="Comments"
                content={<p style={{ paddingInline: 10 }}>{item?.disposition?.comments}</p>}
                action="hover"
              >
                <Icon icon="AiOutlineInfoCircle" style={{ marginInlineStart: 5 }} color="currentColor" />
              </Popover>
            )}
          </Tag>
        </div>
      ))}
    </Group>
  );
};

const Platform = (props) => {
  const { data = [] } = props || {};
  return (
    <Group gap="8px">
      {data?.map((item) => (
        <div key={item.slug} disabled={!item.posted ? 'disabled' : ''}>
          <Icon icon={item.icon} size="1.6em" />
        </div>
      ))}
    </Group>
  );
};

const ShowStats = (props) => {
  const { t } = useTranslation();
  const { data = [] } = props;

  const renderStats = (label, stat, data) => {
    return (
      <Flex align="center" gap="8px">
        <div className="text-muted fz-12">{t(label)}</div>
        {stat === 'loading' ? <Skeleton size="small" /> : stat ? <Number value={stat} compact={false} /> : '0'}
        {label === 'Leads' && (
          <Popover
            content={
              <div style={{ width: 120 }}>
                <Flex justify="space-between">
                  <span>{t('Calls')}</span>
                  <span>{data?.calls ? data?.calls : 0}</span>
                </Flex>
                <Flex justify="space-between">
                  <span>{t('Whatsapp')}</span>
                  <span>{data?.whatsapp ? data?.whatsapp : 0}</span>
                </Flex>
                <Flex justify="space-between">
                  <span>{t('SMS')}</span>
                  <span>{data?.sms ? data?.sms : 0}</span>
                </Flex>
                <Flex justify="space-between">
                  <span>{t('Email')}</span>
                  <span>{data?.emails ? data?.emails : 0}</span>
                </Flex>
              </div>
            }
            action="hover"
          >
            <>
              <Icon icon="AiOutlineInfoCircle" size="1em" color={tenantTheme['primary-color']} />
            </>
          </Popover>
        )}
      </Flex>
    );
  };

  return (
    <Group gap="8px">
      {data.map((item) => (
        <div key={item.slug}>
          {renderStats('Views', item?.views)}
          {renderStats('Clicks', item?.clicks)}
          {renderStats('Leads', item?.leads, item)}
        </div>
      ))}
    </Group>
  );
};

const Stats = (props) => {
  const { data = [], subKey } = props;
  return (
    <Group gap="8px">
      {data.map((item, i) => (
        <div key={item.slug}>
          {item[subKey] === 'loading' ? (
            <Skeleton size="small" />
          ) : item[subKey] ? (
            <Number value={item[subKey]} compact={false} />
          ) : (
            '-'
          )}
        </div>
      ))}
    </Group>
  );
};

const OrderActions = (props) => {
  const { actions = [] } = props;
  return (
    <Group gap="8px">
      {actions.map((item) =>
        item?.type === 'button' ? (
          <Button type="primary" size="small" transparented href={item?.link} key={item?.label}>
            {item?.label}
          </Button>
        ) : (
          ''
        ),
      )}
    </Group>
  );
};

const PlatformListingActions = (props) => {
  return <TenantComponents.PlatformActions {...props} />;
};

const TableListingActions = (props) => {
  return <TenantComponents.ListingsRowActions {...props} />;
};

const UserRowActions = (props) => {
  return <TenantComponents.AgencyUserRowActions {...props} />;
};

const StaffDetails = (props) => {
  const { t } = useTranslation();
  const { name, email, phone, user_role, image } = props;
  return (
    <Flex gap="10px" align="center">
      <div>
        {image ? (
          <Avatar size={34} src={image} style={{ margin: 0, padding: 1 }} />
        ) : (
          <IconStyled style={{ '--icon-styled-width': '40px' }}>
            <Icon size={16} icon="FiUser" />
          </IconStyled>
        )}
      </div>

      <div>
        {name && (
          <Text className="fw-700" style={{ paddingInlineEnd: '8px' }}>
            {name}
          </Text>
        )}
        {user_role == 'owner' && (
          <Tag color="#28B16D" textColor="#fff" gradient style={{ '--tag-font-size': '12px', '--line-height': 1.6 }}>
            {t('Owner')}
          </Tag>
        )}

        {email && (
          <TextWithIcon
            icon="MdMail"
            iconProps={{ size: '12px', color: tenantTheme.gray500 }}
            textColor={tenantTheme.gray700}
            value={email}
            textSize={'12px'}
          />
        )}
        {phone && (
          <TextWithIcon
            icon="MdPhone"
            iconProps={{ size: '12px', color: tenantTheme.gray500 }}
            textColor={tenantTheme.gray700}
            value={<RenderTextLtr text={phone} />}
            textSize={'12px'}
          />
        )}
      </div>
    </Flex>
  );
};

export default {
  AvatarName,
  Image: Img,
  String,
  Number: TableNumber,
  Date,
  PlatformStatus,
  PlatformDisposition,
  Price,
  Area,
  Health,
  Platform,
  PlatformListingActions,
  TableListingActions,
  UserRowActions,
  Stats,
  OrderActions,
  OrderStatus,
  Products,
  PurposeTypeArea,
  ListingPurpose,
  ExpiryRenewal,
  UserDetail,
  ExpandableMessage,
  ShowStats,
  StaffDetails,
};
