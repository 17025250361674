import React from 'react';
import { LinkWithIcon } from '../../../../components/common';
import { IconSwitch } from '../../../../components/svg';
import { getClassifiedBaseURL } from '../../../../utility/env';

const HeaderLink = () => {
  return (
    <LinkWithIcon
      as="a"
      icon={<IconSwitch />}
      linkTitle={'Go to zameen.com'}
      href={`${getClassifiedBaseURL()}`}
      className="btnLink px-8"
      target="_blank"
    />
  );
};

export default HeaderLink;
