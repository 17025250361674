import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Heading } from '../../../../components/common';
import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Row } from 'antd';
import { JSONFormStyled } from '../../../../components/common/json-form/styled';
const OwnerProfile = ({ agencyUserSettings, agencyOwnerFormRef, handleSubmit, postOwnerLoading, loading }) => {
  const { t } = useTranslation();
  const isMobile = useSelector(state => state.AppConfig.isMobile);
  return (
    <Card bodyStyle={{ padding: isMobile ? 16 : 40 }}>
      <Heading as={isMobile ? 'h5' : 'h3'} className={isMobile ? 'mb-20' : 'mb-0'}>
        {t('CEO/Owner Profile')}
      </Heading>
      {!isMobile && <Divider style={{ margin: '28px 0 40px' }} />}
      <div style={{ ...(!!loading && { pointerEvents: 'none', opacity: '0.4' }) }}>
        <JSONFormStyled
          key={'agency-owner'}
          fields={agencyUserSettings?.fields?.[1]?.list}
          formFieldValues={agencyUserSettings?.data?.owner}
          ref={agencyOwnerFormRef}
          onSubmitForm={values => {
            handleSubmit(values, true);
          }}
          noOfContentColumns={2}
        />
      </div>

      <Row>
        <Col xs={24} align="end" style={{ maxWidth: 880, margin: '18px auto 0' }}>
          <Button
            type="primary"
            size="large"
            disabled={!agencyUserSettings || postOwnerLoading}
            onClick={() => {
              agencyOwnerFormRef.current && agencyOwnerFormRef.current.submitForm();
            }}
            style={{
              paddingInline: 60,
            }}
            block={isMobile ? true : false}
          >
            {t('Save Changes')}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};
export default OwnerProfile;
