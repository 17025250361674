import { NetworkService } from "../../../../services/networkService";
import { getVariousDates } from '../../../../utility/date';

export const olxListingPerformanceApi = async (userId, list, user, params) => {
  const user_ids = [...new Set(list.map(item => item.user?.id?.toString()))];
  const listing_ids = [];

  list.forEach(
    item => !!item?.platform?.olx?.platform_listing_id && listing_ids.push(item?.platform?.olx?.platform_listing_id),
  );

  const olxStatsResponse = await NetworkService.get(`/api/listings/olx_stats`, {
    start_date: getVariousDates(365)[0],
    end_date: getVariousDates(365)[1],
    user_ids: user_ids,
    listing_ids: listing_ids,
    group_by: ['ad_external_id'],
    offset: 0,
    limit: 300,
  });
  if (olxStatsResponse) {
    if (olxStatsResponse.error) {
      const newlist = list.map(item => {
        return {
          ...item,
          platforms: {
            ...item.platforms,
            data: [
              { ...item.platforms.data[0] },
              {
                ...item.platforms.data[1],
                views: '',
                clicks: '',
                ctr: '',
                calls: '',
                emails: '',
                sms: '',
                whatsapp: '',
                leads: '',
                statsError: 'Olx Stats Error',
              },
            ],
          },
        };
      });
      return { list: newlist };
    } else {
      const olxStatsData = olxStatsResponse?.data?.stats;
      const newlist = list.map(item => {
        const olxStats = olxStatsData?.items?.find(e => e?.ad_external_id == item?.platform?.olx?.platform_listing_id);
        return {
          ...item,
          platforms: {
            ...item.platforms,
            data: [
              {
                ...item.platforms.data[0],
              },
              {
                ...item.platforms.data[1],
                views: olxStats?.sum_search_count,
                leads: olxStats?.sum_phone_view_count + olxStats?.sum_sms_view_count + olxStats?.sum_chat_lead_count,
                clicks: olxStats?.sum_view_count,
                ctr: (olxStats?.sum_view_count / olxStats?.sum_search_count) * 100,
                calls: olxStats?.sum_phone_view_count,
                emails: olxStats?.sum_email_view_count,
                sms: olxStats?.sum_sms_view_count,
                whatsapp: olxStats?.sum_whatsapp_view_count,
                chat: olxStats?.sum_chat_lead_count,
              },
            ],
          },
        };
      });
      return { list: newlist };
    }
  }
};
