import { agencyConvertFormFields } from './agencyConvertFormFields';
import { agencyConvertFormFieldsValues } from './agencyConvertFormFieldsValues';
import { agencySettingsFields } from './agencySettingsFields';
import { agencySettingsFieldsValues } from './agencySettingsFieldsValues';
import constants from './constants';
import staticLists from './staticLists';
import { listingTypes } from './listingTypes';
import { getPackageBySlug } from './packages';
import products from './products';
import { profileFormFields } from './profileFields';
import { profileFieldsValues } from './profileFieldsValues';
import { slugToType } from './packages';
import { packages } from './packages';
const source = 'profolio';
export const BAYUT_KEY = 'bayut';

export default {
  ...staticLists,
  ...constants,
  source,
  listingTypes,
  ...products,
  profileFormFields,
  profileFieldsValues,
  agencySettingsFields,
  agencySettingsFieldsValues,
  agencyConvertFormFields,
  agencyConvertFormFieldsValues,
  slugToType,
  packages,
  getPackageBySlug,
};
