import { getAppLanguage } from '../../hooks/useAppInit';
import { checkIfMobile } from '../../utility/utility';
import actions from './actions';

const appLanguage = getAppLanguage();

const initialState = {
  darkMode: appLanguage.darkMode,
  rtl: appLanguage.rtl,
  locale: appLanguage.key,
  isMobile: checkIfMobile(navigator?.userAgent),
  version: '',
  isMemberArea: false,
  isMultiPlatform: false,
};

const {
  CHANGE_LAYOUT_MODE,
  CHANGE_RTL_MODE,
  SET_MOBILE,
  SET_LOCALE,
  SET_VERSION,
  SET_MEMBER_AREA,
  SET_MULTI_PLATFORM,
} = actions;

const LayoutChangeReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case CHANGE_LAYOUT_MODE:
      return { ...state, darkMode: data };

    case CHANGE_RTL_MODE:
      return { ...state, rtl: data };

    case SET_MOBILE:
      return { ...state, isMobile: data };

    case SET_LOCALE:
      return { ...state, locale: data };

    case SET_VERSION:
      return { ...state, version: data };

    case SET_MEMBER_AREA:
      return { ...state, isMemberArea: data };

    case SET_MULTI_PLATFORM:
      return { ...state, isMultiPlatform: data };

    default:
      return state;
  }
};

export default LayoutChangeReducer;
