export const PLATFORMS = {
  OLX: 'olx',
  ZAMEEN: 'zameen',
};

export const AREA_UNIT = {
  MARLA: 'marla',
  SQUARE_FEET: 'square_feet',
  SQUARE_YARD: 'square_yards',
  SQUARE_METER: 'square_meters',
  KANAL: 'kanal',
};
export const allowedFilesType = ['jpg', 'png', 'jpeg'];

export default {
  PLATFORMS,
  AREA_UNIT,
  allowedFilesType,
};
