import tenantApi from '@api';
import actions from './actions';

const { SET_RULES } = actions;

const setMonthRules = () => {
  return async dispatch => {
    dispatch({ type: SET_RULES, payload: { loading: true } });
    const response = await tenantApi.getMonthOptions();

    if (response) {
      if (response.error) {
        dispatch({
          type: SET_RULES,
          payload: { error: response?.error, loading: false },
        });
      } else {
        dispatch({
          type: SET_RULES,
          payload: { monthRules: response?.data?.rules, error: null, loading: false },
        });
      }
    }
  };
};
export { setMonthRules };
