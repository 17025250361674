import tenantData from '@data';
import tenantUtils from '@utils';
import { NetworkService } from '../../../../services/networkService';
import { getQueryString } from '../../../../utility/urlQuery';

export const dashboardListingWidgetApi = async (userId, params, platform) => {
  const data = {
    zameen: {
      brandColor: tenantData?.platformList?.find((item) => item.slug === 'zameen')?.brandColor,
      title: 'Listings',
      icon_data: {
        icon: 'IconZameen',
        icon_props: {
          size: '1.6em',
          iconBackgroundColor: '#fff',
          hasBackground: true,
        },
      },
      link_data: {
        text: 'View all Zameen Listings',
        to: '/listings?q[posted_on_zameen_true]=true',
      },

      total_title: 'Active Listings',
      total_title_icon: 'MdOutlineLocationOn',
      total_value: '',
      purposes: [
        {
          id: 1,
          icon: 'IconPropertyBuy',
          title: 'For Sale',
          iconProps: { color: '#28b16d', hasBackground: true },
          value: '',
        },
        {
          id: 2,
          icon: 'IconPropertyRent',
          iconProps: { color: '#479eeb', hasBackground: true },
          title: 'For Rent',
          value: '',
        },
      ],
      products: tenantUtils.generateProductData([{ slug: 'shot_listing' }, { slug: 'hot_listing' }]),
    },
    olx: {
      brandColor: tenantData.platformList?.find((item) => item.slug === 'olx')?.brandColor,
      title: 'Listings',
      icon_data: {
        icon: 'IconOLX',
        icon_props: {
          size: '1.6em',
          iconBackgroundColor: '#fff',
          hasBackground: true,
        },
      },
      link_data: {
        text: 'View all Olx Listings',
        to: '/listings?q[posted_on_olx_true]=true',
      },
      total_title: 'Active Listings',
      total_title_icon: 'MdOutlineLocationOn',
      total_value: '',
      purposes: [
        {
          id: 1,
          icon: 'IconPropertyBuy',
          iconProps: { color: '#28b16d', hasBackground: true },
          title: 'For Sale',
          value: '',
        },
        {
          id: 2,
          icon: 'IconPropertyRent',
          iconProps: { color: '#479eeb', hasBackground: true },
          title: 'For Rent',
          value: '',
        },
      ],
      products: tenantUtils.generateProductData([
        {
          slug: 'olx_feature',
          iconProps: {
            hasBackground: true,
            color: '#1f1f1f',
            size: '1em',
            style: {
              '--icon-bg-color': '#ffec74',
            },
          },
        },
        {
          slug: 'olx_standard',
          iconProps: {
            hasBackground: true,
            color: '#1f1f1f',
            size: '1em',
          },
        },
      ]),
    },
  };

  const widgetParser = (listings) => {
    const currentPlatform = data[platform];
    currentPlatform.total_value = listings[platform].total;
    currentPlatform.purposes[0].value = listings[platform].sale;
    currentPlatform.purposes[1].value = listings[platform].rent;
    currentPlatform.products.forEach((item, i) => {
      currentPlatform.products[i].value = listings[platform][item.slug_alt];
    });

    return data?.[platform];
  };

  let response;

  if (platform === 'zameen') {
    response = await NetworkService.get(`/api/dashboard/zameen_listings_by_area?${getQueryString(params)}`);
  } else if (platform === 'olx') {
    response = await NetworkService.get(`/api/dashboard/olx_listings_by_area?${getQueryString(params)}`);
  }

  if (response) {
    if (response.error) {
      return response;
    }
    return widgetParser(response?.data?.listings);
  }
};
