import { NetworkService } from '../../../../services/networkService';
const validateAdLicense = async (adLicense) => {
  let response = await NetworkService.get(`/api/listings/new_listing_data?ad_license_number=${adLicense}`);
  if (response?.error) {
    return {
      error: response.error,
    };
  } else {
    return response;
  }
};
export default validateAdLicense;
