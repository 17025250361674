import tenantApi from '@api';
import tenantData from '@data';
import { t } from 'i18next';
import store from '../../../redux/store';
import { actionButtonListingsClickEvent } from '../../../services/analyticsService';

export const listingRowActions = {
  ksa: ['listing_detail', 'listing_detail_drawer', 'edit_listing', 'delete_listing'],
};

export const listingplatformActions = (user) => {
  return user?.isCurrencyUser
    ? {
        ksa: [
          'signature-listing',
          'hot-listing',
          'refresh',
          'publish',
          'photography-service',
          'videography-service',
          'drone-footage-service',
        ],
      }
    : {
        ksa: ['hot-listing', 'publish'],
      };
};

export const dispositionDependentPlatformActions = {
  ['signature-listing']: 'live',
  ['hot-listing']: 'live',
  ['refresh']: 'live',
  ['photography-service']: 'live',
  ['videography-service']: 'live',
  ['drone-footage-service']: 'live',
  publish:
    'not-posted,deleted,expired,sold,service-suspended,completed,insufficient-credits,info-completed,fal-license-expired,contract-expired',
};

export const dispositionDependentRowActions = {
  edit_listing:
    'not-posted,draft,live,rejected,expired,invalid-license,service-suspended,completed,insufficient-credits,info-completed,deleted,fal-license-expired,sold,contract-expired',
  listing_detail: 'live',
  listing_detail_drawer:
    'live,ad-in-review,changes-in-review,payment-pending,ready-for-moderation,pending-nafaz-verification,deleted,rejected,sold,expired,deleted-by-ops,fal-license-expired,ad-license-expired,contract-expired,unauthorized-fal-license',
  delete_listing:
    'live,not-posted,rega-expired,invalid-license,service-suspended,completed,insufficient-credits,info-completed',
};

export const listingStatusMapper = (status, data, platform) => {
  switch (status) {
    case 'active':
      return { color: 'green', label: t('Active'), slug: status };
    case 'draft':
      return { color: '', label: t('Draft'), slug: status };
    case 'removed':
      return { color: 'red', label: t('Removed'), slug: status };
    case 'pending':
      return { color: 'warning', label: t('Pending'), slug: status };
    default:
      return { color: '', label: t('Not Posted'), slug: 'not_posted' };
  }
};

export const listingDispositionMapper = (disposition) => {
  switch (disposition?.slug) {
    case 'live':
      return { color: 'green', label: t('Live'), slug: disposition?.slug };
    case 'not-posted':
      return { color: '', label: t('Not Posted'), slug: disposition?.slug };
    case 'ad-in-review':
      return { color: '', label: t('Ad In Review'), slug: disposition?.slug };
    case 'changes-in-review':
      return { color: '', label: t('Changes In Review'), slug: disposition?.slug };
    case 'completed':
      return { color: '', label: t('Completed'), slug: disposition?.slug };
    case 'payment-pending':
      return { color: '', label: t('Payment Pending'), slug: disposition?.slug };
    case 'ready-for-moderation':
      return { color: '', label: t('Ready for moderation'), slug: disposition?.slug };
    case 'deleted':
      return { color: '', label: t('Deleted'), slug: disposition?.slug };
    case 'rejected':
      return { color: 'red', label: t('Rejected'), slug: disposition?.slug };
    case 'service-suspended':
      return { color: 'red', label: t('Service Suspended'), slug: disposition?.slug, comments: '' };
    case 'expired':
      return { color: 'red', label: t('Expired'), slug: disposition?.slug };
    case 'rega-expired':
      return { color: 'red', label: t('Rega Expired'), slug: disposition?.slug };
    case 'invalid-license':
      return { color: 'red', label: t('Invalid License'), slug: disposition?.slug };
    case 'sold':
      return { color: 'red', label: t('Sold'), slug: disposition?.slug };
    case 'deleted-by-ops':
      return { color: 'red', label: t('Deleted By Ops'), slug: disposition?.slug };
    case 'pending-nafaz-verification':
      return { color: '', label: t('Pending Nafaz Verification'), slug: disposition?.slug };
    case 'insufficient-credits':
      return { color: '', label: t('Insufficient Credits'), slug: disposition?.slug };
    case 'info-completed':
      return { color: '', label: t('Info Completed'), slug: disposition?.slug };
    default:
      return { color: '', label: t('Not Posted'), slug: disposition?.slug };
  }
};

export const showPlatformAction = (status, slug) => {
  const index = dispositionDependentPlatformActions[slug].split(',').findIndex((e) => e === status);
  return index != -1;
};

export const showRowActions = (status, slug) => {
  const index = dispositionDependentRowActions[slug]?.split(',')?.findIndex((e) => e === status);
  return index != -1;
};

export const getListingPlatformActions = (listing, platformSlug, user) => {
  const actions = listingplatformActions(user)?.[platformSlug]?.filter((e) =>
    showPlatformAction(listingDispositionMapper(listing?.disposition)?.slug, e),
  );
  let listingActions = [];
  !!actions?.length > 0 &&
    actions.forEach((e) => {
      listingActions.push({
        ...tenantData.getListingActions(e, listing?.platforms?.[platformSlug], platformSlug),
      });
    });

  return listingActions;
};

export const getRowActionList = (slug, refObject, loading, editable = true, previewAble = true, deletable = true) => {
  const { user } = store.getState().loginUser;
  const query = new URLSearchParams(location.search);
  const statusId = query.get('q[status_id_eq]');
  switch (slug) {
    case 'edit_listing':
      if (editable) {
        return {
          iconType: 'edit',
          disabled: loading,
          onClick: () => {
            actionButtonListingsClickEvent(user, statusId, 'edit');
            refObject?.showEditListingPage();
          },
        };
      } else {
        return null;
      }
    case 'listing_detail':
      if (previewAble) {
        return {
          iconType: 'preview-on-bayut',
          disabled: loading,
          onClick: () => {
            actionButtonListingsClickEvent(user, statusId, 'view_on_bayut');
            refObject?.showListingOnClassified();
          },
        };
      } else {
        return null;
      }

    case 'listing_detail_drawer':
      if (previewAble) {
        return {
          iconType: 'detail-drawer',
          disabled: loading,
          onClick: () => {
            actionButtonListingsClickEvent(user, statusId, 'preview');
            refObject?.showListingDetail();
          },
        };
      } else {
        return null;
      }

    case 'disable_listing':
      return {
        iconType: 'disable',
        disabled: loading,
        onClick: () => {
          refObject?.showHideListingModal();
        },
      };

    case 'hide_listing':
      return {
        iconType: 'hide',
        disabled: loading,
        onClick: () => {
          refObject?.showHideListingModal();
        },
      };
    case 'enable_listing':
      return {
        iconType: 'enable',
        color: 'red',
        disabled: loading,
        onClick: () => {
          refObject?.showUnhideListingModal();
        },
      };
    case 'unhide_listing':
      return {
        iconType: 'unhide',
        color: 'red',
        disabled: loading,
        onClick: () => {
          refObject?.showUnhideListingModal();
        },
      };
    case 'delete_listing':
      if (deletable) {
        return {
          slug: 'delete_listing',
          iconType: 'delete',
          disabled: loading,
          onClick: () => {
            actionButtonListingsClickEvent(user, statusId, 'delete');
            refObject?.showDeleteListingModal();
          },
        };
      } else {
        return null;
      }
    case 'change_listing_owner':
      return (
        user?.is_admin && {
          iconType: 'change-owner',
          disabled: loading,
          onClick: () => {
            refObject?.showChangeListingOwnerModal();
          },
        }
      );
    default:
      break;
  }
};

export const getListingRowActions = (item, platform, refObject, loading) => {
  const actions = listingRowActions?.[platform]
    ?.filter((e) => showRowActions(listingDispositionMapper(item?.disposition, item, platform)?.slug, e))
    .map((e) => getRowActionList(e, refObject, loading));
  return actions;
};

export const applyBulkRefresh = async (userId, listings, platform, setApplied, setRefreshCalled) => {
  let successfullyApplied = 0;
  let promiseArray = listings?.map(async (e) => {
    const response = await tenantApi.postPackageOnListing(
      e?.id,
      {
        slug: platform === 'zameen' ? 'refresh_listing' : 'olx_refresh_listing',
      },
      { user_id: !!e?.listingOwner ? e?.listingOwner?.id : !!e?.user ? e?.user?.id : userId },
    );
    if (response) {
      setRefreshCalled((prev) => prev + 1);
      if (!response?.error) {
        successfullyApplied++;
        setApplied((prev) => prev + 1);
      }
    }
  });
  const response = await Promise.all(promiseArray);
  if (response) {
  }
};

const listingTableColumnMapper = (disposition, user) => {
  switch (disposition) {
    case 'active':
      return [
        {
          title: 'Property',
          dataIndex: 'property',
          key: 'property',
          component: 'ListingPurpose',
          className: 'col-property',
        },
        ...(user?.isCurrencyUser
          ? [
              {
                title: 'Timeline',
                dataIndex: 'platforms',
                key: 'auto_renewable_item',
                component: 'ExpiryRenewal',
              },
            ]
          : []),
        { title: 'Performance', dataIndex: 'platforms', key: 'stats', component: 'ShowStats' },
        {
          title: 'Status',
          dataIndex: 'platforms',
          key: 'disposition',
          component: 'PlatformDisposition',
        },
        {
          title: 'Upgrades',
          dataIndex: 'platforms',
          key: 'platform_actions',
          component: 'PlatformListingActions',
          className: 'col-platform-actions',
        },
        {
          title: 'Actions',
          dataIndex: 'platforms',
          key: 'listing_actions',
          component: 'TableListingActions',
          fixed: 'right',
          width: 180,
          className: 'col-listing-actions',
        },
      ];
    case 'draft':
      return [
        {
          title: 'Property',
          dataIndex: 'property',
          key: 'property',
          component: 'ListingPurpose',
          className: 'col-property',
        },
        ...(user?.isCurrencyUser
          ? [
              {
                title: 'Timeline',
                dataIndex: 'platforms',
                key: 'auto_renewable_item',
                component: 'ExpiryRenewal',
              },
            ]
          : []),
        {
          title: 'Status',
          dataIndex: 'platforms',
          key: 'disposition',
          component: 'PlatformDisposition',
        },
        {
          title: 'Publish',
          dataIndex: 'platforms',
          key: 'platform_actions',
          component: 'PlatformListingActions',
          className: 'col-platform-actions',
        },
        {
          title: 'Actions',
          dataIndex: 'platforms',
          key: 'listing_actions',
          component: 'TableListingActions',
          fixed: 'right',
          width: 180,
          className: 'col-listing-actions',
        },
      ];
    case 'pending':
      return [
        {
          title: 'Property',
          dataIndex: 'property',
          key: 'property',
          component: 'ListingPurpose',
          className: 'col-property',
        },
        ...(user?.isCurrencyUser
          ? [
              {
                title: 'Timeline',
                dataIndex: 'platforms',
                key: 'auto_renewable_item',
                component: 'ExpiryRenewal',
              },
            ]
          : []),
        {
          title: 'Status',
          dataIndex: 'platforms',
          key: 'disposition',
          component: 'PlatformDisposition',
        },
        {
          title: 'Actions',
          dataIndex: 'platforms',
          key: 'listing_actions',
          component: 'TableListingActions',
          fixed: 'right',
          width: 180,
          className: 'col-listing-actions',
        },
      ];
    case 'removed':
      return [
        {
          title: 'Property',
          dataIndex: 'property',
          key: 'property',
          component: 'ListingPurpose',
          className: 'col-property',
        },
        ...(user?.isCurrencyUser
          ? [
              {
                title: 'Timeline',
                dataIndex: 'platforms',
                key: 'auto_renewable_item',
                component: 'ExpiryRenewal',
              },
            ]
          : []),
        { title: 'Performance', dataIndex: 'platforms', key: 'stats', component: 'ShowStats' },
        {
          title: 'Status',
          dataIndex: 'platforms',
          key: 'disposition',
          component: 'PlatformDisposition',
        },
        {
          title: 'Publish',
          dataIndex: 'platforms',
          key: 'platform_actions',
          component: 'PlatformListingActions',
          className: 'col-platform-actions',
        },
        {
          title: 'Actions',
          dataIndex: 'platforms',
          key: 'listing_actions',
          component: 'TableListingActions',
          fixed: 'right',
          width: 180,
          className: 'col-listing-actions',
        },
      ];
    default:
      return [
        {
          title: 'Property',
          dataIndex: 'property',
          key: 'property',
          component: 'ListingPurpose',
          className: 'col-property',
        },
        // {
        //   title: 'Quality',
        //   dataIndex: 'health',
        //   key: 'health',
        //   component: 'Health',
        // },
        ...(user?.isCurrencyUser
          ? [
              {
                title: 'Timeline',
                dataIndex: 'platforms',
                key: 'auto_renewable_item',
                component: 'ExpiryRenewal',
              },
            ]
          : []),
        { title: 'Performance', dataIndex: 'platforms', key: 'stats', component: 'ShowStats' },

        // {
        //   title: 'Posted On',
        //   dataIndex: 'platforms',
        //   key: 'posted_on',
        //   component: 'Date',
        // },
        {
          title: 'Status',
          dataIndex: 'platforms',
          key: 'disposition',
          component: 'PlatformDisposition',
        },
        {
          title: 'Upgrades',
          dataIndex: 'platforms',
          key: 'platform_actions',
          component: 'PlatformListingActions',
          className: 'col-platform-actions',
        },
        {
          title: 'Actions',
          dataIndex: 'platforms',
          key: 'listing_actions',
          component: 'TableListingActions',
          fixed: 'right',
          width: 180,
          className: 'col-listing-actions',
        },
      ];
  }
};

const getDateFieldsByStatus = (status, is_autorenewal_on) => {
  switch (status) {
    case 'active':
      return {
        'Posted on': 'posted_on',
        ...(is_autorenewal_on ? { 'Renewing on': 'renewing_on' } : { 'Expiring on': 'expiry_date' }),
      };
    case 'draft':
      return {
        'Created on': 'created_at',
        'Last updated on': 'updated_at',
      };

    case 'pending':
      return {
        'Uploaded on': 'posted_on',
        ...(is_autorenewal_on ? { 'Renewing on': null } : { 'Expiring on': null }),
      };
    case 'removed':
      return {
        'Posted on': 'posted_on',
        // 'Removed on': 'removed_at',
      };
    default:
      return {
        'Posted on': 'posted_on',
        ...(is_autorenewal_on ? { 'Renewing on': 'renewing_on' } : { 'Expiring on': 'expiry_date' }),
      };
  }
};

export default {
  applyBulkRefresh,
  getListingRowActions,
  getRowActionList,
  getListingPlatformActions,
  showRowActions,
  showPlatformAction,
  listingDispositionMapper,
  listingStatusMapper,
  listingplatformActions,
  dispositionDependentPlatformActions,
  dispositionDependentRowActions,
  listingRowActions,
  listingTableColumnMapper,
  getDateFieldsByStatus,
};
