import { NetworkService } from "../../../../services/networkService";

export const fetchPlotsByQuery = async (parentId, query) => {
  const response = await NetworkService.get(
    `https://www.zameen.com/api/plotFinder/parcel/?all=1&externalID=${parentId}${query ? `&query=${query}` : ''}`,
  );
  if (response) {
    return {
      data: response.data,
    };
  } else {
    return { ...response };
  }
};
