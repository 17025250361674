import tenantUtils from '@utils';

const actions = {
  SET_LISTINGS_DATA: 'SET_LISTINGS_DATA',
  SET_LISTINGS_TABLE_DATA: 'SET_LISTINGS_TABLE_DATA',
  SET_ANALYTICS_DATA: 'SET_ANALYTICS_DATA',
  SET_ANALYTICS_FILTER_OBJECT: 'SET_ANALYTICS_FILTER_OBJECT',
  UPDATE_LISTINGS_DATA: 'UPDATE_LISTINGS_DATA',
  UPDATE_LIST_DASHBOARD: 'UPDATE_LIST_DASHBOARD',
  SET_QUOTA_CREDITS_DATA: 'SET_QUOTA_CREDITS_DATA',
  SET_EXPIRY_DATA: 'SET_EXPIRY_DATA,',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_USERS_LIST: 'SET_USERS_LIST',
  SET_QUOTA_CREDITS_ERROR_LOADING: 'SET_QUOTA_CREDITS_ERROR_LOADING',
  SET_UPDATED_CREDITS: 'SET_UPDATED_CREDITS',
  SET_UPDATED_QUOTA: 'SET_UPDATED_QUOTA',
  SET_SELECTED_PLATFORM: 'SET_SELECTED_PLATFORM',
  SET_LEADS_FILTER_DASHBOARD: 'SET_LEADS_FILTER_DASHBOARD',
  LEADS_DATA_BEGIN_DASHBOARD: 'LEADS_DATA_BEGIN_DASHBOARD',
  LEADS_DATA_ERROR_DASHBOARD: 'LEADS_DATA_ERROR_DASHBOARD',
  LEADS_DATA_SUCCESS_DASHBOARD: 'LEADS_DATA_SUCCESS_DASHBOARD',
  SET_LEADS_SUMMARY_PLATFORM_FILTER: 'SET_LEADS_SUMMARY_PLATFORM_FILTER',
  SET_LISTINGS_LOADING: 'SET_LISTINGS_LOADING',
  SET_SELECTED_USER: 'SET_SELECTED_USER',
  SET_LEADS_SUMMARY_PLATFORM_FILTER_DASHBOARD: 'SET_LEADS_SUMMARY_PLATFORM_FILTER_DASHBOARD',

  setSelectedUser: (userId) => {
    return {
      type: 'SET_SELECTED_USER',
      payload: userId,
    };
  },

  updateListDashboard: (data) => {
    return {
      type: 'UPDATE_LIST_DASHBOARD',
      data,
    };
  },

  setListingsDashboardData: (data, platformKey) => {
    return {
      type: 'SET_LISTINGS_DATA',
      payload: { [platformKey]: data },
    };
  },

  setListingsDashboardLoading: (platformKey) => {
    return {
      type: 'SET_LISTINGS_LOADING',
      payload: { platformKey },
    };
  },

  setUpdatedCredits: (data) => {
    return {
      type: 'SET_UPDATED_CREDITS',
      data,
    };
  },
  setUpdatedQuota: (data) => {
    return {
      type: 'SET_UPDATED_QUOTA',
      data,
    };
  },
  setSelectedPlatform: (data) => {
    return {
      type: 'SET_SELECTED_PLATFORM',
      data,
    };
  },
  setLeadsFilter: (dateRange, platformKey) => {
    return {
      type: 'SET_LEADS_FILTER_DASHBOARD',
      payload: { dateRange, platformKey },
    };
  },
  leadsDataBegin: (platformKey, initailize) => {
    return {
      type: 'LEADS_DATA_BEGIN_DASHBOARD',
      payload: {
        platformKey,
        data: {
          ...(initailize && { data: null, filterObj: tenantUtils.reportsData[platformKey].initialFilter.lead }),
          loading: true,
          error: null,
        },
      },
    };
  },

  leadsDataSuccess: (platformKey, data) => {
    return {
      type: 'LEADS_DATA_SUCCESS_DASHBOARD',
      payload: {
        platformKey,
        data: { data, error: null, loading: false },
      },
    };
  },

  leadsDataError: (platformKey, error) => {
    return {
      type: 'LEADS_DATA_ERROR_DASHBOARD',
      payload: { platformKey, data: { error, loading: false } },
    };
  },
  setLeadsSummaryPlatformFilterDashboard: (filterObj, platformKey) => {
    return {
      type: 'SET_LEADS_SUMMARY_PLATFORM_FILTER_DASHBOARD',
      payload: {
        platformKey,
        data: filterObj,
      },
    };
  },
};
export default actions;
