import tenantUtils from '@utils';
import { t } from 'i18next';
import { strings } from '../../../../../constants/strings';
import { emailValidationYup, phoneValidationYup, stringValidationYup } from '../../../../../helpers';
import Algolia from '../../../../../services/algolia';

const fetchCities = async () => {
  const queryObject = Algolia.getAllCities();
  const response = await Algolia.getLocationsIndex().search('', queryObject);
  return response.hits.map(e => {
    return {
      id: e.location_id,
      name: tenantUtils.getLocalisedString(e, 'title'),
    };
  });
};

export const agencyFormFields = async (userId, data) => {
  let cities = null;
  cities = await fetchCities();
  return {
    list: {
      name: {
        type: 'input',
        value: '',
        validation: () => stringValidationYup(t('Please enter name')).nullable(),
        props: {
          label: 'Title (English)',
          placeholder: 'Please enter English title',
          maxLength: 80,
        },
      },
      nameArabic: {
        type: 'input',
        value: '',
        //validation: () => stringValidationYup('يرجى إضافة الاسم'),
        props: {
          label: 'العنوان (عربي)',
          placeholder: 'Please enter Arabic title',
          maxLength: 80,
          groupStyle: {
            direction: 'rtl',
          },
        },
      },
      email: {
        type: 'input',
        value: '',
        validation: data?.email ? false : () => emailValidationYup().nullable(),
        props: {
          label: 'Email',
          placeholder: 'Enter Email',
          maxLength: 300,
          disabled: true,
        },
      },
      city: {
        type: 'location-select',
        value: null,
        props: {
          label: 'City',
          placeholder: 'Select City',
          hideCrossCity: true,
          hideLocation: true,
          hidePlot: true,
          showSearch: false,
          showPrefixIcon: false,
          showLableIcon: false,
          options: [],
        },
      },

      mobile: {
        type: 'phone-input',
        value: '',
        validation: () => phoneValidationYup(true),
        props: {
          //containerClassName: 'pos-rel',
          label: 'Mobile Number',
          placeholder: 'Enter Mobile Number',
          defaultCountry: 'SA',
          disabled: true,
          //countryCallingCodeEditable: false,
          countrySelectProps: { disabled: true },
        },
      },
      // landline: {
      //   type: 'phone-input',
      //   value: '',
      //   validation: () => phoneValidationYup(false),
      //   props: {
      //     label: 'Landline',
      //     placeholder: 'Enter Landline',
      //     defaultCountry: 'SA',
      //     //countryCallingCodeEditable: false,
      //     countrySelectProps: { disabled: true },
      //   },
      // },
      // phone: {
      //   type: 'phone-input',
      //   value: '',
      //   validation: () => phoneValidationYup(false),
      //   props: {
      //     label: 'Landline',
      //     placeholder: 'Enter Landline',
      //     defaultCountry: 'SA',
      //   },
      // },
      whatsapp: {
        type: 'phone-input',
        value: '',
        validation: () => phoneValidationYup(false),
        props: {
          label: 'Whatsapp',
          placeholder: 'Enter whatsapp number',
          defaultCountry: 'SA',
          countryCallingCodeEditable: false,
          countrySelectProps: { disabled: true },
        },
      },

      nationalShortAddress: {
        type: 'input',
        value: '',
        validation: () => stringValidationYup(false).nullable(),
        props: {
          label: 'National Short Address',
          placeholder: 'Enter National Short Address',
          maxLength: 8,
          className: 'span-all',
          //disabled: !!data?.national_short_address,
        },
      },
      address: {
        type: 'input',
        value: '',
        validation: () => stringValidationYup(false).nullable(),
        props: {
          label: 'Address',
          placeholder: 'Enter Address',
          maxLength: 250,
          className: 'span-all',
        },
      },

      image: {
        type: 'image-select',
        value: [],
        validation: () => null,
        props: {
          label: 'Upload a picture',
          placeholder: 'Browse and Upload',
          multi: false,
          className: 'span-all',
          attachmentType: 'user_profile',
        },
      },
    },
  };
};

export const inviteFormFields = () => {
  return {
    list: {
      mobile: {
        type: 'phone-input',
        value: '',
        validation: () => phoneValidationYup(true),
        props: {
          label: 'Enter the user number to invite user in your agency',
          placeholder: '-',
          countryCallingCodeEditable: false,
          countrySelectProps: { disabled: true },
          defaultCountry: 'SA',
        },
      },
    },
  };
};
