import tenantUtils from '@utils';
import { imageStateObject } from '../../../../../helpers/imageHelpers/imageStateObject';
import { formatMobile } from '../../../../../utility/utility';

export const getAgencyValues = (values) => {
  if (!values) return null;
  return {
    id: values?.id,
    country: values?.country_id || 155,
    commercialRegistrationNumber: values?.commercial_registration_number,
    fALLicenseNumber: values?.license?.number
      ? values?.license?.number
      : values?.license?.license_owner_type === 'Agency'
        ? values?.agency_attributes?.license_attributes?.number
        : values?.license_attributes?.number,
    fallId: values?.license?.id,
    fALLicenseExpiryDate: values?.license?.end_date,
    license_type: values?.license?.license_owner_type,
    falLicenseURL: values?.license?.certificate_link
      ? values?.license?.certificate_link
      : values?.license?.license_owner_type === 'Agency'
        ? values?.agency_attributes?.license_attributes?.certificate_link
        : values?.license_attributes?.certificate_link,
    is_mobile_verified: values?.is_mobile_verified,
    is_rega_verified: values?.is_rega_verified,
    city: { city: { location_id: values?.city_id, name: tenantUtils.getLocalisedString(values, 'city') } },
    email: values?.email,
    website: values?.website,
    description: tenantUtils.getLocalisedString(values, 'description', true),
    mobile: values?.mobile ? formatMobile(values?.mobile, 'singleNumber').split(',')?.[0] : '',
    landline: values?.phone ? formatMobile(values?.phone, 'singleNumber') : '',
    whatsapp: values?.whatsapp ? formatMobile(values?.whatsapp, 'singleNumber') : '',
    agencyWhatsappForAll: values?.use_agency_whatsapp,
    logo: values?.agency_logo && [{ gallerythumb: values?.agency_logo, ...imageStateObject() }],
    address: tenantUtils.getLocalisedString(values, 'company_address'),
    typeofBuisness: values?.broker_type,
    buisnessName: tenantUtils.getLocalisedString(values, 'name', true),
    nationalShortAddress: values?.national_short_address,
    updateCompanyListings: values?.update_agency_listing_details,
    enable_whatsapp_leads: values?.enable_whatsapp_leads,
    owner: {
      ownerId: values?.creator_id,
      name: values?.owner?.name,
      designation: values?.designation,
      message: values?.owner?.message,
      profilePhoto: values?.owner?.profile_image && [
        { gallerythumb: values?.owner?.profile_image, ...imageStateObject() },
      ],
    },
  };
};
