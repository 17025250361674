import { formatMobile } from '../../../../../utility/utility';
import { imageStateObject } from '../../../../../helpers/imageHelpers/imageStateObject';

export const getLoggedInUserValues = (values) => {
  if (!values) return null;
  return {
    id: values?.id,
    name: values?.name,
    email: values?.email,
    mobile: values?.mobile ? formatMobile(values?.mobile, 'singleNumber') : '',
    phone: values?.phone ? formatMobile(values?.phone, 'singleNumber') : '',
    address: values?.address,
    location: { city: values?.city_id && { location_id: values?.city_id } },
    ...(values?.profile_image && {
      profile_image: [{ gallerythumb: values?.profile_image, ...imageStateObject() }],
    }),
    whatsapp: values?.whatsapp ? formatMobile(values?.whatsapp, 'singleNumber') : '',
  };
};
