import * as yup from 'yup';
import { regex } from '../constants/regex';

import { strings } from '../constants/strings';

import tenantConstants from '@constants';
import { t } from 'i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const phoneValidationYup = (isRequired = true, isMulti) => {
  if (tenantConstants.country_code === 'PK') {
    if (!!isMulti) {
      return yup.array().of(
        yup
          .string()
          .required(t(strings.vm.phone))
          .test('format', t(strings.im.phone), (value) => {
            return isValidPhoneNumber(value ? value : '-');
          }),
      );
    } else {
      return isRequired
        ? yup
            .string()
            .required(t('Please enter your number'))
            .test('format', t(strings.im.phone), (value) => {
              return isValidPhoneNumber(value ? value : '-');
            })
        : yup
            .string()
            .test('format', t(strings.im.phone), (value) => {
              return value ? isValidPhoneNumber(value ? value : '-') : true;
            })
            .nullable();
    }
  } else if (tenantConstants.country_code === 'SA') {
    if (!!isMulti) {
      return yup.array().of(
        yup
          .string()
          .required(t(strings.vm.phone))
          .test('format', t(strings.im.phone), (value) => {
            if (!value) return true;
            return tenantConstants.phoneRegex ? tenantConstants.phoneRegex.test(value) : true;
          }),
      );
    } else {
      return isRequired
        ? yup
            .string()
            .required(t('Please enter your number'))
            .test('format', t(strings.im.phone), (value) => {
              if (!value) return true;
              return tenantConstants.phoneRegex ? tenantConstants.phoneRegex.test(value) : true;
            })
        : yup
            .string()
            .test('format', t(strings.im.phone), (value) => {
              if (!value) return true;
              return tenantConstants.phoneRegex ? tenantConstants.phoneRegex.test(value) : true;
            })
            .nullable();
    }
  }
};

export const websiteValidation = (
  websiteRegex = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}(\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)|(\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)\.[a-zA-Z0-9()]{1,6}\b))$/,
) => {
  return yup
    .string()
    .test('', t(strings.enter_valid_website), function (value) {
      if (!value) return true;
      const isValidWebsite = websiteRegex.test(value);
      if (!isValidWebsite) {
        return false;
      }
      return true;
    })
    .nullable();
};

export const emailValidationYup = (emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/) => {
  return yup
    .string()
    .required(t('Email is required'))
    .test('', strings.enter_valid_email, function (value) {
      const isValidEmail = emailRegex.test(value);
      if (!isValidEmail) {
        return false;
      }
      return true;
    });
};

export const stringValidationYup = (requiredMsg, regex = '', invalidMsg = 'invalid msg hasnt been added') => {
  if (requiredMsg) {
    if (regex && requiredMsg) return yup.string().required(requiredMsg).matches(regex, invalidMsg).nullable();
    return yup.string().required(requiredMsg).nullable();
  } else
    return yup.string().test('', invalidMsg, (value) => {
      if (!value) return true;
      return regex ? regex.test(value) : true;
    });
};

export const numValidation = (requiredMsg, limitTo = 12) => {
  if (requiredMsg) {
    return yup
      .string()
      .required(requiredMsg)
      .test('regex', strings.im.invalid_value, (value) => regex.naturalNumbers.test(value?.trim()))
      .test('limitTest', `${strings.im.value_exceeded_max} (${limitTo})`, (value) => value?.trim().length <= limitTo);
  }
  return yup
    .string()
    .test('regex', strings.im.invalid_value, (value) => (value ? regex.naturalNumbers.test(value?.trim()) : true))
    .test('limitTest', `${strings.im.value_exceeded_max} (${limitTo})`, (value) =>
      value ? value?.trim().length <= limitTo : true,
    )
    .nullable();
};

export const numValidationByAmount = (requiredMsg, amount) => {
  if (requiredMsg) {
    return yup
      .string()
      .required(requiredMsg)
      .test('regex', strings.im.invalid_value, (value) => (value ? regex.naturalNumbers.test(value?.trim()) : true))
      .test('limitTest', `${strings.im.value_exceeded} ${amount}`, (value) => (value ? value <= amount : true));
  }
  return yup
    .string()
    .test('regex', strings.im.invalid_value, (value) => (value ? regex.naturalNumbers.test(value?.trim()) : true))
    .test('limitTest', `${strings.im.value_exceeded} ${amount}`, (value) => (value ? value <= amount : true));
};
