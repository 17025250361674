import tenantTheme from '@theme';
import React from 'react';
import styled from 'styled-components';
import Card from '../cards/Card';
import JSONForm from './json-form';

export const JSONFormStyled = styled(JSONForm)`
  max-width: 880px;
  margin: 0 auto;
  
  > div {
    align-items: start; // user-settings/profile - after error msgs field alignment
  }

  @media only screen and (max-width: 991px){
    > div {
      --template: minmax(min(20ch, 100%), 1fr) !important;
    }
  }
`;

export const AmenitiesCard = styled(props => <Card {...props} />)`
  &.ant-card {
    cursor: pointer;
    input {
      height: 33px;
      max-width: 100px;
      width: 100%;
    }
    &.selected-card {
      border-color: ${tenantTheme['primary-light-2']};
      background-color: ${tenantTheme['primary-light-4']};
    }
    .amenities-data {
      + div {
        justify-content: end;
      }
    }
  }
`;
