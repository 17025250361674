import tenantTheme from '@theme';
import { Badge, Row } from 'antd';
import cx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ChartjsDonutChart } from '../charts/chartjs';
import { Card, Group, TextWithIcon } from '../common';
import { SkeletonBody } from '../skeleton/Skeleton';
import { SessionChartWrapper } from './styled';

function BreakdownByAreaWidget(props) {
  const { t } = useTranslation();
  const { areaBreakdownData: data, loading, icon } = props;
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const chartDimension = isMobile ? 114 : 160;

  return (
    <>
      <Row
        className={cx(isMobile ? 'px-16' : 'px-4', 'mb-8')}
        justify="space-between"
        style={{ minHeight: 34, alignItems: 'end', columnGap: 24, rowGap: 4 }}
      >
        <TextWithIcon
          className={cx(isMobile ? 'fz-14' : 'fz-16')}
          fontWeight={700}
          icon={icon || data?.icon_data?.icon}
          iconProps={data?.icon_data?.icon_props}
          title={t(data?.title)}
          textColor="#272b41"
          loading={loading}
          loadingProps={{ rectSize: 'small' }}
        />
      </Row>
      <Card
        className="flex-grow"
        bodyStyle={{ minHeight: 'inherit', padding: '16px 24px' }}
        style={{ minHeight: 'calc(100% - 42px)' }}
      >
        {loading ? (
          <Group
            className={cx(!isMobile && 'py-16')}
            template="repeat(auto-fill, minmax(min(42ch, 100%), 1fr))"
            gap={isMobile ? '24px' : '48px'}
            style={{ alignItems: 'start' }}
          >
            <Row align="middle" style={{ gap: isMobile ? 16 : 32 }}>
              <SkeletonBody type="avatar" size={chartDimension} />
              <SkeletonBody size={chartDimension} />
            </Row>
            <Row align="middle" style={{ gap: isMobile ? 16 : 32 }}>
              <SkeletonBody type="avatar" size={chartDimension} />
              <SkeletonBody size={chartDimension} />
            </Row>
          </Group>
        ) : (
          <SessionChartWrapper style={{ fontSize: isMobile && 12 }}>
            <>
              <div className="session-chart-inner">
                <Group
                  className={cx(!isMobile && 'py-16')}
                  template="repeat(auto-fill, minmax(min(42ch, 100%), 1fr))"
                  gap={isMobile ? '24px' : '48px'}
                  style={{ alignItems: 'start' }}
                >
                  {data?.types?.map((item) => {
                    const isZero = data?.data[item?.key]?.total === 0;

                    return (
                      <Row key={item?.key} style={{ gap: isMobile ? 16 : 32 }}>
                        <div>
                          <ChartjsDonutChart
                            labels={data?.data[item?.key]?.chart.labels}
                            innerLabel={t(item.label)}
                            showTotalInnerLable
                            datasets={[
                              {
                                data: isZero ? [100] : data?.data[item?.key]?.chart.dataset,
                                backgroundColor: isZero ? tenantTheme.gray200 : data?.data[item?.key]?.backgroundColor,
                                total: data?.data[item?.key]?.total,
                                borderWidth: 1,
                              },
                            ]}
                            width={chartDimension}
                            height={chartDimension}
                            options={{
                              cutoutPercentage: 82,
                              legend: { display: false },
                              animation: { duration: isZero ? 0 : 1000 },
                              tooltips: { enabled: !isZero },
                            }}
                          />
                        </div>
                        <div className="flex flex-col flex-grow">
                          {data?.data[item?.key]?.locations?.length ? (
                            <Group template="initial" gap="0px" style={{ height: '100%', placeContent: 'center' }}>
                              {data?.data[item?.key]?.locations?.map((item2, index) => (
                                <Row
                                  justify="space-between"
                                  style={{ alignItems: 'baseline', gap: '16px' }}
                                  key={index}
                                >
                                  <Badge
                                    color={data?.data[item?.key]?.backgroundColor[index] || '#e5e5e5'}
                                    text={item2?.label}
                                    style={{
                                      color: '#5a5f7d',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      flex: 1,
                                    }}
                                  />
                                  <div style={{ fontSize: 16 }}>
                                    <span>{item2.data}</span>{' '}
                                    <small style={{ paddingInlineStart: 5, color: '#868eae' }}>
                                      {((item2.data / data?.data[item?.key]?.total) * 100).toFixed(1)}%
                                    </small>
                                  </div>
                                </Row>
                              ))}
                            </Group>
                          ) : (
                            <Group style={{ height: '100%', placeContent: 'center' }}>
                              <Badge
                                color={tenantTheme.gray400}
                                text={t('Not enough data')}
                                style={{
                                  color: '#5a5f7d',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  flex: 1,
                                }}
                              />
                            </Group>
                          )}
                        </div>
                      </Row>
                    );
                  })}
                </Group>
              </div>
            </>
          </SessionChartWrapper>
        )}
      </Card>
    </>
  );
}

export default BreakdownByAreaWidget;
