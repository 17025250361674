import tenantData from '@data';
import { getVariousDates } from '../../utility/date';
import actions from './actions';

const { SET_TRAFFIC_TABLE_DATA, SET_TRAFFIC_WIDGET_DATA, SET_TRAFFIC_WIDGET_FILTER, SET_TRAFFIC_PLATFORM_FILTER } =
  actions;

const initialState = {
  widgetData: {
    date_between: getVariousDates(29),
  },
  tableData: {
    error: null,
    loading: true,
    list: null,
    pagination: null,
  },
};

export const TrafficSummaryReducer = (state = initialState, action) => {
  const { type, payload } = action;

  const filterObj = state.widgetData[payload?.platformKey]?.filterObj;

  const handleFilterUpdate = (platformKey) => {
    if (!!platformKey) {
      return {
        [payload.platformKey]: {
          ...state.widgetData[payload.platformKey],
          filterObj: { ...filterObj, ...payload.data },
        },
      };
    }

    const platformObj = {};
    tenantData.platformList.forEach((platform) => {
      platformObj[platform.slug] = {
        ...state.widgetData[platform.slug],
        filterObj: { ...state.widgetData[platform.slug]?.filterObj, ...payload.data },
      };
    });

    return platformObj;
  };

  switch (type) {
    case SET_TRAFFIC_TABLE_DATA:
      return { ...state, tableData: { ...state.tableData, loading: false, ...payload } };
    case SET_TRAFFIC_WIDGET_DATA:
      return {
        ...state,
        widgetData: {
          ...state.widgetData,
          [payload.platformKey]: { ...state.widgetData[payload.platformKey], ...payload.data },
        },
      };

    case SET_TRAFFIC_PLATFORM_FILTER:
      return {
        ...state,
        widgetData: {
          ...state.widgetData,
          ...handleFilterUpdate(payload.platformKey),
        },
      };
    case SET_TRAFFIC_WIDGET_FILTER:
      return {
        ...state,
        widgetData: { ...state.widgetData, ...payload },
      };
    default:
      return state;
  }
};
