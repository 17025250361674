import tenantTheme from '@theme';
import tenantUtils from '@utils';
import { Divider, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, EmptyState, Group, Skeleton } from '../../../../components/common';
import { ListingContainer } from '../../../../components/listing-container/ListingContainer';
import { NetworkError } from '../../../../components/svg';
import ReportsListingsSection from '../../../../components/widgets/ReportsListingsSection';
import ListingPerformance from '../../../../container/pages/reports/listing-performance';
import { useGetLocation, useRouteNavigate } from '../../../../hooks';
import { setListingPerformanceTableData } from '../../../../redux/listingPerformance/actionCreator';
import { convertQueryObjToString, mapQueryStringToFilterObject } from '../../../../utility/urlQuery';
import CardListingByDate from './CardListingByDate';
import CardListingPerformance from './CardListingPerformance';

const getReportsDateTypeData = (item) => {
  return [
    {
      title: 'Basic',
      value: item?.basic?.value,
      icon: 'IconBasic',
      iconProps: {
        color: tenantTheme['color-basic'],
        hasBackground: true,
        iconContainerSize: '24px',
        size: '1.1em',
      },
    },
    {
      title: 'Hot',
      value: item?.hot?.value,
      icon: 'IconSuperHot',
      iconProps: {
        color: tenantTheme['color-hot'],
        hasBackground: true,
        iconContainerSize: '24px',
        size: '1em',
      },
    },
    {
      title: 'Signature',
      value: item?.signature?.value,
      icon: 'BsFillLightningChargeFill',
      iconProps: {
        color: tenantTheme['color-signature'],
        hasBackground: true,
        iconContainerSize: '24px',
        size: '.9em',
      },
    },
    {
      title: 'Refresh',
      value: item?.refresh?.value,
      icon: 'MdRefresh',
      iconProps: {
        color: tenantTheme['color-for-rent'],
        hasBackground: true,
        iconContainerSize: '24px',
        size: '1.1em',
      },
    },
    {
      title: 'Photography',
      value: item?.photography?.value,
      icon: 'HiCamera',
      iconProps: {
        color: tenantTheme['color-for-sale'],
        hasBackground: true,
        iconContainerSize: '24px',
        size: '1.1em',
      },
    },
    {
      title: 'Videography',
      value: item?.videography?.value,
      icon: 'HiVideoCamera',
      iconProps: {
        color: tenantTheme['color-clicks'],
        hasBackground: true,
        iconContainerSize: '24px',
        size: '1.1em',
      },
    },
  ];
};

const getServiceTypesDateData = (item) => {
  return [
    {
      value: item?.refresh?.value,
      icon: 'MdRefresh',
      iconProps: {
        color: tenantTheme['color-for-rent'],
        hasBackground: true,
        iconContainerSize: '24px',
      },
    },
    {
      value: item?.photography?.value,
      icon: 'HiCamera',
      iconProps: {
        color: tenantTheme['color-for-sale'],
        hasBackground: true,
        iconContainerSize: '24px',
        size: '1em',
      },
    },
    {
      value: item?.videography?.value,
      icon: 'HiVideoCamera',
      iconProps: {
        color: tenantTheme['color-clicks'],
        hasBackground: true,
        iconContainerSize: '24px',
        size: '1em',
      },
    },
  ];
};

function ListingsSummary({
  showTable = true,
  widgetData,
  tableData,
  fetchTableData,
  fetchWidgetData,
  setPageDate,
  ...rest
}) {
  const { t } = useTranslation();
  const [activeTabKey, setActiveTabKey] = useState('by-performance');
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const listingPerformance = useSelector((state) => state.ListingPerformance);
  const { user } = useSelector((state) => state.loginUser);
  const [page, setPage] = useState(1);
  const navigate = useRouteNavigate();
  const location = useGetLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (listingPerformance?.list?.[0]?.platforms?.data?.[1]?.statsError) {
      notification.error(t(listingPerformance?.list?.[0]?.platforms?.data?.[1]?.statsError));
    }
  }, [listingPerformance]);

  useEffect(() => {
    showTable &&
      activeTabKey === 'by-performance' &&
      user.id &&
      dispatch(setListingPerformanceTableData(user.id, { page }, listingPerformance));
  }, [page, activeTabKey, showTable]);

  const onRetry = () => {
    activeTabKey === 'by-performance' &&
      user.id &&
      dispatch(setListingPerformanceTableData(user.id, { page }, listingPerformance));
  };
  const tabList = useMemo(
    () => [
      { key: 'by-performance', tab: t('Listing Performance') },
      { key: 'by-date', tab: t('Listing By Date') },
    ],
    [],
  );
  const onSearch = (object) => {
    const { filterObj, filterParams } = tenantUtils.mapFilterBeforeFetch({ ...object });
    delete filterObj.page;
    navigate({
      pathname: location.pathname,
      search: filterParams || convertQueryObjToString(filterObj),
    });
  };
  const onChangeTab = (e) => {
    const { queryObj } = mapQueryStringToFilterObject(location.search);
    setActiveTabKey(e);
    const obj = tenantUtils.mapFilterAfterFetch({
      ...queryObj,
    });
    onSearch(obj);
  };

  const getContent = () => ({
    'by-performance': isMobile ? (
      <>
        {!!listingPerformance?.loading && !listingPerformance?.list?.length && (
          <>
            <Group gap="8px">
              {[1, 2, 3].map(() => (
                <Card>
                  <Group template="106px 1fr" gap=".571rem">
                    <Skeleton type="image" active={false} style={{ borderRadius: 6, width: '100%', height: '100%' }} />
                    <Group template="initial" gap="4px">
                      <Skeleton type="paragraph" />
                    </Group>
                  </Group>
                  <Divider style={{ marginBlock: 8 }} />
                  <Row align="middle" justify="space-between">
                    <div>
                      <Skeleton style={{ height: 14 }} />
                      <div>
                        <Skeleton style={{ width: 106, height: 20 }} />
                      </div>
                    </div>
                    <Group template="repeat(5, 1fr)" gap="4px">
                      {[1, 2, 3, 4, 5].map(() => {
                        return <Skeleton type="avatar" active={false} />;
                      })}
                    </Group>
                  </Row>
                </Card>
              ))}
            </Group>
          </>
        )}
        {!listingPerformance?.loading && listingPerformance?.error && (
          <EmptyState
            illustration={<NetworkError />}
            title="Error"
            message={listingPerformance?.error}
            onClick={() => {
              onRetry();
            }}
            buttonLoading={listingPerformance?.loading}
          />
        )}
        {
          <CardListingPerformance
            tableData={listingPerformance}
            disabled={!!listingPerformance?.loading && !!listingPerformance?.list?.length}
            setPage={setPage}
          />
        }
      </>
    ) : (
      <ListingPerformance isMain={false} />
    ),
    'by-date': isMobile ? (
      <>
        {!tableData?.error ? (
          <CardListingByDate
            getServiceTypesDateData={getServiceTypesDateData}
            getReportsDateTypeData={getReportsDateTypeData}
            tableData={tableData}
            disabled={!!tableData?.loading && !tableData?.error && !!tableData?.list?.length}
            setPageDate={setPageDate}
          />
        ) : (
          <EmptyState
            illustration={<NetworkError />}
            title="Error"
            message={tableData?.error}
            onClick={(params) => {
              fetchTableData(params);
            }}
            buttonLoading={tableData?.loading}
          />
        )}
      </>
    ) : (
      <ListingContainer
        listingsData={tableData}
        listingApi={(params) => fetchTableData(params)}
        skeletonLoading={!tableData.list?.length && tableData.loading}
        loading={tableData.loading}
        error={tableData.error}
        onRetry={(params) => fetchTableData(params)}
        renderFiltersAsTopRight
        isMain={false}
      />
    ),
  });

  return (
    <div>
      <ReportsListingsSection rootClassName="mb-16" data={widgetData} fetchWidgetData={fetchWidgetData} />
      {showTable && (
        <Card
          bodyStyle={{
            backgroundColor: isMobile && tenantTheme['layout-body-background'],
            paddingInline: 0,
            paddingBlockStart: 1,
          }}
          tabList={tabList}
          activeTabKey={activeTabKey}
          onTabChange={(e) => onChangeTab(e)}
        >
          {getContent()[activeTabKey]}
        </Card>
      )}
    </div>
  );
}
export default ListingsSummary;
