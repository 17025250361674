import React from 'react';
import { LinkWithIcon } from '../../../../components/common';
import { IconSwitch } from '../../../../components/svg';
import { useTranslation } from 'react-i18next';
import { getClassifiedBaseURL } from '../../../../utility/env';
import { useSelector } from 'react-redux';

const HeaderLink = () => {
  const { t } = useTranslation();
  const locale = useSelector(state => state.AppConfig.locale);
  return (
    <LinkWithIcon
      as="a"
      icon={<IconSwitch />}
      linkTitle={t('Go to bayut.sa')}
      href={`${getClassifiedBaseURL()}/${locale}`}
      className="btnLink px-8 mb-12"
      target="_blank"
    />
  );
};

export default HeaderLink;
