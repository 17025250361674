import constants from './constants';
import { agencySettingsFields } from './agencySettingsFields';
import { agencySettingsFieldsValues } from './agencySettingsFieldsValues';
import { listingTypes } from './listingTypes';
import products from './products';
import profileFieldsValues from './profileFieldsValues';
import profileFormFields from './profileFields';
import staticLists from './staticLists';
const source = 'profolio';

export const ZAMEEN_KEY = 'zameen';

export default {
  source,
  ...constants,
  listingTypes,
  ...products,
  profileFormFields,
  profileFieldsValues,
  agencySettingsFields,
  agencySettingsFieldsValues,
  ...staticLists,
};
