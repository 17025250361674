import tenantData from '@data';
import tenantUtils from '@utils';
import { t } from 'i18next';
import store from '../../../../redux/store';
import { setUsersList } from '../../../../redux/users/actionCreator';
import { NetworkService } from '../../../../services/networkService';
import { capitalizeFirstLetter, mapQueryParams } from '../../../../utility/utility';

export const postedToZameen = (e) => {
  switch (e) {
    case 'not_listed':
      return false;
    case undefined:
      return false;
    case 'limit':
      return false;
    default:
      return true;
  }
};

export const postedToOLX = (e) => {
  switch (e) {
    case 'not_listed':
      return false;
    case undefined:
      return false;
    default:
      return true;
  }
};

const getTitle = (key) => {
  switch (key) {
    case 'active':
      return {
        title: 'Active',
        mapping: '1',
      };
    case 'inactive':
      return {
        title: 'Inactive',
        mapping: '2',
      };
    case 'deleted':
      return {
        title: 'Deleted',
        mapping: 'Deleted',
      };
    case 'downgraded':
      return {
        title: 'Downgraded',
        mapping: 'Downgraded',
      };

    case 'expired':
      return {
        title: 'Expired',
        mapping: 'Expired',
      };

    case 'pending':
      return {
        title: 'Pending',
        mapping: 'Pending',
      };
    case 'rejected':
      return {
        title: 'Rejected',
        mapping: 'Rejected',
      };
  }
};

const getSummaryData = (data, statuses) => {
  const arr = Object.keys(data)?.length > 0 ? Object.keys(data) : [];
  const stats = {};
  arr.forEach((e) => {
    const status = statuses.find((it) => it.slug == e);
    stats[capitalizeFirstLetter(e)] = {
      title: status.name,
      mapping: status.id,
      total: data[e],
    };
  });
  return stats;
};

export const zameenMapper = (e, statsExists = false) => {
  return {
    id: e.id,
    property_id: e?.id,
    slug: 'zameen',
    posted_on: e?.created_at,
    consumedQuota: e?.consumed_quota,
    expiry_date: e?.expiry_date,
    updated_at: e?.updated_at,
    status: tenantUtils.listingStatusMapper(e?.platform?.zameen?.status, e, 'zameen'),
    ...(e.listing_purpose && { purpose: e.listing_purpose.title }),
    price: { value: e?.price ? e?.price : e?.rental_price, currency: e?.currency },
    location: e.location,
    hidden: e?.platform?.zameen?.hidden,
    ...(!statsExists && {
      views: e?.listing_stats?.imp_total,
      leads:
        e?.listing_stats?.phone_total +
        e?.listing_stats?.email_total +
        e?.listing_stats?.whatsapp_total +
        e?.listing_stats?.sms_total,
    }),
    posted: e?.platform?.zameen?.status ? postedToZameen(e?.platform?.zameen?.status) : false,
    stories: e?.platform?.zameen?.stories,
    category: e?.platform?.zameen?.mark?.title,
    icon: 'IconZameen',
    public_url: e?.platform?.zameen?.public_url,
    listingPlatformActions: tenantUtils.getListingPlatformActions(e, 'zameen'),
    listingRowActions: (refsObject, loading) => {
      const actions = tenantUtils.getListingRowActions(e, 'zameen', refsObject, loading);
      return actions;
    },
    ...(!!e?.user && { listingOwner: e?.user }),
  };
};

export const ksaMapper = (e, user, statsLoaded, existingStats) => {
  return {
    id: e.id,
    property_id: e?.id,
    slug: 'ksa',
    posted_on: e?.platforms?.posted_at,
    expiry_date: e?.expiry_date,
    updated_at: e?.updated_at,
    created_at: e?.created_at,
    renewing_on: e?.platforms?.['ksa']?.products_information?.['basic-listing']?.['auto_renewable_item']?.renewing_on,
    removed_at: e?.removed_at,
    ...(!statsLoaded && { views: 'loading', clicks: 'loading', leads: 'loading' }),
    status: tenantUtils.listingStatusMapper(e?.status?.slug),
    ...(existingStats && existingStats),
    disposition: tenantUtils.listingDispositionMapper(e?.disposition),
    ...(e.listing_purpose && { purpose: e.listing_purpose.title, purposeId: e?.listing_purpose?.id }),
    price: { value: e?.price, currency: t('SAR') },
    location: { ...e.location, breadcrumb: e?.location?.breadcrumb?.reverse() },
    posted: e?.status ? postedToZameen('active') : false,
    icon: 'IconBayut',
    public_url: tenantUtils.getLocalisedString(e, 'url'),
    listingPlatformActions: tenantUtils.getListingPlatformActions(e, 'ksa', user),
    listingRowActions: (refsObject, loading) => {
      const actions = tenantUtils.getListingRowActions(e, 'ksa', refsObject, loading);
      return actions;
    },
    expiryDays: e?.expiry_days || 30,
    ...(!!e?.user && { listingOwner: e?.user }),
  };
};

export const olxMapper = (e, statsExists = false) => {
  return {
    id: e?.platform?.olx?.platform_listing_id,
    property_id: e?.id,
    slug: 'olx',
    posted: !!e?.platform?.olx?.status ? postedToOLX(e?.platform?.olx?.status) : false,
    consumedQuota: e?.platform?.olx?.consumed_quota,
    status: tenantUtils.listingStatusMapper(e?.platform?.olx?.status, e, 'olx'),
    ...(e.listing_purpose && { purpose: e.listing_purpose.title }),
    price: { value: e.price ? e?.price : e?.rental_price, currency: e?.currency },
    location: e.location,
    posted_on: !!e?.platform?.olx && e?.platform?.olx?.date_added,
    updated_at: !!e?.platform?.olx && e?.platform?.olx?.date_updated,
    hidden: e?.platform?.olx?.hidden,
    ...(!statsExists && { views: 'loading' }),
    ...(!statsExists && { leads: 'loading' }),
    category: e?.platform?.olx?.mark?.title,
    public_url: e?.platform?.olx?.public_url,
    icon: 'IconOLX',
    listingPlatformActions: tenantUtils.getListingPlatformActions(e, 'olx'),
    listingRowActions: (refsObject, loading) => {
      const actions = tenantUtils.getListingRowActions(e, 'olx', refsObject, loading);
      return actions;
    },
    ...(!!e?.user && { listingOwner: e?.user }),
  };
};

export const listingMapper = (listing, user, stats, existingStats) => {
  const platforms = {
    property_id: listing?.id,
    ...(listing?.listing_purpose && { purpose: listing?.listing_purpose }),
    type: listing?.listing_type,
    price: { value: listing?.price, currency: 'SAR' },
    location: { ...listing?.location, breadcrumb: listing?.location?.breadcrumb?.reverse() },
    auto_renewable_item: {
      isApplied: listing?.is_autorenewal_on,
      ...listing?.platforms?.['ksa']?.products_information?.['basic-listing']?.['auto_renewable_item'], //Pending
    },
    data: [...(!!user?.products?.platforms['ksa'] ? [ksaMapper(listing, user, stats, existingStats)] : [])],
  };
  return {
    ...listing,
    id: listing?.id,
    type: listing?.listing_type?.title,
    purpose: listing?.listing_purpose,
    property: {
      id: listing?.id,
      ...(listing?.listing_purpose && { purpose: listing?.listing_purpose }),
      isDailyRental: listing?.listing_purpose?.slug == 'daily-rental',
      type: listing?.listing_type,
      area: { value: listing?.area_unit?.value, unit: listing?.area_unit?.id || 2 },
      location: { ...listing?.location, breadcrumb: listing?.location?.breadcrumb?.reverse() },
      image: listing?.image,
      price: { value: listing.price, currency: 'SAR' },
      details: {
        ...listing?.rega_details?.property_specs,
        beds: listing?.beds,
        baths: listing?.baths,
        listing_type: listing?.listing_type,
        regaId: listing?.ad_license,
        regaExpiryDate: listing?.platforms?.rega_expiry_date,
      },
      ...(listing?.health && {
        health: listing?.health,
      }),
      productsInfo: listing?.platforms?.ksa?.products_information, //Pending
      platforms,
    },
    price: { value: listing.price, currency: 'SAR' },
    area: { value: listing?.area_unit?.value, unit: listing?.area_unit?.id || 2 },
    ...(listing?.health && {
      health: listing?.health,
    }),
    platforms,
  };
};

export const updateAutoRenew = async (autoRenewId, listingId, productId, user) => {
  let response = '';
  if (autoRenewId) {
    response = await NetworkService.delete(`/api/auto_renewable_items/${autoRenewId}`);
  } else {
    response = await NetworkService.post('/api/auto_renewable_items', {
      auto_renewable_item: {
        auto_renewable_id: listingId,
        auto_renewable_type: 'Listing',
        product_id: productId,
      },
    });
  }
  if (response) {
    if (response?.error) {
      return response;
    } else if (response?.data?.auto_renewable_item) {
      return {
        ...response?.data?.auto_renewable_item,
        isApplied: !!response?.data?.auto_renewable_item?.auto_renewable_id,
        id: response?.data?.auto_renewable_item?.id,
        renewing_on: response?.data?.auto_renewable_item?.renewing_on,
        listing_id: listingId,
        replaceKey: 'platforms.auto_renewable_item',
        valueExists: true,
      };
    } else {
      return {
        isApplied: false,
        id: null,
        renewing_on: null,
        listing_id: listingId,
        replaceKey: 'platforms.auto_renewable_item',
        valueExists: true,
      };
    }
  }
};

export const fetchListingPageData = async (userId, params, filtersList) => {
  const user = store.getState().loginUser.user;
  const agencyUsersList = store.getState().userGroup?.list;
  const savedAreaUnit = store.getState().listings?.units?.area_unit;
  const defaultAreaUnit = savedAreaUnit
    ? savedAreaUnit
    : store.getState().loginUser.user.settings?.length > 0
      ? tenantData.areaUnitList.find(
          (e) => e.slug == store.getState().loginUser.user.settings.find((e) => e?.name === 'area_unit')?.value,
        )?.slug
      : 'square_meters';
  const mappedParams = mapQueryParams(params, filtersList);

  const listingSummary = await NetworkService.get(`/api/listings/summary?${mappedParams}`, {});
  if (listingSummary) {
    if (!!listingSummary?.error) {
      return { ...listingSummary, error: 'Network Error' };
    } else {
      const response = await NetworkService.get(`/api/listings/my_listings?${mappedParams}`, {});
      if (response) {
        if (!!response?.error) {
          return response;
        } else {
          const listings = response?.data?.listing;
          const pagination = response?.data?.pagination;
          const summary = getSummaryData(listingSummary?.data?.summary, response?.data?.statuses_and_dispositions);
          !!user?.agency && !agencyUsersList && store.dispatch(setUsersList(user?.agency?.id));

          return {
            data: {
              list: listings && listings?.length ? listings?.map((item) => listingMapper(item, user, false)) : [],
              pagination: tenantUtils.getPaginationObject(pagination),
              tabFilterKey: 'q[status_id_eq]',
              dontAllowRowSlection: true,
              statuses: summary
                ? [
                    ...Object.keys(summary).map((e) => ({
                      ...summary[e],
                      key: e === 'All' ? '' : summary[e].mapping,
                      label: `${t(capitalizeFirstLetter(e))} (${summary[e].total})`,
                      tab: `${t(capitalizeFirstLetter(e))} (${summary[e].total})`,
                    })),
                  ]
                : [],
              // ...(listingOnly ? {} : {}),
              table: tenantUtils.listingTableColumnMapper(
                response?.data?.statuses_and_dispositions?.find((e) => e.id == params?.['q[status_id_eq]'])?.slug,
                user,
              ),
            },
          };
        }
      }
      return response;
    }
  }
};

export const hideUnhideListings = async (userId, listingId, platform, status) => {
  let response = '';
  if (platform === 'olx') {
    response = await NetworkService.put(`/api/listings/${listingId}/olx_status`, {
      status: status == 'hidden' ? 'enable' : 'disable',
      user_id: userId,
    });
  } else {
    response = await NetworkService.patch(`/api/listings/${listingId}/toggle_visibility`, {
      user_id: userId,
    });
  }
  if (response) {
    if (response.error) {
      return response;
    } else {
      if (!response?.data?.listing) {
        return {
          ...response,
          dontUpdate: true,
        };
      } else {
        return {
          data:
            platform === 'olx'
              ? {
                  data: {
                    ...olxMapper(response.data.listing, true),
                    listing_id: listingId,
                    replaceKey: 'platforms.data[1]',
                    valueExists: true,
                  },
                }
              : {
                  data: {
                    ...zameenMapper(response.data.listing, true),
                    listing_id: listingId,
                    replaceKey: 'platforms.data[0]',
                    valueExists: true,
                  },
                },
        };
      }
    }
  }
};

export const deleteListings = async (userId, listingId, reasonId, reasonText) => {
  let response = '';
  response = await NetworkService.delete(`/api/listings/${listingId}`, {
    user_id: userId,
    reason_id: reasonId,
    reason_text: reasonText,
  });
  if (response) {
    if (response.error) {
      return response;
    } else {
      if (response?.data?.listing) {
        return {
          data: {
            ...response?.data?.listing?.disposition,
            listing_id: listingId,
            replaceKey: 'disposition',
            valueExists: true,
          },
        };
      }
    }
  }

  return response;
};
export const fetchDeleteReasons = async (purposeId) => {
  const queryString = `q[listing_purpose_eq]=${purposeId}&q[reason_type_eq]=deletion`;
  const response = await NetworkService.get(`/api/reasons?${queryString}`);
  if (response) {
    if (response.error) {
      return response;
    } else {
      return response?.data?.reasons;
    }
  }
};

export const getListingDetail = async (listingId) => {
  const response = await NetworkService.get(`/api/listings/${listingId}/edit`);
  if (response) {
    if (response.error) {
      return response;
    } else {
      return {
        ...response?.data?.listing,
        isDailyRental: response?.data?.listing?.listing_purpose?.slug == 'daily-rental',
      };
    }
  }
};
