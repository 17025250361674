import tenantUtils from '@utils';
import { NetworkService } from '../../../../services/networkService';
import { getErrorString } from '../../../../utility/utility';

const createCart = async (itemsObj) => {
  try {
    const response = await NetworkService.post(`/api/carts`, itemsObj);
    const cartDetail = response.data?.cart?.cart_details;

    if (response.error) {
      return {
        error: response.error,
      };
    } else {
      const prodList = tenantUtils.cartProductList(cartDetail);
      return {
        id: response.data?.cart?.id,
        ...prodList,
      };
    }
  } catch (e) {
    return {
      error: getErrorString(e),
    };
  }
};

export default createCart;
