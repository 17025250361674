import actions from './actions';

const {
  CART_DATA_BEGIN,
  CART_DATA_SUCCESS,
  CART_DATA_ERR,

  ACTIVE_PRODUCTS_BEGIN,
  ACTIVE_PRODUCTS_SUCCESS,
  ACTIVE_PRODUCTS_ERR,

  CART_ADD_BEGIN,
  CART_ADD_SUCCESS,
  CART_ADD_ERR,

  CART_UPDATE_BEGIN,
  CART_UPDATE_SUCCESS,
  CART_UPDATE_ERR,

  CART_DELETE_BEGIN,
  CART_DELETE_SUCCESS,
  CART_DELETE_ERR,

  CART_DISABLE_CHECKOUT,
  CART_EMPTY,
} = actions;

const initialState = {
  data: null,
  loading: true,
  error: null,
  cartError: null,
  cartLoading: true,
  disableCheckOut: false,
};

const cartReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CART_DATA_BEGIN:
      return {
        ...state,
        cartLoading: true,
      };
    case CART_DATA_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...data },
        cartLoading: false,
        cartError: null,
      };
    case CART_DATA_ERR:
      return {
        ...state,
        cartError: err,
        cartLoading: false,
      };

    case ACTIVE_PRODUCTS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIVE_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...data },
        loading: false,
        error: null,
      };
    case ACTIVE_PRODUCTS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CART_UPDATE_BEGIN:
      return {
        ...state,
        cartLoading: true,
      };
    case CART_UPDATE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...data },
        cartLoading: false,
        cartError: null,
      };
    case CART_EMPTY:
      return {
        ...state,
        data: null,
        cartLoading: false,
        cartError: null,
      };
    case CART_UPDATE_ERR:
      return {
        ...state,
        cartError: err,
        cartLoading: false,
      };

    case CART_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CART_ADD_SUCCESS:
      const productToAdd = data;
      const addedList = state.data.added || [];
      const isProductInList = addedList.findIndex(product => product.product_id === productToAdd.product_id);
      if (isProductInList === -1) {
        return {
          ...state,
          data: { ...state.data, added: [...addedList, productToAdd] },
          loading: false,
          error: null,
        };
      }
    case CART_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CART_DELETE_BEGIN:
      return {
        ...state,
        cartLoading: true,
      };
    case CART_DELETE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, cartProducts: data },
        cartLoading: false,
        cartError: false,
      };
    case CART_DELETE_ERR:
      return {
        ...state,
        error: err,
        cartLoading: false,
      };

    case CART_DISABLE_CHECKOUT:
      return {
        ...state,
        cartLoading: data,
      };
    default:
      return state;
  }
};

export default cartReducer;
