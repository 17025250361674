import actions from './actions';

const { SET_LICENSE_LIST, UPDATE_LICENSE } = actions;

const initialState = {
  licenseData: null,
  loading: true,
  error: null,
};

const LicenseReducer = (state = initialState, action) => {
  const { type, payload, data } = action;
  switch (type) {
    case SET_LICENSE_LIST:
      return {
        ...state,
        ...payload,
      };

    case UPDATE_LICENSE:
      return {
        ...state,
        licenseData: {
          ...state.licenseData,
          data: {
            ...state.licenseData.data,
            licenses: state.licenseData.data.licenses.map(license =>
              license.id === data.id ? { ...license, is_shareable: data.is_shareable } : license,
            ),
          },
        },
      };
    default:
      return state;
  }
};

export { LicenseReducer };
