import tenantApi from '@api';
import { strings } from '../../../../constants/strings';
import { NetworkService } from '../../../../services/networkService';
import { formatNumberString } from '../../../../utility/utility';
import tenantUtils from '@utils';

const fetchAgencyStaffList = async (user, agencyId, params) => {
  const response = await NetworkService.get(`/api/agencies/${agencyId}`);
  if (response) {
    if (response.error) {
      return response;
    }
    let listingsPosted = 0;
    return {
      owner_id: response?.data?.agency?.creator_id,
      total_agency_credits: response?.data?.agency?.credits?.ksa?.total,
      agency_available_credits: response?.data?.agency?.credits?.ksa?.available,
      agency_used_credits: response?.data?.agency?.credits?.ksa?.used,
      list: response?.data?.agency?.users?.map((e) => {
        let activeListings = 0;
        return {
          ...e,
          user_name: tenantUtils.getLocalisedString(e, 'name'),
          user_email: e?.email,
          user_phone: e?.mobile,
          user_image: e?.profile_image,
          user_credits: e?.credits?.ksa?.total ? { value: e?.credits?.ksa?.total } : { value: 0, dashForNone: false },
          used_credits: e?.credits?.ksa?.used ? { value: e?.credits?.ksa?.used } : { value: 0, dashForNone: false },
          quota: e?.quotas?.available ? formatNumberString(e?.quotas?.available) : 0,
          active_listings: { value: activeListings },
          leads: { value: null },
          user_details: {
            user_role: e?.user_role_within_agency,
            name: tenantUtils.getLocalisedString(e, 'name'),
            email: e?.email,
            phone: e?.mobile,
            image: e?.profile_image,
          },
          rowActions: {
            ...e,
            agencyId: agencyId,
            userId: e?.id,
            isAdmin: e?.is_admin,
            isOwner: e?.user_role_within_agency === 'owner',
            name: tenantUtils.getLocalisedString(e, 'name'),
            creditsAvailable: e?.credits?.ksa?.available,
            deleteModalData: {
              table: [
                {
                  title: 'Name',
                  dataIndex: 'name',
                  key: 'name',
                  component: 'String',
                },
                {
                  title: 'Email',
                  dataIndex: 'email',
                  key: 'email',
                  component: 'String',
                },
                // {
                //   title: 'Active Listings',
                //   dataIndex: 'active_listings',
                //   key: 'active_listings',
                //   component: 'Number',
                // },
              ],
              list: [
                {
                  ...e,
                  name: tenantUtils.getLocalisedString(e, 'name'),
                  active_listings: { value: activeListings },
                },
              ],
              onDelete: async () => {
                const response = await tenantApi.deleteAgencyUser(agencyId, e?.id);
                if (response) {
                  if (response?.error) {
                    return { error: response.error };
                  } else {
                    return { id: e?.id };
                  }
                }
              },
            },
          },
        };
      }),
      cappedUsersList: response?.data?.agency?.users
        ?.filter((e) => e?.user_role_within_agency != 'owner')
        ?.map((e, index, originalArray) => {
          return {
            ...e,
            staff_user_details: {
              user_name: tenantUtils.getLocalisedString(e, 'name'),
              image: e?.profile_image,
            },
            user_credit_details: {
              user_id: e?.id,
              available_credits: e?.credits?.ksa?.available ? e?.credits?.ksa?.available : 0,
              used_credits: e?.credits?.ksa?.used ? e?.credits?.ksa?.used : 0,
              total_available_credits: e?.credits?.ksa?.total ? e?.credits?.ksa?.total : 0,
            },
          };
        }),
      headerCards: [
        {
          icon: 'MdPersonPinCircle',
          iconColor: '',
          iconSize: '1em',
          title: strings.total_agents,
          value: response.data.data?.users?.length,
        },
        {
          icon: 'IoMdPin',
          iconColor: '#00a651',
          iconSize: '1em',
          title: strings.listings_posted,
          value: listingsPosted,
        },
      ],
      table: [
        {
          title: 'Staff Details',
          dataIndex: 'user_details',
          key: 'user_details',
          component: 'StaffDetails',
        },
        // {
        //   title: 'Name',
        //   dataIndex: 'user_name',
        //   key: 'user_name',
        //   component: 'AvatarName',
        // },
        // {
        //   title: 'Phone Number',
        //   dataIndex: 'user_phone',
        //   key: 'user_phone',
        //   component: 'String',
        //   render: record => <RenderTextLtr text={record} />,
        // },
        // {
        //   title: 'Email',
        //   dataIndex: 'user_email',
        //   key: 'user_email',
        //   component: 'String',
        // },
        ...(!user?.is_credit_user
          ? [
              {
                title: 'Available Quota',
                dataIndex: 'quota',
                key: 'quota',
                component: 'String',
              },
            ]
          : [
              {
                title: 'Credits Limit',
                dataIndex: 'user_credits',
                key: 'user_credits',
                component: 'Number',
              },
              {
                title: 'Used Credits',
                dataIndex: 'used_credits',
                key: 'used_credits',
                component: 'Number',
              },
            ]),
        // {
        //   title: 'Active Listings',
        //   dataIndex: 'active_listings',
        //   key: 'active_listings',
        //   component: 'Number',
        // },
        {
          title: 'Actions',
          dataIndex: 'rowActions',
          key: 'rowActions',
          component: 'UserRowActions',
          // align: 'right',
        },
      ],
      creditCappingDetails: {
        table: [
          {
            title: 'Staff Details',
            dataIndex: 'user_name',
            key: 'user_name',
            component: 'AvatarName',
          },
          {
            title: 'Set Limit',
            dataIndex: 'user_credit_details',
            key: 'user_credit_details',
            component: 'SetCappingLimit',
          },
        ],
      },
    };
  }
};

export default fetchAgencyStaffList;
