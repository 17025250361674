import tenantData from '@data';
import tenantUtils from '@utils';
import { NetworkService } from '../../../../services/networkService';

const cartDetail = async (userId, orderId) => {
  const response = await (orderId
    ? NetworkService.get(`/api/orders/${orderId}`)
    : NetworkService.get(`/api/carts/current`));
  if (response) {
    if (response.error) {
      return response;
    } else {
      if (orderId) {
        const { id, status, order_details } = response?.data?.order;
        if (status === 'completed') {
          return { error: `Order is in ${status} state` };
        }

        return {
          cartProducts: tenantUtils.cartProductList(
            order_details?.map((e) => ({
              id: e.id,
              product_id: e.product?.product_id,
              product: {
                id: e.product?.product_id,
                title: e.product?.product_title,
                slug: tenantData.quotaCreditProducts?.find((pr) => pr?.id == e?.product?.product_id)?.slug,
                price: e.price,
              },
              quantity: e.quantity,
              price: e.price,
              source: 'profolio',
            })),
          ),
          cartId: id,
          source: 'profolio',
        };
      } else if (!response.data?.cart?.cart_details?.length) {
        return { cartProducts: [] };
      } else {
        const { id, source, cart_details } = response.data?.cart;
        return { cartProducts: tenantUtils.cartProductList(cart_details), cartId: id, source: source };
      }
    }
  }
};

export default cartDetail;
