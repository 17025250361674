import { NetworkService } from '../../../../services/networkService';
import { getErrorString } from '../../../../utility/utility';
import tenantUtils from '@utils';

const deleteCart = async (userId, cartId, productList) => {
  try {
    const response = await NetworkService.put(`/api/carts/update`, {
      cart: { cart_details_attributes: productList },
    });
    if (response.error) {
      return {
        error: response.error,
      };
    } else {
      const cart = response?.data?.cart;

      return {
        cartProducts: cart?.cart_details?.length ? tenantUtils.cartProductList(cart?.cart_details) : [],
      };
    }
  } catch (e) {
    return {
      error: getErrorString(e),
    };
  }
};

export default deleteCart;
