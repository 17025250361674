import tenantTheme from '@theme';
import { Card } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, Icon, LinkWithIcon } from '../../../../components/common';
import { IconSwitch } from '../../../../components/svg';
import { CardMetaStyled } from '../../../../container/pages/user-settings/style';

const CustomCard = ({ avatarIcon, title, description, link }) => {
  const isMobile = useSelector(state => state.AppConfig.isMobile);

  return (
    <a href={link}>
      <Card className="mb-8 " style={{ borderWidth: 1 }} bodyStyle={{ padding: 16 }}>
        <Flex justify="space-between" gap={isMobile ? '20px' : '4px'}>
          <CardMetaStyled
            className="p-0"
            style={{ alignItems: 'center' }}
            avatar={<Icon size={32} icon={avatarIcon} />}
            title={
              <div className="fw-700" style={{ fontSize: 14 }}>
                {title}
              </div>
            }
            description={<div className="color-gray-dark fz-12">{description}</div>}
          />

          <LinkWithIcon
            as="a"
            icon={<IconSwitch color={tenantTheme['primary-color']} />}
            href={link}
            className="btnLink"
            target="_blank"
          />
        </Flex>
      </Card>
    </a>
  );
};

export default CustomCard;
