import tenantConstants from '@constants';
import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal } from '../../../../components/common';
import AccountManager from '../../../../components/manager-info/manager-info.js';
import {
  faqSectionClickEvent,
  profolioButtonClickEvent,
  regaComplianceClickEvent,
} from '../../../../services/analyticsService/index.js';
import CustomCard from './customCard.js';

export const HelpAndSupport = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);
  const { isMobile, isMemberArea } = useSelector(state => state.AppConfig);
  const { user } = useSelector(state => state.loginUser);
  const { t } = useTranslation();
  const locale = useSelector(state => state.AppConfig.locale);
  useImperativeHandle(ref, () => ({
    show: (value) => {
      setVisible(value);
    },
  }));
  const cardData = useMemo(() => [
    {
      avatarIcon: 'ProfileIcon',
      title: t('Profolio'),
      description: t('Discover more about Profolio. Our best-in-class listings management software.'),
      link: 'https://help.bayut.sa/hc/en-us/categories/16333193987090-Property-and-Profolio-Management?webview=1',
      onClick: profolioButtonClickEvent,
    },
    {
      avatarIcon: 'SecurityIcon',
      title: t('REGA Compliance'),
      description: t('Discover more about REGA and real estate regulations.'),
      link: 'https://help.bayut.sa/hc/en-us/categories/18347574773906-REGA-Compliances?webview=1',
      onClick: regaComplianceClickEvent,
    },
    {
      avatarIcon: 'FAQIcon',
      title: t("FAQ's"),
      description: t('Explore answers to frequently asked questions and have your issues resolved.'),
      link: `https://help.bayut.sa/hc/${locale}/categories/16333324007186-Client-Engagement-and-Support?webview=1`,
      onClick: faqSectionClickEvent,
    },
  ]);
  return (
    <Modal
      bodyStyle={{ padding: isMobile ? 12 : 24 }}
      title={t('Help & Support')}
      visible={visible}
      onCancel={() => setVisible(false)}
      {...props}
      footer={null}
    >
      <div>
        {cardData.map(card => (
          <CustomCard
            avatarIcon={card.avatarIcon}
            title={card.title}
            description={card.description}
            link={card.link}
            onClick={card.onClick}
          />
        ))}
        {tenantConstants.SHOW_ACCOUNT_MANAGER && !isMemberArea && <AccountManager user={user} />}
      </div>
    </Modal>
  );
});
