import tenantData from '@data';
import tenantUtils from '@utils';
import { t } from 'i18next';
import { NetworkService } from '../../../../services/networkService';

const dataMapper = (platformWiseData, listingDetailResponse) => {
  const productsHash = {};
  tenantData.products.forEach((e) => {
    productsHash[e.slug] = e;
  });

  const data = {
    availableCredits: platformWiseData?.available_credits,
    creditUnitPrice: platformWiseData?.credit_unit_price,
    isPosted: listingDetailResponse?.isListingPosted,
    // isPosted: true,
    headingMsg: '',
    descriptionMsg: '',
    currency: '',
    applicableProducts: [],
    subServices: [],
    serviceStates: {},
    autoRenewState: {},
    listing: listingDetailResponse?.listing,
  };

  data?.availableCredits > 0 ? (data.currency = 'Credits') : (data.currency = t('SAR'));

  platformWiseData?.products.forEach((item) => {
    if (item.is_add_on === false && item.slug !== 'refresh') {
      data.applicableProducts.push({
        ...productsHash[item.slug],
        requiredCredits: item.credits_required,
        price: item.product_price,
        isRecommended: item.is_recommended,
        isAddOn: item.is_add_on,
        activeDuration: listingDetailResponse?.listingExpiryDays || 30,
        id: item.product_id,
        title: tenantUtils.getLocalisedString(item, 'product_title'),
        description: tenantUtils.getLocalisedString(item, 'description'),
      });
      data.autoRenewState[item.product_id] = false;
    } else if (item.is_add_on === true) {
      data.subServices.push({
        ...productsHash[item.slug],
        requiredCredits: item.credits_required,
        price: item.product_price,
        isRecommended: item.is_recommended,
        activeDuration: listingDetailResponse?.listingExpiryDays || 30,
        id: item.product_id,
        title: tenantUtils.getLocalisedString(item, 'product_title'),
        description: tenantUtils.getLocalisedString(item, 'description'),
      });
      data.serviceStates[item.product_id] = false;
    }
  });
  data.headingMsg = listingDetailResponse?.isListingPosted
    ? 'Your Listing has been Posted Successfully!'
    : 'Almost There';
  data.descriptionMsg = listingDetailResponse?.isListingPosted
    ? 'Make your Listing Hot and reach 10x more Buyers'
    : 'Choose the type of listing you want to publish';

  return data;
};

const getListingDetail = async (listingId) => {
  const response = await NetworkService.get(`/api/listings/${listingId}/edit`);

  if (response) {
    if (response.error) {
      return response;
    } else if (response?.data) {
      return {
        ...response.data,
        isListingPosted: response?.data?.listing?.is_posted,
        listingExpiryDays: response?.data?.listing?.expiry_days,
      };
    }
  }
};
export const getApplicableProducts = async (
  listingId,
  platforms,
  mapData = true,
  fetchListingDetail = false,
  locationId,
) => {
  let listingDetailResponse = null;
  if (fetchListingDetail) {
    listingDetailResponse = await getListingDetail(listingId);
    if (listingDetailResponse.error) {
      return listingDetailResponse;
    }
  }
  const requestPayload = { listing_id: listingId, ...(locationId && { location_id: locationId }) };
  const response = await NetworkService.get(`/api/products/applicable_products`, requestPayload);

  let data = {};
  if (response) {
    if (response.error) {
      return response;
    } else if (mapData) {
      platforms?.forEach((platform) => {
        if (response?.data) {
          data[platform?.slug] = dataMapper(response?.data, listingDetailResponse);
        }
      });
    } else {
      data = response.data;
    }
    return data;
  }
};
