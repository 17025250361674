import { NetworkService } from '../../../../services/networkService';

export const getCustomCreditsPrice = async (values) => {
  const response = await NetworkService.get(`/api/packages/calculate_price`, values);
  if (response.error) {
    return response;
  } else if (response?.data) {
    return {
      price: response?.data?.net_price,
      nonDiscountedPrice: response?.data?.price,
    };
  }
};
