import { NetworkService } from "../../../../services/networkService";
import { getAgencyValues } from './agencySettingsForm/getAgencyValues';

const fetchAgencySettingsDetail = async agencyId => {
  let response = await NetworkService.get(`/api/agencies/${agencyId}`);

  if (response?.error) {
    return response;
  } else {
    return {
      data: getAgencyValues(response.data?.agency),
    };
  }
};
export default fetchAgencySettingsDetail;
