import { NetworkService } from '../../../../services/networkService';
import { getVariousDates } from '../../../../utility/date';
import tenantData from '@data';

const getExpiryTitle = (expiryData) => {
  let expiryTitle = '';
  expiryData.forEach((item, i) => {
    expiryTitle += `${item?.available_qty} ${item.title}${
      i !== expiryData.length - 1 && expiryData.length !== 2 ? ', ' : i === expiryData.length - 2 ? ' and ' : ''
    }`;
  });
  return expiryTitle;
};

export const getProductExpiryData = async (id, user) => {
  const dates = getVariousDates(30, undefined, true).reverse().join(',');

  const queryObj = {
    [`q[expiry_date_gteq]`]: dates?.split(',')?.[0],
    [`q[expiry_date_lteq]`]: dates?.split(',')?.[1],
    ...(!!id && id != -1 && { [`q[user_id_eq]`]: id }),
  };

  let response;
  if (id === -1) {
    response = await NetworkService.get(`/api/credits`, queryObj);
  } else {
    response = await NetworkService.get(`/api/credits`, queryObj);
  }
  if (response) {
    if (response.error) {
      return response;
    } else {
      const data = response?.data?.credits?.filter((e) => e?.available_qty > 0);

      const productData = data?.length
        ? data?.map((e) => {
            const product = tenantData.quotaCreditProducts?.find((pr) => pr?.id === e?.product_id);
            return { ...product, ...e };
          })
        : [];

      const zameenTitle = `${getExpiryTitle(
        productData?.filter((e) => e?.platformSlug === 'zameen'),
      )} expiring in the next 30 days.`;
      const olxTitle = `${getExpiryTitle(
        productData?.filter((e) => e?.platformSlug === 'olx'),
      )} expiring in the next 30 days.`;

      return {
        zameen: {
          data: productData?.filter((e) => e?.platformSlug === 'zameen')?.length
            ? productData?.filter((e) => e?.platformSlug === 'zameen')
            : [],
          title: zameenTitle,
        },
        olx: {
          data: productData?.filter((e) => e?.platformSlug === 'olx')?.length
            ? productData?.filter((e) => e?.platformSlug === 'olx')
            : [],
          title: olxTitle,
        },
      };
    }
  }
};
