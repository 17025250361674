import tenantTheme from '@theme';
import tenantUtils from '@utils';
import tenantFilters from '@filters';
import { Button, Card, Col, Row, Tag } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationModal, Icon, Tabs } from '../../../../components/common';
import ProgressBar from '../../../../components/common/progress-bar/ProgressBar';
import { ListingCardContainer } from '../../../../components/listing-card-container/ListingCardContainer';
import { ListingContainer } from '../../../../components/listing-container/ListingContainer';
import PostListingButton from '../../../../components/post-listing-button/post-listing-button';
import { EmptyListing } from '../../../../components/svg';
import { Main } from '../../../../container/styled';
import { useFetchOnQueryUpdate, useGetLocation, useRouteNavigate } from '../../../../hooks';
import { fetchListings, setListingId } from '../../../../redux/listings/actionCreator';
import { mapQueryStringToFilterObject } from '../../../../utility/urlQuery';
import { TabsStyled } from '../../bayut/listing/styled';
import listingStates from './listings.json';
import { useSearchParams } from 'react-router-dom';

const Listings = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.loginUser);
  const users = useSelector((state) => state.userGroup.list);
  const dispatch = useDispatch();
  const listingsData = useSelector((state) => state.listings);
  const { selectedListings } = useSelector((state) => state.listings);
  const [applied, setApplied] = useState(0);
  const [refreshCalled, setRefreshCalled] = useState(0);
  const [selectedPlatorm, setSeletedPlatform] = useState(null);
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const location = useGetLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { queryObj } = mapQueryStringToFilterObject(location.search);
  const filtersList = useMemo(() => tenantFilters.getMyListingsFilters(user, users), [user, users]);

  const bulkRefreshRef = useRef();
  const progressBarRef = useRef();

  const { fetchData } = useFetchOnQueryUpdate(
    (params) => {
      if (user?.id) {
        dispatch(fetchListings(user.id, params, filtersList, isMobile ? '' : 'desktop'));
      }
    },
    [user?.id],
  );
  const [tab, setTab] = useState('');
  const navigate = useRouteNavigate();

  useEffect(() => {
    return () => {
      dispatch(setListingId({ data: [], selected: false, multiple: false, clearSelectedItems: true }));
    };
  }, []);

  useEffect(() => {
    const { queryObj } = mapQueryStringToFilterObject(location.search);
    listingsData?.tabFilterKey &&
      setTab(queryObj?.['q[firmstate]'] || queryObj?.[listingsData?.tabFilterKey] || listingsData?.statuses?.[0]?.key);
  }, [listingsData, location.search]);

  const renderStatesTabs = useCallback(() => {
    return (
      listingsData.statuses && (
        <TabsStyled
          className="mb-0"
          activeKey={tab || listingsData?.statuses?.[0]?.key?.toString()}
          onChange={onChangeTab}
        >
          {listingsData?.statuses?.map((e) => (
            <Tabs.TabPane tab={e?.tab} key={e?.key} />
          ))}
        </TabsStyled>
      )
    );
  }, [listingsData.statuses, tab]);

  const getRowSelection = () => {
    return {
      type: 'checkbox',
      preserveSelectedRowKeys: true,
      selectedRowKeys: selectedListings?.map((e) => e?.id),
      onSelect: (record, selected) => {
        const selectedListing = listingsData?.list?.find((e) => e?.id === record?.id);
        if (selected) {
          record.selected = true;
          record.listingOwner = selectedListing?.user;
        }
        dispatch(setListingId({ listings: record, selected, multiple: false }));
      },
      onSelectAll: (selected, selectedRows, currentSelectedRows) => {
        let listings = [];
        currentSelectedRows?.forEach((e) => {
          e.selected = selected;
          e.listingOwner = listingsData?.list?.find((e) => e?.id == e?.id)?.user;
          listings.push(e);
        });
        dispatch(setListingId({ listings: listings, selected, multiple: true }));
      },
    };
  };

  const onChangeTab = (e) => {
    const obj = { ...queryObj, [listingsData?.tabFilterKey]: e };
    const decodedParams = Object.entries(obj).reduce((acc, [key, value]) => {
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});
    setSearchParams(decodedParams);
  };
  const emptyState = useMemo(
    () => ({
      title: listingStates.listingStates[tab]?.title,
      subtitle: listingStates.listingStates[tab]?.subtitle,
      illustration: <EmptyListing color={tenantTheme['primary-light-2']} />,
      button: <PostListingButton />,
    }),
    [tab],
  );

  return (
    <>
      <ConfirmationModal
        ref={bulkRefreshRef}
        onCancel={() => {
          dispatch(setListingId({ data: [], selected: false, multiple: false, clearSelectedItems: true }));
          bulkRefreshRef?.current && bulkRefreshRef?.current?.hideModal();
        }}
        onSuccess={async () => {
          progressBarRef?.current && progressBarRef?.current?.showModal();
          bulkRefreshRef?.current && bulkRefreshRef?.current?.hideModal();
          await tenantUtils.applyBulkRefresh(user?.id, selectedListings, selectedPlatorm, setApplied, setRefreshCalled);
        }}
      >
        <div>
          {`${t('Do you want to apply')} ${selectedPlatorm === 'zameen' ? t('Refresh') : t('Boost')} ${t(
            'Credits to the selected',
          )}
          ${selectedListings?.length} ${t('Listings')}?`}
        </div>
        <div>
          {t(
            'Zone factor and other cases will be handled in the background and the process will stop if the credits are exhausted.',
          )}
        </div>
      </ConfirmationModal>

      <ConfirmationModal
        ref={progressBarRef}
        onCancel={() => {
          fetchData();
          dispatch(setListingId({ data: [], selected: false, multiple: false, clearSelectedItems: true }));
          setApplied(0);
          setRefreshCalled(0);
          progressBarRef?.current && progressBarRef?.current?.hideModal();
        }}
        title={selectedPlatorm === 'zameen' ? t('Refresh Progress') : t('Boost to Top Progress')}
        footer={<></>}
      >
        <ProgressBar
          done={refreshCalled}
          successfull={applied}
          total={selectedListings?.length}
          selectedPlatorm={selectedPlatorm}
        />
      </ConfirmationModal>
      {isMobile ? (
        <Main style={{ paddingBlock: '0 8px' }}>
          <ListingCardContainer
            filtersList={filtersList}
            listingsData={listingsData}
            renderTableTopRight={renderStatesTabs}
            pagination={listingsData?.pagination}
            renderFiltersAsTopRight
            fetchData={fetchData}
            skeletonLoading={!listingsData?.list || !!listingsData.loading}
            showPagination
            loading={listingsData.loading}
            error={listingsData?.error}
            scrollToShowMore
            selectedListings={selectedListings}
            setSelectedListings={(listings) => dispatch(setListingId(listings))}
            emptyState={emptyState}
          />
        </Main>
      ) : (
        <>
          <ListingContainer
            filtersList={filtersList}
            listingsData={listingsData}
            listingApi={fetchListings}
            activeTab={tab}
            renderTableTopRight={listingsData.statuses}
            onChangeTab={onChangeTab}
            loading={listingsData.loading}
            error={listingsData.error}
            onRetry={fetchData}
            rowSelection={tab === 'Active' ? getRowSelection() : undefined}
            emptyState={emptyState}
            disableTabsOnLoading
          />
        </>
      )}
      {isMobile ? (
        !selectedListings?.length ? (
          <></>
        ) : (
          <Card className={'selectedRowsMobile'} bodyStyle={{ padding: 20 }}>
            <div className="flex justify-content-between align-items-center mb-16">
              <div>
                <Tag color="#00A4D1">{selectedListings.length}</Tag>
                {t('Listings Selected')}
              </div>
              <Button
                onClick={() => {
                  dispatch(setListingId({ data: [], selected: false, multiple: false, clearSelectedItems: true }));
                }}
                transparented
                block={false}
                style={{ borderColor: '#fff', padding: '4px 8px' }}
              >
                {t('Cancel')}
              </Button>
            </div>
            <div className="flex justify-content-between align-items-center">
              {user?.platforms?.map((e) => {
                return (
                  <Button
                    onClick={() => {
                      setSeletedPlatform(e?.slug);
                      bulkRefreshRef?.current && bulkRefreshRef?.current?.showModal();
                    }}
                    style={{ padding: '4px 8px' }}
                  >
                    <Icon icon={e?.icon} size="1.4em" style={{ verticalAlign: 'text-bottom' }} />
                    {e?.slug === 'zameen' ? t('Refresh Listings') : t('Boost To Top')}
                  </Button>
                );
              })}
            </div>
          </Card>
        )
      ) : (
        !!selectedListings.length && (
          <Card className={isMobile ? 'selectedRowsMobile' : 'selectedRows'} bodyStyle={{ padding: 20 }}>
            <Row gutter={48} className="pi-32">
              <Col>
                <Tag color="#00A4D1">{selectedListings.length}</Tag>
                {t('Listings Selected')}
              </Col>
              {user?.platforms?.map((e) => {
                return (
                  <Col>
                    <div className="span-all">
                      <Button
                        onClick={() => {
                          setSeletedPlatform(e?.slug);
                          bulkRefreshRef?.current && bulkRefreshRef?.current?.showModal();
                        }}
                        block
                        style={{ padding: '4px 8px' }}
                      >
                        <Icon icon={e?.icon} size="1.4em" style={{ verticalAlign: 'text-bottom' }} />
                        {e?.slug === 'zameen' ? t('Refresh Listings') : t('Boost To Top')}
                      </Button>
                    </div>
                  </Col>
                );
              })}
              <Col style={{ marginInlineStart: 'auto' }}>
                <Button
                  onClick={() => {
                    dispatch(setListingId({ data: [], selected: false, multiple: false, clearSelectedItems: true }));
                  }}
                  transparented
                  block
                  style={{ borderColor: '#fff', padding: '4px 8px' }}
                >
                  {t('Cancel')}
                </Button>
              </Col>
            </Row>
          </Card>
        )
      )}
    </>
  );
};

export default Listings;
