import { NetworkService } from "../../../../services/networkService";
const updateLicense = async (licenseId, values) => {
  let response = await NetworkService.patch(`api/licenses/${licenseId}`, values);
  if (response.error) {
    return {
      error: response.error,
    };
  } else {
    return response;
  }
};
export default updateLicense;
