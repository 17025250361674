import { isProduction } from './utility/env';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactGA from 'react-ga4';
import { useLocation, useNavigationType, matchRoutes } from 'react-router-dom';

// Sentry for errors
isProduction &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      // Sentry.browserTracingIntegration()
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 0.1,
  });

// Google Analytics
isProduction &&
  process.env.REACT_APP_GA_MEASUREMENT_ID &&
  ReactGA.initialize([{ trackingId: process.env.REACT_APP_GA_MEASUREMENT_ID }]);
