import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducers';
import { thunk } from 'redux-thunk';
function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    return undefined;
  }
}

const store = configureStore({
  reducer: rootReducer,
  middleware: () => [thunk],
  devTools: process.env.REACT_APP_ENVIRONMENT === 'development',
});

store.subscribe(() => {
  const state = store.getState();
  saveToLocalStorage(state);
});

export default store;
