import tenantConstants from '@constants';
import tenantUtils from '@utils';
import { NetworkService } from '../../../../services/networkService';
import { getDateDiffInDays, getVariousDates } from '../../../../utility/date';
import { getQueryString } from '../../../../utility/urlQuery';
import { getResultGroup } from '../../../../utility/utility';

const COLORS_ARRAY = ['#20C997', '#FA8B0C', '#5F63F2', '#1ce383', '#14449f'];

const limit = 10;

const getPageData = (pageNumber, last_page, data) => {
  let start_index = (pageNumber - 1) * limit;
  let end_index = pageNumber == last_page ? data?.length : pageNumber * limit;

  return data.slice(start_index, end_index);
};

const data = [
  {
    id: 'zameen',
    slug: 'zameen',
    icon: 'IconZameen',
    title: 'Listings',
    listing_breakdown: {
      title: 'Listings',
      total_title: 'Total',
      total_title_icon: 'MdOutlineLocationOn',
      total_value: '',
      purposes: [
        {
          id: 1,
          icon: 'IconPropertyBuy',
          iconProps: { size: '1.2em', color: '#28b16d' },
          title: 'For Sale',
          value: '',
        },
        {
          id: 2,
          icon: 'IconPropertyRent',
          iconProps: { size: '1.2em', color: '#479eeb' },
          title: 'For Rent',
          value: '',
        },
      ],
      products: [
        { id: 1, icon: 'IconSuperHot', iconProps: { size: '1.2em', color: '#c00' }, title: 'Super Hot', value: '' },
        { id: 2, icon: 'IconHot', iconProps: { size: '1.2em', color: '#ffa900' }, title: 'Hot', value: '' },
      ],
    },
    area_breakdown: {
      title: 'Breakdown By Area',
      icon_data: {
        icon_props: {
          hasBackground: true,
          iconBackgroundColor: '#fff',
        },
      },
      types: [
        { id: 1, key: 'sale_breakdown_by_area', label: 'For Sale' },
        { id: 2, key: 'rent_breakdown_by_area', label: 'For Rent' },
      ],
      data: {
        sale_breakdown_by_area: {
          total: '',
          chart: { labels: [], dataset: [] },
          locations: [],
          backgroundColor: COLORS_ARRAY,
        },
        rent_breakdown_by_area: {
          total: '',
          chart: { labels: [], dataset: [] },
          locations: [],
          backgroundColor: COLORS_ARRAY,
        },
      },
    },
  },
  {
    id: 'olx',
    slug: 'olx',
    icon: 'IconOLX',
    title: 'Listings',
    listing_breakdown: {
      title: 'Listings',
      total_title: 'Total',
      total_title_icon: 'MdOutlineLocationOn',
      total_value: '',
      purposes: [
        {
          id: 1,
          icon: 'IconPropertyBuy',
          iconProps: { size: '1.2em', color: '#28B16D' },
          title: 'For Sale',
          value: '',
        },
        {
          id: 2,
          icon: 'IconPropertyRent',
          iconProps: { size: '1.2em', color: '#479EEB' },
          title: 'For Rent',
          value: '',
        },
      ],
      products: [
        {
          id: 1,
          icon: 'IconF',
          iconProps: {
            hasBackground: true,
            color: '#1f1f1f',
            size: '1em',
            style: {
              '--icon-bg-color': '#ffec74',
              padding: 4,
            },
          },
          title: 'Featured',
          value: '',
        },
        {
          id: 2,
          icon: 'IconS',
          iconProps: {
            hasBackground: true,
            color: '#1f1f1f',
            size: '1em',
            style: {
              '--icon-bg-color': '#f0f0f0',
              padding: 4,
            },
          },
          title: 'Standard',
          value: '',
        },
      ],
    },
    area_breakdown: {
      title: 'Breakdown By Area',
      icon_data: {
        icon_props: {
          hasBackground: true,
          iconBackgroundColor: '#fff',
        },
      },
      types: [
        { id: 1, key: 'sale_breakdown_by_area', label: 'For Sale' },
        { id: 2, key: 'rent_breakdown_by_area', label: 'For Rent' },
      ],
      data: {
        sale_breakdown_by_area: {
          total: '',
          chart: { labels: [], dataset: [] },
          locations: [],
          backgroundColor: COLORS_ARRAY,
        },
        rent_breakdown_by_area: {
          total: '',
          chart: { labels: [], dataset: [] },
          locations: [],
          backgroundColor: COLORS_ARRAY,
        },
      },
    },
  },
];

const ITEMS_COUNT = COLORS_ARRAY.length;

const pushPropertyTypeByBreakdown = (key, listings, currentPlatform, currentPlatformData, keyValue) => {
  let dataset = [];
  const others = { label: 'Others', data: 0 };
  const locations = listings?.[currentPlatform]?.[key];
  const locationsDataList = [];
  Object?.keys(listings?.[currentPlatform]?.[key])?.length &&
    Object?.keys(listings?.[currentPlatform]?.[key])?.forEach(e =>
      locationsDataList.push({ title: e, count: locations?.[e]?.count, percentage: locations?.[e]?.percentage }),
    );
  locationsDataList.sort((a, b) => b.count - a.count);
  locationsDataList.forEach((location, index) => {
    if (index < ITEMS_COUNT) {
      dataset.push({ label: location?.title, data: location?.count });
    } else {
      others.data = others.data + location?.count;
    }
  });
  others.data && dataset.push(others);
  currentPlatformData.area_breakdown.data[key].locations = dataset;
  currentPlatformData.area_breakdown.data[key].chart.labels = dataset.map(e => e.label);
  currentPlatformData.area_breakdown.data[key].chart.dataset = dataset.map(e => e.data);
  currentPlatformData.area_breakdown.data[key].total = listings[currentPlatform]?.[keyValue];
};

const widgetParser = (listings, platformKey) => {
  const platformWidgetData = data?.find(e => e?.slug === platformKey);

  platformWidgetData.listing_breakdown.total_value = listings?.[platformKey].total;
  platformWidgetData.listing_breakdown.purposes[0].value = listings?.[platformKey]?.sale;
  platformWidgetData.listing_breakdown.purposes[1].value = listings?.[platformKey]?.rent;
  platformWidgetData.listing_breakdown.products[0].value =
    platformKey === 'zameen' ? listings?.[platformKey]?.superhot : listings?.[platformKey]?.featured;
  platformWidgetData.listing_breakdown.products[1].value =
    platformKey === 'zameen'
      ? listings?.[platformKey]?.hot
      : listings?.[platformKey]?.sale + listings?.[platformKey]?.rent;
  pushPropertyTypeByBreakdown('rent_breakdown_by_area', listings, platformKey, platformWidgetData, 'rent');
  pushPropertyTypeByBreakdown('sale_breakdown_by_area', listings, platformKey, platformWidgetData, 'sale');
  return data;
};

const tableParser = (listings, params) => {
  const list = Object?.keys(listings)?.map(item => ({
    date: { value: item },
    zameen_listings: { value: listings?.[item]?.zameen_listings || 0, dashForNone: true },
    olx_listings: { value: listings?.[item].olx_listings || 0, dashForNone: true },
    sale: {
      value: listings?.[item]?.sale || 0,
      dashForNone: true,
    },
    rent: {
      value: listings?.[item]?.rent || 0,
      dashForNone: true,
    },
    super_hot: {
      value: listings?.[item]?.super_hot || 0,
      dashForNone: true,
    },
    hot: {
      value: listings?.[item]?.hot || 0,
      dashForNone: true,
    },
    featured: {
      value: listings?.[item]?.featured || 0,
      dashForNone: true,
    },
    total_listings: {
      value: (listings?.[item]?.zameen_listings || 0) + (listings?.[item]?.olx_listings || 0),
      dashForNone: true,
    },
  }));

  const pagination = {
    from: params?.page ? (params?.page - 1) * limit + 1 : 1,
    to: params?.page ? params?.page * limit : limit,
    current_page: parseInt(params.page ? params?.page : 1),
    per_page: limit,
    last_page: parseInt(list?.length / limit) + 1,
    total_pages: parseInt(list?.length / limit) + 1,
    total_records: list?.length,
  };

  const tableData = {
    list: getPageData(pagination?.current_page, pagination?.last_page, list.reverse()),
    pagination: tenantUtils.getPaginationObject(pagination),
    filtersData: {
      list: [
        {
          label: 'Listed Date',
          key: tenantConstants.VALUE_IN_ARRAY_KEY('date_between'),
          placeholder: 'Select Date Range',
          type: 'dateRange',
          showInSingleTag: true,
          labelProps: { muted: true },
          minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 2)),
          reportsStaticRanges: true,
          getTagContent: value => value,
        },
      ],
    },
    table: [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        component: 'Date',
      },
      {
        title: 'Zameen Listings',
        dataIndex: 'zameen_listings',
        key: 'zameen_listings',
        component: 'Number',
      },
      {
        title: 'OLX Listings',
        dataIndex: 'olx_listings',
        key: 'olx_listings',
        component: 'Number',
      },
      {
        title: 'Sale',
        dataIndex: 'sale',
        key: 'sale',
        component: 'Number',
      },
      {
        title: 'Rent',
        dataIndex: 'rent',
        key: 'rent',
        component: 'Number',
      },
      {
        title: 'Super Hot',
        dataIndex: 'super_hot',
        key: 'super_hot',
        component: 'Number',
      },
      {
        title: 'Hot',
        dataIndex: 'hot',
        key: 'hot',
        component: 'Number',
      },
      {
        title: 'Featured',
        dataIndex: 'featured',
        key: 'featured',
        component: 'Number',
      },
      {
        title: 'Total Listings',
        dataIndex: 'total_listings',
        key: 'total_listings',
        component: 'Number',
      },
    ],
  };

  return tableData;
};

export const listingsSummaryApi = async ({ params, parseFor, platformKey }) => {
  let response;
  if (platformKey === 'zameen') {
    response = await NetworkService.get(`/api/dashboard/zameen_listings_by_area?${getQueryString(params)}`);
  } else {
    response = await NetworkService.get(`/api/dashboard/olx_listings_by_area?${getQueryString(params)}`);
  }
  if (response) {
    if (response.error || !response?.data?.listings) {
      return response;
    } else {
      const listings = response?.data?.listings;
      if (parseFor === 'widget') {
        return widgetParser(listings, platformKey)?.find(e => e?.slug === platformKey);
      }
      return tableParser(listings);
    }
  }
};

export const fetchListingPostedByDate = async (userId, params) => {
  const {
    ['filter[date_between]']: dateBetween = `${getVariousDates(29)}`,
    page,
    group_by = getResultGroup(
      getDateDiffInDays(typeof dateBetween == 'string' ? dateBetween?.split(',') : dateBetween),
      true,
    )?.result_group,
  } = params;

  const byDateParams = {
    start_date: dateBetween?.split(',')?.[0],
    end_date: dateBetween?.split(',')?.[1],
    ...(page && { page: page }),
    group_by,
  };

  const response = await NetworkService.get(`/api/dashboard/listings_product_stats?${getQueryString(byDateParams)}`);
  if (response) {
    if (response.error || !response?.data?.listings_product_stats) {
      return response;
    } else {
      return tableParser(response.data.listings_product_stats, { page: page ? page : 1 });
    }
  }
};
