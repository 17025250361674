import React, { forwardRef, useImperativeHandle, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Modal } from '..';

const ConfirmationModal = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { title, onSuccess, children, onCancel, type, okText, footer, footerError, ...rest } = props;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useImperativeHandle(ref, () => ({
    showModal() {
      setShowModal(true);
    },
    hideModal() {
      setShowModal(false);
    },
    getModalVisiblity() {
      return showModal;
    },
    setLoading,
    setError,
  }));

  const handleOk = () => {
    onSuccess();
  };

  const handleCancel = () => {
    onCancel();
    setShowModal(false);
  };

  return (
    <Modal
      type={type}
      title={t(title)}
      visible={showModal}
      okText={t(okText) ?? t('Confirm')}
      onOk={handleOk}
      onCancel={handleCancel}
      loading={loading}
      footer={footer}
      {...rest}
    >
      {children}
      <ErrorMessage message={error} asAlert />
      <ErrorMessage message={footerError} asAlert />
    </Modal>
  );
});

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  onSuccess: PropTypes.func,
  children: PropTypes.node,
  onCancel: PropTypes.func,
  type: PropTypes.string,
  okText: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  onCancel: () => {},
};

export default ConfirmationModal;
