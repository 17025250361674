import { DATE_FORMAT } from '../constants/formats';
import moment from 'moment/moment';
import 'moment/locale/ar'; // Import Arabic locale

export function getMonthFromString(mon) {
  const month = new Date(Date.parse(`${mon} 1, 2012`)).getMonth() + 1;
  return month.toString().length === 1 ? `0${month}` : month;
}

export function getStartValue(start) {
  return `${start[3]}-${getMonthFromString(start[1])}-${start[2]}`;
}

export function getEndValue(end) {
  return `${end[3]}-${getMonthFromString(end[1])}-${end[2]}`;
}

export function getStartEndValue(start, end) {
  const startDate = getTimeDateString(getStartValue(start), DATE_FORMAT, false, true);
  const endDate = getTimeDateString(getStartValue(end), DATE_FORMAT, false, true);
  return `${startDate} - ${endDate}`;
}

export function getDateDiff(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const timeDifference = Math.abs(startDate - endDate);
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
}

export const getUTCOffset = timezone => {
  return moment()
    .utcOffset(
      moment()
        .tz('Asia/Karachi')
        .utcOffset(),
    )
    .format('Z');
};

export const getTimeDateString = (dateString, dateFormat = DATE_FORMAT, local, defaultLocale) => {
  if (!!dateString) {
    let date = new Date(
      typeof dateString === 'string' && dateString.includes('Z') && !local
        ? dateString.replace('Z', '')
        : typeof dateString === 'string'
        ? dateString.replace(' ', 'T')
        : dateString,
    );
    var datetime = defaultLocale
      ? moment(date)
          .locale('en')
          .format(dateFormat)
      : moment(date).format(dateFormat); // Set the locale explicitly for formatting time
    return datetime;
  }
  return '';
};

export const getVariousDates = (diff, format = 'YYYY-MM-DD', add) => {
  const currentDate = moment()
    .locale('en')
    .format(format);
  const prevDate = add
    ? moment()
        .locale('en')
        .add(diff, 'days')
        .format(format)
    : moment()
        .locale('en')
        .subtract(diff, 'days')
        .format(format);
  return [prevDate, currentDate];
};

export const getDateLabels = (startDate, endDate) => {
  let dateArray = [];
  let currentDate = moment(startDate).locale('en');
  let stopDate = moment(endDate).locale('en');
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).locale('en').format('MMM DD, YYYY'));
    currentDate = currentDate.add(1, 'days');
  }
  return dateArray;
};

export const addOrSubDaysToDate = (days = 0) => {
  return new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000);
};

export const getDateDiffInDays = dateRange => {
  return Math.ceil((new Date(dateRange[1]).getTime() - new Date(dateRange[0]).getTime()) / (1000 * 3600 * 24)) + 1;
};
