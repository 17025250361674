import tenantConstants from '@constants';
import tenantUtils from '@utils';
import moment from 'moment';
import { NetworkService } from '../../../../services/networkService';
import { getDateDiffInDays, getVariousDates } from '../../../../utility/date';
import { getQueryString } from '../../../../utility/urlQuery';
import { capitalizeFirstLetter, getResultGroup } from '../../../../utility/utility';
import tenantTheme from '@theme';

const COLORS_ARRAY = ['#20C997', '#FA8B0C', '#5F63F2', '#1ce383', '#14449f'];

const limit = 10;

const getAreaBreakdownData = (statsData, dataKey) => {
  const arrayList = statsData?.[dataKey]?.length
    ? statsData?.[dataKey]?.map((e) => ({ ...e, label: tenantUtils.getLocalisedString(e, 'title') }))
    : [];
  const sortedList = arrayList?.sort((a, b) => b?.count - a?.count) || [];
  const othersDataValue = sortedList
    ?.map((entry) => entry?.['count'])
    .slice(COLORS_ARRAY.length)
    .reduce((a, b) => a + b, 0);
  const othersData = sortedList?.length > COLORS_ARRAY.length ? { label: 'Others', data: othersDataValue } : null;
  const total = sortedList?.map((kl) => kl?.['count'])?.reduce((a, b) => a + b, 0);
  return {
    total: total,
    chart: {
      labels: [
        ...sortedList?.map((entry) => entry?.label).slice(0, COLORS_ARRAY.length),
        ...(othersData ? [othersData?.label] : []),
      ],
      dataset: [
        ...sortedList?.map((entry) => entry?.['count']).slice(0, COLORS_ARRAY.length),
        ...(othersData ? [othersData?.data] : []),
      ],
    },
    locations: [
      ...sortedList
        ?.map((loc) => ({
          label: loc?.label,
          data: loc?.['count'],
        }))
        .slice(0, COLORS_ARRAY.length),
      ...(othersData ? [othersData] : []),
    ],
    backgroundColor: COLORS_ARRAY,
  };
};

const widgetParser = (data, user) => {
  const { areaStats, listings } = data;
  const [platformKey, platformStats] = Object.entries(listings?.stats || {})?.[0];
  const productsToShow = user?.isCurrencyUser
    ? [{ slug: 'signature-listing' }, { slug: 'hot-listing' }, { slug: 'basic-listing' }]
    : [{ slug: 'hot-listing' }, { slug: 'basic-listing' }];

  const formattedData = {
    id: platformKey,
    slug: platformKey,
    title: 'Listings',
    listing_breakdown: {
      title: 'Listings',
      main_title: capitalizeFirstLetter(platformKey),
      total_title: 'Active',
      total_value: platformStats.active,
      purposes: [
        {
          icon: 'IconPropertyBuy',
          title: 'For Sale',
          iconProps: { size: '1.2em', color: '#28B16D' },
          value: platformStats?.sale || 0,
        },
        {
          id: 2,
          icon: 'IconPropertyRent',
          title: 'To Rent',
          iconProps: { size: '1.2em', color: '#479EEB' },
          value: platformStats?.rent || 0,
        },
        {
          id: 4,
          icon: 'IconRental',
          iconProps: { color: tenantTheme['color-for-rent'], hasBackground: true },
          title: 'Daily Rentals',
          value: platformStats?.daily_rental || 0,
        },
      ],
      products: tenantUtils.generateProductData(productsToShow),
    },
    area_breakdown: {
      title: 'Breakdown By Location',
      types: [
        { key: 'sale_breakdown_by_area', label: 'For Sale' },
        { key: 'rent_breakdown_by_area', label: 'To Rent' },
      ],
      data: {
        sale_breakdown_by_area: getAreaBreakdownData(areaStats?.listings?.[platformKey], 'sale_breakdown_by_area'),
        rent_breakdown_by_area: getAreaBreakdownData(areaStats?.listings?.[platformKey], 'rent_breakdown_by_area'),
      },
    },
  };
  formattedData.listing_breakdown.products.forEach((e) => {
    e.value = platformStats[e.slug_alt];
  });
  return formattedData;
};

const tableParser = (listings, params, user) => {
  const list = Object?.keys(listings)?.map((item) => ({
    date: { value: item },
    active_listings: { value: listings?.[item]?.active || 0, dashForNone: true },
    sale: { value: listings?.[item]?.sale || 0, dashForNone: true },
    rent: { value: listings?.[item]?.rent || 0, dashForNone: true },
    signature: { value: listings?.[item]?.signature || 0, dashForNone: true },
    hot: { value: listings?.[item]?.hot || 0, dashForNone: true },
    basic: { value: listings?.[item]?.basic || 0, dashForNone: true },
    total: { value: listings?.[item]?.total || 0, dashForNone: true },
    photography: { value: listings?.[item]?.photography || 0, dashForNone: true },
    videography: { value: listings?.[item]?.videography || 0, dashForNone: true },
    refresh: { value: listings?.[item]?.refresh || 0, dashForNone: true },
  }));

  const pagination = {
    from: params?.page ? (params?.page - 1) * limit + 1 : 1,
    to: params?.page ? params?.page * limit : limit,
    current_page: parseInt(params.page ? params?.page : 1),
    per_page: limit,
    last_page: parseInt(list?.length / limit) + 1,
    total_pages: parseInt(list?.length / limit) + 1,
    total_records: list?.length,
  };

  const tableData = {
    list: list,
    pagination: tenantUtils.getPaginationObject(pagination),
    filtersData: {
      list: [
        {
          label: 'Listed Date',
          key: tenantConstants.VALUE_IN_ARRAY_KEY('date_between'),
          placeholder: 'Select Date Range',
          type: 'dateRange',
          showInSingleTag: true,
          labelProps: { muted: true },
          minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 2)),
          reportsStaticRanges: true,
          getTagContent: (value) => value,
        },
      ],
    },
    table: [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        component: 'Date',
      },
      {
        title: 'Listings',
        dataIndex: 'active_listings',
        key: 'active_listings',
        component: 'Number',
      },
      {
        title: 'Sale',
        dataIndex: 'sale',
        key: 'sale',
        component: 'Number',
      },
      {
        title: 'Rent',
        dataIndex: 'rent',
        key: 'rent',
        component: 'Number',
      },
      {
        title: 'Signature',
        dataIndex: 'signature',
        key: 'signature',
        component: 'Number',
      },
      {
        title: 'Hot',
        dataIndex: 'hot',
        key: 'hot',
        component: 'Number',
      },
      {
        title: 'Basic',
        dataIndex: 'basic',
        key: 'basic',
        component: 'Number',
      },
    ],
  };
  return tableData;
};

export const listingsSummaryApi = async ({ userId, user }) => {
  const listingStats = await NetworkService.get(
    `/api/dashboard/listing_stats?${!user?.is_agency_admin ? `q[user_id_eq]=${userId}` : ''}`,
  );
  const listingsByArea = await NetworkService.get(
    `/api/dashboard/listings_by_area?${!user?.is_agency_admin ? `q[user_id_eq]=${userId}` : ''}`,
  );
  if (listingStats && listingsByArea) {
    return widgetParser({ listings: listingStats.data, areaStats: listingsByArea.data }, user);
  }
};

export const fetchListingPostedByDate = async (user, params) => {
  const {
    ['filter[date_between]']: dateBetween = `${getVariousDates(29)}`,
    page,
    group_by = getResultGroup(
      getDateDiffInDays(typeof dateBetween == 'string' ? dateBetween?.split(',') : dateBetween),
      true,
    )?.result_group,
  } = params;

  const byDateParams = {
    start_date: moment(dateBetween?.split(',')?.[0]).locale('en').format('YYYY-MM-DD'),
    end_date: moment(dateBetween?.split(',')?.[1]).locale('en').format('YYYY-MM-DD'),
    ...(page && { page: page }),
    group_by,
  };

  const response = await NetworkService.get(`/api/dashboard/listings_history?${getQueryString(byDateParams)}`);
  if (response) {
    if (response.error || !response?.data?.result) {
      return response;
    } else {
      return tableParser(response.data.result, { page: page ? page : 1 }, user);
    }
  }
};
