import { Image as AntdImage } from 'antd';
import React from 'react';

const ImagePreviewGroup = props => {
  return <AntdImage.PreviewGroup {...props} />;
};

ImagePreviewGroup.defaultProps = {};

ImagePreviewGroup.propTypes = {};

export default ImagePreviewGroup;
