const actions = {
  CHANGE_LAYOUT_MODE: 'CHANGE_LAYOUT_MODE',
  CHANGE_RTL_MODE: 'CHANGE_RTL_MODE',
  SET_MOBILE: 'SET_MOBILE',
  SET_LOCALE: 'SET_LOCALE',
  SET_VERSION: 'SET_VERSION',
  SET_MEMBER_AREA: 'SET_MEMBER_AREA',
  SET_MULTI_PLATFORM: 'SET_MULTI_PLATFORM',

  changeLayoutMode: data => {
    return { type: actions.CHANGE_LAYOUT_MODE, data };
  },

  changeRtlMode: data => {
    return { type: actions.CHANGE_RTL_MODE, data };
  },

  setMobile: data => {
    return { type: actions.SET_MOBILE, data };
  },

  setLocale: data => {
    return { type: actions.SET_LOCALE, data };
  },

  setVersion: data => {
    return { type: actions.SET_VERSION, data };
  },

  setMemberArea: data => {
    return { type: actions.SET_MEMBER_AREA, data };
  },

  setMultiPlatform: data => {
    return { type: actions.SET_MULTI_PLATFORM, data };
  },
};

export default actions;
