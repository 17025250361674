import PropTypes, { object } from 'prop-types';

import React from 'react';
import { Button } from '../button/button';
import Icon from '../icon/icon';
import { ModalStyled } from './styled';
import { useTranslation } from 'react-i18next';

const Modal = props => {
  const {
    onCancel,
    className,
    onOk,
    visible,
    title,
    type,
    color,
    footer,
    width,
    children,
    okText,
    cancelText,
    loading,
    ...rest
  } = props;
  const { t } = useTranslation();

  return (
    <ModalStyled
      title={title}
      visible={visible}
      okText={okText ?? t('Confirm')}
      cancelText={cancelText}
      onOk={onOk}
      onCancel={onCancel}
      type={color ? type : false}
      width={width}
      className={className}
      centered
      closeIcon={<Icon icon="IoMdClose" />}
      footer={
        footer || footer === null
          ? footer
          : [
              <Button type="default" key="back" onClick={onCancel}>
                {t('Cancel')}
              </Button>,
              <Button type={type} key="submit" onClick={onOk} loading={loading} disabled={loading}>
                {okText ? t(okText) : t('Confirm')}
              </Button>,
            ]
      }
      {...rest}
    >
      {children}
    </ModalStyled>
  );
};

Modal.defaultProps = {
  width: 620,
  type: 'primary',
};

Modal.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  visible: PropTypes.bool,
  title: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  footer: PropTypes.arrayOf(object),
  width: PropTypes.number,
  color: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
};

const alertModal = ModalStyled;
export { Modal, alertModal };
