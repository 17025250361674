import { activeProducts, cartDetail, checkout, deleteCart, updateCart } from './cart';
import {
  addAgencyUser,
  deleteAgencyUser,
  fetchAgencyStaffList,
  fetchAgencyUserDetail,
  fetchAgencyUsers,
  getUserQuotaCreditDetail,
  manageTransfer,
  updateAgencyUser,
} from './agency-staff';
import {
  applyUtilizationPlan,
  getAppliedAutoPlan,
  getPlans,
  stopAutoPlan,
} from './utilization-plans/plans';
import {
  deleteImageFromBank,
  deleteStory,
  fetchCrossCityDetail,
  fetchListingDetailToUpdate,
  getAmenities,
  getCreditDeductionApi,
  getPhotographyVideoGraphyCredits,
  getImageBank,
  getListingDetailApi,
  getProjects,
  getQuotaDeductionApi,
  getS3PresignedUrl,
  postNewListing,
  postPackageOnListing,
  postSavedListing,
  priceCheckApi,
  updateListing,
  deleteImageFromS3,
} from './listings/postListingApis';
import { deleteListings, fetchListingPageData, hideUnhideListings, listingMapper } from './listings/listingsApis';
import {
  deleteMailThread,
  UntrashThead,
  getMailCount,
  getMailbox,
  getMessageDetail,
  postReply,
} from './mailbox/mailbox';
import { fetchAgencySettingsDetail, updateAgencySettings } from './agency-settings';
import { fetchListingPostedByDate, listingsSummaryApi } from './reports/listingsSummaryApi';
import {
  fetchUserDetail,
  fetchUserDetailFromToken,
  fetchUserProductsDetail,
} from './user/userDetail';
import {
  fetchUserSettingsDetail,
  updateUserPreferenceDetail,
  updateUserSettings,
} from './user-settings';
import { getPurchaseLogs, getQuotaCreditsDetail, fetchUserQuotaCredits } from './quota-credits/quota-credits';
import { requestPayment, updatePayment } from './checkout/checkout';
import { changePassword } from './change-password/changePasswordApi';

import { agencyQuotaCredit } from './quota-credits/agency-quota-credit';
import { dashboardListingWidgetApi } from './dashboard/listingsWidgetApi';
import { fetchListingDetail } from './listings/fetchListingDetail';
import { fetchListingPeformanceData } from './reports/listingPerformanceApi';
import { fetchPlotsByQuery } from './listings/plotfinder';
import { getMyOrders } from './my-orders/getMyOrders';
import { getProductExpiryData } from './quota-credits/getPrdouctExpiryData';
import { leadsSummaryApi } from './reports/leadsSummaryApi';
import { loginApi } from './auth/loginApi';
import { olxListingPerformanceApi } from './reports/olxListingPerformanceApi';
import { trafficSummaryApi } from './reports/trafficSummaryApi';
import { fetchContractsDetails } from './user/userDetail';
import {
  agencyUser,
  permissions,
  loggedInUser,
  preferencesUpdate,
  agencySettings,
  userPassword,
} from './requestPayloads/requestPayloads';
export default {
  getProductExpiryData,
  fetchListingPageData,
  fetchListingDetailToUpdate,
  leadsSummaryApi,
  listingsSummaryApi,
  trafficSummaryApi,
  fetchUserDetail,
  loginApi,
  fetchPlotsByQuery,
  getS3PresignedUrl,
  getImageBank,
  fetchAgencyStaffList,
  fetchAgencyUserDetail,
  addAgencyUser,
  deleteAgencyUser,
  updateAgencyUser,
  postNewListing,
  fetchUserSettingsDetail,
  updateUserSettings,
  fetchAgencySettingsDetail,
  updateAgencySettings,
  dashboardListingWidgetApi,
  priceCheckApi,
  getQuotaDeductionApi,
  getListingDetailApi,
  postPackageOnListing,
  deleteStory,
  getCreditDeductionApi,
  getPhotographyVideoGraphyCredits,
  postSavedListing,
  fetchListingPeformanceData,
  deleteListings,
  hideUnhideListings,
  getAppliedAutoPlan,
  applyUtilizationPlan,
  getPlans,
  getMailbox,
  deleteImageFromBank,
  getQuotaCreditsDetail,
  fetchUserQuotaCredits,
  getPurchaseLogs,
  getAmenities,
  cartDetail,
  activeProducts,
  updateCart,
  deleteCart,
  checkout,
  fetchAgencyUsers,
  stopAutoPlan,
  updateListing,
  updateUserPreferenceDetail,
  deleteMailThread,
  getMessageDetail,
  postReply,
  getMailCount,
  getUserQuotaCreditDetail,
  manageTransfer,
  fetchCrossCityDetail,
  fetchUserProductsDetail,
  fetchContractsDetails,
  fetchListingPostedByDate,
  getProjects,
  agencyQuotaCredit,
  fetchUserDetailFromToken,
  getMyOrders,
  requestPayment,
  olxListingPerformanceApi,
  fetchListingDetail,
  updatePayment,
  UntrashThead,
  changePassword,
  deleteImageFromS3,
  agencyUser,
  permissions,
  loggedInUser,
  preferencesUpdate,
  agencySettings,
  userPassword,
  listingMapper
};
