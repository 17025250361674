import tenantTheme from '@theme';
import { List, Row, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Group, Heading, Popover } from '../../../../../components/common';
import { ColorBlob, LegendHorizontal } from './styled';

const getBlobColor = (color) => {
  switch (color) {
    case 3:
      return tenantTheme['health-color-low'];
    case 2:
      return tenantTheme['health-color-avg'];
    case 1:
      return tenantTheme['health-color-good'];
    default:
      return tenantTheme['bg-color-deep'];
  }
};

const getScoreIcon = (color) => {
  switch (color) {
    case 3:
      return (
        <svg width={10} height={34} viewBox="0 0 10 34">
          <path d="M10 0H0v10h10V0Z" fill={tenantTheme['bg-color-deep']} />
          <path d="M10 12H0v10h10V12Z" fill={tenantTheme['bg-color-deep']} />
          <path d="M10 24H0v10h10V24Z" fill={tenantTheme['health-color-low']} />
        </svg>
      );
    case 2:
      return (
        <svg width={10} height={34} viewBox="0 0 10 34">
          <path d="M10 0H0v10h10V0Z" fill={tenantTheme['bg-color-deep']} />
          <path d="M10 12H0v10h10V12Z" fill={tenantTheme['health-color-avg']} />
          <path d="M10 24H0v10h10V24Z" fill={tenantTheme['health-color-avg']} />
        </svg>
      );
    case 1:
      return (
        <svg width={10} height={34} viewBox="0 0 10 34">
          <path d="M10 0H0v10h10V0Z" fill={tenantTheme['health-color-good']} />
          <path d="M10 12H0v10h10V12Z" fill={tenantTheme['health-color-good']} />
          <path d="M10 24H0v10h10V24Z" fill={tenantTheme['health-color-good']} />
        </svg>
      );
    default:
      return (
        <svg width={10} height={34} viewBox="0 0 10 34">
          <path d="M10 0H0v10h10V0Z" fill={tenantTheme['bg-color-deep']} />
          <path d="M10 12H0v10h10V12Z" fill={tenantTheme['bg-color-deep']} />
          <path d="M10 24H0v10h10V24Z" fill={tenantTheme['bg-color-deep']} />
        </svg>
      );
  }
};

const Health = (props) => {
  const { t } = useTranslation();

  const healthData = [
    {
      title: t('Listing Freshness'),
      subTitle: props?.age_message,
      rightTitle: t('Listing Age'),
      rightDescription: props?.age,
      color: props?.age_score,
    },
    {
      title: t('Unique Images Score'),
      subTitle: props?.unique_images_message,
      rightTitle: t('Unique Images:'),
      rightDescription: props?.unique_images,
      color: props?.unique_images_score,
    },
    {
      title: t('Features Score'),
      subTitle: props?.features_message,
      rightTitle: t('Feature Selected:'),
      rightDescription: props?.features_selected,
      color: props?.features_score,
    },
  ];

  const item = (title, subTitle, rightTitle, rightDescription, color) => {
    return (
      <>
        <List.Item className="py-16">
          <Heading className="color-gray-lighter" style={{ textTransform: 'uppercase' }} as="h6">
            {title}
          </Heading>
          <Group template="max-content 1fr max-content">
            <div style={{ alignSelf: 'center' }}>{getScoreIcon(color)}</div>
            <div>
              <Heading className="mb-0" as="h6">
                {title}
              </Heading>
              <div className="color-gray-lighter">{subTitle}</div>
            </div>
            <div className="text-right">
              <strong>
                <Heading className="mb-0 color-gray-lighter" as="h6">
                  {rightTitle}
                </Heading>
                <Heading className="mb-0" as="h5">
                  {rightDescription}
                </Heading>
              </strong>
            </div>
          </Group>
        </List.Item>
      </>
    );
  };
  return (
    <Popover
      placement="right"
      action={props.isMobile ? 'click' : 'hover'}
      getTooltipContainer={null}
      getPopupContainer={null}
      content={
        <List
          itemLayout="vertical"
          dataSource={healthData}
          renderItem={(e) => item(e.title, e.subTitle, e.rightTitle, e.rightDescription, e.color)}
          footer={
            <Row justify="space-evenly" style={{ gap: 16, paddingInlineStart: 16 }}>
              <Space align="center">
                <LegendHorizontal>{getScoreIcon(3)}</LegendHorizontal>
                {t('Low Score')}
              </Space>
              <Space align="center">
                <LegendHorizontal>{getScoreIcon(2)}</LegendHorizontal>
                {t('Average Score')}
              </Space>
              <Space align="center">
                <LegendHorizontal>{getScoreIcon(1)}</LegendHorizontal>
                {t('Best Score')}
              </Space>
            </Row>
          }
          style={{ margin: -16 }}
        />
      }
    >
      <ColorBlob color={getBlobColor(props.overall_score)} size={props.blobSize} />
    </Popover>
  );
};

export default Health;
