import tenantData from '@data';
import tenantUtils from '@utils';
import { Divider, Progress, Typography } from 'antd';
import cx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Flex, Group, TextWithIcon } from '../common';
import Statistic from '../common/statistic';

const { Text } = Typography;

const QuotaCreditsStatsWidget = ({ available, used, loading, currencyType, brandColor, showPackage }) => {
  const { user } = useSelector((state) => state.loginUser);
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const total = available + used;
  const pwidth = (used / total) * 100;
  const vDiv = <Divider type="vertical" style={{ height: 40, margin: 0 }} />;
  const { t } = useTranslation();
  const slugType = tenantData.slugToType?.[user?.package?.slug];
  const statsFw = 800;

  return (
    <React.Fragment>
      <Group
        template="repeat(5, auto) 1fr"
        justify="start"
        gap={isMobile ? '12px 24px' : '32px'}
        style={{ justifySelf: 'start' }}
        className={'w-100'}
      >
        
        <Statistic
          fontWeight={statsFw}
          title={currencyType == 'quota' ? t('Available Quota') : t('Available Credits')}
          value={available}
          loading={loading}
          lead={!isMobile}
          trends={false}
          precision={available % 1 != 0 && 2}
        />
        {vDiv}
        <Statistic
          fontWeight={statsFw}
          title={t('Used')}
          value={used}
          loading={loading}
          lead={!isMobile}
          trends={false}
          precision={used % 1 != 0 && 2}
        />

        {vDiv}

        <Statistic
          fontWeight={statsFw}
          title={t('Total')}
          value={total || 0}
          lead={!isMobile}
          loading={loading}
          trends={false}
          precision={total % 1 != 0 && 2}
        />


        {!!user?.isCurrencyUser && !!showPackage && user?.package && (
          <Flex
            className={cx(isMobile && 'span-all w-100')}
            vertical
            gap="4px"
            style={{ justifySelf: !isMobile && 'end', gridRow: isMobile && 1 }}
          >
            <Text className={cx('fw-400', !isMobile && 'text-right')} type="secondary">
              {t('Current Plan')}
            </Text>
            <TextWithIcon
              icon={tenantData.packages?.[slugType]?.icon}
              iconProps={{
                hasBackground: true,
                size: 18,
                iconBackgroundColor: tenantData.packages?.[slugType]?.packageColor,
              }}
              title={tenantUtils.getLocalisedString(user?.package, 'name')}
              fontWeight="800"
              style={{ '--icon-styled-width': '30px' }}
            />
          </Flex>
        )}
      </Group>
      <Progress percent={pwidth} showInfo={false} strokeColor={brandColor} />
    </React.Fragment>
  );
};
export default QuotaCreditsStatsWidget;
