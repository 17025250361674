import tenantConstants from '@constants';
import { NetworkService } from '../../../../services/networkService';
import { getVariousDates } from '../../../../utility/date';

export const olxListingPerformanceApi = async (userId, list, user, params) => {
  const user_ids = [
    ...new Set(list.map((item) => (tenantConstants.KC_ENABLED ? item.user?.external_id : item.user?.id?.toString()))),
  ];
  const listing_ids = [];

  list.forEach((item) => listing_ids.push(item?.id));

  const olxStatsResponse = await NetworkService.get(`/api/ovation/stats`, {
    start_date: getVariousDates(365)[0],
    end_date: getVariousDates(365)[1],
    user_external_ids: user_ids,
    category_ids: [1, 2],
    ad_external_ids: listing_ids,
    group_by: ['ad_external_id'],
  });
  if (olxStatsResponse) {
    if (olxStatsResponse.error) {
      const newlist = list.map((item) => {
        return {
          ...item,
          platforms: {
            ...item.platforms,
            data: [
              {
                ...item.platforms.data[0],
                views: '',
                clicks: '',
                ctr: '',
                calls: '',
                emails: '',
                sms: '',
                whatsapp: '',
                leads: '',
                statsError: 'Bayut Stats Error',
              },
            ],
          },
        };
      });
      return { list: newlist };
    } else {
      const olxStatsData = olxStatsResponse?.data?.stats;
      const newlist = list.map((item) => {
        const olxStats = olxStatsData?.items?.find((e) => e?.ad_external_id == item?.id);
        return {
          ...item,
          platforms: {
            ...item.platforms,
            data: [
              {
                ...item.platforms.data[0],
                views: olxStats?.sum_search_count,
                leads: olxStats?.sum_lead_count,
                clicks: olxStats?.sum_view_count,
                ctr: (olxStats?.sum_view_count / olxStats?.sum_search_count) * 100,
                calls: olxStats?.sum_phone_view_count,
                emails: olxStats?.sum_email_lead_count,
                sms: olxStats?.sum_sms_view_count,
                whatsapp: olxStats?.sum_whatsapp_view_count,
                chat: olxStats?.sum_chat_lead_count,
                stats: {
                  views: olxStats?.sum_search_count,
                  clicks: olxStats?.sum_view_count,
                  leads: olxStats?.sum_lead_count,
                },
              },
            ],
          },
        };
      });
      return { list: newlist };
    }
  }
};
