import tenantData from '@data';
import store from '../../../../redux/store';
import { NetworkService } from '../../../../services/networkService';

export const ZONES = [
  { title: 'All', slug: 'all', tier_id: -1 },
  { title: 'Zone A', slug: 'zone_a', tier_id: 1 },
  { title: 'Zone B', slug: 'zone_b', tier_id: 2 },
  { title: 'Zone C', slug: 'zone_c', tier_id: 3 },
];

const defaultQuotaObject = {
  total: 0,
  available: 0,
  extended: 0,
  consumed: 0,
  rent: { available: 0, consumed: 0, extended: 0 },
  sale: { available: 0, consumed: 0, extended: 0 },
};

const defaultCreditObject = { total: 0, available: 0, consumed: 0 };

const getProductsArray = (products, type, zone) => {
  const filteredProducts = products.filter((e) => (type == 'olx' ? e.slug.includes('olx') : !e.slug.includes('olx')));
  const quota = filteredProducts
    .filter((e) => e.type == 'quota')
    .map((e) => ({ ...e, ...(zone ? { tier_id: zone.tier_id } : {}), ...defaultQuotaObject }));
  const credits = filteredProducts
    .filter((e) => e.type === 'credit')
    .sort((a, b) => Number(b.price) - Number(a.price))
    .map((e) => ({ ...e, ...(zone ? { tier_id: zone.tier_id } : {}), ...defaultCreditObject }));
  return [...quota, ...credits];
};

const mapQuotaObj = (current = defaultQuotaObject, obj) => {
  return {
    ...current,
    total: current.total + obj.allocated,
    available: current.available + obj.available,
    extended: current.extended + obj.extended,
    consumed: current.consumed + obj.consumed,
    rent: {
      available: current.rent.available + obj.rent.available,
      consumed: current.rent.consumed + obj.rent.consumed,
      extended: current.rent.extended + obj.rent.extended,
    },
    sale: {
      available: current.sale.available + obj.sale.available,
      consumed: current.sale.consumed + obj.sale.consumed,
      extended: current.sale.extended + obj.sale.extended,
    },
  };
};

const mapCreditObj = (current = defaultCreditObject, obj) => {
  return {
    ...current,
    total: current.total + obj.total_credits,
    available: current.available + obj.available_credits,
    consumed: current.consumed + obj.consumed,
  };
};

const getProductsAndZonalBreakdownData = (zone, products, chartProducts, zonalBreakDown) => {
  Object.defineProperty(chartProducts, zone, { value: {} });
  Object.defineProperty(zonalBreakDown, zone, { value: {} });
  products &&
    products.forEach((it) => {
      chartProducts[zone][it.title] = [it.total - it.available, it.available];
      zonalBreakDown[zone][it.title] = Object.keys(it.credits_consumption || {}).map((item) => ({
        label: item,
        value: it.credits_consumption[item],
      }));
    });
};

const addToAllWithoutZone = (all, data, type) => {
  const { credits, quota } = data;
  if (!!credits?.products[type] && Array.isArray(credits?.products[type])) {
    credits.products[type].forEach((userProductItem) => {
      const productItemIndex = all.findIndex((it) => it.id == userProductItem.product_id);
      if (productItemIndex != -1) {
        all[productItemIndex] = mapCreditObj(all[productItemIndex], userProductItem);
      }
    });
  }
  if (quota?.products[type] && Object.keys(quota?.products[type]).length !== 0) {
    // As quota to be placed on 0th index
    all[0] = mapQuotaObj(all[0], quota.products[type]);
  }
};

export const getProductsByZones = (data, type, allProducts = tenantData.products, addZBAndChart) => {
  // const { credits, quota } = data;
  const zones = {},
    chartProducts = {},
    zonalBreakdown = {},
    all = getProductsArray(allProducts, type);
  addToAllWithoutZone(all, data, type);
  addZBAndChart && getProductsAndZonalBreakdownData('all', all, chartProducts, zonalBreakdown);
  return { zones: { all, ...zones }, ...(addZBAndChart ? { chartProducts, zonalBreakdown } : {}) };
};
export const getPermissionsObj = (val) => ({
  inbox: val,
  leads: val,
  listings: val,
  profile: val,
  staff: val,
  stats: val,
});

export const fetchUserDetail = async () => {
  const response = await NetworkService.get(`/api/users/current`);
  if (response.error) {
    return response;
  } else {
    const data = response?.data?.user;
    let settings = [];
    const settingsData = ['area_unit', 'currency', 'email_notification', 'newsletter', 'automated_reports'];
    settingsData.forEach((e) => settings.push(data?.[e]));
    const isOnOlx = !!data?.olx_user_id;
    const quotaCreditProducts = store.getState().general.products.data.length
      ? store.getState().general.products.data
      : tenantData.products;

    return {
      data: {
        ...data,
        id: data?.id,
        can_post_limit_listings: true,
        is_shifted_to_olx_quota: data?.is_quota_shifted,
        olx_user_id: data?.olx_user_id,
        // type: data.is_premium ? 'premium' : 'free',
        type: 'premium',
        permissions: data?.is_agency_admin ? getPermissionsObj(true) : getPermissionsObj(false),
        is_owner: data?.is_agency_admin,
        is_single_platform: !isOnOlx,
        can_post_free: { zameen: false, olx: false },
        listing_posted: !!data?.is_listing_posted,
        platform_user: isOnOlx ? [{ id: 2, slug: 'olx', title: 'OLX' }] : [],
        settings: settings,
        platforms: tenantData.platformList,
        isCurrencyUser: data?.is_credit_user,
        // is_package_user: false,
        products: {
          platforms: {
            ksa: quotaCreditProducts?.filter((e) => e?.platform === 'ksa'),
          },
        },
      },
    };
  }
};

const getContractsObj = (contracts) => {
  return {
    contracts: {
      olx: {
        active_contracts: 0,
        downgraded_contracts: 0,
        total_contracts: 0,
        ...contracts?.olx,
      },
      zameen: {
        active_contracts: 0,
        downgraded_contracts: 0,
        total_contracts: 0,
        ...contracts?.zameen,
      },
    },
  };
};

export const fetchContractsDetails = async () => {
  const response = await NetworkService.get(`/api/dashboard/contracts`);
  if (response.error) {
    return getContractsObj();
  } else {
    return getContractsObj(response?.data?.contracts);
  }
};

export const fetchUserDetailFromToken = async (headers) => {
  const response = await NetworkService.get(`/api/users/current`, null, headers);
  if (response?.error) {
    return response;
  } else {
    localStorage.setItem('showBanner', JSON.stringify(true));
    const data = response?.data?.user;
    let settings = [];
    const settingsData = ['area_unit', 'currency', 'email_notification', 'newsletter', 'automated_reports'];
    settingsData.forEach((e) => settings.push(data?.[e]));
    const isOnOlx = !!data?.olx_user_id;
    const quotaCreditProducts = store.getState().general.products.data.length
      ? store.getState().general.products.data
      : tenantData.products;

    return {
      data: {
        ...data,
        id: data?.id,
        can_post_limit_listings: true,
        is_shifted_to_olx_quota: data?.is_quota_shifted,
        olx_user_id: data?.olx_user_id,
        type: 'premium',
        permissions: data?.is_agency_admin ? getPermissionsObj(true) : getPermissionsObj(false),
        is_owner: data?.is_agency_admin,
        is_single_platform: !isOnOlx,
        can_post_free: { zameen: false, olx: false },
        listing_posted: !!data?.is_listing_posted,
        platform_user: isOnOlx ? [{ id: 2, slug: 'olx', title: 'OLX' }] : [],
        settings: settings,
        platforms: tenantData.platformList,
        isCurrencyUser: data?.is_credit_user,
        // is_package_user: false,
        products: {
          platforms: {
            ksa: quotaCreditProducts?.filter((e) => e?.platform === 'ksa'),
          },
        },
      },
    };
  }
};

export const fetchUserContracts = async (headers) => {
  const response = await NetworkService.get('api/users/contracts');
  if (response?.error) {
    if (response?.response?.status === 404) {
      return getContractsObj();
    } else {
      return response;
    }
  } else {
    return {
      contracts: getContractsObj(response?.data?.data).contracts,
    };
  }
};

export const fetchUserProductsDetail = async () => {
  const response = await NetworkService.get(`/api/dashboard/qc_summary`);
  if (response.error) {
    return response;
  } else {
    return {
      data: {
        products: {
          platforms: {
            zameen:
              Object?.keys(response?.data?.zameen)?.length &&
              tenantData.products?.filter((e) => e?.platform === 'zameen'),
            olx: Object?.keys(response?.data?.olx)?.length && tenantData.products?.filter((e) => e?.platform === 'olx'),
          },
        },
      },
    };
  }
};
