import tenantData from '@data';
import store from '../../../../redux/store';
import { NetworkService } from '../../../../services/networkService';
import { getErrorAllResponse } from '../../../../utility/utility';

const quotaCreditParser = (item, prods, loggedInUser) => {
  const data = {};
  tenantData.platformList.forEach((platform) => {
    let { quota = {}, credits = [] } = item?.[platform?.slug];
    //removed video and photo credits bcz no need to show these credits in transfer quota screen right now
    credits = credits?.length ? credits?.filter((e) => e.id !== 13 && e.id !== 14) : [];
    credits.unshift(quota);
    const platformProducts = [];
    tenantData.products?.forEach(
      (e) =>
        credits?.find((it) => it?.id === e?.id) &&
        platformProducts.push({ ...e, ...credits?.find((it) => it?.id === e?.id) }),
    );
    data[platform.slug] = {
      title: loggedInUser?.is_shifted_to_olx_quota ? 'Quota and Credits' : 'Listings and Credits',
      icon: platform.icon,
      zones: { all: platformProducts },
    };
  });
  return data;
};

export const getUserQuotaCreditDetail = async (userId, loggedInUser) => {
  let promiseArray = [];
  promiseArray = [
    NetworkService.get(`/api/dashboard/qc_summary?q[user_id_eq]=${loggedInUser?.id}`),
    NetworkService.get(`/api/dashboard/qc_summary?q[user_id_eq]=${userId}`),
  ];
  const response = await Promise.all(promiseArray);
  if (response) {
    const responseError = getErrorAllResponse(response);
    if (responseError) {
      return response;
    } else {
      const loggedInUserData = response?.[0]?.data;
      const userData = response?.[1]?.data;

      const quotaCreditProducts = store.getState().general.products.data.length
        ? store.getState().general.products.data
        : tenantData.products;
      return {
        loggedInUserData: quotaCreditParser(loggedInUserData, quotaCreditProducts, loggedInUser),
        userData: quotaCreditParser(userData, quotaCreditProducts, loggedInUser),
      };
    }
  }
};

export const manageTransfer = async (newCredits, from_user_id, to_user_id) => {
  let credits = Number(newCredits);
  const creditsResponse = await NetworkService.post(`/api/credits/transfer`, {
    credit: {
      quantity: Math.abs(credits),
      from_user_id: from_user_id,
      to_user_id: to_user_id,
    },
  });
  return {
    creditsResponse,
  };
};
