import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { AntIconStyled, IconStyled } from './IconStyled';

import * as LocalIcons from '../../svg';
import * as ReactIcons from '../../icons';
import * as AmenitiesIcons from '../../widgets/AmenitiesSvg';

function Icon(props) {
  const { icon, size = '1.2em', styled, iconProps, className = '', ...rest } = props;

  const Component = ReactIcons[icon] || LocalIcons[icon] || AmenitiesIcons[icon];

  const getIcon = useCallback(
    e => {
      return (
        <AntIconStyled
          className={className}
          component={() => (Component ? <Component size={size} {...e} {...rest} /> : null)}
        />
      );
    },
    [props],
  );

  return styled ? <IconStyled {...iconProps}>{getIcon()}</IconStyled> : getIcon(iconProps);
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconProps: PropTypes.object,
  styled: PropTypes.bool,
  className: PropTypes.string,
};

// Icon.defaultProps = {
//   size: '1.2em',
// };

export default Icon;
