import { Avatar as AntdAvatar } from 'antd';
import { IconStyled } from '../icon/IconStyled';
import Icon from '../icon/icon';
import React from 'react';

const Avatar = ({ src, iconSize = 16, ...rest }) => {
  return src ? (
    <AntdAvatar src={src} {...rest} />
  ) : (
    <IconStyled style={{ '--icon-styled-width': '40px' }}>
      <Icon size={iconSize} icon="FiUser" />
    </IconStyled>
  );
};

export default Avatar;
