import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { TagStyled } from './styled';
import Icon from '../icon/icon';

const ProductTag = props => {
  const { icon, iconColor, name, iconProps, ...rest } = props;
  const { t } = useTranslation();

  const getCustomizedIconProps = e => {
    if (e === 'IconSuperHot') {
      return {
        icon: 'IconSuperHotAlt',
        color: iconColor,
        ...iconProps,
      };
    }
    return iconProps;
  };

  return (
    <TagStyled shape="round" tagBackgroundColor={iconProps?.color}>
      <Icon
        styled
        icon={icon}
        size={iconProps?.size || '1em'}
        iconProps={{
          ...getCustomizedIconProps(icon),
          iconContainerSize: '1.4em',
          style: { '--icon-bg-color': '#fff' },
        }}
      />
      {t(name)}
    </TagStyled>
  );
};

ProductTag.propTypes = {
  name: PropTypes.string,
  iconColor: PropTypes.string,
  icon: PropTypes.string,
  iconProps: PropTypes.object,
};

export default ProductTag;
