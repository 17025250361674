import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Group } from '../../../../components/common';
import ActiveProducts from '../../../../components/prop-shop/ActiveProducts';
import OrderSummary from '../../../../components/prop-shop/Ordersummary';
import { Main } from '../../../../container/styled';
import { useGetMatch } from '../../../../hooks';
import {useRouteNavigate} from '../../../../hooks';
import { cartGetData } from '../../../../redux/cart/actionCreator';

function PropShop({ isMobile }) {
  const [disableProceedButton, setDisableProceedButton] = useState(false);

  const { isExact } = useGetMatch();

  const dispatch = useDispatch();
  const user = useSelector(state => state.loginUser.user);
  const cartData = useSelector(state => state.cart.data);
  const navigate = useRouteNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      dispatch(cartGetData(user.id));
    }
  }, [user]);

  const handleCheckout = () => {
    navigate(`/checkout?cart_id=${cartData.cartId}`);
  };

  return (
    <>
      <Main>
        <div className={isExact ? 'cartWraper' : 'checkoutWraper'}>
          <Row gutter={32} justify="center" style={{ rowGap: 16 }}>
            <Col xxl={13} lg={16} xs={24}>
              <Group gap="16px">
                <ActiveProducts isMobile={isMobile} setDisableProceedButton={setDisableProceedButton} />
              </Group>
            </Col>
            <Col xxl={7} lg={8} xs={24}>
              <Card className="mb-24" bodyStyle={{ padding: 24 }}>
                <OrderSummary
                  checkoutButtonText={t('Proceed To Payment')}
                  handleCheckout={handleCheckout}
                  disableProceedButton={disableProceedButton}
                  showIcon={false}
                  buttonProps={{
                    style: {
                      '--btn-bg-color': '#009f2b',
                      '--btn-content-color': '#fff',
                      height: 52,
                    },
                  }}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </Main>
    </>
  );
}

export default PropShop;
