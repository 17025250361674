import { useEffect, useState } from 'react';

import tenantConstants from '@constants';
import tenantRoutes from '@routes';
import Cookies from 'js-cookie';
import { isDevelopment } from '../utility/env';

const useProfolioSwitch = () => {
  const [show, setShow] = useState(!isDevelopment ? false : true);
  
  const hasToken = () => {
    console.log('=> redirection ');
    tenantRoutes.redirectToAppPath(window.location.pathname, () => {
      setShow(true);
    });
  };

  useEffect(() => {
    if (!isDevelopment) {
      const auth_token = Cookies.get(tenantConstants.AUTH_TOKEN_COOKIE_KEY);
      console.log('=> auth_token ', auth_token);
      if (auth_token) {
        hasToken();
      } else {
        if (tenantConstants.KC_ENABLED || tenantConstants.HAVE_LOGIN_SCREEN) {
          hasToken();
        } else {
          console.log('=> No auth found!');
          window.location.href = tenantConstants.GET_LOGIN_PATH(window?.location?.href);
        }
      }
    } else {
      tenantRoutes.redirectToAppPath(window.location.pathname);
    }
  }, []);

  return [show];
};
export default useProfolioSwitch;
