import { lazy } from 'react';

const Dashboard = lazy(() => import('./pages/dashboard/dashboard'));
const InboxPage = lazy(() => import('./pages/inbox-page/inbox-page'));
//const InboxPage = lazy(() => import('./pages/inbox/inbox-container'));
const PostListingPage = lazy(() => import('./pages/post-listing/post-listing'));
const PostListingNafazPage = lazy(() => import('./pages/post-listing/post-listing-nafaz'));
const ListingsPage = lazy(() => import('./pages/listings/listings'));
const AutoUtilizationPage = lazy(() => import('./pages/auto-utilisation/auto-utilisation'));
const PropShopPage = lazy(() => import('./pages/prop-shop/prop-shop'));
const MyOrdersPage = lazy(() => import('./pages/my-orders/my-orders'));
const ReportsSummaryPage = lazy(() => import('./pages/reports/reports-summary'));
const ListingReportsPage = lazy(() => import('./pages/reports/listing-reports'));
const LeadsReports = lazy(() => import('./pages/reports/leads-reports'));
const UserSettingsPage = lazy(() => import('./pages/user-settings/user-settings'));
const UserProfile = lazy(() => import('./pages/user-settings/profile'));
const AgencySettingsPage = lazy(() => import('./pages/agancy-settings/agancy-settings-page'));
const AgencyStaffPage = lazy(() => import('./pages/agancy-staff/agancy-staff'));
const UpgradeListingPage = lazy(() => import('./pages/post-listing/upgrade-listing'));
const QuotaCreditsPage = lazy(() => import('./pages/quota-credits/quota-credits'));
const ImageBankPage = lazy(() => import('./pages/image-bank/image-bank'));
const CheckoutPage = lazy(() => import('./pages/payment/checkout'));
const CreditsUsage = lazy(() => import('./pages/credits-usage/credits-usage'));
const creditCalculatorModal = lazy(() => import('./pages/credits-calculator/creditCalculator'));
const AgencyUserFormMobile = lazy(() => import('./pages/agancy-staff/agency-user-form-mobile'));
const InviteUserPage = lazy(() => import('./pages/invite-user/invite-user'));

export default {
  AgencyUserFormMobile,
  Dashboard,
  PostListingPage,
  PostListingNafazPage,
  UpgradeListingPage,
  ListingsPage,
  AutoUtilizationPage,
  ImageBankPage,
  AgencyStaffPage,
  QuotaCreditsPage,
  InboxPage,
  ReportsSummaryPage,
  ListingReportsPage,
  LeadsReports,
  UserSettingsPage,
  AgencySettingsPage,
  PropShopPage,
  MyOrdersPage,
  CheckoutPage,
  CreditsUsage,
  creditCalculatorModal,
  InviteUserPage,
  UserProfile,
};
