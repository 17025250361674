import tenantData from '@data';
import tenantUtils from '@utils';
import { NetworkService } from '../../../../services/networkService';

const dataMapper = (e) => {
  const productsHash = {};
  tenantData.products.forEach((e) => {
    productsHash[e.slug] = e;
  });

  const by_products = e?.consumption_summary?.map((item) => {
    return {
      ...item,
      icon: productsHash[item.slug]?.icon,
      iconColor: productsHash[item.slug]?.iconColor,
      used_credits: item.consume_credits,
    };
  });

  let endPoint = 0;
  // let lineChartData = {};
  let lineChartData = '90deg';
  const totalUsed = e?.used;
  // let totalUsed = 0;
  // e?.consumption_summary?.forEach(e => {
  //   totalUsed = e.consume_credits + totalUsed;
  // });
  let startPoint = 0;
  e?.consumption_summary?.forEach((e) => {
    endPoint = endPoint + (e.consume_credits / totalUsed) * 100;
    if (productsHash[e?.slug]) {
      // lineChartData[endPoint] = productsHash[e?.id]?.iconColor;
      lineChartData += ', ' + productsHash[e?.slug]?.iconColor + ' ' + startPoint + '% ' + endPoint + '%';
    }
    startPoint = endPoint;
  });
  lineChartData = `linear-gradient(${lineChartData})`;
  const totalUsedCredits = by_products.reduce((acc, item) => acc + item.used_credits, 0);

  const chartData = {
    datasets: [
      {
        data: by_products.map((item) => item.used_credits),
        backgroundColor: by_products.map((item) => item.iconColor || 'rgba(0, 0, 0, 0.2)'),
      },
    ],
    total: totalUsedCredits,
    labels: by_products.map((item) => tenantUtils.getLocalisedString(item, 'title')),
  };

  const res = {
    details: [
      { label: 'Available', value: e?.available },
      { label: 'Used', value: e?.used },
      { label: 'Total', value: e?.total },
    ],
    by_products: by_products,
    chartData: chartData,
    lineChartData: lineChartData,
  };
  return res;
};

export const getConsumptionSummaryData = async (platforms) => {
  const response = await NetworkService.get(`/api/credits/consumption_summary`);
  let responses = {};
  if (response) {
    platforms?.map((platform) => {
      if (response.error) {
        return response;
      } else if (response?.data?.credits_consumption_summary?.[platform?.slug]) {
        responses[platform?.slug] = dataMapper(response?.data?.credits_consumption_summary?.[platform?.slug]);
      } else {
      }
    });
  }
  return responses;
};
