export const fetchPlotsByQuery = async (parentId, query) => {
  const rs = await fetch(
    `https://www.zameen.com/api/plotFinder/parcel/?all=1&externalID=${parentId}${query ? `&query=${query}` : ''}`,
  );
  const response = await rs.json();
  if (response) {
    return {
      data: response,
    };
  } else {
    return { ...response };
  }
};
