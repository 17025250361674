import { NetworkService } from "../../../../services/networkService";
const fetchActiveLicenses = async id => {
  let response = await NetworkService.get(`/api/licenses/applicable_licenses`);
  if (response?.error) {
    return {
      error: response.error,
    };
  } else {
    return response;
  }
};
export default fetchActiveLicenses;
