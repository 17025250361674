const actions = {
  FILTER_LISTING_BEGIN: 'FILTER_LISTING_BEGIN',
  FILTER_LISTING_SUCCESS: 'FILTER_LISTING_SUCCESS',
  FILTER_LISTING_ERR: 'FILTER_LISTING_ERR',

  SORTING_LISTING_BEGIN: 'SORTING_LISTING_BEGIN',
  SORTING_LISTING_SUCCESS: 'SORTING_LISTING_SUCCESS',
  SORTING_LISTING_ERR: 'SORTING_LISTING_ERR',

  FETCH_LISTING_BEGIN: 'FETCH_LISTING_BEGIN',
  FETCH_LISTING_SUCCESS: 'FETCH_LISTING_SUCCESS',
  FETCH_LISTING_ERR: 'FETCH_LISTING_ERR',
  UPDATE_LIST: 'UPDATE_LIST',
  SET_UNIT: 'SET_UNIT',
  SET_LISTING_ID_FOR_BULK_REFRESH: 'SET_LISTING_ID_FOR_BULK_REFRESH',
  FETCH_DELETE_REASONS_BEGIN: 'FETCH_DELETE_REASONS_BEGIN',
  FETCH_DELETE_REASONS_SUCCESS: 'FETCH_DELETE_REASONS_SUCCESS',
  FETCH_DELETE_REASONS_FAILURE: 'FETCH_DELETE_REASONS_FAILURE',

  filterListingBegin: () => {
    return {
      type: actions.FILTER_LISTING_BEGIN,
    };
  },

  filterListingSuccess: data => {
    return {
      type: actions.FILTER_LISTING_SUCCESS,
      data,
    };
  },

  filterListingErr: err => {
    return {
      type: actions.FILTER_LISTING_ERR,
      err,
    };
  },

  sortingListingBegin: () => {
    return {
      type: actions.SORTING_LISTING_BEGIN,
    };
  },

  sortingListingSuccess: data => {
    return {
      type: actions.SORTING_LISTING_SUCCESS,
      data,
    };
  },

  sortingListingErr: err => {
    return {
      type: actions.SORTING_LISTING_ERR,
      err,
    };
  },

  fetchListingBegin: () => {
    return {
      type: actions.FETCH_LISTING_BEGIN,
    };
  },

  fetchListingSuccess: data => {
    return {
      type: actions.FETCH_LISTING_SUCCESS,
      data,
    };
  },

  updateList: data => {
    return {
      type: actions.UPDATE_LIST,
      data,
    };
  },

  fetchListingErr: err => {
    return {
      type: actions.FETCH_LISTING_ERR,
      err,
    };
  },

  setUnit: data => {
    return {
      type: actions.SET_UNIT,
      data,
    };
  },

  setListingId: data => {
    return {
      type: actions.SET_LISTING_ID_FOR_BULK_REFRESH,
      data,
    };
  },
};

export default actions;
