import tenantConstants from '@constants';
import tenantTheme from '@theme';
import tenantUtils from '@utils';

import moment from 'moment';
import store from '../../../../redux/store';
import { NetworkService } from '../../../../services/networkService';
import { getDateLabels, getVariousDates } from '../../../../utility/date';
import { getQueryString } from '../../../../utility/urlQuery';
import { getErrorAllResponse } from '../../../../utility/utility';

const limit = 10;

const getPageData = (pageNumber, last_page, data) => {
  let start_index = (pageNumber - 1) * limit;
  let end_index = pageNumber == last_page ? data?.length : pageNumber * limit;

  return data.slice(start_index, end_index);
};

const sumProductData = (productList, key) => {
  return productList
    .filter((productType) => productType.ad_product === key)
    .reduce((acc, productType) => {
      for (const [prop, value] of Object.entries(productType)) {
        if (prop !== 'ad_product' && prop !== 'date' && typeof value === 'number') {
          acc[prop] = (acc[prop] || 0) + value;
        } else if (prop === 'ad_product') {
          acc[prop] = key;
        } else if (prop === 'date') {
          acc[prop] = productType[prop];
        }
      }
      return acc;
    }, {});
};

const tableParserOlx = (responseData, params) => {
  const tableDataOlx = tenantUtils.reportsData['ksa'].leadTable();
  if (!responseData) {
    return { list: [], ...tableDataOlx };
  }
  const { items: grouped_stats, total } = responseData;
  if (total === 0) {
    const labels = getDateLabels(params?.start_date, params?.end_date);
    const list = labels.map((dateKey) => {
      return {
        key: `traffic-${dateKey}`,
        date: { value: dateKey },
        views: { value: 0 },
        clicks: { value: 0 },
        leads: { value: 0 },
        calls: { value: 0 },
        chats: { value: 0 },
        sms: { value: 0 },
        whatsapp: { value: 0 },
        email: { value: 0 },
      };
    });
    const paginate = {
      from: params?.page ? (params?.page - 1) * limit + 1 : 1,
      to: params?.page ? params?.page * limit : 10,
      current_page: parseInt(params.page ? params?.page : 1),
      per_page: limit,
      last_page: parseInt(list?.length / limit) + 1,
      total_pages: parseInt(list?.length / limit) + 1,
      total_records: list?.length,
    };

    return {
      list: getPageData(paginate?.current_page, paginate?.last_page, list.reverse()),
      pagination: tenantUtils.getPaginationObject(paginate),
      ...tableDataOlx,
    };
  }
  const dateKeys = Object.keys(grouped_stats);
  const list = dateKeys.map((dateKey) => {
    const {
      sum_chat_lead_count,
      sum_phone_view_count,
      sum_sms_view_count,
      sum_lead_count,
      sum_view_count,
      sum_search_count,
      sum_whatsapp_view_count,
      sum_email_lead_count,
    } = grouped_stats[dateKey];
    return {
      key: `traffic-${dateKey}`,
      date: { value: dateKey },
      views: { value: sum_search_count },
      clicks: { value: sum_view_count },
      leads: { value: sum_sms_view_count + sum_phone_view_count + sum_email_lead_count + sum_whatsapp_view_count || 0 },
      calls: { value: sum_phone_view_count || 0 },
      chats: { value: sum_chat_lead_count || 0 },
      sms: { value: sum_sms_view_count || 0 },
      whatsapp: { value: sum_whatsapp_view_count || 0 },
      emails: { value: sum_email_lead_count || 0 },
    };
  });
  const pagination = {
    from: params?.page ? (params?.page - 1) * limit + 1 : 1,
    to: params?.page ? params?.page * limit : 10,
    current_page: parseInt(params.page ? params?.page : 1),
    per_page: limit,
    last_page: parseInt(list?.length / limit) + 1,
    total_pages: parseInt(list?.length / limit) + 1,
    total_records: list?.length,
  };

  return {
    list: getPageData(pagination?.current_page, pagination?.last_page, list.reverse()),
    ...tableDataOlx,
    pagination: tenantUtils.getPaginationObject(pagination),
  };
};

const graphValueData = (data, dataKeysInitialData, tabList, statTabs) => {
  const result = {};
  tabList?.forEach((t) => {
    if (t?.key == 'leads') {
      let tabularData = {};
      statTabs.forEach((e) => {
        tabularData[e?.key] = { ...dataKeysInitialData };
      });
      data &&
        Object.values(data)?.forEach((value, index) => {
          Object.keys(tabularData)?.forEach((tabKey) => {
            Object.keys(tabularData[tabKey]).forEach((key) => {
              const obj = value.product_wise?.find((productType) => {
                return productType.ad_product === key;
              });
              const statType = statTabs.find((e) => e.key == tabKey);
              if (obj && key != 'all') {
                tabularData[tabKey][key] = [...(tabularData[tabKey][key] || []), obj?.[statType?.responseKey]];
              } else if (!obj && key != 'all') {
                tabularData[tabKey][key] = [...(tabularData[tabKey][key] || []), 0];
              } else if (key == 'all') {
                tabularData[tabKey]['all'] = [...(tabularData[tabKey]['all'] || []), value?.[statType?.responseKey]];
              }
            });
          });
        });
      result[t.key] = tabularData;
    } else {
      let tabularData = { ...dataKeysInitialData };
      data &&
        Object.values(data)?.forEach((value, index) => {
          Object.keys(tabularData)?.forEach((key) => {
            const obj = value.product_wise?.find((productType) => {
              return productType.ad_product === key;
            });
            if (obj && key != 'all') {
              tabularData[key] = [...(tabularData[key] || []), obj?.[t?.responseKey]];
            } else if (!obj && key != 'all') {
              tabularData[key] = [...(tabularData[key] || []), 0];
            } else {
              tabularData['all'] = [...(tabularData['all'] || []), value?.[t?.responseKey]];
            }
          });
        });
      result[t.key] = tabularData;
    }
  });
  return result;
};

const fillMissingDates = (apiResponse, start_date, end_date) => {
  start_date = new Date(start_date);
  end_date = new Date(end_date);

  let filledEntries = {};

  let currentDate = new Date(start_date);
  while (currentDate <= end_date) {
    let formattedDate = currentDate.toISOString().slice(0, 10);

    if (apiResponse.hasOwnProperty(formattedDate)) {
      filledEntries[formattedDate] = apiResponse[formattedDate];
    } else {
      filledEntries[formattedDate] = {
        product_wise: [],
        sum_chat_lead_count: 0,
        sum_chat_view_count: 0,
        sum_confirmed_sms: 0,
        sum_confirmed_whatsapp: 0,
        sum_email_lead_count: 0,
        sum_email_view_count: 0,
        sum_lead_count: 0,
        sum_phone_lead: 0,
        sum_phone_view_count: 0,
        sum_search_count: 0,
        sum_sms_lead_count: 0,
        sum_sms_view_count: 0,
        sum_view_count: 0,
        sum_whatsapp_lead_count: 0,
        sum_whatsapp_view_count: 0,
      };
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return filledEntries;
};

const getAreaGraphData = (data, paramsObj, statTabs) => {
  const { start_date, end_date, purpose } = paramsObj;
  const user = store.getState().loginUser.user;
  const { purposeTabs, productTabs: tabs, performanceBy } = tenantUtils.reportsData['ksa'];
  const productTabs = tabs(user?.isCurrencyUser);
  const items = Object.keys(data?.stats?.items)?.length
    ? fillMissingDates(data?.stats?.items, start_date, end_date)
    : [];

  const dataKeysInitialData = { all: [] };
  productTabs.forEach((t) => {
    dataKeysInitialData[t.key] = [];
  });
  const finalProductTabs = [
    {
      key: 'all',
      value: 'all',
      label: 'All',
      backgroundColor: tenantTheme['primary-light'],
      borderColor: tenantTheme['primary-color'],
    },
    ...productTabs,
  ];
  const graphData = graphValueData(items, dataKeysInitialData, performanceBy, statTabs);
  const parsedData = {
    labels: Object.keys(items)?.map((d) => moment(d)?.locale('en')?.format('MMM DD')),
    purposes: purposeTabs,
    types: finalProductTabs,
    performanceBy: performanceBy,
    data: graphData,
  };
  return parsedData;
};

const parseStatsData = (data, productStats, paramsObj) => {
  const { aggregates, trends } = data?.data?.stats || {};
  const data_summary = [
    {
      key: 'leads',
      responseKey: 'sum_lead_count',
      // TODO: need value from backend
      value: aggregates?.sum_lead_count,
      percentage: trends?.sum_lead_count,
      label: 'All Leads',
      title: 'All Leads',
      hit: true,
      id: 0,
      growth: trends?.sum_lead_count > 0,
      since_when: '',
    },
    {
      key: 'calls',
      responseKey: 'sum_phone_view_count',
      value: aggregates?.sum_phone_view_count,
      percentage: trends?.sum_phone_view_count,
      label: 'Calls',
      title: 'Calls',
      icon: 'MdPhone',
      iconProps: {
        hasBackground: false,
        size: '1.1em',
        color: tenantTheme.gray600,
        style: { marginBlockStart: 4 },
      },
      hit: true,
      id: 0,
      growth: trends?.sum_phone_view_count > 0,
      since_when: '',
      inline: true,
    },
    {
      key: 'whatsapp',
      responseKey: 'sum_whatsapp_view_count',
      icon: 'RiWhatsappLine',
      iconProps: {
        hasBackground: false,
        size: '1.1em',
        color: tenantTheme.gray600,
        style: { marginBlockStart: 4 },
      },
      value: aggregates?.sum_whatsapp_view_count,
      percentage: trends?.sum_whatsapp_view_count,
      label: 'WhatsApp',
      title: 'WhatsApp',
      hit: true,
      id: 0,
      growth: trends?.sum_whatsapp_view_count > 0,
      since_when: '',
      inline: true,
    },
    {
      key: 'sms',
      responseKey: 'sum_sms_view_count',
      value: aggregates?.sum_sms_view_count,
      percentage: trends?.sum_sms_view_count,
      label: 'SMS',
      title: 'SMS',
      icon: 'MdSms',
      iconProps: {
        hasBackground: false,
        size: '1.1em',
        color: tenantTheme.gray600,
        style: { marginBlockStart: 4 },
      },
      hit: true,
      id: 0,
      growth: trends?.sum_sms_view_count > 0,
      since_when: '',
      inline: true,
    },
    {
      key: 'emails',
      responseKey: 'sum_email_lead_count',
      value: aggregates?.sum_email_lead_count,
      percentage: trends?.sum_email_lead_count,
      label: 'Emails',
      title: 'Emails',
      icon: 'MdEmail',
      iconProps: {
        hasBackground: false,
        size: '1.1em',
        color: tenantTheme.gray600,
        style: { marginBlockStart: 4 },
      },
      hit: true,
      id: 0,
      growth: trends?.sum_email_lead_count > 0,
      since_when: '',
      inline: true,
    },
  ];
  const graphData = getAreaGraphData(productStats.data, paramsObj, data_summary);

  const parsedData = {
    id: 'ksa',
    slug: 'ksa',
    icon: 'IconBayut',
    title: 'Performance',
    reach_data: [
      {
        key: 'views',
        value: aggregates?.sum_search_count,
        percentage: trends?.sum_search_count,
        label: 'Views',
        title: 'Views',
        icon: 'IoMdEye',
        iconProps: { color: '#28b16d' },
        hit: true,
        id: 0,
        growth: trends?.sum_search_count > 0,
        since_when: '',
        lead: true,
      },
      {
        key: 'clicks',
        value: aggregates?.sum_view_count,
        percentage: trends?.sum_view_count,
        label: 'Clicks',
        title: 'Clicks',
        icon: 'HiCursorClick',
        iconProps: { color: '#f0a742' },
        hit: true,
        id: 0,
        growth: trends?.sum_view_count > 0,
        since_when: '',
        lead: true,
      },
      {
        key: 'leads',
        value: aggregates?.sum_lead_count,
        percentage: trends?.sum_lead_count,
        label: 'Leads',
        title: 'Leads',
        icon: 'MdPhone',
        iconProps: { color: '#479eeb' },
        hit: true,
        id: 0,
        growth: trends?.sum_lead_count > 0,
        since_when: '',
        lead: true,
      },
    ],
    data_summary: data_summary,
    data_breakdown: graphData,
  };
  return parsedData;
};

export const leadsSummaryApi = async (paramObj, parseFor, platformKey, user) => {
  const params = paramObj ? paramObj : tenantUtils.reportsData[platformKey].initialFilter.lead;
  const { user_id: userId, page, date_between = getVariousDates(30), stat: include = 'table_view' } = params;
  const { purpose } = params;

  const paramsObj = {
    start_date: typeof date_between === 'string' ? date_between?.split(',')?.[0] : date_between?.[0],
    end_date: typeof date_between === 'string' ? date_between?.split(',')?.[1] : date_between?.[1],
    ...(page && { page: page }),
  };

  if (parseFor === 'table') {
    const tableData = await NetworkService.get(
      `/api/ovation/stats/product_stats?${getQueryString(paramsObj)}&${
        !purpose || purpose == 'all'
          ? `category_ids[]=1&category_ids[]=2`
          : `category_ids[]=${purpose == 'sale' ? 1 : purpose == 'rent' ? 2 : purpose == 'dailyrental' ? 4 : purpose}`
      }&${
        user?.is_agency_admin
          ? `agency_external_ids[]=${user?.agency?.id}`
          : `user_external_ids[]=${tenantConstants.KC_ENABLED ? user?.external_id : userId ? userId : user?.id}`
      }`,
    );
    if (tableData) {
      if (tableData?.error) {
        return tableData;
      } else {
        return tableParserOlx(tableData?.data?.stats, { ...paramsObj, page: params.page });
      }
    }
  } else {
    const widgetData = [
      NetworkService.get(
        `/api/ovation/stats/trends?${getQueryString(paramsObj)}&${
          !purpose || purpose === 'all'
            ? `category_ids[]=1&category_ids[]=2`
            : `category_ids[]=${purpose == 'sale' ? 1 : purpose == 'rent' ? 2 : purpose == 'dailyrental' ? 4 : purpose}`
        }&${
          user?.is_agency_admin
            ? `agency_external_ids[]=${user?.agency?.id}`
            : `user_external_ids[]=${tenantConstants.KC_ENABLED ? user?.external_id : userId ? userId : user?.id}`
        }`,
      ),
      NetworkService.get(
        `/api/ovation/stats/product_stats?${getQueryString(paramsObj)}&${
          !purpose || purpose === 'all'
            ? `category_ids[]=1&category_ids[]=2`
            : `category_ids[]=${purpose == 'sale' ? 1 : purpose == 'rent' ? 2 : purpose == 'dailyrental' ? 4 : purpose}`
        }&${
          user?.is_agency_admin
            ? `agency_external_ids[]=${user?.agency?.id}`
            : `user_external_ids[]=${tenantConstants.KC_ENABLED ? user?.external_id : userId ? userId : user?.id}`
        }`,
      ),
    ];

    const widgetDataResponse = await Promise.all(widgetData);
    let formattedData;
    const widgetDataError = getErrorAllResponse(widgetDataResponse);
    if (widgetDataError) {
      return { error: widgetDataError };
    }
    if (widgetDataResponse[0] && widgetDataResponse[1]) {
      formattedData = parseStatsData(widgetDataResponse[0], widgetDataResponse[1], { ...paramsObj, purpose });
    }
    return formattedData;
  }
};
