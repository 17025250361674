import tenantData from '@data';
import tenantUtils from '@utils';
import { t } from 'i18next';
import { TIME_DATE_FORMAT } from '../../../../constants/formats';
import { NetworkService } from '../../../../services/networkService';
import { getQueryString } from '../../../../utility/urlQuery';

const statusMapper = (e) => {
  switch (e?.status) {
    case 'completed':
    case 'Completed':
      return {
        color: 'green',
        label: 'Completed',
        slug: e?.status,
      };
    case 'failed':
    case 'Failed':
      return {
        color: 'red',
        label: t('Failed'),
        slug: e?.status,
      };
    case 'Pending':
    case 'pending':
      return {
        color: 'warning',
        label: t('Pending'),
        slug: e?.status,
      };
    default:
      return {};
  }
};

const getActionsList = (e) => {
  switch (e?.status) {
    case 'failed':
    case 'Failed':
    case 'Pending':
    case 'pending':
      return {
        label: t('Retry'),
        color: 'green',
        type: 'button',
        link: `/checkout?order_id=${e.id}`,
        id: e?.id,
      };
    default:
      return '';
  }
};
const producstMapper = (e) => {
  let orderedProducts = [];
  e.forEach((item) => {
    const index = orderedProducts.findIndex((it) => it?.id === item?.product?.product_id);
    if (index === -1) {
      orderedProducts.push({
        ...tenantData.products?.find((e) => e?.id === item?.product?.product_id),
        id: item?.product?.product_id,
        quantity: item?.quantity,
        name: item?.product?.product_title,
      });
    } else {
      orderedProducts[index].quantity += item?.quantity;
    }
  });
  return orderedProducts;
};

const ordersDataMapper = (e) => {
  return {
    ...e,
    orderId: e?.id,
    products: { products: producstMapper(e?.order_details) },
    date: {value :e?.order_date, format: TIME_DATE_FORMAT},
    price: { value: e?.total, currency: 'PKR' },
    actions: { actions: [getActionsList(e)] },
    status: { state: statusMapper(e) },
  };
};

export const getMyOrders = async (userId, params) => {
  const response = await NetworkService.get(`/api/users/${userId}/orders?${getQueryString(params)}`);
  const ordersData = response?.data;
  if (response) {
    if (response.error) {
      return response;
    } else {
      return {
        pagination: tenantUtils.getPaginationObject(ordersData.pagination),
        list: ordersData?.orders.map((item) => ordersDataMapper(item)),
        table: [
          {
            title: 'Order ID',
            dataIndex: 'orderId',
            key: 'orderId',
            component: 'String',
          },
          {
            title: 'Products',
            dataIndex: 'products',
            key: 'products',
            component: 'Products',
          },
          {
            title: 'Date & Time',
            dataIndex: 'date',
            key: 'date',
            component: 'Date',
          },
          {
            title: 'Order Status',
            dataIndex: 'status',
            key: 'status',
            component: 'OrderStatus',
          },
          {
            title: 'Price (PKR)',
            dataIndex: 'price',
            key: 'price',
            component: 'Price',
          },
          {
            title: 'Action',
            dataIndex: 'actions',
            key: 'actions',
            component: 'OrderActions',
          },
        ],
      };
    }
  }
};
