export const imageStateObject = (state = 'uploaded') => {
  const imageState = {
    uploaded: true,
    uploading: false,
    inError: false,
  };
  switch (state) {
    case 'uploaded':
      return imageState;
    case 'uploading':
      return { uploaded: false, uploading: true, inError: false };
    case 'inError':
      return { uploaded: false, uploading: false, inError: true };
    case 'select':
      return { ...imageState, selected: false };
  }
};
