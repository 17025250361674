import { checkRequiredKeys } from '../../tenant/checkRequiredKeys';
import { TENANT_KEY } from '../../utility/env';
import BayutTheme, { BAYUT_KEY } from './bayut';
import ZameenTheme, { ZAMEEN_KEY } from './zameen';

const configKeys = [];

export const tenants = Object.freeze({
  [ZAMEEN_KEY]: checkRequiredKeys(ZameenTheme, configKeys),
  [BAYUT_KEY]: checkRequiredKeys(BayutTheme, configKeys),
});

export default tenants[TENANT_KEY];
