import tenantApi from '@api';
import tenantConstants from '@constants';
import TenantComponents from '../../../../tenant/components';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Card, Group, Switch, notification } from '../../../../components/common';
import { ActionButton } from '../../../../container/pages/user-settings/style';
import { fetchUserDetail, updateUserSetting } from '../../../../redux/user/actionCreator';

const UserPreference = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.loginUser.user);
  const { settings } = useSelector((state) => state.loginUser.user);
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const [loading, setLoading] = useState(false);
  const [isAutoUtilisationEnabled, setIsAutoUtilisationEnabled] = useState(!!user?.is_auto_utilization_enabled);
  const [smartCreditsModalVisible, setSmartCreditsTopUpModalVisible] = useState(false);
  const dispatch = useDispatch();

  const defaultPreferences = {
    area_unit: 'marla',
    automated_reports: false,
    currency: tenantConstants.currency,
    email_notification: false,
    newsletter: false,
  };

  useEffect(() => {
    const preferences = defaultPreferences;
    if (Object?.keys(settings)?.length) {
      settings?.forEach((e) => (preferences[e?.name] = e?.value == '0' ? false : e?.value == '1' ? true : e?.value));
    }
    formik.setValues(preferences);
  }, [settings]);

  const postUserSetting = async (values, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    setLoading(true);
    const res = await tenantApi.updateUserPreferenceDetail(user?.id, values);
    if (res) {
      setLoading(false);
      setSubmitting(false);
      if (res.error) {
        setStatus(res.error);
      } else {
        dispatch(updateUserSetting(values));
        notification.success(res.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: defaultPreferences,
    onSubmit: postUserSetting,
  });

  const onToggleSmartCredit = async () => {
    setLoading(true);
    const response = await tenantApi.toggleSmartCreditsUtilisation(user?.id);
    if (response) {
      setLoading(false);
      if (response.error) {
        notification.error(response.error);
      } else {
        notification.success(response?.data?.message);
        setIsAutoUtilisationEnabled(!isAutoUtilisationEnabled);
        dispatch(fetchUserDetail(user?.id));
      }
    }
  };
  return (
    <form>
      <Card bodyStyle={{ padding: isMobile ? 16 : 40 }}>
        <Group>
          <Switch
            rowWrap={false}
            label={t('Smart Credit Utilization')}
            labelSuffix={
              <TenantComponents.SmartCreditsUtilizationModal
                visible={smartCreditsModalVisible}
                setVisible={setSmartCreditsTopUpModalVisible}
                btnType={'info'}
                readOnly
              />
            }
            desc={t(
              'Turn on this to ensure that your expiring credits are smartly utilized to boost your property listings',
            )}
            value={isAutoUtilisationEnabled}
            onChange={onToggleSmartCredit}
            disabled={user?.user_role_within_agency == 'staff'}
            loading={loading}
          />
          <Alert message={formik.status} />
          {/* <ActionButton>
            <Button
              type="primary"
              size="large"
              onClick={formik?.handleSubmit}
              disabled={formik?.isSubmitting || loading}
              style={{ paddingInline: 64 }}
            >
              {t('Save Changes')}
            </Button>
          </ActionButton> */}
        </Group>
      </Card>
    </form>
  );
};

export default UserPreference;
