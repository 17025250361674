import { useDispatch, useSelector } from 'react-redux';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListingContainer } from '../../../components/listing-container/ListingContainer';
import { notification } from '../../../components/common';
import { setListingPerformanceTableData } from '../../../redux/listingPerformance/actionCreator';

function ListingPerformance(props) {
  const { user } = useSelector((state) => state.loginUser);
  const listingPerformance = useSelector((state) => state.ListingPerformance);
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [previousState, setPreviouseState] = useState(listingPerformance);
  const dispatch = useDispatch();

  useEffect(() => {
    if (listingPerformance?.list?.[0]?.platforms?.data?.[1]?.statsError) {
      notification.error(t(listingPerformance?.list?.[0]?.platforms?.data?.[1]?.statsError));
    }
    if (!listingPerformance?.loading) {
      setPreviouseState(listingPerformance);
    }
  }, [listingPerformance]);

  const fetchTableData = (params) => {
    user.id && dispatch(setListingPerformanceTableData(user.id, { page: params?.page }, previousState));
  };

  return (
    <ListingContainer
      listingsData={listingPerformance}
      listingApi={(params) => {
        fetchTableData(params);
      }}
      loading={listingPerformance?.loading}
      error={listingPerformance?.error}
      onRetry={(params) => {
        fetchTableData(params);
      }}
      pageSize={listingPerformance?.pagination?.pageCount}
      showPagination
      noUrlPush={true}
      renderFiltersAsTopRight
      {...props}
    />
  );
}

export default ListingPerformance;
