const actions = {
  CART_DATA_BEGIN: 'CART_DATA_BEGIN',
  CART_DATA_SUCCESS: 'CART_DATA_SUCCESS',
  CART_DATA_ERR: 'CART_DATA_ERR',

  ACTIVE_PRODUCTS_BEGIN: 'ACTIVE_PRODUCTS_BEGIN',
  ACTIVE_PRODUCTS_SUCCESS: 'ACTIVE_PRODUCTS_SUCCESS',
  ACTIVE_PRODUCTS_ERR: 'ACTIVE_PRODUCTS_ERR',

  CART_UPDATE_BEGIN: 'CART_UPDATE_BEGIN',
  CART_UPDATE_SUCCESS: 'CART_UPDATE_SUCCESS',
  CART_UPDATE_ERR: 'CART_UPDATE_ERR',

  CART_DELETE_BEGIN: 'CART_DELETE_BEGIN',
  CART_DELETE_SUCCESS: 'CART_DELETE_SUCCESS',
  CART_DELETE_ERR: 'CART_DELETE_ERR',

  CART_ADD_BEGIN: 'CART_ADD_BEGIN',
  CART_ADD_SUCCESS: 'CART_ADD_SUCCESS',
  CART_ADD_ERR: 'CART_ADD_ERR',

  CART_DISABLE_CHECKOUT: 'CART_DISABLE_CHECKOUT',
  CART_EMPTY: 'CART_EMPTY',

  cartDataBegin: () => {
    return {
      type: actions.CART_DATA_BEGIN,
    };
  },

  cartDataSuccess: data => {
    return {
      type: actions.CART_DATA_SUCCESS,
      data,
    };
  },

  cartDataErr: err => {
    return {
      type: actions.CART_DATA_ERR,
      err,
    };
  },

  activeProductsBegin: () => {
    return {
      type: actions.ACTIVE_PRODUCTS_BEGIN,
    };
  },

  activeProductsSuccess: data => {
    return {
      type: actions.ACTIVE_PRODUCTS_SUCCESS,
      data,
    };
  },

  activeProductsErr: err => {
    return {
      type: actions.ACTIVE_PRODUCTS_ERR,
      err,
    };
  },

  cartUpdateBegin: () => {
    return {
      type: actions.CART_UPDATE_BEGIN,
    };
  },

  cartUpdateSuccess: data => {
    return {
      type: actions.CART_UPDATE_SUCCESS,
      data,
    };
  },

  cartUpdateErr: err => {
    return {
      type: actions.CART_UPDATE_ERR,
      err,
    };
  },

  cartEmpty: () => {
    return {
      type: actions.CART_EMPTY,
    };
  },

  cartDeleteBegin: () => {
    return {
      type: actions.CART_DELETE_BEGIN,
    };
  },

  cartDeleteSuccess: data => {
    return {
      type: actions.CART_DELETE_SUCCESS,
      data,
    };
  },

  cartDeleteErr: err => {
    return {
      type: actions.CART_DELETE_ERR,
      err,
    };
  },

  cartAddBegin: () => {
    return {
      type: actions.CART_ADD_BEGIN,
    };
  },

  cartAddSuccess: data => {
    return {
      type: actions.CART_ADD_SUCCESS,
      data,
    };
  },

  cartAddErr: err => {
    return {
      type: actions.CART_ADD_ERR,
      err,
    };
  },

  cartDisableCheckout: data => {
    return {
      type: actions.CART_DISABLE_CHECKOUT,
      data,
    };
  },
};

export default actions;
