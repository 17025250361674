import tenantTheme from '@theme';
import { Divider, Typography } from 'antd';
import cx from 'clsx';
import React from 'react';
import { Button, Card, Flex, Heading, Icon, TextInput } from '../../../../components/common';
import { IconStyled } from '../../../../components/common/icon/IconStyled';
import { getClassifiedBaseURL } from '../../../../utility/env';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
const { Text } = Typography;

const NationalCRForm = ({ formik, advertiserId, loading }) => {
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const { t } = useTranslation();
  const locale = useSelector((state) => state.AppConfig.locale);
  return (
    <Card bodyStyle={{ padding: isMobile ? 16 : 40 }}>
      <Flex
        className="w-100 m-auto"
        vertical
        align="center"
        justify={!isMobile && 'center'}
        style={{ minHeight: '410px', maxWidth: '560px' }}
      >
        <div className={cx(isMobile ? 'mb-20' : 'mb-32', 'text-center')}>
          <IconStyled
            className={cx(isMobile ? 'mb-20' : 'mb-24', 'm-auto')}
            style={{
              '--icon-styled-width': isMobile ? '94px' : '124px',
              background: tenantTheme['primary-color-1'],
            }}
          >
            <Icon
              icon={advertiserId == 'nationalID' ? 'IDCardIcon' : 'BuildingIcon'}
              size={isMobile ? '54px' : '70px'}
            />
          </IconStyled>
          <Heading as={isMobile ? 'h6' : 'h2'} className={isMobile ? 'mb-0' : 'mb-8'} style={{ fontWeight: '700' }}>
            {advertiserId == 'nationalID'
              ? t('Provide Your National ID Number')
              : t('Provide Your Commercial Registry Number')}
          </Heading>
          <Text className={isMobile ? 'fz-12' : 'fz-16'} type="secondary">
            {advertiserId == 'nationalID'
              ? t('Your ad license will be verified against the provided ID.')
              : t('Your ad license will be verified against the provided CR Number')}
          </Text>
        </div>
        <Flex className="mb-24 w-100" vertical={isMobile && true} gap="20px">
          <TextInput
            key="advertiserId"
            name="advertiserId"
            value={formik?.values?.advertiserId}
            type="input"
            onChange={(e) => formik.setFieldValue('advertiserId', e?.target?.value)}
            onBlur={() => formik.setFieldTouched('advertiserId', true)}
            errorMsg={
              formik?.errors['advertiserId'] && formik?.touched['advertiserId'] && formik?.errors['advertiserId']
            }
            placeholder={advertiserId == 'national' ? 'e.g 1100000000' : 'e.g 7010000000'}
            maxLength={10}
            className="w-100"
          />
        </Flex>

        <Button
          className="w-100"
          onClick={() => {
            formik?.handleSubmit();
          }}
          loading={loading}
          block
          type={'primary'}
          size={'large'}
        >
          {t('Continue')}
        </Button>
        {advertiserId == 'crID' && (
          <>
            <Divider
              style={{ fontSize: 14, color: tenantTheme['gray600'], '--ant-color-split': tenantTheme['gray400'] }}
            >
              {t('OR')}
            </Divider>
            <Button
              style={{ padding: 0, height: 0, '--btn-padding-y': 0 }}
              size={isMobile ? 'small' : 'large'}
              type={'link'}
              href={`${getClassifiedBaseURL()}/${locale}/verification/rega/?redirectPath=${window.location.href}`}
            >
              {t('Permanently Skip this step by verifying your FAL License')}
            </Button>
          </>
        )}
      </Flex>
    </Card>
  );
};
export default NationalCRForm;
