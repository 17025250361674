import { isProduction } from '../../../utility/env';

const GET_LOGIN_PATH = (redirectedUrl) => {
  return appConstants.HAVE_LOGIN_SCREEN
    ? `${window.location.origin}/auth/signin`
    : `https://zameen.com/login.html?r=${redirectedUrl ? redirectedUrl : 'https://profolio.zameen.com'}`;
};

const APP_LOGO = {
  title: 'Zameen',
  icon: { rtl: '', ltr: 'IconZameenMobileApp' },
  linkIos: {
    en: 'https://apps.apple.com/us/app/zameen-no-1-property-portal/id903880271?utm_source=profolio&utm_medium=banner&utm_campaign=app_adoption',
  },
  linkPlayStore: {
    en: 'https://play.google.com/store/apps/details?id=com.zameen.zameenapp&hl=en&utm_source=profolio&utm_medium=banner&utm_campaign=app_adoption&hl=en',
  },
};

const languages = [{ key: 'en', label: 'EN', rtl: false, darkMode: false }];


const appConstants = Object.freeze({
  title: 'Zameen',
  locale: 'en-PK',
  country_code: 'PK',
  currency: 'PKR',
  timezone: 'Asia/Karachi',
  languages,
  KC_ENABLED: false,
  SHOW_ACCOUNT_MANAGER: false,
  IS_CREDIT_CAPPING_ENABLED: false,
  APP_SOURCE: 'profolio2',
  HAS_MEMBER_AREA: false,
  AUTH_TOKEN_COOKIE_KEY: isProduction ? 'zn_cd' : 'access-token',
  GET_LOGIN_PATH,
  HAVE_LOGIN_SCREEN: !isProduction,
  CONVERT_TO_AGENCY: false,
  SHOW_LISTING_DETAIL: false,
  SHOW_REGA_DETAIL: false,
  LOCATION_LEVELS: { city: 3 },
  SHOW_SEARCH_HEADER: true,
  LINK_TITLE: 'zameen.com',
  APP_LOGO,
  NUMBER_SYSTEM: 'indian',
  ZENDESK: true,
});

export default appConstants;
