import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PERMISSIONS_TYPE } from '../../../constants/permissions';
import { addPropertyEvent, pageViewPackageScreenEvent, pageViewInboxEvent } from '../../../services/analyticsService';

const menus = (user, t) => {
  return [
    {
      key: 'dashboard',
      icon: 'SideMenuDashboard',
      title: t('Dashboard'),
      path: '/dashboard',
    },
    {
      key: 'post-listing',
      icon: 'SideMenuPostList',
      title: t('Post Listing'),
      path: '/post-listing',
      onClick: () => addPropertyEvent(user, null, true),
    },
    {
      key: 'all-listings',
      icon: 'SideMenuProperty',
      title: t('My Listings'),
      path: '/listings',
    },
    ...(user && user.isCurrencyUser
      ? [
          {
            key: 'credits_usage',
            icon: 'SideMenuQuota',
            title: t('Credits Usage'),
            path: '/credits-usage',
          },
        ]
      : []),
    {
      key: 'inbox',
      icon: 'SideMenuInbox',
      title: t('Inbox'),
      path: '/inbox/received',
      basePath: '/inbox',
      onClick: () => pageViewInboxEvent(user),
    },
    ...(user && user.isCurrencyUser
      ? [
          {
            key: 'reports',
            icon: 'SideMenuReports',
            title: t('Reports'),
            isPremiumUserPage: true,
            list: [
              {
                title: t('Summary'),
                path: '/reports/summary',
                key: 'summary',
                isPremiumUserPage: true,
              },
              {
                title: t('Listing Report'),
                path: '/reports/listing-report',
                key: 'listing-reports',
                isPremiumUserPage: true,
              },
              {
                title: t('Leads & Reach Report'),
                path: '/reports/leads-reports',
                key: 'leads-reports',
                isPremiumUserPage: true,
              },
            ],
          },
        ]
      : []),
    {
      key: 'agency-staff',
      icon: 'SideMenuAgency',
      title: t('Agency Staff'),
      path: '/agency-staff',
      permission: PERMISSIONS_TYPE.STAFF,
      isAgencyPage: true,
    },
    {
      key: 'settings',
      icon: 'IoSettingsOutline',
      title: t('Settings'),
      path: '/user-settings/user-profile',
      basePath: '/user-settings',
    },
    ...(user && user.isCurrencyUser
      ? [
          {
            key: 'prop-shop',
            icon: 'SideMenuPropShop',
            path: '/packages',
            title: t('Credits & Packages'),
            onClick: () => pageViewPackageScreenEvent(user),
          },
        ]
      : []),
  ];
};

export const menuList = (permissions, isAgency, isPremiumUser) => {
  const { user } = useSelector((state) => state.loginUser);
  const { t } = useTranslation();
  let newMenuList = menus(user, t);
  const sList = [];
  const filterItems = (e, list) => {
    if (!isAgency && e.isAgencyPage) {
    } else if (!isPremiumUser && e.isPremiumUserPage) {
    } else if (permissions && e.permission) {
      permissions[e.permission] && list.push(e);
    } else if (e.list) {
      const subList = [];
      e.list.forEach((e) => filterItems(e, subList));
      e.list = subList;
      list.push(e);
    } else {
      list.push(e);
    }
  };
  newMenuList.forEach((e) => filterItems(e, sList));

  return sList;
};
