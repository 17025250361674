import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LinkStyled = styled(Link)`
  align-items: center;
  display: inline-flex;
  gap: 4px;
  line-height: 1;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }

  .anticon {
    &,
    svg {
      vertical-align: middle;
    }
  }
`;
