import tenantData from '@data';

export const makePriceForPackage = (platform, product) => {
  const dependents = tenantData.productDependents?.[platform]?.[product?.key]?.split(',');
  let dependentsTotalPrice = 0;
  dependents?.forEach((dep) => {
    dependentsTotalPrice =
      parseInt(tenantData.quotaCreditProducts?.find((e) => e?.slug == dep)?.price) + dependentsTotalPrice;
  });
  const totalPackagePrice = dependentsTotalPrice
    ? dependentsTotalPrice + parseInt(product?.product_price)
    : parseInt(product?.product_price);
  return totalPackagePrice;
};
