import tenantApi from '@api';
import tenantUtils from '@utils';
import { getErrorString } from '../../utility/utility';
const setLeadsWidgetData = (params, platformKey, initalize, user) => async (dispatch) => {
  dispatch({
    type: 'SET_LEADS_WIDGET_DATA',
    payload: {
      platformKey,
      data: {
        ...(initalize && {
          data: null,
          filterObj: tenantUtils.reportsData[platformKey].initialFilter.lead,
        }),
        error: null,
        loading: true,
      },
    },
  });
  const response = await tenantApi.leadsSummaryApi(params, 'widget', platformKey, user);

  if (response?.error) {
    dispatch({
      type: 'SET_LEADS_WIDGET_DATA',
      payload: {
        platformKey,
        data: {
          error: getErrorString(response.error),
          loading: false,
        },
      },
    });
  } else {
    dispatch({
      type: 'SET_LEADS_WIDGET_DATA',
      payload: {
        platformKey,
        data: {
          data: response,
          error: null,
          loading: false,
        },
      },
    });
  }
};
const setEmailLeadsData = (user, page) => async (dispatch) => {
  dispatch({ type: 'SET_EMAIL_LEADS_DATA', payload: { loading: true } });
  const response = await tenantApi.getMailLeads(user, page);
  if (response) {
    if (response.error) {
      dispatch({ type: 'SET_EMAIL_LEADS_DATA', payload: { error: response.error, loading: false } });
    } else {
      dispatch({ type: 'SET_EMAIL_LEADS_DATA', payload: { ...response, error: null, loading: false } });
    }
  }
};
const setLeadsTableData = (params, platformKey, user) => async (dispatch) => {
  dispatch({
    type: 'SET_LEADS_TABLE_DATA',
    payload: {
      loading: true,
    },
  });
  const response = await tenantApi.leadsSummaryApi(params, 'table', platformKey, user);

  if (response.error) {
    dispatch({
      type: 'SET_LEADS_TABLE_DATA',
      payload: {
        error: getErrorString(response.error),
      },
    });
  } else {
    dispatch({
      type: 'SET_LEADS_TABLE_DATA',
      payload: {
        ...response,
        error: null,
      },
    });
  }
};

const setLeadsWidgetDateRange = (dateFilter) => async (dispatch) => {
  dispatch({
    type: 'SET_LEADS_WIDGET_FILTER',
    payload: dateFilter,
  });
};

const setLeadsPlatformFilter = (filterObj, platformKey) => async (dispatch) => {
  dispatch({
    type: 'SET_LEADS_PLATFORM_FILTER',
    payload: {
      platformKey,
      data: {
        ...filterObj,
      },
    },
  });
};

export { setEmailLeadsData, setLeadsPlatformFilter, setLeadsTableData, setLeadsWidgetData, setLeadsWidgetDateRange };
