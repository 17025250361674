import tenantUtils from '@utils';
import { NetworkService } from '../../../../services/networkService';
import { getErrorString } from '../../../../utility/utility';

const updateCart = async (userId, cartId, productList, isCartEmpty, updateRedux = true) => {
  try {
    const response = isCartEmpty
      ? await NetworkService.put(`/api/carts/update`, {
          cart: { cart_details_attributes: productList },
        })
      : await NetworkService.put(`/api/carts/update`, {
          cart: { cart_details_attributes: productList },
        });
    const cartDetail = response.data?.cart;
    if (response.error) {
      return {
        error: response.error,
      };
    } else if (updateRedux) {
      return { cartProducts: tenantUtils.cartProductList(cartDetail?.cart_details), cartId: cartDetail.id };
    }
  } catch (e) {
    return {
      error: getErrorString(e),
    };
  }
};

export default updateCart;
