import tenantApi from '@api';
import tenantConstants from '@constants';
import tenantData from '@data';
import tenantTheme from '@theme';
import tenantUtils from '@utils';
import { Divider, Row, Space, Typography } from 'antd';
import { t } from 'i18next';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TenantComponents from '../..';
import {
  Flex,
  Group,
  Image,
  Label,
  Modal,
  Number,
  ProductTag,
  Skeleton,
  Switch,
  Tag,
  TextWithIcon,
  notification,
} from '../../../../components/common';
import Statistic from '../../../../components/common/statistic';
import ListingDrawer from '../../../../components/listing-drawer/listingDrawer';
import { ListingCardStyled, SwitchWrapped, TenantList, Thumbnail } from '../../../../components/styled';
import { useGetLocation } from '../../../../hooks';
import { updateListForDashboard } from '../../../../redux/dashboard/actionCreators';
import { updateList } from '../../../../redux/listings/actionCreator';
import { getTimeDateString } from '../../../../utility/date';
import { getBaseURL } from '../../../../utility/env';
import { HealthContainer, Stats } from './styled';

const { Text } = Typography;

const getStatsData = (data = {}) => {
  const { views, clicks, leads } = data;
  return [
    { title: 'Views', value: views, icon: 'IoMdEye', iconProps: { color: tenantTheme['secondary-color'] } },
    { title: 'Clicks', value: clicks, icon: 'HiCursorClick', iconProps: { color: tenantTheme['color-clicks'] } },
    {
      title: 'Leads',
      value: leads,
      icon: 'MdPhone',
      iconProps: { color: tenantTheme['color-leads'], hasBackground: true, size: '1em', iconContainerSize: '24px' },
    },
  ];
};

const ListingCard = ({ item, disableDiv, selected, wrap, align }) => {
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const listingDrawerRef = useRef();
  const location = useGetLocation();
  const [autoRenewModalVisible, setAutoRenewModalVisible] = useState(false);
  const isDailyRentalListing = item?.purpose?.slug == 'daily-rental';

  const dateLabelMapping = tenantUtils.getDateFieldsByStatus(
    item?.status?.slug,
    item?.platforms?.auto_renewable_item?.isApplied,
  );
  const dateLabels = Object.keys(dateLabelMapping);

  const updateExpiryRenewal = async (autoRenewId, listingId, productId, setLoading) => {
    const response = await tenantApi.updateAutoRenew(autoRenewId, listingId, productId);
    if (response) {
      setLoading(false);
      if (response?.error) {
        notification.error(response?.error);
      } else {
        notification.success(t('Updated Successfully'));
        if (location.pathname === '/dashboard') {
          dispatch(updateListForDashboard(response));
        } else {
          dispatch(updateList(response));
        }
        setAutoRenewModalVisible(false);
      }
    }
  };

  const onChange = () => {
    setAutoRenewModalVisible(true);
  };

  const getTagProps = () => {
    const { productsInfo = {} } = item?.property;

    if (productsInfo?.['signature-listing']?.is_applied) {
      return tenantData.getListingActions('signature-listing');
    }
    if (productsInfo?.['hot-listing']?.is_applied) {
      return tenantData.getListingActions('hot-listing');
    }
    return tenantData.getListingActions('basic-listing');
  };

  const handleClick = () => {
    if (tenantConstants?.SHOW_LISTING_DETAIL) {
      listingDrawerRef?.current && listingDrawerRef.current.open(item?.id);
    }
  };

  const renderStats = (item) => {
    return item?.status?.slug === 'active' || item?.status?.slug === 'removed' ? (
      <>
        <Stats className="mb-8" template="repeat(3, 1fr)" gap="8px">
          {getStatsData(item?.platforms?.data[0]).map((e, i) => {
            return e.value === 'loading' ? (
              <Skeleton />
            ) : (
              <Statistic
                icon={e?.icon}
                iconProps={{ ...e?.iconProps, hasBackground: true, size: '1em', iconContainerSize: '24px' }}
                spaceProps={{ align: 'center' }}
                title={t(e?.title)}
                formatter={<Number value={e?.value ? e.value : 0} compact={false} />}
                value={e?.value ? e.value : 0}
                trends={false}
                key={i}
              />
            );
          })}
        </Stats>
      </>
    ) : null;
  };

  return (
    <div>
      <ListingCardStyled
        style={{
          ...(disableDiv && { pointerEvents: 'none', opacity: 0.4 }),
          ...(selected && { backgroundColor: '#e7f3ef' }),
        }}
      >
        <Group template="106px 1fr" gap=".571rem" className="mb-8">
          <Thumbnail>
            <Image
              onClick={handleClick}
              src={item?.image?.thumbnail}
              fallback={`${getBaseURL()}/profolio-assets/images/ph-listings.svg`}
            />
          </Thumbnail>
          <Group template="initial" gap="4px">
            <Row align={align || 'middle'} justify="space-between" wrap={wrap}>
              <Space className="mb-2">
                <div className="fz-14 fw-800" style={{ lineHeight: 1.2 }}>
                  <Number compact={false} type="price" {...item?.price} />
                  {isDailyRentalListing && (
                    <span
                      className="fw-500"
                      style={{ marginInlineStart: '4px', fontSize: '11px', color: tenantTheme['primary-color'] }}
                    >
                      {t('Night')}
                    </span>
                  )}
                </div>
                {item?.property?.productsInfo?.['basic-listing']?.is_applied && (
                  <ProductTag {...getTagProps()} iconProps={{ ...getTagProps().iconProps }} />
                )}
              </Space>
              <HealthContainer style={{ alignItems: 'start' }}>
                {item?.platforms?.data?.map((platform) => (
                  <>
                    {item?.health && (
                      <TenantComponents.Health {...item?.health} listingsData={item?.property} isMobile />
                    )}
                    <TenantComponents.ListingsRowActions
                      data={item?.platforms.data.filter((e) => e.slug == platform.slug)}
                      location={item?.platforms?.location}
                      property_id={item?.platforms?.property_id}
                      purpose={item?.platforms.purpose}
                      type={item?.platforms?.type}
                      isMobile
                    />
                  </>
                ))}
              </HealthContainer>
            </Row>
            <span>
              {item?.listing_type?.title && item?.listing_purpose?.title && (
                <Tag
                  color={tenantTheme['primary-light-4']}
                  style={{ '--tag-color': tenantTheme['primary-color'], fontWeight: '700' }}
                >
                  {`${tenantUtils.getLocalisedString(item?.listing_type, 'title')} ${t(`for ${item?.listing_purpose?.title}`)}`}
                </Tag>
              )}
            </span>
            <div>
              <Space size="large">
                {item?.beds > 0 && (
                  <TextWithIcon
                    icon="IconBedroom"
                    iconProps={{ size: '1.2em', color: tenantTheme.gray500 }}
                    value={`${item?.beds} ${t('Rooms')}`}
                  />
                )}
                {item?.baths > 0 && (
                  <TextWithIcon
                    icon="IconBathroom"
                    iconProps={{ size: '1.2em', color: tenantTheme.gray500 }}
                    value={`${item?.baths} ${t('Baths')}`}
                  />
                )}
                <TextWithIcon
                  icon="IconAreaSize"
                  iconProps={{ size: '1.4em', color: tenantTheme.gray500 }}
                  value={`${item?.area?.value} ${t('Sq. M.')}`}
                />
              </Space>
              <div>
                <div>
                  {item?.platforms?.location?.breadcrumb
                    ?.filter((e) => e?.level > 1)
                    ?.map((e) => tenantUtils.getLocalisedString(e, 'title'))
                    ?.join(', ')}
                </div>
              </div>
            </div>
            <span className="fz-10">
              <Text className="text-muted" style={{ fontSize: 10 }}>
                {t('Bayut ID:')}
              </Text>{' '}
              <Text className="text-primary" style={{ fontSize: 10 }}>
                {item?.id}
              </Text>
              {item?.ad_license ? (
                <>
                  <Divider type="vertical" style={{ marginInline: 4 }} />
                  <Text className="text-muted" style={{ fontSize: 10 }}>
                    {t('REGA ID:')}{' '}
                  </Text>
                  <Text className="text-primary" style={{ fontSize: 10 }}>
                    {item?.ad_license}
                  </Text>
                </>
              ) : (
                <>
                  <Divider type="vertical" style={{ marginInline: 4 }} />
                  <Tag shape="round" style={{ alignSelf: 'center', fontSize: 10 }}>
                    {t('Unlicensed')}
                  </Tag>
                </>
              )}
            </span>
            {dateLabels[0] && (
              <Row justify="space-between" style={{ alignItems: 'baseline' }}>
                {t(dateLabels[0])}{' '}
                {getTimeDateString(item?.platforms?.data?.[0]?.[dateLabelMapping[dateLabels[0]]]) || '-'}
              </Row>
            )}
          </Group>
          {tenantConstants.SHOW_LISTING_DETAIL && <ListingDrawer ref={listingDrawerRef} />}
        </Group>
        {renderStats(item)}

        {item?.platforms?.data?.map((platform) => (
          <div>
            <TenantList template="40% auto" gap="8px 4px" key={platform?.id}>
              <div>
                <div>
                  {dateLabels[1] && (
                    <Flex className="fz-10" wrap gap="3px" align="center">
                      {t(dateLabels[1])}
                      {
                        <Text strong style={{ fontSize: '12px' }}>
                          {getTimeDateString(item?.platforms?.data?.[0]?.[dateLabelMapping[dateLabels[1]]]) || '-'}
                        </Text>
                      }
                    </Flex>
                  )}
                </div>
                <SwitchWrapped className="fz-10" align="center">
                  <Switch
                    switchOnly
                    name="auto-renew"
                    size="small"
                    value={item?.platforms?.auto_renewable_item?.isApplied}
                    onChange={onChange}
                    loading={loading}
                  />
                  <Label
                    htmlFor="auto-renew"
                    className={isMobile ? 'fz-10' : 'fz-12'}
                    color={tenantTheme['primary-color']}
                  >
                    {t('AUTO-RENEW')}
                  </Label>
                </SwitchWrapped>
                <Modal
                  title={`${item?.platforms?.auto_renewable_item?.isApplied ? t('Disable') : t('Enable')} ${t(
                    'Auto Renew',
                  )}`}
                  visible={autoRenewModalVisible}
                  onOk={() => {
                    setLoading(true);
                    updateExpiryRenewal(
                      item?.platforms?.auto_renewable_item?.id,
                      item?.property?.id,
                      tenantData.getListingActions('basic-listing')?.id,
                      setLoading,
                    );
                  }}
                  onCancel={() => {
                    setAutoRenewModalVisible(false);
                  }}
                  loading={loading}
                >
                  {!item?.platforms?.auto_renewable_item?.isApplied
                    ? t(
                        "By turning the 'Auto-Renew' feature ON. The system will automatically renew the listing when it reaches its expiry, if you have enough credits available",
                      )
                    : t('Are you sure you want to disable auto renew for this listing')}
                </Modal>
              </div>
              <div style={{ justifySelf: 'end', textAlign: 'right' }}>
                <TenantComponents.PlatformActions
                  data={item?.platforms.data.filter((e) => e.slug === platform.slug)}
                  location={item?.platforms?.location}
                  property_id={item?.platforms?.property_id}
                  purpose={item?.platforms.purpose}
                  type={item?.platforms?.type}
                />
              </div>
            </TenantList>
          </div>
        ))}
      </ListingCardStyled>
    </div>
  );
};

export default ListingCard;
