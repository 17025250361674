import tenantApi from '@api';
import { NetworkService } from '../../../../services/networkService';

const addAgencyUser = async (agencyId, values) => {
  let response = await NetworkService.post(`/api/agencies/${agencyId}/add_staff`, tenantApi.agencyUser(values));
  if (response.error) {
    return {
      error: response.error,
    };
  } else if (response.data) {
    const user = response?.data?.user;
    return {
      user: {
        user_email: user?.email,
        user_id: user?.id,
        user_name: user?.name,
        user_phone: user?.phone,
        active_listings: { value: 0 },
        credits: [],
        quota: [],
        listing: [],
        leads: { value: null },
        rowActions: {
          agencyId: agencyId,
          userId: user?.id,
          isAdmin: user?.isAdmin,
          name: user?.name,
          user_email: user?.email,
          user_name: user?.name,
          user_phone: user?.phone,
          deleteModalData: {
            table: [
              {
                title: 'Name',
                dataIndex: 'user_name',
                key: 'user_name',
                component: 'String',
              },
              {
                title: 'Email',
                dataIndex: 'user_email',
                key: 'user_email',
                component: 'String',
              },
              {
                title: 'Active Listings',
                dataIndex: 'active_listings',
                key: 'active_listings',
                component: 'Number',
              },
            ],
            list: [
              {
                credits: [],
                is_admin: user?.isAdmin,
                listings: [],
                quota: [],
                user_email: user?.email,
                user_id: user?.id,
                user_name: user?.name,
              },
            ],
            onDelete: async () => {
              const response = await tenantApi.deleteAgencyUser(agencyId, user?.id);
              if (response.error) {
                return { error: response.error };
              } else {
                return { id: user?.id };
              }
            },
          },
        },
      },
    };
  }
};
export default addAgencyUser;
