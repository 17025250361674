import { getKCBaseURL, kcClientId, kcRealm } from '../utility/env';

const kcRequestBaseURL = () => `${getKCBaseURL()}/auth/realms/${kcRealm}`;

export const KC_REQUESTS = {
  name: { url: `${kcRequestBaseURL()}/user-profile/attributes`, body: val => ({ name: val }) },
  email: {
    url: `${kcRequestBaseURL()}/user-profile/email/generate-challenge`,
    body: val => ({ email: val }),
  },
  verify_email: {
    url: `${kcRequestBaseURL()}/user-profile/email/verify-challenge`,
    body: (res, code) => ({ token: res.token, code: code }),
  },
  resend_verify_email: {
    url: `${kcRequestBaseURL()}/user-profile/email/resend-challenge`,
    body: (res, medium) => ({ medium: medium, token: res.token }),
  },
  mobile: {
    url: `${kcRequestBaseURL()}/user-profile/phone-number`,
    body: val => ({ phone_number: val, challenge_medium: 'sms' }),
  },
  verify_mobile: {
    url: `${kcRequestBaseURL()}/user-profile/phone-number/verify-challenge`,
    body: (res, code) => ({ token: res.token, code: code }),
  },
  resend_verify_mobile: {
    url: `${kcRequestBaseURL()}/user-profile/phone-number/resend-challenge`,
    body: (res, medium) => ({ medium: medium, token: res.token }),
  },
  change_password: { url: `${kcRequestBaseURL()}/user-profile/credentials/password` },
  logout: {
    url: `${kcRequestBaseURL()}/protocol/openid-connect/logout`,
    body: refresh_token => {
      const data = new URLSearchParams();
      data.append('client_id', kcClientId);
      data.append('refresh_token', refresh_token);
      return data;
    },
  },
};
