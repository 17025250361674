import chroma from 'chroma-js';

const gray800 = '#4c4a4a';
const gray700 = '#767676';
const gray600 = '#9D9D9D';
const gray500 = '#C1BFBF';
const gray400 = '#DEDEDE';
const gray300 = '#E6E6E6';
const gray200 = '#f5f5f5';
const gray100 = '#f0f0f0';

const primaryColor = '#006169';
const primaryHover = '#006169';
const primaryLight = '#F7FCFC';
const primaryLight1 = '#A6C8CA';
const primaryLight2 = '#CCDFE1';
const primaryLight3 = '#E5EFF0';
const primaryLight4 = '#F2FAFA';

const secondaryColor = '#28b16d';
const secondaryHover = '#28b16d';
const linkColor = '#1890ff';
const linkHover = '#0D79DF';
const baseColor = '#222';
const headingColor = baseColor;
const successColor = secondaryColor;
const successHover = '#0CAB7C';
const warningColor = '#f0a742';
const warningHover = '#D47407';
const dangerColor = '#f73131';
const errorColor = dangerColor;
const errorHover = '#df4d4f';
const infoColor = '#2C99FF';
const infoHover = '#0D79DF';
const infoColorAlt = '#479eeb';
const darkColor = '#272B41';
const darkHover = '#131623';
const grayColor = '#5A5F7D';
const grayLightestColor = '#bdbdbd';
const grayDarkColor = gray700;
const grayHover = '#363A51';
const lightColor = gray600;
const lightHover = '#e2e6ea';
const whiteColor = '#fff';
const dashColor = gray100;
const whiteHover = '#5A5F7D';
const extraLightColor = '#ADB4D2';
const borderColorLight = '#F1F2F6';
const borderColorNormal = gray400;
const borderColorDeep = '#C6D0DC';
const bgGrayColorDeep = '#EFF0F3';
const bgGrayColorLight = '#F8F9FB';
const bgGrayColorNormal = '#F4F5F7';
const lightGrayColor = '#868EAE';
const sliderRailColor = 'rgba(95,99,242,0.2)';
const graySolid = lightColor;
const healthColorLow = '#FF7258';
const healthColorAvg = '#FFDC65';
const healthColorGood = '#98DAB9';
const healthColorDefault = '#C1BFBF';
const placeholderColor = gray600;
const labelMuted = gray700;

const btnlg = '48px';
const btnsm = '36px';
const btnxs = '29px';

const theme = {
  gray800,
  gray700,
  gray600,
  gray500,
  gray400,
  gray300,
  gray200,
  gray100,

  'primary-color': primaryColor,
  'primary-hover': primaryHover,
  'primary-light': primaryLight,
  'primary-light-color': primaryLight3,
  'primary-light-1': primaryLight1,
  'primary-light-2': primaryLight2,
  'primary-light-3': primaryLight3,
  'primary-light-4': primaryLight4,
  'secondary-color': secondaryColor,
  'secondary-hover': secondaryHover,
  'link-color': linkColor,
  'link-hover': linkHover,
  'success-color': successColor,
  'success-hover': successHover,
  'warning-color': warningColor,
  'warning-hover': warningHover,
  'error-color': errorColor,
  'error-hover': errorHover,
  'info-color': infoColor,
  'info-color-alt': infoColorAlt,
  'info-hover': infoHover,
  'base-color': baseColor,
  'dark-color': darkColor,
  'dark-hover': darkHover,
  'gray-color': grayColor,
  'gray-lightest-color': grayLightestColor,
  'gray-dark-color': grayDarkColor,
  'gray-hover': grayHover,
  'light-color': lightColor,
  'light-hover': lightHover,
  'white-color': whiteColor,
  'white-hover': whiteHover,
  white: whiteColor,
  black: '#000',
  'placeholder-color': placeholderColor,
  'label-muted': labelMuted,
  'dash-color': dashColor,
  'extra-light-color': extraLightColor,
  'danger-color': dangerColor,
  'font-family': 'Lato, Droid Arabic Kufi, sans-serif',
  'font-size-base': '14px',
  'heading-color': headingColor,
  'text-color': darkColor,
  'text-color-secondary': gray700,
  'disabled-color': 'rgba(0, 0, 0, 0.25)',
  'border-radius-base': '6px',
  'border-radius-lg': '8px',
  'border-color-base': gray100,
  'box-shadow-base': '0 2px 8px rgba(0, 0, 0, 0.15)',
  'border-color-light': borderColorLight,
  'border-color-normal': borderColorNormal,
  'border-color-deep': borderColorDeep,
  'bg-color-light': bgGrayColorLight,
  'bg-color-normal': bgGrayColorNormal,
  'bg-color-deep': bgGrayColorDeep,
  'bg-color-default': healthColorDefault,
  'light-gray-color': lightGrayColor,
  'gray-solid': graySolid,
  'btn-height-large': btnlg,
  'btn-height-small': btnsm,
  'btn-height-extra-small': btnxs,
  'btn-default-color': darkColor,
  'color-signature': '#af6fff',
  'color-hot': dangerColor,
  'color-basic': secondaryColor,
  'color-for-rent': infoColorAlt,
  'color-for-sale': '#2d3e9b',
  'color-clicks': warningColor,
  'color-leads': infoColorAlt,
  'main-bg-color': 'transparent',

  // cards
  'card-head-background': '#fff',
  'card-head-color': darkColor,
  'card-background': '#fff',
  'card-head-padding': '16px',
  'card-padding-base': '12px',
  'card-radius': '10px',
  'card-shadow': '0 5px 20px rgba(146,153,184,0.03)',
  'card-border-width-lg': '1px',
  'card-border-width-sm': '0px',

  // Layout
  'layout-body-background': '#F6F7FB',
  'layout-header-background': '#fff',
  'layout-footer-background': '#fafafa',
  'layout-header-height': '74px',
  'layout-header-padding': '0 30px 0 15px',
  'layout-footer-padding': '24px 15px',
  'layout-sider-background': '#fff',
  'layout-trigger-height': '48px',
  'layout-trigger-background': '#002140',
  'layout-trigger-color': '#fff',
  'layout-zero-trigger-width': '36px',
  'layout-zero-trigger-height': '42px',

  // Layout light theme
  'layout-sider-background-light': '#fff',
  'layout-trigger-background-light': '#fff',
  'layout-trigger-color-light': 'rgba(0, 0, 0, 0.65)',

  // PageHeader
  // ---
  'page-header-padding': '24px',
  'page-header-padding-vertical': '16px',
  'page-header-padding-breadcrumb': '12px',
  'page-header-back-color': '#000',
  'page-header-ghost-bg': 'inherit',

  // Popover body background color
  'popover-color': darkColor,

  // alert
  'alert-success-border-color': successColor,
  'alert-success-bg-color': chroma.mix('fff', successColor, 0.16).saturate(0.08),
  'alert-success-icon-color': successColor,
  'alert-info-border-color': infoColor,
  'alert-info-bg-color': chroma.mix('fff', infoColor, 0.16).saturate(0.08),
  'alert-info-icon-color': infoColor,
  'alert-warning-border-color': warningColor,
  'alert-warning-bg-color': chroma.mix('fff', warningColor, 0.16).saturate(0.08),
  'alert-warning-icon-color': warningColor,
  'alert-error-border-color': errorColor,
  'alert-error-bg-color': chroma.mix('fff', errorColor, 0.16).saturate(0.08),
  'alert-error-icon-color': errorColor,
  'alert-text-color': baseColor,

  // Radio buttons
  'radio-button-bg': '#fff',
  'radio-button-checked-bg': primaryColor,
  'radio-button-color': gray700,
  // radio pills
  'radio-pill-bg': gray200,
  'radio-pill-checked-bg': primaryLight3,
  'radio-pill-border-color': 'transparent',
  'radio-pill-checked-border-color': primaryLight1,
  'radio-pill-text-color': baseColor,
  'radio-pill-checked-text-color': primaryColor,

  // gutter width
  'grid-gutter-width': 25,

  // skeleton
  'skeleton-color': borderColorLight,

  // slider
  'slider-rail-background-color': sliderRailColor,
  'slider-rail-background-color-hover': sliderRailColor,
  'slider-track-background-color': primaryColor,
  'slider-track-background-color-hover': primaryColor,
  'slider-handle-color': primaryColor,
  'slider-handle-size': '16px',

  // input
  'input-height-base': '44px',
  'input-border-color': borderColorNormal,
  'input-height-sm': '40px',
  'input-height-lg': '50px',
  'input-placeholder-color': placeholderColor,

  // rate
  'rate-star-color': warningColor,
  'rate-star-size': '13px',

  // Switch
  'switch-min-width': '35px',
  'switch-sm-min-width': '30px',
  'switch-height': '18px',
  'switch-sm-height': '15px',

  // result
  'result-title-font-size': '20px',
  'result-subtitle-font-size': '12px',
  'result-icon-font-size': '50px',

  // tabs
  'tabs-horizontal-padding': '12px 15px',
  'tabs-horizontal-margin': '0',

  // list
  'list-item-padding': '10px 24px',

  // Steps
  'process-tail-color': gray400,
  'steps-background': '#F5F5F5',

  // Tags
  'tag-default-bg': '#EFF0F3',
  'tag-default-color': darkColor,
  'tag-font-size': '11px',

  // Health
  'health-color-low': healthColorLow,
  'health-color-avg': healthColorAvg,
  'health-color-good': healthColorGood,

  // Select
  'select-border-color': borderColorNormal,
};

const darkTheme = { ...theme, backgroundColor: '#000' };

const themeTokens = {
  token: {
    borderColor: gray100,
    borderRadius: '6px',
    borderRadiusLG: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    btnHeightLG: btnlg,
    btnHeightSM: btnsm,
    colorBgSpotlight: '#fff',
    colorError: errorColor,
    colorInfo: infoColor,
    colorLink: linkColor,
    colorPrimary: primaryColor,
    colorSuccess: successColor,
    colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
    colorWarning: warningColor,
    fontFamily: 'Figtree, Droid Arabic Kufi, sans-serif',
    fontSize: 14,
    fontSizeHeading1: 38,
    fontSizeHeading2: 30,
    fontSizeHeading3: 24,
    fontSizeHeading4: 20,
    fontSizeHeading5: 16,
    headingColor: headingColor,
    lineHeight: 1.571,
    textColor: darkColor,
    textColorSecondary: gray700,
  },
  components: {
    Button: {
      contentLineHeight: 2,
      paddingInlineSM: '1em',
      paddingInline: '1.2em',
      paddingInlineLG: '1.5em',

      defaultBorderColor: gray100,
      //  paddingBlockSM: 12
      controlHeight: 36,
      controlHeightLG: 40,
      controlHeightSM: 32,
    },

    Card: {
      colorTextHeading: darkColor,
      headerBg: '#fff',
      colorBgContainer: '#fff',
      cardPaddingBase: 12,
      borderRadiusLG: 10,
      paddingLG: 12,

      // The following tokens need to be set directly in the component or custom styles as they are either deprecated or not directly mapped:
      // 'card-head-padding': '16px',  // Deprecated
      // 'card-shadow': '0 5px 20px rgba(146,153,184,0.03)', // No direct mapping
      // 'card-border-width-lg': '1px',  // No direct mapping
      // 'card-border-width-sm': '0px',  // No direct mapping
    },

    // Layout
    Layout: {
      bodyBg: '#F6F7FB',
      headerBg: '#fff',
      footerBg: '#fafafa',
      headerHeight: 74,
      headerPadding: '0 30px 0 15px',
      footerPadding: '24px 15px',
      siderBg: '#fff',
      triggerHeight: 48,
      triggerBg: '#002140',
      triggerColor: '#fff',
      zeroTriggerWidth: 36,
      zeroTriggerHeight: 42,
      lightSiderBg: '#fff',
      lightTriggerBg: '#fff',
      lightTriggerColor: 'rgba(0, 0, 0, 0.65)',
    },

    // Popover
    Popover: {
      colorText: darkColor,
      innerPadding: '16px',
    },

    //List
    List: {
      itemPadding: '10px 24px',
    },

    Menu: {
      iconMarginInlineEnd: 10,
    },

    // Alert
    Alert: {
      colorSuccessBorder: successColor,
      colorSuccessBg: chroma.mix('fff', successColor, 0.16).saturate(0.08),
      colorSuccess: successColor,
      colorInfoBorder: infoColor,
      colorInfoBg: chroma.mix('fff', infoColor, 0.16).saturate(0.08),
      colorInfo: infoColor,
      colorWarningBorder: warningColor,
      colorWarningBg: chroma.mix('fff', warningColor, 0.16).saturate(0.08),
      colorWarning: warningColor,
      colorErrorBorder: errorColor,
      colorErrorBg: chroma.mix('fff', errorColor, 0.16).saturate(0.08),
      colorError: errorColor,
      colorText: baseColor,
    },

    // Radio buttons
    Radio: {
      buttonBg: '#fff',
      buttonCheckedBg: primaryColor,
      buttonColor: gray700,
      radioPillBg: gray200,
      radioPillCheckedBg: primaryLight3,
      radioPillBorderColor: 'transparent',
      radioPillCheckedBorderColor: primaryLight1,
      radioPillTextColor: baseColor,
      radioPillCheckedTextColor: primaryColor,
    },

    // skeleton
    Skeleton: {
      gradientFromColor: borderColorLight,
    },

    // slider
    Slider: {
      railBg: sliderRailColor,
      railHoverBg: sliderRailColor,
      trackBg: primaryColor,
      trackHoverBg: primaryColor,
      handleColor: primaryColor,
      // handleSize: '16px',
      handleSize: 12,
    },

    // Input
    Input: {
      colorBorder: borderColorNormal,
      colorTextPlaceholder: placeholderColor,
      paddingBlock: 10,
      paddingBlockSM: 8,
      paddingBlockLG: 12,
      activeShadow: `0 0 0 2px ${chroma(primaryColor).alpha(0.1)}`,
    },

    // Rate
    Rate: {
      starColor: warningColor,
      starSize: 13,
    },

    // Switch
    Switch: {
      trackMinWidth: 35,
      trackMinWidthSM: 30,
      trackHeight: 18,
      trackHeightSM: 15,
      trackPadding: 2,
      handleSize: 14,
      colorTextTertiary: 'rgba(0, 0, 0, .25)',
    },

    // result
    Result: {
      titleFontSize: 20,
      subtitleFontSize: 12,
      iconFontSize: 50,
    },

    // tabs
    Tabs: {
      horizontalItemPadding: '12px 15px',
      horizontalItemMargin: '0',
      horizontalItemGutter: 0,
    },

    // Steps
    Steps: {
      colorSplit: gray400,
      colorBgContainer: '#F5F5F5',
    },

    // Tags
    Tags: {
      defaultBg: '#EFF0F3',
      defaultColor: darkColor,
      fontSizeSM: 11,
    },

    // Select
    Select: {
      colorBorder: borderColorNormal,
      controlHeight: 44,
      controlHeightSM: 34,
      controlHeightLG: 50,
      colorBgContainerDisabled: gray200,
      colorTextPlaceholder: gray600,
      multipleItemBg: 'color-mix(in srgb, var(--primary-color) 10%, #fff)',
      multipleItemHeight: 22,
      paddingXXS: 11,
    },

    Modal: {
      titleFontSize: 16,
    },
    InputNumber: {
      paddingBlock: 10,
    },
    DatePicker: {
      controlHeight: 44,
      controlHeightSM: 34,
      controlHeightLG: 50,
    },
    Typography: {
      colorTextDescription: gray700,
    },
  },
};

export { theme, darkTheme, themeTokens };
