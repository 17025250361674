import tenantApi from '@api';
import { getErrorString } from '../../utility/utility';

const setUsersList = (agencyId) => async (dispatch) => {
  const response = await tenantApi.fetchAgencyUsers(agencyId);
  if (response) {
    if (response.error) {
      dispatch({ type: 'SET_USERS_LIST', payload: { error: getErrorString(response.error) } });
    } else {
      dispatch({ type: 'SET_USERS_LIST', payload: { list: response.data, error: null } });
    }
  }
};

export { setUsersList };
