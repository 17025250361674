const actions = {
  SET_LISTINGS_TABLE_DATA: 'SET_LISTINGS_TABLE_DATA',
  SET_LISTINGS_WIDGET_DATA: 'SET_LISTINGS_WIDGET_DATA',
  SET_LISTINGS_WIDGET_FILTER: 'SET_LISTINGS_WIDGET_FILTER',
  SET_LISTINGS_TABLE_FILTER: 'SET_LISTINGS_TABLE_FILTER',

  setListingsWidget: (data, platformKey) => {
    return {
      type: 'SET_LISTINGS_WIDGET_DATA',
      payload: { [platformKey]: data },
    };
  },
};

export default actions;
