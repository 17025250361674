import React from 'react';
import PropTypes from 'prop-types';
import { SegmentedStyled } from './styled';

const Segmented = (props) => {
  const { children, ...rest } = props;
  return <SegmentedStyled {...rest}>{children}</SegmentedStyled>;
};

Segmented.propTypes = {
  children: PropTypes.node,
};

export default Segmented;
