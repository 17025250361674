import { trackEventGA4 } from './gtmEventHandler';

import {
  PAGE_TYPES,
  INTERACTED_FROM,
  WEBSITE_SECTIONS,
  getCommonParams,
  PAGE_GROUPS,
  PAGE_SECTIONS,
  STRINGS,
  statusMap,
  EVENT_NAMES,
  EVENT_CATEGORIES,
} from './constants';

export const addPropertyEvent = (user, currentPathSlug, isSideMenu) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.ADD_PROPERTY_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: isSideMenu ? PAGE_TYPES.SIDE_MENU : currentPathSlug,
      interacted_from: isSideMenu
        ? INTERACTED_FROM.SIDE_MENU
        : user.is_package_user
          ? INTERACTED_FROM.HEADER_PROFOLIO
          : INTERACTED_FROM.HEADER_LITE,
      ...commonParams,
    },
  });
};

export const regaAdValidationEvent = (user, response, formValues) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.REGA_AD_VALIDATION_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      status: response.error ? STRINGS.ERROR : STRINGS.SUCCESS,
      value: response.error ? response.error : null,
      ad_license_number: formValues.licenseId,
      fal_license: formValues.falLicense ? formValues.falLicense : null,
      national_id: formValues.licenseType === 'agency' ? null : formValues.advertiserId,
      cr_number: formValues.licenseType === 'agency' ? formValues.advertiserId : null,
      ...commonParams,
    },
  });
};

export const pageViewPostListingsDetailEvent = (user, is_new) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      page_section: is_new ? PAGE_SECTIONS.NEW : PAGE_SECTIONS.EDIT,
      posting_reason: is_new ? STRINGS.NEW : STRINGS.EDIT,
      ...commonParams,
    },
  });
};

export const regaInformationClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      page_section: PAGE_SECTIONS.REGA_DETAILS,
      ...commonParams,
    },
  });
};

export const regaQRCodeClickEvent = (user, is_qr, license_info) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      page_section: is_qr ? PAGE_SECTIONS.REGA_QR : PAGE_SECTIONS.REGA_LINK,
      ad_license_number: license_info.ad_license_number,
      ...commonParams,
    },
  });
};
export const changeLocationClickEvent = (user, formValues, is_new) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      page_section: PAGE_SECTIONS.MAP,
      property_type: formValues.listing_type.title,
      posting_reason: is_new ? STRINGS.NEW : STRINGS.EDIT,
      ...commonParams,
    },
  });
};

export const changeLocationConfirmEvent = (user, propertyType, is_new) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.MAP_PIN_SELECTED_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      property_type: propertyType ? propertyType?.title : '',
      posting_reason: is_new ? STRINGS.NEW : STRINGS.EDIT,
      ...commonParams,
    },
  });
};

export const generateTitleClickEvent = (user, response, formValues) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.CONTENT_GENERATION_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      page_section: PAGE_SECTIONS.TITLE,
      status: response.error ? STRINGS.ERROR : STRINGS.SUCCESS,
      property_type: formValues.listing_type.title || '',
      posting_reason: !formValues.is_posted ? STRINGS.NEW : STRINGS.EDIT,
      ...commonParams,
    },
  });
};

export const generateDescriptionClickEvent = (user, response, formValues) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.CONTENT_GENERATION_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      page_section: PAGE_SECTIONS.DESCRIPTION,
      status: response.error ? STRINGS.ERROR : STRINGS.SUCCESS,
      property_type: formValues.listing_type.title || '',
      posting_reason: !formValues.is_posted ? STRINGS.NEW : STRINGS.EDIT,
      ...commonParams,
    },
  });
};

export const getTopUpClickEvent = (user, credits) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PACKAGE_BUYING_CAT,
    event: EVENT_NAMES.GET_TOPUP_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.PACKAGES,
      page_type: PAGE_TYPES.DETAILS,
      get_package_type: credits,
      ...commonParams,
    },
  });
};

export const suggestCreditsClickEvent = (user, credits) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PACKAGE_BUYING_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.PACKAGES,
      page_type: PAGE_TYPES.DETAILS,
      get_package_type: credits,
      ...commonParams,
    },
  });
};

export const emailButtonClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ACCOUNT_MANAGER_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.HELP_CENTRE,
      page_type: PAGE_TYPES.ACCOUNT_MANAGER,
      page_section: PAGE_SECTIONS.EMAIL,
      ...commonParams,
    },
  });
};

export const whatsappButtonClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ACCOUNT_MANAGER_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.HELP_CENTRE,
      page_type: PAGE_TYPES.ACCOUNT_MANAGER,
      page_section: PAGE_SECTIONS.WHATSAPP,
      ...commonParams,
    },
  });
};

export const phoneCallButtonClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ACCOUNT_MANAGER_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.HELP_CENTRE,
      page_type: PAGE_TYPES.ACCOUNT_MANAGER,
      page_section: PAGE_SECTIONS.CALL,
      ...commonParams,
    },
  });
};

export const pageViewDashboardEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.REPORTS,
      page_type: PAGE_TYPES.DASHBOARD,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};

export const pageViewCreditUsageEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.REPORTS,
      page_type: PAGE_TYPES.CREDIT_USAGE,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};

export const pageViewReportSummaryEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.REPORTS,
      page_type: PAGE_TYPES.SUMMARY,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};

export const pageViewLeadsReportEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.REPORTS,
      page_type: PAGE_TYPES.LEADS,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};

export const pageViewListingReportEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.REPORTS,
      page_type: PAGE_TYPES.LISTINGS,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};

export const pageViewAgencyStaffEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.AGENCY,
      page_type: PAGE_TYPES.AGENCY_STAFF,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};

export const pageViewAgencySettingEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.AGENCY,
      page_type: PAGE_TYPES.AGENCY_SETTINGS,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};

export const pageViewUserSettingEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.AGENCY,
      page_type: PAGE_TYPES.USER_SETTINGS,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};

export const pageViewMyListingEvent = (user, currentStatusID) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PAGE_VIEW_SIDE_MENU_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.MANAGE_LISTING,
      page_type: statusMap[currentStatusID] || PAGE_TYPES.ACTIVE,
      interacted_from: user.is_package_user ? INTERACTED_FROM.HEADER_PROFOLIO : INTERACTED_FROM.HEADER_LITE,
      ...commonParams,
    },
  });
};

export const helpCenterClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ACCOUNT_MANAGER_CAT,
    event: EVENT_NAMES.HELP_CENTRE_CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.HELP_CENTRE,
      page_type: PAGE_TYPES.HELP_CENTRE,
      ...commonParams,
    },
  });
};

export const profolioButtonClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ACCOUNT_MANAGER_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.HELP_CENTRE,
      page_type: PAGE_TYPES.PROFOLIO,
      ...commonParams,
    },
  });
};

export const regaComplianceClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ACCOUNT_MANAGER_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.HELP_CENTRE,
      page_type: PAGE_TYPES.REGA_COMPLIANCE,
      ...commonParams,
    },
  });
};

export const faqSectionClickEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ACCOUNT_MANAGER_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.HELP_CENTRE,
      page_type: PAGE_TYPES.FAQ,
      ...commonParams,
    },
  });
};
export const pageViewPackageScreenEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PACKAGE_BUYING_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.PACKAGES,
      page_type: PAGE_TYPES.DETAILS,
      interacted_from: INTERACTED_FROM.SIDE_MENU,
      ...commonParams,
    },
  });
};
export const packageMonthClickEvent = (user, packageDuration) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PACKAGE_BUYING_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.PACKAGES,
      page_type: PAGE_TYPES.DETAILS,
      page_section: PAGE_SECTIONS.PACKAGE_DURATION,
      package_duration: packageDuration,
      ...commonParams,
    },
  });
};
export const packageSelectEvent = (user, packageDuration, packageType) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PACKAGE_BUYING_CAT,
    event: EVENT_NAMES.GET_PACKAGE_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.PACKAGES,
      page_type: PAGE_TYPES.DETAILS,
      // remaining_months: remainingMonths,
      package_duration: packageDuration,
      get_package_type: packageType,
      ...commonParams,
    },
  });
};
export const upgradePackageConfirmClickEvent = (user, packageDuration, packageType) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.PACKAGE_BUYING_CAT,
    event: EVENT_NAMES.UPGRADE_CONFIRM_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.PACKAGES,
      page_type: PAGE_TYPES.DETAILS,
      page_section: PAGE_SECTIONS.CONFIRMATION_POPUP,
      package_duration: packageDuration,
      get_package_type: packageType,
      ...commonParams,
    },
  });
};

//re-check once upgrade is live
export const upgradeClickEvent = (user, upgrade, listingType, purpose, city, area) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.MANAGE_LISTING_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.MANAGE_LISTING,
      page_type: PAGE_TYPES.ACTIVE,
      page_section: upgrade,
      listing_type: listingType,
      listing_purpose: purpose,
      city: city,
      area: area,
      ...commonParams,
    },
  });
};

export const imageUploadSuccessEvent = (user, response, is_new, formValues) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.IMAGE_UPLOAD_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      status: response.status === 200 ? STRINGS.SUCCESS : STRINGS.ERROR,
      value: response.status === 200 ? null : response.statusText,
      property_type: formValues?.listing_type?.title,
      posting_reason: is_new ? STRINGS.NEW : STRINGS.EDIT,
      ...commonParams,
    },
  });
};
export const pageViewUpsellEvent = (user, listingData) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.UPSELL,
      listing_status: listingData.status.name,
      listing_disposition: listingData.disposition.name,
      listing_purpose: listingData.listing_purpose.title,
      city: listingData.city,
      ...commonParams,
    },
  });
};
export const upgradeButtonUpsellClickEvent = (user, response, listingType, listingData) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.UPSELL_SUBMIT_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.UPSELL,
      status: response.status === 200 ? STRINGS.SUCCESS : STRINGS.ERROR,
      photography_service: true ? STRINGS.YES : STRINGS.NO,
      videography_service: true ? STRINGS.YES : STRINGS.NO,
      drone_service: true ? STRINGS.YES : STRINGS.NO,
      listing_type: listingType,
      listing_status: listingData.status.name,
      listing_disposition: listingData.disposition.name,
      listing_purpose: listingData.listing_purpose.title,
      ...commonParams,
    },
  });
};

export const postListingClickEvent = (user, responseStatus, response) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.SUBMIT_LISTING_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.DETAILS,
      status: responseStatus === 200 ? STRINGS.SUCCESS : STRINGS.ERROR,
      property_type: response.listing_type.title,
      listing_status: response.status.slug,
      listing_disposition: response.disposition.slug,
      listing_purpose: response.listing_purpose.title,
      rental_frequency: response.rent_frequency,
      amenities_added: Object.values(response.rega_details.utilities).filter((value) => value === true).length,
      bedrooms: response.beds,
      price: response.price,
      city: response.rega_details.location.city,
      furnished: response.is_furnished ? STRINGS.TRUE : STRINGS.FALSE,
      images_added: response.images_count,
      posting_reason: STRINGS.NEW,
      area: response.area_unit.value,
      ...commonParams,
    },
  });
};

export const pageViewCheckoutEvent = (user, interactedFrom, cartData, disposition, status, payment_method) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.CHECKOUT,
      payment_method: payment_method,
      interacted_from: interactedFrom,
      value: cartData.total,
      listing_status: status,
      listing_disposition: disposition,
      ...commonParams,
    },
  });
};

export const checkoutPayClickEvent = (user, interactedFrom, cartData, disposition, status) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.PAY_CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.CHECKOUT,
      status: response.status === 200 ? STRINGS.SUCCESS : STRINGS.ERROR,
      value: errorValue,
      listing_type: listingType,
      listing_status: listingData.status.name,
      listing_disposition: listingData.disposition.name,
      listing_purpose: listingData.listing_purpose.title,
      payment_method: paymentMethod,
      ...commonParams,
    },
  });
};

export const nafathButtonClickEvent = (user, response, errorValue) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.ADD_PROPERTY_CAT,
    event: EVENT_NAMES.VERIFY_NAFATH_CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.ADD_PROPERTY,
      page_type: PAGE_TYPES.NAFATH,
      status: response.status === 200 ? STRINGS.SUCCESS : STRINGS.ERROR,
      value: errorValue,
      ...commonParams,
    },
  });
};

export const upgradeButtonClickEvent = (user, response, errorValue) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.MANAGE_LISTING_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.MANAGE_LISTING,
      page_type: PAGE_TYPES.ACTIVE,
      page_section: upgradeType,
      listing_type: listingType,
      purpose: ListingPurpose,
      city: city,
      area: area,
      ...commonParams,
    },
  });
};

export const actionButtonQualityWidgetClickEvent = (user, currentStatusID, actionType, props) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.MANAGE_LISTING_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.MANAGE_LISTING,
      page_type: statusMap[currentStatusID] || PAGE_TYPES.ACTIVE,
      page_section: PAGE_SECTIONS.QUALITY,
      value: actionType,
      amenities_added: props.features_selected,
      images_added: props.unique_images,
      ...commonParams,
    },
  });
};

export const actionButtonListingsClickEvent = (user, currentStatusID, pageSection) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.MANAGE_LISTING_CAT,
    event: EVENT_NAMES.CLICK_ACT,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.MANAGE_LISTING,
      page_type: statusMap[currentStatusID] || PAGE_TYPES.ACTIVE,
      pageSection: pageSection,
      ...commonParams,
    },
  });
};

export const hoverListingQualityDonut = (user, currentStatusID, qualityScore) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.MANAGE_LISTING_CAT,
    event: EVENT_NAMES.QUALITY_HOVER,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.MANAGE_LISTING,
      page_type: statusMap[currentStatusID] || PAGE_TYPES.ACTIVE,
      value: qualityScore,
      ...commonParams,
    },
  });
};

export const deleteListingConfirmEvent = (user, currentStatusID, response, deletionReason) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.MANAGE_LISTING_CAT,
    event: EVENT_NAMES.DELETE_LISTING,
    is_non_interaction: false,
    params: {
      website_section: user.is_package_user ? WEBSITE_SECTIONS.PROFOLIO : WEBSITE_SECTIONS.LITE,
      page_group: PAGE_GROUPS.MANAGE_LISTING,
      page_type: statusMap[currentStatusID] || PAGE_TYPES.ACTIVE,
      page_section: PAGE_SECTIONS.DELETE,
      status: response.error ? STRINGS.ERROR : STRINGS.SUCCESS,
      value: deletionReason,
      ...commonParams,
    },
  });
};

export const downloadAppButtonClick = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.APP_REDIRECT,
    event: EVENT_NAMES.DOWNLOAD_APP_CLICK,
    is_non_interaction: false,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.APP_REDIRECT,
      page_type: PAGE_TYPES.DOWNLOAD_BUTTON,
      ...commonParams,
    },
  });
};
export const downloadAppActionEvent = (user, pageSection) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.APP_REDIRECT,
    event: EVENT_NAMES.DOWNLOAD_APP_ACTION,
    is_non_interaction: false,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.APP_REDIRECT,
      page_type: STRINGS.DOWNLOAD_BUTTON,
      page_section: pageSection,
      ...commonParams,
    },
  });
};
export const pageViewInboxEvent = (user) => {
  const commonParams = getCommonParams(user);
  trackEventGA4({
    category: EVENT_CATEGORIES.LEADS,
    event: EVENT_NAMES.PAGE_VIEW_ACT,
    is_non_interaction: true,
    params: {
      website_section: WEBSITE_SECTIONS.PROFOLIO,
      page_group: PAGE_GROUPS.LEADS,
      page_type: PAGE_TYPES.INBOX,
      ...commonParams,
    },
  });
};
