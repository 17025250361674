import actions from './actions';

const { SET_AGENCY_STAFF, ADD_AGENCY_STAFF_USER, UPDATE_AGENCY_STAFF_USER, DELETE_AGENCY_STAFF_USER } = actions;

const initialState = {
  agencyStaff: null,
  loading: true,
  error: null,
};

const AgencyStaffReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_AGENCY_STAFF:
      return {
        ...state,
        ...payload,
      };
    case ADD_AGENCY_STAFF_USER:
      return {
        ...state,
        agencyStaff: {
          ...state.agencyStaff,
          list: [...state.agencyStaff.list, payload.user],
        },
      };
    case UPDATE_AGENCY_STAFF_USER:
      // const index = state.agencyStaff?.list?.findIndex(e => e.id === payload?.user?.id);
      const updateUser = { user_name: payload.user.name, user_email: payload.user.email };
      return {
        ...state,
        agencyStaff: {
          ...state.agencyStaff,
          list: state.agencyStaff.list.map(user => {
            if (user.user_id === payload.user.id) {
              return { ...user, ...updateUser };
            }
            return user;
          }),
        },
      };
    case DELETE_AGENCY_STAFF_USER:
      return {
        ...state,
        agencyStaff: {
          ...state.agencyStaff,
          list: state.agencyStaff.list.filter(e => e.user_id !== payload.user.id),
        },
      };
    default:
      return state;
  }
};

export { AgencyStaffReducer };
