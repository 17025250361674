import tenantTheme from '@theme';
import tenantUtils from '@utils';
import { Divider, Row, Space, Typography } from 'antd';
import React, { useMemo } from 'react';
import TenantComponents from '../..';
import { Flex, Group, Icon, Image, Number, Popover, Tag, TextWithIcon } from '../../../../components/common';
import { ListingCardStyled, TenantList, Thumbnail } from '../../../../components/styled';
import { getTimeDateString } from '../../../../utility/date';
import { getBaseURL } from '../../../../utility/env';

const { Text } = Typography;

const ListingCard = ({ item, disableDiv, selected, array }) => {
  const locationTitle = () =>
    useMemo(
      () =>
        item?.platforms?.location?.breadcrumb
          ?.filter(e => e?.level > 2)
          ?.map(e => tenantUtils.getLocalisedString(e, 'title'))
          ?.join(', '),
      [item],
    );

  return (
    <div>
      <ListingCardStyled
        style={{
          ...(disableDiv && { pointerEvents: 'none', opacity: 0.4 }),
          ...(selected && { backgroundColor: '#e7f3ef' }),
        }}
      >
        <Group template="110px 1fr" gap=".571rem">
          <Thumbnail>
            <Image
              src={item?.images?.[0]?.thumbnail}
              wrapperStyle={{ borderRadius: 6, overflow: 'hidden' }}
              style={{ objectFit: 'cover', width: '100%', aspectRatio: '1' }}
              fallback={`${getBaseURL()}/profolio-assets/images/ph-listings.svg`}
            />
          </Thumbnail>
          <Group template="initial" gap="4px">
            <Row align="middle" justify="space-between">
              <span className="fz-10">
                <Text className="text-muted">ID:</Text> <Text className="text-primary">{item?.id}</Text>
              </span>
            </Row>
            <div>
              <div className="fz-14 fw-800" style={{ lineHeight: 1.2 }}>
                <Number compact={false} type="price" {...item?.price} />
              </div>
              <div>{locationTitle}</div>
            </div>
            <div>
              <div>
                {item?.platforms?.type?.alternate_title} {item?.platforms?.purpose?.alternate_title}
              </div>

              <Space size="large">
                {item?.beds > 0 && (
                  <TextWithIcon
                    icon="IconBedroom"
                    iconProps={{ size: '1.2em', color: tenantTheme.gray500 }}
                    value={item?.beds}
                  />
                )}
                {item?.baths > 0 && (
                  <TextWithIcon
                    icon="IconBathroom"
                    iconProps={{ size: '1.2em', color: tenantTheme.gray500 }}
                    value={item?.baths}
                  />
                )}
                <TextWithIcon
                  icon="IconAreaSize"
                  iconProps={{ size: '1.4em', color: tenantTheme.gray500 }}
                  value={`${item?.area?.value} ${item?.area?.unit}`}
                />
              </Space>
            </div>
          </Group>
        </Group>
        {item?.platforms?.data?.map(platform => (
          <>
            <Divider style={{ marginBlock: 8 }} />
            <Flex gap="2px" vertical>
              <TenantList template="36% auto" gap="8px 4px" key={platform?.id}>
                <Flex align="center" gap="8px">
                  <Icon icon={platform.icon} size="1.6em" disabled={!platform.posted} />
                  <Tag color={platform.status.color} shape="round" size="12px">
                    {platform.status.label}
                    {platform?.status?.comments && (
                      <Popover placement="right" title="Comments" content={platform?.status?.comments} action="hover">
                        <Icon icon="AiOutlineInfoCircle" style={{ marginInlineStart: 5 }} color="#a3a3a3" />
                      </Popover>
                    )}
                  </Tag>
                </Flex>
                <Flex align="center" justify="end" style={{ textAlign: 'right' }}>
                  <TenantComponents.PlatformActions
                    data={item?.platforms.data.filter(e => e.slug === platform.slug)}
                    location={item?.platforms?.location}
                    property_id={item?.platforms?.property_id}
                    purpose={item?.platforms.purpose}
                    type={item?.platforms?.type}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                    isMobile
                  />
                  <TenantComponents.ListingsRowActions
                    data={item?.platforms.data.filter(e => e.slug == platform.slug)}
                    location={item?.platforms?.location}
                    property_id={item?.platforms?.property_id}
                    purpose={item?.platforms.purpose}
                    type={item?.platforms?.type}
                    isMobile
                  />
                </Flex>
              </TenantList>
              {platform?.posted_on && (
                <Row className="fz-10" justify="space-between" align="bottom">
                  <div>{!!array(platform).length && array(platform)?.reduce((prev, curr) => [prev, ' • ', curr])}</div>
                  <TextWithIcon
                    icon="PiClockCounterClockwiseBold"
                    iconProps={{ size: '1.2em', color: tenantTheme.gray500 }}
                    value={`Posted on ${getTimeDateString(platform.posted_on)}`}
                  />
                </Row>
              )}
            </Flex>
          </>
        ))}
      </ListingCardStyled>
    </div>
  );
};

export default ListingCard;
