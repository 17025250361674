import tenantData from '@data';
import tenantUtils from '@utils';
import { NetworkService } from '../../../../services/networkService';

const packageDataMapper = (data) => {
  const type = tenantData.slugToType?.[data?.slug];
  const IS_YEARLY = !data?.slug.includes('half-yearly');

  return {
    ...data,
    id: data.id,
    slug: data.slug,
    name: tenantUtils.getLocalisedString(data, 'name'),
    yearlyCredits: data.total_credits,
    monthlyCredits: data.total_credits / (IS_YEARLY ? 12 : 6),
    oldPrice: data.total_amount,
    price: data.net_amount,
    icon: tenantData.packages?.[type]?.icon,
    iconProps: tenantData.packages?.[type]?.iconProps,
    packageColor: tenantData.packages?.[type]?.packageColor,
  };
};

const separatePackages = (packages) => {
  const yearlyPackages = [];
  const halfYearlyPackages = [];

  packages.forEach((item) => {
    if (item.slug.includes('half-yearly')) {
      halfYearlyPackages.push(packageDataMapper(item));
    } else {
      yearlyPackages.push(packageDataMapper(item));
    }
  });

  return {
    yearly: yearlyPackages,
    halfYearly: halfYearlyPackages,
  };
};

export const getPackagesData = async ({ months, rtl }) => {
  const response = months
    ? await NetworkService.get(`/api/packages?per_page=50&q[duration_in_months_eq]=${months}`)
    : await NetworkService.get(`/api/packages?per_page=50`);
  let data = {};
  if (response.error) {
    return response;
  } else if (response?.data) {
    if (response?.data?.packages?.length) {
      const separatedPackages = separatePackages(response?.data?.packages);
      data = {
        benefits: Object.values(response?.data?.packages[0]?.additional_info?.options),
        packageData: {
          12: separatedPackages.yearly,
          6: separatedPackages.halfYearly,
        },
        refundable_amount: response?.data?.refundable_amount,
        pagination: response?.data?.pagination,
      };
    }

    return data;
  }
};
