import { checkRequiredKeys } from '../../tenant/checkRequiredKeys';
import { TENANT_KEY } from '../../utility/env';
import BayutMenuList, { BAYUT_KEY } from './bayut';
import ZameenMenuList, { ZAMEEN_KEY } from './zameen';

const configKeys = ['menuList'];

export const tenants = Object.freeze({
  [ZAMEEN_KEY]: checkRequiredKeys(ZameenMenuList, configKeys)?.menuList,
  [BAYUT_KEY]: checkRequiredKeys(BayutMenuList, configKeys)?.menuList,
});

export default tenants[TENANT_KEY];
