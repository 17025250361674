import tenantTheme from '@theme';
import PropTypes from 'prop-types';
import React from 'react';
import { Flex, Icon } from '..';
import { SkeletonBody } from '../../skeleton/Skeleton';
import { Text } from './styled';

const TextWithIcon = (props) => {
  const {
    className,
    icon,
    iconProps,
    title,
    gap,
    textSize,
    textColor,
    fontWeight,
    iconStyle,
    loading,
    loadingProps,
    lead,
    value,
    ...rest
  } = props;
  return (
    <Flex className={className} align="center" gap={gap} {...rest}>
      {icon &&
        (loading ? (
          <SkeletonBody type="avatar" size={loadingProps.avatarSize || 'small'} />
        ) : iconProps.hasBackground ? (
          <Icon
            styled
            iconProps={{
              color: iconProps.color,
              style: { backgroundColor: iconProps.iconBackgroundColor, ...iconProps.style, ...iconStyle },
              ...iconProps,
            }}
            icon={icon}
          />
        ) : (
          <Icon icon={icon} size={iconProps.size} color={iconProps.color} style={iconProps.style} />
        ))}
      {loading ? (
        <SkeletonBody type="button" size={loadingProps.rectSize || 'small'} />
      ) : (
        <Text textSize={textSize} textColor={textColor} lead={lead} fontWeight={fontWeight}>
          {title}
          {value && <div className="value">{value}</div>}
        </Text>
      )}
    </Flex>
  );
};

TextWithIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  iconProps: PropTypes.object,
  title: PropTypes.string,
  gap: PropTypes.oneOf([PropTypes.number, 'small', 'middle', 'large']),
  textSize: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  textColor: PropTypes.string,
  fontWeight: PropTypes.string,
  loading: PropTypes.bool,
  loadingProps: PropTypes.object,
  rest: PropTypes.object,
  lead: PropTypes.bool,
  value: PropTypes.node,
};

TextWithIcon.defaultProps = {
  icon: null,
  iconProps: {
    size: '1.2em',
    color: tenantTheme['light-gray-color'],
  },
  loadingProps: {
    avatarSize: 'small',
    rectSize: 'small',
  },
  title: null,
  gap: '8px',
};

export default TextWithIcon;
