const actions = {
  PRODUCTS_BEGIN: 'PRODUCTS_BEGIN',
  PRODUCTS_SUCCESS: 'PRODUCTS_SUCCESS',
  PRODUCTS_ERR: 'PRODUCTS_ERR',

  productsBegin: () => {
    return {
      type: actions.PRODUCTS_BEGIN,
    };
  },

  productsSuccess: data => {
    return {
      type: actions.PRODUCTS_SUCCESS,
      data,
    };
  },

  productsErr: err => {
    return {
      type: actions.PRODUCTS_ERR,
      err,
    };
  },
};

export default actions;
