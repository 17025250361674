import tenantRoutes from '@routes';
import tenantTheme from '@theme';
import { Row } from 'antd';
import cx from 'clsx';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useRouteNavigate } from '../../../hooks';
import TenantComponents from '../../../tenant/components';
import { formatNumberString } from '../../../utility/utility';
import { Button, Card, EmptyState, Flex, Group, Modal, Select, TextWithIcon } from '../../common';
import LoaderWrapper from '../../common/loader-wrapper/loader-wrapper';
import WarningNotice from '../../warning-notice';
import QuotaCreditsStatsWidget from '../QuotaCreditsStatWidget';
import { QCSkeleton } from './credits-quota-skeleton';

const CreditsQuota = (props) => {
  const {
    error,
    loading,
    loadingOnUser,
    onUserChange,
    platform,
    skeletonLoading,
    userId,
    setUserId,
    users,
    loggedInUser,
    usersPermission,
    title,
    titleIcon,
    platformSpecificCall,
    expiryData,
    downgradedData,
    products: allProducts = [],
    accentColor,
    assignToLink,
    showAssignToLink,
    isDashboard,
    widgetGap,
    rowHeight,
    cardHeight,
    bodyPadding,
  } = props;
  const { t } = useTranslation();
  const { isMemberArea, isMobile, isMultiPlatform } = useSelector((state) => state.AppConfig);
  const { user } = useSelector((state) => state.loginUser);
  const [activeTab, setActiveTab] = useState(null);
  const creditDrawerRef = useRef();
  const navigate = useRouteNavigate();
  const [topUpModalVisible, setTopUpModalVisible] = useState(false);
  const [smartCreditsModalVisible, setSmartCreditsTopUpModalVisible] = useState(false);

  const CreditTopUps = TenantComponents.CreditTopUps;
  const modalRef = useRef(null);

  const renderUserSelect = useCallback(() => {
    return (
      !!usersPermission && (
        <Select
          size="small"
          horizontal
          value={userId}
          options={users}
          onChange={(value) => {
            setUserId(value, platform?.slug);
            onUserChange(value, platform?.slug);
          }}
          getOptionLabel={(op) => {
            return `${op.name} ${op.id === loggedInUser?.id ? t('(Me)') : ''}`;
          }}
          inputLoading={loadingOnUser}
          dropdownMatchSelectWidth={false}
          accentColor={accentColor}
        />
      )
    );
  });

  const renderCreditTopUpModal = () => {
    return (
      <Modal
        title={isMemberArea || !!user?.is_custom_package_allowed ? t('Custom Package & Credits') : t('Credit Top-Up')}
        visible={topUpModalVisible}
        onCancel={() => {
          setTopUpModalVisible(false);
          modalRef?.current && modalRef?.current?.clearState();
        }}
        footer={null}
        bodyStyle={{ padding: 0 }}
        width={880}
      >
        <CreditTopUps
          ref={modalRef}
          header={false}
          bodyStyle={{ padding: '16px 24px' }}
          style={{ borderRadius: 0, border: 'none' }}
        />
      </Modal>
    );
  };

  const tabList = useMemo(() => {
    return (
      allProducts?.length &&
      allProducts.map((item) => ({
        key: item.slug,
        tab: t(item.title) + ` (${formatNumberString(item.dataSet?.[1], { maximumFractionDigits: 0 })})`,
      }))
    );
  }, [allProducts]);

  useEffect(() => {
    if (allProducts?.length) {
      setActiveTab(allProducts?.[0]?.slug);
    }
  }, [allProducts]);

  return error ? (
    <EmptyState message={error} onClick={() => onUserChange(userId, platform?.slug)} buttonLoading={loadingOnUser} />
  ) : (
    <div>
      <Row
        className={cx('mb-8', isMobile ? 'px-16 fz-14' : 'px-4 fz-16')}
        align="bottom"
        justify="space-between"
        style={{ minHeight: rowHeight || 34, alignItems: 'end', columnGap: 24, rowGap: 4 }}
      >
        <Flex align="center" gap="6px">
          <TextWithIcon
            title={title}
            icon={(isMobile || !isDashboard) && isMultiPlatform && titleIcon}
            iconProps={{ size: '1.6em', iconBackgroundColor: '#fff', hasBackground: true }}
            fontWeight={700}
            loading={skeletonLoading}
            className={cx(isMobile ? 'fz-14' : 'fz-16')}
            loadingProps={{ avatarSize: 24, rectSize: 'small' }}
            onClick={() => creditDrawerRef.current.openDrawer(true)}
            style={{ cursor: 'pointer' }}
          />
          {user.isCurrencyUser && !skeletonLoading && (
            <>
              <Button
                onClick={() => creditDrawerRef.current.openDrawer(true)}
                shape="circle"
                icon={'BsInfoLg'}
                type="primary-light"
                iconSize="14px"
                style={{ '--btn-bg-color': tenantTheme['primary-light-2'], height: '18px', width: '18px ' }}
                iconColor={tenantTheme['primary-color']}
                size="small"
                className="btn-icon-size"
              />
              <TenantComponents.CreditInfoDrawer ref={creditDrawerRef} />
            </>
          )}
        </Flex>

        <Flex gap="8px">
          {!!assignToLink && !!showAssignToLink && (
            <Link to={assignToLink}>
              <Button icon="FiUsers" color={accentColor || tenantTheme['primary-color']} size="small">
                {t('Assign to Users')}
              </Button>
            </Link>
          )}
          {renderUserSelect()}
        </Flex>
      </Row>
      <Card
        style={{ minHeight: cardHeight || (isMobile && 146) }}
        bodyStyle={{ padding: bodyPadding || isMobile ? 16 : 24 }}
        tabList={tabList?.length === 1 ? null : tabList}
        defaultActiveTabKey="premium_listing"
        activeTabKey={activeTab}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
        accentColor={platform?.brandColor}
        loading={skeletonLoading}
      >
        {skeletonLoading ? (
          <QCSkeleton isMobile={isMobile} />
        ) : (
          <Group template="initial" gap={widgetGap || (isMobile ? '12px' : '16px')}>
            {!!error && (platformSpecificCall ? platformSpecificCall === platform : true) ? (
              <EmptyState
                message={error}
                onClick={() => onUserChange(userId, platform?.slug)}
                buttonLoading={loadingOnUser}
              />
            ) : (
              <>
                {allProducts?.length ? (
                  <LoaderWrapper loading={loading && !!allProducts}>
                    <QuotaCreditsStatsWidget
                      available={allProducts?.find((e) => e?.slug === activeTab)?.available}
                      used={allProducts?.find((e) => e?.slug === activeTab)?.used}
                      loading={skeletonLoading}
                      currencyType={allProducts?.find((e) => e?.slug === activeTab)?.type}
                      brandColor={platform.brandColor}
                      showPackage={true}
                    />
                  </LoaderWrapper>
                ) : null}

                <Row justify={isMobile ? 'center' : 'end'} style={{ gap: '16px 0px' }} wrap>
                  {!!expiryData && !!expiryData?.data?.length && (
                    <WarningNotice style={{ width: 'initial', flex: '1 70%' }} title={expiryData?.title} />
                  )}

                  {!!downgradedData && !!downgradedData?.downgraded_contracts && (
                    <WarningNotice
                      style={{ '--warning-notice-bg': '#FCEAEA' }}
                      title={`${downgradedData?.downgraded_contracts} ${
                        downgradedData?.downgraded_contracts > 1 ? t('contracts are') : t('contract is')
                      } ${t('downgraded')}.`}
                    />
                  )}
                </Row>
                {user?.isCurrencyUser && !isMemberArea && (
                  <Flex justify="center" gap="10px">
                    <Button
                      onClick={() => {
                        setTopUpModalVisible(true);
                      }}
                      type="primaryOutlined"
                      icon={'CgArrowTopRightO'}
                      size={isMobile && 'small'}
                      className="w-100"
                    >
                      {t('Top-Up your Credits')}
                    </Button>
                    <Button
                      onClick={() => navigate(tenantRoutes.app().credits_usage.path)}
                      type="primaryOutlined"
                      icon={'PiClockClockwiseFill'}
                      size={isMobile && 'small'}
                      className="w-100"
                    >
                      {t('Credits Consumption')}
                    </Button>
                    {renderCreditTopUpModal()}
                  </Flex>
                )}
                {user?.isCurrencyUser && allProducts?.length
                  ? allProducts?.map((e) => {
                      if (allProducts?.find((e) => e?.slug === activeTab)?.expiring_credits) {
                        return (
                          <Flex align="center" className="w-100">
                            <WarningNotice
                              className="px-12 py-8"
                              icon="CreditIcon"
                              iconProps={{ size: isMobile ? '12px' : '16px' }}
                              textSize={isMobile ? '12px' : '14px'}
                              type="simple"
                              style={{
                                '--warning-notice-bg': '#FFFAF1',
                                '--align-items': 'center',
                                flexWrap: 'wrap',
                              }}
                              title={`${formatNumberString(
                                allProducts?.find((e) => e?.slug === activeTab)?.expiring_credits,
                              )} ${user?.is_auto_utilization_enabled ? t('credits will be auto-utilized smartly within 7 days.') : t('credits are expiring within 7 days')}`}
                              extra={
                                !user?.is_auto_utilization_enabled &&
                                user?.user_role_within_agency != 'staff' && (
                                  <TenantComponents.SmartCreditsUtilizationModal
                                    visible={smartCreditsModalVisible}
                                    setVisible={setSmartCreditsTopUpModalVisible}
                                  />
                                )
                              }
                            />
                          </Flex>
                        );
                      }
                    })
                  : null}
              </>
            )}
          </Group>
        )}
      </Card>
    </div>
  );
};

export default CreditsQuota;
