import React from 'react';
import PropTypes from 'prop-types';
import { CardStyled } from './styled';

const Card = props => {
  const { children, hasHovered, ...rest } = props;
  return (
    <CardStyled tabProps={{ size: 'middle', ...rest.tabProps }} hasHovered={hasHovered} {...rest}>
      {children}
    </CardStyled>
  );
};

Card.propTypes = {
  children: PropTypes.node,
};

export default Card;
