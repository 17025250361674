import tenantConstants from '@constants';
import tenantUtils from '@utils';
import moment from 'moment';
import { NetworkService } from '../../../../services/networkService';
import { getDateDiffInDays, getDateLabels, getVariousDates } from '../../../../utility/date';
import { getQueryString } from '../../../../utility/urlQuery';
import { getErrorAllResponse, getResultGroup } from '../../../../utility/utility';

const limit = 10;

const getPageData = (pageNumber, last_page, data) => {
  let start_index = (pageNumber - 1) * limit;
  let end_index = pageNumber == last_page ? data?.length : pageNumber * limit;

  return data.slice(start_index, end_index);
};

const findProductSum = (grouped_stats, dateKey, filteredProducts) => {
  const sum = filteredProducts.reduce((acc, item) => {
    if (grouped_stats?.[dateKey]?.[item]) {
      return acc + grouped_stats[dateKey][item];
    } else {
      return acc;
    }
  }, 0);

  return sum;
};
const widgetParser = (responseData, dataSummary, params) => {
  const data = {
    id: 'zameen',
    slug: 'zameen',
    icon: 'IconZameen',
    title: 'Reach',
    reach_data: [
      {
        key: 'views',
        value: dataSummary?.views,
        label: 'Views',
        title: 'Views',
        icon: 'MdPermPhoneMsg',
        hit: true,
        id: 0,
        growth: false,
        since_when: '',
      },
      {
        key: 'clicks',
        value: dataSummary?.clicks,
        label: 'Clicks',
        title: 'Clicks',
        icon: 'MdPermPhoneMsg',
        hit: true,
        id: 0,
        growth: false,
        since_when: '',
      },
    ],
    data_summary: tenantUtils.generateProductData('zameen', ['reach', 'views', 'clicks', 'ctr']),
    data_breakdown: tenantUtils.generateProductData('zameen', ['views', 'clicks']),
  };
  const { result_group, purpose } = params;
  let grouped_stats = responseData;
  const summarydata = data.data_summary;
  const breakdowndata = data.data_breakdown;
  summarydata.forEach((performanceMetric, i) => {
    summarydata[i].value = dataSummary?.[performanceMetric.key]?.toFixed(2);
    summarydata[i].percentage = dataSummary?.leads_data?.[performanceMetric.key];
    summarydata[i].growth = dataSummary?.leads_data?.[performanceMetric.key] > 0;
  });
  const purposeTypes = breakdowndata.purposes.map((e) => e.key);
  const performanceBy = breakdowndata.performanceBy.map((e) => {
    return e.key;
  });
  const marked = breakdowndata.types.map((e) => e.key);
  const dateKeys = Object.keys(grouped_stats);
  const labels = dateKeys.map((e) => {
    if (result_group === 'dd') {
      return moment(e).format('MMM-DD');
    }
    if (result_group === 'ww' || result_group === 'mm') {
      return moment(grouped_stats[e].start_date).format('MMM-DD');
    }
    if (result_group === 'yy') {
      return moment(grouped_stats[e].start_date).format('MMM-DD, YY');
    }
  });
  breakdowndata['labels'] = labels;
  const currentPurposes = !purpose ? 'all' : purpose === 1 ? 'sale' : 'rent';
  const filteredProducts = marked.filter((item) => item != 'all');
  purposeTypes.forEach((purposeKey) => {
    performanceBy.forEach((performanceMetricKey) => {
      marked.forEach((productKey) => {
        dateKeys.forEach((dateKey) => {
          breakdowndata.data[performanceMetricKey][productKey].push(
            productKey == 'all'
              ? findProductSum(grouped_stats, dateKey, filteredProducts) || 0
              : grouped_stats?.[dateKey]?.[productKey] || 0,
          );
        });
      });
    });
  });

  return data;
};

const tableParser = (responseData, params) => {
  const tableData = tenantUtils.generateProductData['zameen'].trafficTable();

  let { meta: pagination = {} } = responseData;
  const dateKeys = Object.keys(responseData);

  const list = dateKeys.map((dateKey, i) => {
    const { views, reach, clicks } = responseData?.[dateKey];
    return {
      key: `traffic-${i}`,
      date: moment(dateKey).format('ll'),
      views: { value: views || 0 },
      traffic: { value: clicks || 0 },
      ctr: { value: clicks / views ? (clicks / views) * 100 : 0, unit: !!(clicks / views) ? '%' : '' },
    };
  });

  return {
    list: getPageData(pagination?.current_page, pagination?.last_page, list?.reverse()),
    ...tableData,
    pagination: tenantUtils.getPaginationObject(pagination),
  };
};

const widgetParserOlx = (responseData, dataSummary, params) => {
  const olxData = {
    id: 'olx',
    slug: 'olx',
    icon: 'IconOLX',
    title: 'Reach',
    reach_data: [
      {
        key: 'views',
        value: dataSummary?.aggregates?.sum_search_count,
        percentage: dataSummary?.trends?.sum_search_count,
        label: 'Views',
        title: 'Views',
        icon: 'MdPermPhoneMsg',
        hit: true,
        id: 0,
        growth: false,
        since_when: '',
      },
      {
        key: 'clicks',
        value: dataSummary?.aggregates?.sum_view_count,
        percentage: dataSummary?.trends?.sum_view_count,
        label: 'Clicks',
        title: 'Clicks',
        icon: 'MdPermPhoneMsg',
        hit: true,
        id: 0,
        growth: false,
        since_when: '',
      },
    ],
    data_summary: tenantUtils.generateProductData('olx', ['traffic', 'sum_search_count', 'sum_view_count', 'ctr']),
    data_breakdown: tenantUtils.generateProductData('olx', ['views', 'clicks']),
  };

  const summarydata = olxData.data_summary;
  const breakdowndata = olxData.data_breakdown;
  const { items: grouped_stats, total } = responseData;
  const purposeTypes = breakdowndata.purposes.map((e) => e.key);
  const performanceBy = breakdowndata.performanceBy.map((e) => e.key);
  const marked = breakdowndata.types.map((e) => e.key);

  if (total === 0) {
    const labels = getDateLabels(params?.start_date, params?.end_date).map((date) => moment(date).format('MMM-DD'));
    breakdowndata['labels'] = labels;
    performanceBy.forEach((performanceMetricKey) => {
      marked.forEach((product) => {
        labels.forEach((dateKey) => {
          breakdowndata.data[performanceMetricKey][product].push(0);
        });
      });
    });

    return olxData;
  }

  if (!dataSummary || !responseData || !Object?.keys(responseData)?.length || !Object.keys(dataSummary)?.length) {
    return olxData;
  }

  const dateKeys = Object.keys(grouped_stats);
  const labels = dateKeys.map((e) => {
    return moment(e).format('MMM-DD');
  });
  summarydata.forEach((performanceMetric, i) => {
    let totalValue = 0;
    Object.keys(dataSummary)
      .filter((key) => key !== 'trends')
      .forEach((productId) => {
        totalValue += dataSummary?.[productId]?.[performanceMetric?.key] || 0;
      });

    summarydata[i].value = totalValue?.toFixed(2) || 0;
    //no trend available
    summarydata[i].percentage = dataSummary?.['trends']?.[performanceMetric?.key];
    summarydata[i].growth = dataSummary?.['trends']?.[performanceMetric?.key] > 0;
  });

  summarydata.forEach((performanceMetric, i) => {
    summarydata[0].value = Number(summarydata[0].value) + Number(summarydata[i].value);
  });

  //Setting Traffic percentage as it will not be available in API
  let totalPercentageChange = tenantUtils.generateProductData(
    summarydata[2].value,
    summarydata[1].value,
    summarydata[2].percentage,
    summarydata[1].percentage,
  );
  summarydata[0].percentage = totalPercentageChange;
  summarydata[0].growth = totalPercentageChange > 0;

  //Setting CTR value and percentage as it will not be available in API
  summarydata[3].value = (summarydata[2].value / summarydata[1].value) * 100;
  let ctrPercentage = tenantUtils.generateProductData(
    summarydata[2].value,
    summarydata[1].value,
    summarydata[2].percentage,
    summarydata[1].percentage,
  );
  summarydata[3].percentage = ctrPercentage;
  summarydata[3].growth = ctrPercentage > 0;

  breakdowndata['labels'] = labels;
  const currentPurposes =
    params.category_id.length === 2
      ? ['all']
      : params.category_id.map((e) => {
          return e === 1 ? 'sale' : 'rent';
        });

  purposeTypes.forEach((purposeType) => {
    performanceBy.forEach((performanceMetricKey) => {
      marked.forEach((product) => {
        dateKeys.forEach((dateKey, i) => {
          const statType = breakdowndata?.performanceBy?.find((e) => e.key == performanceMetricKey);
          const olxProduct = grouped_stats[dateKey]?.product_wise?.find((item) => item.ad_product === product);
          breakdowndata.data[performanceMetricKey][product].push(
            product == 'all'
              ? grouped_stats[dateKey]?.[statType?.responseKey] || 0
              : olxProduct?.[statType?.responseKey] || 0,
          );
        });
      });
    });
  });
  return olxData;
};

const tableParserOlx = (responseData, params) => {
  const tableDataOlx = tenantUtils.generateProductData['olx'].trafficTable();

  const { items: grouped_stats, total } = responseData;
  if (total === 0) {
    const labels = getDateLabels(params?.start_date, params?.end_date);
    const list = labels.map((dateKey) => {
      return {
        key: `traffic-${dateKey}`,
        date: moment(dateKey).format('ll'),
        views: { value: 0 },
        traffic: { value: 0 },
        ctr: { value: 0 },
      };
    });
    return { list, ...tableDataOlx, pagination: null };
  }
  const dateKeys = Object?.keys(grouped_stats);
  const list = dateKeys.map((dateKey) => {
    const { sum_view_count, sum_search_count } = grouped_stats?.[dateKey];
    return {
      key: `traffic-${dateKey}`,
      date: moment(dateKey).format('ll'),
      views: { value: sum_search_count || 0 },
      traffic: { value: sum_view_count || 0 },
      ctr: {
        value: sum_view_count / sum_search_count ? (sum_view_count / sum_search_count) * 100 : 0,
        unit: !!(sum_view_count / sum_search_count) ? '%' : '',
      },
    };
  });

  const pagination = {
    from: params?.page ? (params?.page - 1) * limit + 1 : 1,
    to: params?.page ? params?.page * limit : 10,
    current_page: parseInt(params.page ? params?.page : 1),
    per_page: limit,
    last_page: parseInt(list?.length / limit) + 1,
    total_pages: parseInt(list?.length / limit) + 1,
    total_records: list?.length,
  };

  return {
    list: getPageData(pagination?.current_page, pagination?.last_page, list.reverse()),
    ...tableDataOlx,
    pagination: tenantUtils.getPaginationObject(pagination),
  };
};

const trafficQueryObj = {
  include: ['clicks', 'views'],
  date_between: getVariousDates(29),
  trend: 1,
  ctr: 1,
  without_pagination: 1,
  result_group: 'dd',
  purpose: null,
};
const makeZameenParams = (params, parseFor) => {
  if (parseFor === 'widget') {
    const paramsObj = {
      ...trafficQueryObj,
      ...(!!params?.date_between && {
        date_between: params.date_between,
        ...getResultGroup(getDateDiffInDays(params.date_between)),
      }),
      ...(!!params?.purpose && params.purpose !== 'all' && { purpose: params?.purpose === 'sale' ? 1 : 2 }),
      ...(!!params?.stat && { include: params.stat }),
    };
    return paramsObj;
  }

  return {
    ...trafficQueryObj,
    ...params,
    without_pagination: 0,
    trend: 0,
  };
};

const makeOlxParams = (params, parseFor) => {
  const dates = getVariousDates(29);
  const trafficQueryObjOlx = {
    start_date: dates[0],
    end_date: dates[1],
    category_id: [1, 2],
  };

  if (parseFor === 'widget') {
    const paramsObj = {
      ...trafficQueryObjOlx,
      ...(!!params?.date_between?.[0] && { start_date: params?.date_between[0] }),
      ...(!!params?.date_between?.[1] && { end_date: params?.date_between[1] }),
      ...(!!params?.purpose &&
        params.purpose !== 'all' && {
          category_id: params?.purpose === 'sale' ? [1] : [2],
        }),
    };
    return paramsObj;
  }
  const paramsObj = {
    ...trafficQueryObjOlx,
    ...(!!params?.date_between && {
      start_date: params?.date_between.split(',')[0],
      end_date: params?.date_between.split(',')[1],
    }),
    ...(!!params?.[tenantConstants.VALUE_IN_ARRAY_KEY('purpose')] && {
      category_id: [params?.[tenantConstants.VALUE_IN_ARRAY_KEY('purpose')]],
    }),
    ...(!!params?.[tenantConstants.VALUE_IN_ARRAY_KEY('user_id')] && {
      user_id: [...params?.[tenantConstants.VALUE_IN_ARRAY_KEY('user_id')].split(',')],
    }),
    limit,
    offset: 0,
    ...(!!params?.page && {
      offset: params.page === 1 ? 0 : (params.page - 1) * limit,
    }),
  };
  return paramsObj;
};

const makeOlxParamsObj = (params) => {
  const {
    date_between = getVariousDates(29),
    page = 1,
    ['filter[user_id]']: userId,
    purpose,
    stat: include = 'views',
  } = params;
  return {
    start_date: typeof date_between === 'string' ? date_between?.split(',')?.[0] : date_between?.[0],
    end_date: typeof date_between === 'string' ? date_between?.split(',')?.[1] : date_between?.[1],
    group_by: getResultGroup(getDateDiffInDays(date_between), true)?.result_group,
    include,
    ...(userId && { [`user_id`]: userId }),
    ...(page && { page: page }),
    offset: page === 1 ? 0 : (params.page - 1) * limit,
  };
};

export const trafficSummaryApi = async (paramObj, parseFor, platformKey) => {
  const params = paramObj ? paramObj : tenantUtils.generateProductData[platformKey].initialFilter.traffic;
  const {
    ['filter[user_id]']: userId,
    page,
    date_between = getVariousDates(30),
    stat: include = 'views',
    purpose,
  } = params;

  const paramsObj = {
    start_date: typeof date_between === 'string' ? date_between?.split(',')?.[0] : date_between?.[0],
    end_date: typeof date_between === 'string' ? date_between?.split(',')?.[1] : date_between?.[1],
    group_by: getResultGroup(
      getDateDiffInDays(typeof date_between == 'string' ? date_between?.split(',') : date_between),
      true,
    )?.result_group,
    include,
    ...(userId && { [`q[user_id_eq]`]: userId }),
  };

  const olxParmasObj = {
    start_date: typeof date_between === 'string' ? date_between?.split(',')?.[0] : date_between?.[0],
    end_date: typeof date_between === 'string' ? date_between?.split(',')?.[1] : date_between?.[1],
    ...(userId && { [`q[user_id_eq]`]: userId }),
  };

  const zameenParams = makeZameenParams(params, parseFor);

  if (platformKey === 'zameen') {
    if (parseFor === 'widget') {
      const widgetData = [
        NetworkService.get(
          `/api/dashboard/summary_by_date?${getQueryString(paramsObj)}&${
            !purpose || purpose == 'all'
              ? 'purpose_id[]=1&purpose_id[]=2'
              : `purpose_id[]=${purpose == 'sale' ? 1 : purpose == 'rent' ? 2 : purpose}`
          }`,
        ),
        NetworkService.get(
          `/api/dashboard/zameen_analytics?start_date=${paramsObj?.start_date}&end_date=${paramsObj?.end_date}`,
        ),
      ];
      const widgetDataResponse = await Promise.all(widgetData);
      const widgetDataError = getErrorAllResponse(widgetDataResponse);
      if (widgetDataError || !widgetDataResponse?.[1]?.data?.stats || !widgetDataResponse?.[0]?.data?.stats_summary) {
        return { error: widgetDataError || 'Network Error' };
      } else {
        const data = widgetDataResponse?.[0]?.data?.stats_summary;
        const summaryData = widgetDataResponse?.[1]?.data?.stats;
        return widgetParser(data, summaryData, zameenParams);
      }
    } else {
      const tableData = await NetworkService.get(
        `/api/dashboard/traffic_reach?${getQueryString({
          ...paramsObj,
        })}&${
          !purpose || purpose == 'all'
            ? 'purpose_id[]=1&purpose_id[]=2'
            : `purpose_id[]=${purpose == 'sale' ? 1 : purpose == 'rent' ? 2 : purpose}`
        }`,
      );
      if (tableData) {
        if (tableData?.error) {
          return tableData;
        } else {
          return tableParser(tableData?.data?.traffic_reach);
        }
      }
    }
  } else if (platformKey === 'olx') {
    const olxParams = makeOlxParams(params, parseFor);
    if (parseFor === 'widget') {
      const widgetData = [
        NetworkService.get(
          `/api/dashboard/olx_product_stats?${getQueryString(olxParmasObj)}&${
            !purpose || purpose == 'all'
              ? 'category_ids[]=1&category_ids[]=2'
              : `category_ids[]=${purpose == 'sale' ? 1 : purpose == 'rent' ? 2 : purpose}`
          }`,
        ),
        NetworkService.get(
          `/api/dashboard/olx_analytics?${getQueryString({
            ...olxParmasObj,
            trends: true,
          })}`,
        ),
      ];

      const widgetDataResponse = await Promise.all(widgetData);
      const widgetDataError = getErrorAllResponse(widgetDataResponse);
      if (!!widgetDataError) {
        return {
          error: widgetDataError,
        };
      } else if (widgetDataResponse) {
        const data = widgetDataResponse?.[0]?.data?.stats;
        const summaryData = widgetDataResponse?.[1]?.data?.stats;
        return widgetParserOlx(data, summaryData, olxParams);
      }
    } else {
      const tableData = await NetworkService.get(
        `/api/dashboard/olx_product_stats?${getQueryString(olxParmasObj)}&${
          !purpose || purpose == 'all'
            ? 'category_ids[]=1&category_ids[]=2'
            : `category_ids[]=${purpose == 'sale' ? 1 : purpose == 'rent' ? 2 : purpose}`
        }`,
      );
      if (tableData) {
        if (tableData?.error) {
          return tableData;
        } else {
          return tableParserOlx(tableData?.data?.stats, {
            ...makeOlxParamsObj(params),
            page: params.page,
          });
        }
      }
    }
  }
};
