import tenantData from '@data';
import store from '../../../../redux/store';
import { NetworkService } from '../../../../services/networkService';
import { getVariousDates } from '../../../../utility/date';

export const defaultDataForWidget = {
  ksa: [
    {
      id: 14,
      name: 'Normal Listing',
    },
    {
      id: 15,
      name: 'Hot Listing',
    },
    {
      id: 16,
      name: 'Super Hot Listing',
    },
    {
      id: 17,
      name: 'Credit',
    },
    {
      id: 18,
      name: 'Photography Service',
    },
    {
      id: 19,
      name: 'Videography Service',
    },
    {
      id: 39,
      name: 'Refresh Listing',
    },
  ],
};

const getDataSets = (data, platform) => {
  let productData;
  if (Array.isArray(data)) {
    productData = data?.map((e) => {
      return {
        ...e,
        dataSet: [e?.used, e?.available],
        ...tenantData.products?.find((it) => e?.slug == it?.slug),
        name: defaultDataForWidget?.[platform]?.find((item) => item?.id == e?.id)?.name,
      };
    });
  } else {
    productData = {
      ...data,
      dataSet: [data?.used, data?.available],
      ...tenantData.products?.find((it) => it?.slug == data?.slug),
      name: defaultDataForWidget?.[platform]?.find((item) => item?.id == data?.id)?.name,
    };
  }
  return productData;
};

const getDataSetsForCredits = (data, platform) => {
  let productData;
  productData = data?.map((e) => {
    return {
      ...e,
      dataSet: [e?.used, e?.available],
      ...tenantData.products?.find((it) => it?.id == 17), //as only credit
      name: defaultDataForWidget?.[platform]?.find((item) => item?.id == 17)?.name,
    };
  });
  return productData;
};

export const quotaCreditsWidgetMapper = (platform, productsByPlatform, user) => {
  return {
    key: platform?.slug,
    platform,
    title: 'Quota and Credits',
    cardTitle: 'Quota and Credits',
    assignToLink: '/agency-staff',
    buyMoreLink: '/prop-shop',
    products: Object?.keys(productsByPlatform?.[platform?.slug])
      ? [
          {
            ...getDataSets(productsByPlatform?.[platform?.slug]?.quota, platform?.slug),
            ...productsByPlatform?.[platform?.slug]?.quota,
            name: defaultDataForWidget?.[platform?.slug]?.find(
              (item) => item?.id == productsByPlatform?.[platform?.slug]?.quota?.id,
            )?.name,
          },
          ...getDataSets(productsByPlatform?.[platform?.slug]?.credits, platform?.slug),
        ]
      : defaultDataForWidget?.[platform?.slug],
    labels: [
      {
        label: 'Available',
        icon: 'NotOpen',
        type: 'primary',
        slug: 'available',
      },
      {
        label: 'Used',
        icon: 'Opened',
        type: 'success',
        slug: 'used',
      },
    ],
    links: [
      {
        link: '/credits-usage',
        link_title: 'View Usage History',
      },
    ],
    colors: ['#9299B8', tenantData.platformList.find((item) => item.slug === platform?.slug).brandColor],
  };
};

export const creditsWidgetMapper = (platform, productsByPlatform, user) => {
  return {
    key: platform?.slug,
    platform,
    title: 'Credits Balance',
    cardTitle: 'Credits',
    assignToLink: '/agency-staff',
    buyMoreLink: '/prop-shop',
    products: getDataSetsForCredits([productsByPlatform?.[platform?.slug]], platform?.slug),
    current_package_details: productsByPlatform?.current_package,
    labels: [
      {
        label: 'Available',
        icon: 'NotOpen',
        type: 'primary',
        slug: 'available',
      },
      {
        label: 'Used',
        icon: 'Opened',
        type: 'success',
        slug: 'used',
      },
    ],
    links: [
      {
        link: '/credits-usage',
        link_title: 'About Bayut Credits',
      },
    ],
    colors: ['#9299B8', tenantData.platformList.find((item) => item.slug === platform?.slug).brandColor],
  };
};

export const chartDataMapper = (e, productsByZones, responseData, products, zb) => {
  return {
    key: e,
    platform: e,
    title: e === 'olx' && !responseData?.is_shifted_to_olx_quota ? 'Listing and Credits' : 'Quota and Credits',
    titleIcon: e === 'olx' ? 'IconOLX' : 'IconZameen',
    cardTitle: e === 'olx' && !responseData?.is_shifted_to_olx_quota ? 'Listing and Credits' : 'Quota and Credits',
    assignToLink: '/agency-staff',
    buyMoreLink: !!e === 'zameen' ? '/prop-shop' : undefined,
    products,
    zones: Object.keys(productsByZones).map((e) => ({ value: e, label: e.replace('_', ' ').toUpperCase() })),
    types: productsByZones,
    labels: [
      {
        label: 'Available',
        icon: 'NotOpen',
        type: 'primary',
      },
      {
        label: 'Used',
        icon: 'Opened',
        type: 'success',
      },
    ],
    zonal_breakdown: zb,
    links: [
      {
        link: '/quota-and-credits',
        link_title: 'View Purchase Logs',
      },
    ],
    colors: ['#9299B8', tenantData.platformList.find((item) => item.slug === e).brandColor],
  };
};

export const getQuotaCreditsDetail = async (userId, platformOnlyKey) => {
  const loggedInUser = store.getState().loginUser.user;
  const agencyUsers = store.getState().userGroup.list;
  const isAgencyAdmin = agencyUsers?.find((e) => e?.id == loggedInUser?.id)?.is_agency_admin;

  const response = await NetworkService.get(`/api/dashboard/qc_summary`, {
    ...(isAgencyAdmin && { [`q[user_id_eq]`]: userId }),
  });
  let quotaCredits = {};
  if (response) {
    if (response.error) {
      return response;
    } else if (response?.data) {
      tenantData.platformList.forEach((e) => {
        if (Object?.keys(response?.data?.[e?.slug])?.length) {
          quotaCredits[e.slug] = quotaCreditsWidgetMapper(e, response?.data, loggedInUser);
        }
      });
      return quotaCredits;
    }
  }
};

export const fetchUserQuotaCredits = async (userId, agencyId) => {
  const response = await NetworkService.get(`/api/dashboard/qc_summary`);
  if (response) {
    if (response?.error) {
      return response;
    } else {
      const data = response?.data;
      const quotaCredits = { zameen: {}, olx: {} };
      tenantData.platformList?.forEach((e) => {
        quotaCredits[e?.slug]['credits'] = data?.[e?.slug]?.credits?.length
          ? data?.[e?.slug]?.credits?.map((e) => {
              return {
                ...tenantData.getListingActions(tenantData.quotaCreditProducts?.find((pr) => pr?.id === e?.id)?.slug),
                ...e,
              };
            })
          : [];
        quotaCredits[e?.slug]['quota'] = data?.[e?.slug]?.quota
          ? {
              ...tenantData.getListingActions(
                tenantData.quotaCreditProducts?.find((pr) => pr?.id === data?.[e?.slug]?.quota?.id)?.slug,
              ),
              ...data?.[e?.slug]?.quota,
            }
          : {};
      });

      return quotaCredits;
    }
  }
};

const getTranscationParams = (paramObj) => {
  const defaultParams = !paramObj?.['filter[date_between]']
    ? { ...paramObj, ['filter[date_between]']: getVariousDates(365)?.join(',') }
    : paramObj;
  const filters = Object?.keys(defaultParams);
  let params = [];
  filters.forEach((e) => {
    if (e === 'filter[date_between]') {
      params?.push(
        `q[transaction_date_gteq]=${defaultParams?.[e]?.split(',')?.[0]}&q[transaction_date_lteq]=${
          defaultParams?.[e]?.split(',')?.[1]
        }`,
      );
    } else {
      params.push(`${e}=${defaultParams?.[e]}`);
    }
  });
  return params?.join('&');
};
