import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import BreakdownByAreaWidget from './BreakdownByAreaWidget';
import ListingBreakdown from './listing-breakdown/listing-breakdown';
import { strings } from '../../constants/strings';
import { EmptyState, Group } from '../common';
import { ContainerWidgets } from './styled';
import tenantData from '@data';

function ReportsListingsSection({ rootClassName, data, fetchWidgetData }) {
  const { user } = useSelector((state) => state.loginUser);
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const isMultiPlatform = useMemo(() => user?.platforms?.length > 1, [user?.id]);

  return (
    <>
      {tenantData?.platformList?.map((platform) => {
        return user?.products?.platforms?.[platform?.slug] ? (
          <>
            {!data?.[platform?.slug] || data?.[platform?.slug]?.loading ? (
              <Group className={rootClassName} template={isMobile ? '1fr' : '32% minmax(0, 1fr)'} gap="16px">
                <div>
                  <ListingBreakdown
                    listingBreakdownData={data?.[platform?.slug]?.data?.listing_breakdown}
                    brandColor={platform.brandColor}
                    linear
                    loading
                    trends={false}
                  />
                </div>
                <div>
                  <BreakdownByAreaWidget areaBreakdownData={data?.[platform?.slug]?.data?.area_breakdown} loading />
                </div>
              </Group>
            ) : !data?.[platform?.slug]?.loading && data?.[platform?.slug]?.error ? (
              <EmptyState
                title={strings.error_}
                message={data?.[platform?.slug]?.error}
                buttonLoading={data?.[platform?.slug]?.loading}
                onClick={() => {
                  fetchWidgetData && fetchWidgetData();
                }}
              />
            ) : (
              <>
                <ContainerWidgets
                  className={rootClassName}
                  template={isMobile ? '1fr' : '32% minmax(0, 1fr)'}
                  gap="16px"
                  key={data?.[platform?.slug]?.data?.id}
                >
                  <div>
                    <ListingBreakdown
                      listingBreakdownData={data?.[platform?.slug]?.data?.listing_breakdown}
                      brandColor={platform?.brandColor}
                      icon={isMultiPlatform && data?.[platform?.slug]?.data?.icon}
                      linear
                      trends={false}
                      minHeight="calc(100% - 42px)"
                    />
                  </div>
                  <div>
                    <BreakdownByAreaWidget
                      areaBreakdownData={data?.[platform?.slug]?.data?.area_breakdown}
                      icon={isMultiPlatform && data?.[platform?.slug]?.data?.icon}
                    />
                  </div>
                </ContainerWidgets>
              </>
            )}
          </>
        ) : (
          <></>
        );
      })}
    </>
  );
}

export default ReportsListingsSection;
