import tenantUtils from '@utils';

const actions = {
  LISTINGS_DATA_BEGIN: 'LISTINGS_DATA_BEGIN',
  LISTINGS_DATA_ERROR: 'LISTINGS_DATA_ERROR',
  LISTINGS_DATA_SUCCESS: 'LISTINGS_DATA_SUCCESS',

  LEADS_DATA_BEGIN: 'LEADS_DATA_BEGIN',
  LEADS_DATA_ERROR: 'LEADS_DATA_ERROR',
  LEADS_DATA_SUCCESS: 'LEADS_DATA_SUCCESS',

  TRAFFIC_DATA_BEGIN: 'TRAFFIC_DATA_BEGIN',
  TRAFFIC_DATA_ERROR: 'TRAFFIC_DATA_ERROR',
  TRAFFIC_DATA_SUCCESS: 'TRAFFIC_DATA_SUCCESS',

  SET_LISTINGS_FILTER: 'SET_LISTINGS_FILTER',
  SET_LEADS_FILTER: 'SET_LEADS_FILTER',
  SET_TRAFFIC_FILTER: 'SET_TRAFFIC_FILTER',

  SET_LEADS_SUMMARY_PLATFORM_FILTER: 'SET_LEADS_SUMMARY_PLATFORM_FILTER',
  SET_TRAFFIC_SUMMARY_PLATFORM_FILTER: 'SET_TRAFFIC_SUMMARY_PLATFORM_FILTER',
  SET_LISTINGS_DATA: 'SET_LISTINGS_DATA',

  getListingData: (data, platformKey) => {
    return {
      type: 'SET_LISTINGS_DATA',
      payload: { [platformKey]: data },
    };
  },

  leadsDataBegin: (platformKey, initailize) => {
    return {
      type: 'LEADS_DATA_BEGIN',
      payload: {
        platformKey,
        data: {
          ...(initailize && { data: null, filterObj: tenantUtils.reportsData[platformKey].initialFilter.lead }),
          loading: true,
          error: null,
        },
      },
    };
  },

  leadsDataSuccess: (platformKey, data) => {
    return {
      type: 'LEADS_DATA_SUCCESS',
      payload: {
        platformKey,
        data: { data, error: null, loading: false },
      },
    };
  },

  leadsDataError: (platformKey, error) => {
    return {
      type: 'LEADS_DATA_ERROR',
      payload: { platformKey, data: { error, loading: false } },
    };
  },

  trafficDataBegin: (platformKey, initailize) => {
    return {
      type: 'TRAFFIC_DATA_BEGIN',
      payload: {
        platformKey,
        data: {
          ...(initailize && {
            data: null,
            filterObj: tenantUtils.reportsData[platformKey].initialFilter.traffic,
          }),
          loading: true,
          error: null,
        },
      },
    };
  },

  trafficDataSuccess: (platformKey, data) => {
    return {
      type: 'TRAFFIC_DATA_SUCCESS',
      payload: { platformKey, data: { data, loading: false, error: null } },
    };
  },

  trafficDataError: (platformKey, error) => {
    return {
      type: 'TRAFFIC_DATA_ERROR',
      payload: { platformKey, data: { error, loading: false } },
    };
  },

  setLeadsFilter: (dateRange, platformKey) => {
    return {
      type: 'SET_LEADS_FILTER',
      payload: { dateRange, platformKey },
    };
  },
  setListingsFilter: (dateRange) => {
    return {
      type: 'SET_LISTINGS_FILTER',
      payload: dateRange,
    };
  },
  setTrafficFilter: (dateRange) => {
    return {
      type: 'SET_TRAFFIC_FILTER',
      payload: dateRange,
    };
  },

  setLeadsSummaryPlatformFilter: (filterObj, platformKey) => {
    return {
      type: 'SET_LEADS_SUMMARY_PLATFORM_FILTER',
      payload: {
        platformKey,
        data: filterObj,
      },
    };
  },

  setTrafficSummaryPlatformFilter: (filterObj, platformKey) => {
    return {
      type: 'SET_TRAFFIC_SUMMARY_PLATFORM_FILTER',
      payload: {
        platformKey,
        data: filterObj,
      },
    };
  },
};

export default actions;
