import tenantApi from '@api';
import actions from './actions';

const { productsBegin, productsSuccess, productsErr } = actions;

const getProducts = (...rest) => {
  return async (dispatch) => {
    dispatch(productsBegin());
    const response = await tenantApi.activeProducts(...rest);
    if (response?.error) {
      dispatch(productsErr(response.error));
    } else {
      response?.products && dispatch(productsSuccess(response.products));
    }
  };
};

export { getProducts };
