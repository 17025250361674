import { checkRequiredKeys } from '../../tenant/checkRequiredKeys';
import { TENANT_KEY } from '../../utility/env';
import BayutConstants, { BAYUT_KEY } from './bayut';
import ZameenConstants, { ZAMEEN_KEY } from './zameen';

const configKeys = ['key', 'locale', 'country_code', 'currency', 'timezone', 'languages'];

export const tenants = Object.freeze({
  [ZAMEEN_KEY]: checkRequiredKeys(ZameenConstants, configKeys),
  [BAYUT_KEY]: checkRequiredKeys(BayutConstants, configKeys),
});

export default tenants[TENANT_KEY];
