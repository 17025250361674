import { DatePicker } from 'antd';

import React from 'react';
import { DATE_BEFORE_TIME_FORMAT } from '../../../constants/formats';
import { SkeletonBody } from '../../skeleton/Skeleton';
import Label from '../Label/Label';
import ErrorMessage from '../errorMessage/errorMessage';
import Group from '../group/group';
import { useSelector } from 'react-redux';

function DateSelect(props) {
  const {
    className,
    placeholder = 'Select Date',
    label,
    name,
    labelProps,
    skeletonLoading,
    value,
    onChange,
    groupTemplate,
    errorMsg,
    containerClassName,
    ...rest
  } = props;
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  return skeletonLoading ? (
    <SkeletonBody type={'input'} />
  ) : (
    <Group className={className} template={groupTemplate || 'initial'} gap="8px">
      {label && (
        <Label htmlFor={name} {...labelProps}>
          {label}
        </Label>
      )}
      <DatePicker
        className={containerClassName}
        showTime
        format={DATE_BEFORE_TIME_FORMAT}
        value={value}
        placeholder={placeholder}
        onChange={(date) => {
          onChange(date);
        }}
        style={{ margin: !isMobile && '5px', ...rest.pickerStyle }}
        inputReadOnly
        {...rest}
      />
      <ErrorMessage message={errorMsg} />
    </Group>
  );
}

export default DateSelect;
