import tenantData from '@data';
import tenantTheme from '@theme';
import tenantUtils from '@utils';
import React, { useEffect, useRef } from 'react';

import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Flex, Group, IconWithSubtext, TextWithIcon } from '../../../../components/common';
import Statistic from '../../../../components/common/statistic';
import WarningNotice from '../../../../components/warning-notice';
import QuotaCreditsStatsWidget from '../../../../components/widgets/QuotaCreditsStatWidget';
import { fetchQuotaCreditsData } from '../../../../redux/dashboard/actionCreators';
import CreditInfoDrawer from '../credit-info-drawer/creditInfoDrawer';
import { DATE_FORMAT } from '../../../../constants/formats';
import { getTimeDateString } from '../../../../utility/date';

const PackageHeader = () => {
  const isMobile = useSelector(state => state.AppConfig.isMobile);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.loginUser);
  const { quotaCreditsData } = useSelector(state => state.Dashboard);
  const platformColor = tenantData.platformList[0]?.brandColor;
  const platformSlug = tenantData.platformList[0]?.slug;
  const slugType = tenantData.slugToType?.[user?.package?.slug];
  const creditDrawerRef = useRef();

  useEffect(() => {
    dispatch(fetchQuotaCreditsData(user?.id));
  }, []);

  return (
    <Card style={{ border: 0 }}>
      <Group template={isMobile ? 'initial' : 'repeat(2, minmax(0, 1fr))'} gap="8px">
        <Card style={{ borderWidth: 1, borderRadius: '6px' }}>
          <Flex gap="25px" justify="space-between">
            <TextWithIcon
              title={t('Current Package')}
              value={
                <div className={isMobile ? 'fz-14' : 'fz-16'} style={{ color: '#222' }}>
                  {tenantUtils.getLocalisedString(quotaCreditsData?.data?.ksa?.current_package_details, 'name')}
                  <span className="fz-12 color-gray-dark fw-400">
                    {` (${quotaCreditsData?.data?.ksa?.current_package_details?.duration_in_months} ${t('months')})`}
                  </span>
                </div>
              }
              icon={tenantData.packages?.[slugType]?.icon}
              iconProps={{
                hasBackground: true,
                size: '24px',
                iconContainerSize: '30px',
                color: tenantTheme['primary-color'],
                iconBackgroundColor: tenantData.packages?.[slugType]?.packageColor,
                marginTop: '6px',
              }}
              trends={false}
              fontWeight="700"
              className="color-gray-dark"
              textColor="#767676"
              textSize="12px"
              iconStyle={{ marginTop: '6px' }}
              loading={quotaCreditsData?.loading}
            />

            <IconWithSubtext
              vertical={isMobile && true}
              title={quotaCreditsData?.data?.ksa?.current_package_details?.credits_per_month}
              subText={`${t('credits')}/${t('mo')}`}
              textSize="20px"
              justify="center"
              style={{ lineHeight: isMobile && 1.2 }}
              loading={quotaCreditsData?.loading}
            />
          </Flex>
          <Divider type="horizontal" className="span-all" style={{ marginBlock: '12px' }} />
          <Flex vertical={isMobile && true} gap={isMobile ? '15px' : '40px'} justify="space-between">
            <Statistic
              title={t('Next Credit Disbursement')}
              value={getTimeDateString(
                quotaCreditsData?.data?.ksa?.current_package_details?.next_disbursement_date,
                DATE_FORMAT,
                true,
              )}
              icon="FiCalendar"
              iconProps={{
                hasBackground: true,
                size: '16px',
                iconContainerSize: '28px',
                color: tenantTheme['primary-color'],
              }}
              spaceProps={{ align: 'center' }}
              trends={false}
              fontSize="14px"
              titleFontSize="12px"
              loading={quotaCreditsData?.loading}
            />
            <Statistic
              icon="PiClockClockwiseFill"
              iconProps={{
                hasBackground: true,
                size: '16px',
                iconContainerSize: '28px',
                color: tenantTheme['primary-color'],
              }}
              spaceProps={{ align: 'center' }}
              title={t('Package End Date')}
              value={getTimeDateString(
                quotaCreditsData?.data?.ksa?.current_package_details?.end_date,
                DATE_FORMAT,
                true,
              )}
              trends={false}
              fontSize="14px"
              titleFontSize="12px"
              loading={quotaCreditsData?.loading}
            />
            <Statistic
              icon="TransactionIcon"
              iconProps={{
                hasBackground: true,
                size: '16px',
                iconContainerSize: '28px',
                color: tenantTheme['primary-color'],
              }}
              spaceProps={{ align: 'center' }}
              title={t('Top-ups Purchased ')}
              value={quotaCreditsData?.data?.ksa?.current_package_details?.top_up_credits}
              trends={false}
              fontSize="14px"
              titleFontSize="12px"
              loading={quotaCreditsData?.loading}
            />
          </Flex>
        </Card>
        <Card style={{ borderWidth: 1, borderRadius: '6px' }}>
          <QuotaCreditsStatsWidget
            available={quotaCreditsData?.data?.ksa?.products[0]?.available}
            used={quotaCreditsData?.data?.ksa?.products[0]?.used}
            loading={quotaCreditsData?.loading}
            currencyType={!!user?.isCurrencyUser ? 'credits' : 'quota'}
            brandColor={platformColor}
            showPackage={false}
          />
          <Flex
            vertical={isMobile && true}
            align={isMobile ? 'start' : 'center'}
            gap={isMobile && '8px'}
            style={{ marginBlockStart: '8px' }}
          >
            <Button
              size={isMobile && 'small'}
              style={{ textDecoration: 'underline' }}
              className="p-0"
              type="link"
              onClick={() => creditDrawerRef.current && creditDrawerRef.current.openDrawer(true)}
            >
              {t('What are Bayut Credits?')}
            </Button>
            <CreditInfoDrawer ref={creditDrawerRef} />
          </Flex>
        </Card>
      </Group>
    </Card>
  );
};
export default PackageHeader;
