import tenantApi from '@api';
import store from '../store';
import actions from './actions';

const {
  fetchListingBegin,
  fetchListingSuccess,
  fetchListingErr,
  updateList,
  setUnit,
  setListingId,
  FETCH_DELETE_REASONS_BEGIN,
  FETCH_DELETE_REASONS_SUCCESS,
  FETCH_DELETE_REASONS_FAILURE,
} = actions;

const fetchListings = (userId, params, filtersList, viewType) => {
  return async (dispatch) => {
    try {
      dispatch(fetchListingBegin());
      const response = await tenantApi.fetchListingPageData(userId, params, filtersList);
      if (response) {
        if (response.error) {
          dispatch(fetchListingErr(response.error));
        } else {
          dispatch(fetchListingSuccess({ ...response?.data, viewType }));
          const user = store.getState()?.loginUser?.user;
          if ((user?.products?.platforms['ksa'] || user?.products?.platforms['olx']) && response?.data?.list.length) {
            const olxStats = await tenantApi.olxListingPerformanceApi(userId, response?.data?.list, user, params);
            if (olxStats) {
              olxStats.list && dispatch(fetchListingSuccess({ ...response?.data, list: olxStats.list, viewType }));
            }
          }
        }
      }
    } catch (err) {
      dispatch(fetchListingErr(err));
    }
  };
};
const fetchDeleteReasons = (purposeId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_DELETE_REASONS_BEGIN });
    try {
      const response = await tenantApi.fetchDeleteReasons(purposeId);
      if (response)
        dispatch({
          type: FETCH_DELETE_REASONS_SUCCESS,
          data: response,
        });
    } catch (error) {
      dispatch({
        type: FETCH_DELETE_REASONS_FAILURE,
        err: error.message || 'Failed to fetch deleteReasons',
      });
    }
  };
};

export { fetchDeleteReasons, fetchListings, setListingId, setUnit, updateList };
