import actions from './actions';

const { SET_USERS_LIST, SET_NEW_USERS_LIST } = actions;

const initialState = {
  list: [],
  error: null,
};

export const UsersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USERS_LIST:
      return { ...state, ...payload };
    default:
      return state;
  }
};
