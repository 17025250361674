import { getKeyValueFromObject } from '../../utility/utility';
import store from '../store';
import actions from './actions';

const {
  SET_QUOTA_CREDITS_WIDGET_DATA,
  SET_QUOTA_CREDITS_WIDGET_ERROR_LOADING,
  SET_PRODUCT_EXPIRY_DATA,
  SET_CURRENT_WIDGET_USER,
} = actions;

const initialState = {
  data: null,
  loading: true,
  error: null,
  selectedUser: {},
  productExpiryData: {},
};

const QuotaCreditsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_QUOTA_CREDITS_WIDGET_DATA: {
      const { data, loading, error } = payload;
      return {
        ...state,
        ...{ data: { ...state.data, ...data } },
        ...{ loading },
        ...{ error },
      };
    }
    case SET_QUOTA_CREDITS_WIDGET_ERROR_LOADING: {
      return {
        ...state,
        ...payload,
      };
    }
    case SET_PRODUCT_EXPIRY_DATA: {
      const objToSpread = payload.platformKey
        ? { [payload.platformKey]: payload.data[payload.platformKey] }
        : { ...payload.data };
      return {
        ...state,
        productExpiryData: {
          ...state.productExpiryData,
          ...objToSpread,
        },
      };
    }

    case SET_CURRENT_WIDGET_USER: {
      return {
        ...state,
        selectedUser: { ...state.selectedUser, ...{ [payload.platformKey]: payload.userId } },
      };
    }

    default:
      return state;
  }
};

export { QuotaCreditsReducer };
