export function checkRequiredKeys(obj, requiredKeys) {
  for (const key of requiredKeys) {
    if (key.includes('.')) {
      // Handle nested keys
      const [firstLevelKey, ...remainingKeys] = key.split('.');
      if (obj[firstLevelKey]) {
        checkRequiredKeys(obj[firstLevelKey], [remainingKeys.join('.')]);
      } else {
        throw new Error(`Missing required export: ${key}`);
      }
    } else {
      if (!(key in obj)) {
        throw new Error(`Missing required export: ${key}`);
      }
    }
  }
  return obj;
}
