import { activeProducts, createCart, cartDetail, checkout, deleteCart, updateCart } from './cart';
import {
  inviteAgencyUser,
  checkInviteOtp,
  manageAgencyInvite,
  getInviteInformation,
  deleteAgencyUser,
  fetchAgencyStaffList,
  fetchAgencyUserDetail,
  fetchAgencyUsers,
  getUserQuotaCreditDetail,
  manageTransfer,
  updateAgencyUser,
  fetchInviteUserFields,
} from './agency-staff';
import { applyUtilizationPlan, getAppliedAutoPlan, getPlans, stopAutoPlan } from './utilization-plans/plans';
import {
  deleteImageFromBank,
  deleteStory,
  fetchCrossCityDetail,
  fetchListingDetailToUpdate,
  getAmenities,
  getCreditDeductionApi,
  getPhotographyVideoGraphyCredits,
  getImageBank,
  getListingDetailApi,
  fetchApplicableProductsById,
  getProjects,
  getQuotaDeductionApi,
  getS3PresignedUrl,
  postNewListing,
  postPackageOnListing,
  postSavedListing,
  priceCheckApi,
  updateListing,
  deleteImageFromS3,
  fetchAutoGeneratedContent,
} from './listings/postListingApis';
import {
  deleteListings,
  fetchListingPageData,
  fetchDeleteReasons,
  updateAutoRenew,
  hideUnhideListings,
  listingMapper
} from './listings/listingsApis';
import {
  deleteMailThread,
  UntrashThead,
  getMailCount,
  getMailbox,
  getMessageDetail,
  postReply,
} from './mailbox/mailbox';
import { fetchAgencySettingsDetail, updateAgencySettings, getAgencyLicense, updateLicense } from './agency-settings';
import { fetchListingPostedByDate, listingsSummaryApi } from './reports/listingsSummaryApi';
import { fetchAccountManager } from './account-manager/accountMaangerInfoApi';
import { fetchUserDetail, fetchUserDetailFromToken, fetchUserProductsDetail } from './user/userDetail';
import {
  getOtp,
  verifyOTP,
  fetchUserSettingsDetail,
  updateUserPreferenceDetail,
  updateUserSettings,
  getLicense,
  fetchActiveLicenses,
  convertToAgency,
} from './user-settings';
import { getQuotaCreditsDetail, fetchUserQuotaCredits } from './quota-credits/quota-credits';
import { requestPayment, updatePayment } from './checkout/checkout';
import { changePassword } from './change-password/changePasswordApi';

import { agencyQuotaCredit } from './quota-credits/agency-quota-credit';
import { dashboardListingWidgetApi } from './dashboard/listingsWidgetApi';
import { fetchListingDetail } from './listings/fetchListingDetail';
import { fetchListingPeformanceData } from './reports/listingPerformanceApi';
import { fetchPlotsByQuery } from './listings/plotfinder';
import { fetchUserDetailApi } from './user/userEditApi';
import { getMyOrders } from './my-orders/getMyOrders';
import { getProductExpiryData } from './quota-credits/getPrdouctExpiryData';
import { leadsSummaryApi } from './reports/leadsSummaryApi';
import { loginApi } from './auth/loginApi';
import { olxListingPerformanceApi } from './reports/olxListingPerformanceApi';
import { fetchContractsDetails } from './user/userDetail';
import { getApplicableProducts } from './upselling/applicable-products';
import { applyProducts } from './upselling/apply-product';
import {
  agencyUser,
  permissions,
  loggedInUser,
  preferencesUpdate,
  agencySettings,
  userPassword,
  autoGeneratedContentPayload,
  convertToAgencyPayload,
} from './requestPayloads/requestPayloads';
import { getConsumptionHistoryData } from './credits-usage/consumption-history';
import { getConsumptionSummaryData } from './credits-usage/consumption_summary';
import { createUpsellCart } from './upselling/create-cart';
import { getMailLeads } from './mail-leads/mail-leads';
import { getPackagesData } from './prop-shop/getPackages';
import { getCustomCreditsPrice } from './prop-shop/customCreditPrice';
import { getListingCardData } from './listings/getListingCardData';
import { getListingDetail } from './listings/listingsApis';
import updateStaffCapping from './agency-staff/updateStaffCapping';
import { toggleSmartCreditsUtilisation } from './smart-credits-utilisation/smartCreditsUtilisationApi';

import getMonthOptions from './month-rules/month-rules';
import validateAdLicense from './post-listing/validateLicense';
import getLocationsBasedOnNational from './post-listing/getLocations';

export default {
  getListingCardData,
  getListingDetail,
  getMailLeads,
  createCart,
  getProductExpiryData,
  fetchListingPageData,
  fetchDeleteReasons,
  updateAutoRenew,
  fetchListingDetailToUpdate,
  leadsSummaryApi,
  listingsSummaryApi,
  fetchUserDetail,
  loginApi,
  fetchUserDetailApi,
  fetchPlotsByQuery,
  getS3PresignedUrl,
  getImageBank,
  fetchAgencyStaffList,
  fetchAgencyUserDetail,
  fetchInviteUserFields,
  inviteAgencyUser,
  checkInviteOtp,
  manageAgencyInvite,
  getInviteInformation,
  deleteAgencyUser,
  updateAgencyUser,
  postNewListing,
  fetchUserSettingsDetail,
  updateUserSettings,
  fetchAgencySettingsDetail,
  fetchAccountManager,
  updateAgencySettings,
  dashboardListingWidgetApi,
  priceCheckApi,
  getQuotaDeductionApi,
  getListingDetailApi,
  fetchApplicableProductsById,
  postPackageOnListing,
  deleteStory,
  getCreditDeductionApi,
  getPhotographyVideoGraphyCredits,
  postSavedListing,
  fetchListingPeformanceData,
  deleteListings,
  hideUnhideListings,
  getAppliedAutoPlan,
  applyUtilizationPlan,
  getPlans,
  getMailbox,
  deleteImageFromBank,
  getQuotaCreditsDetail,
  fetchUserQuotaCredits,
  getAmenities,
  cartDetail,
  activeProducts,
  updateCart,
  deleteCart,
  checkout,
  fetchAgencyUsers,
  stopAutoPlan,
  updateListing,
  updateUserPreferenceDetail,
  deleteMailThread,
  getMessageDetail,
  postReply,
  getMailCount,
  getUserQuotaCreditDetail,
  manageTransfer,
  fetchCrossCityDetail,
  fetchUserProductsDetail,
  fetchContractsDetails,
  fetchListingPostedByDate,
  getProjects,
  agencyQuotaCredit,
  fetchUserDetailFromToken,
  getMyOrders,
  requestPayment,
  olxListingPerformanceApi,
  fetchListingDetail,
  updatePayment,
  UntrashThead,
  changePassword,
  deleteImageFromS3,
  fetchAutoGeneratedContent,
  agencyUser,
  permissions,
  loggedInUser,
  preferencesUpdate,
  agencySettings,
  userPassword,
  getConsumptionHistoryData,
  getConsumptionSummaryData,
  getApplicableProducts,
  applyProducts,
  createUpsellCart,
  getOtp,
  verifyOTP,
  getLicense,
  fetchActiveLicenses,
  getAgencyLicense,
  updateLicense,
  getPackagesData,
  getCustomCreditsPrice,
  autoGeneratedContentPayload,
  updateStaffCapping,
  convertToAgencyPayload,
  convertToAgency,
  toggleSmartCreditsUtilisation,
  getMonthOptions,
  validateAdLicense,
  listingMapper,
  getLocationsBasedOnNational,
};
