import tenantApi from '@api';
import actions from './actions';

const { SET_AGENCY_STAFF, ADD_AGENCY_STAFF_USER, UPDATE_AGENCY_STAFF_USER, DELETE_AGENCY_STAFF_USER } = actions;

const setAgencyStaffList = (user, agencyId, params) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AGENCY_STAFF,
      payload: { loading: true },
    });
    const response = await tenantApi.fetchAgencyStaffList(user, agencyId, params);
    if (response) {
      if (response.error) {
        dispatch({
          type: SET_AGENCY_STAFF,
          payload: { error: response.error, loading: false },
        });
      } else {
        dispatch({
          type: SET_AGENCY_STAFF,
          payload: {
            agencyStaff: response,
            error: null,
            loading: false,
          },
        });
      }
    }
  };
};

const addAgencyUser = (data) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_AGENCY_STAFF_USER,
      payload: { user: data },
    });
  };
};

const updateAgencyUser = (data) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_AGENCY_STAFF_USER,
      payload: { user: data },
    });
  };
};

const deleteAgencyUser = (data) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_AGENCY_STAFF_USER,
      payload: { user: data },
    });
  };
};
export { addAgencyUser, deleteAgencyUser, setAgencyStaffList, updateAgencyUser };

