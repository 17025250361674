import Styled from 'styled-components';

const Div = Styled.div`
    position: relative;

    .container {
      width: min(100%, var(--container-width, 78.571428em));
      margin-inline: auto;

      @media (min-width: 85.4375rem) {
        --container-width: 75rem;
      }
    }

    header{
        box-shadow: 0 2px 30px ${({ theme }) => theme['gray-solid']}10;
        ${({ darkMode }) => (darkMode ? `background: #272B41;` : '')};
        z-index: 999;

        @media print {
            display: none;
        }

        .ant-btn-link{
            ${({ darkMode }) =>
              darkMode ? `background: #272B41;border-color: #272B41;color: #7D808D !important` : ''};
        
        }

        .head-example{
            ${({ darkMode }) => (darkMode ? `color: #A8AAB3;` : '')};
        }
        .ant-menu-sub.ant-menu-vertical{
            .ant-menu-item{
                a{
                    color: ${({ theme }) => theme['gray-color']};
                }
            }
        }
        .ant-menu.ant-menu-horizontal{
            display: flex;
            align-items: center;
            margin: 0 -16px;
            li.ant-menu-submenu{
                margin: 0 16px;
            }
            .ant-menu-submenu{
                &.ant-menu-submenu-active,
                &.ant-menu-submenu-selected,
                &.ant-menu-submenu-open {
                    .ant-menu-submenu-title {
                        color: ${({ darkMode }) => (darkMode ? `#fff;` : '#5A5F7D')};

                        svg,
                        i{
                            color: ${({ darkMode }) => (darkMode ? `#fff;` : '#5A5F7D')};
                        }
                    }
                }
                .ant-menu-submenu-title{
                    font-weight: 500;
                    color: ${({ darkMode }) => (darkMode ? `#ffffff90;` : '#5A5F7D')};
                    svg,
                    i{
                        color: ${({ darkMode }) => (darkMode ? `#ffffff90;` : '#5A5F7D')};
                    }
                    .ant-menu-submenu-arrow{
                        font-family: "FontAwesome";
                        font-style: normal;
                        margin-inline-start: 6px;
                        &:after{
                            color: ${({ darkMode }) => (darkMode ? `#ffffff90;` : '#9299B8')};
                            content: '\f107';
                            background-color: transparent;
                        }
                    }
                }
            }
        }


    }
    .header-more{
        .head-example{
            ${({ darkMode }) => (darkMode ? `color: #A8AAB3;` : '')};
        }
    }
    .striking-logo {
        @media only screen and (max-width: 875px){
            margin-inline-end: 4px;
        }
        @media only screen and (max-width: 767px){
            margin-inline-end: 0;
        }
        img, svg {
            max-width: ${({ theme }) => (theme.topMenu ? '140px' : '250px')};
            vertical-align: middle;
            width: 100%;
            @media only screen and (max-width: 767px){
                max-width: ${({ theme }) => (theme.topMenu ? '140px' : '150px')};
            }
        }
        &.top-menu{
            margin-inline-start: 15px;
        }
    }
    .certain-category-search-wrapper{
         border-inline-end: 1px solid ${({ darkMode }) => (darkMode ? '#272B41' : '')};
         @media only screen and (max-width: 767px){
            padding: 0 15px;
        }
        input{
            max-width: 350px;
            ${({ darkMode }) => (darkMode ? `background: #272B41;` : '')};
            ${({ darkMode }) => (darkMode ? `color: #fff;` : '#5A5F7D')};
            @media only screen and (max-width: 875px){
              padding-inline-start: 5px;
            }
        }
    }

    .navbar-brand{
        button{
            padding-block: 0;
            padding-inline: 10px 30px;
            line-height: 0;
            color: ${({ theme }) => theme['dark-color']};
            @media only screen and (max-width: 875px){
              padding-block: 0;
              padding-inline: 25px 10px;
            }
            @media only screen and (max-width: 767px){
              padding-block: 0;
              padding-inline: 0 15px;
            }
        }
    }

    /* Sidebar styles */
    .ant-layout-sider{
        box-shadow: 0 0 30px #9299B810;
        border-inline-end: 1px solid #f0f0f0;
        padding-inline: 0 15px;
        .ant-menu{
         .ant-menu-item{
         --ant-menu-item-height: 44px;
        }
        }
       


        @media (max-width: 991px){
            box-shadow: 0 0 10px #00000020;
            box-shadow: none;
        }
        @media print {
            display: none;
        }
        &.ant-layout-sider-dark{
            background: ${({ theme }) => theme['dark-color']};
            .ant-layout-sider-children{
                .ant-menu{
                    .ant-menu-submenu-inline{
                        > .ant-menu-submenu-title{
                            padding: 0 30px !important;
                        }
                    }
                    .ant-menu-item {
                        padding: 0 30px !important;
                    }
                }
            }
        }

        .ant-layout-sider-children {
					padding-bottom: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

					> .sidebar-nav-title{
						margin-top: 8px;
					}

					.ant-menu-item,
					.ant-menu-submenu {
						.ant-menu-item-icon {
							color: ${({ theme }) => theme['gray-lightest-color']};
							font-size: 16px;
						}
					}

					.ant-menu-item,
					.ant-menu-submenu-title {
						border-radius: 36px;

            &:active {
              background-color: ${({ theme }) => theme['primary-light']};
            }
					}

					.ant-menu {
						overflow-x: hidden;
						border-inline-end: 0;

						.ant-menu-item,
						.ant-menu-submenu {
							.anticon,
							.ant-menu-item-icon {
								color: ${({ theme }) => theme['gray-lightest-color']};
								font-size: 16px;
							}

							.anticon {
								display: flex;
								align-items: center;
								flex: none;
							}
						}

						.ant-menu-sub.ant-menu-inline{
							background-color: #fff;
						}

						&.ant-menu-inline-collapsed > .ant-menu-item,
						&.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
							display: flex;
							align-items: center;
							margin-inline: auto;
							padding-inline: 18px;
							text-align: center;
							width: 70%;
						}

						&.ant-menu-inline > .ant-menu-item,
						&.ant-menu-inline .ant-menu-submenu-title {
							margin-inline-start: 12px;
							width: auto;
						}

            .ant-menu-submenu .ant-menu-item {
              margin-inline-start: 16px;

              a {
                color: #a3a3a3;
              }
            }

						.ant-menu-item-selected {
							&::after {
								content: none;
							}
						}

            .ant-menu-item-selected,
            .ant-menu-submenu-selected > .ant-menu-submenu-title {
              background-color: ${({ theme }) => theme['primary-light']};
              font-weight: 600;
              color: ${({ theme }) => theme['primary-color']};


              .ant-menu-item-icon {
                color: ${({ theme }) => theme['primary-color']};
              }
            }

            .ant-menu-submenu-selected .ant-menu-item-selected {
              background-color: transparent;
              font-weight: 600;

              a {
                color: ${({ theme }) => theme['primary-color']};
              }
            }
					}
        }

        .sidebar-nav-title{
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            ${({ darkMode }) => (darkMode ? `color: rgba(255, 255, 255, .38);` : 'color: #9299B8;')};
            padding: 0 ${({ theme }) => (theme.rtl ? '20px' : '15px')};
            display: flex;
        }
        &.ant-layout-sider-collapsed{
            padding-inline: 0;
            .sidebar-nav-title{
                display: none;
            }
            .ant-menu-item{
                color: #333;
                .badge{
                    display: none;
                }
            }
        }
    }
    @media only screen and (max-width: 1150px){
        .ant-layout-sider.ant-layout-sider-collapsed{
            inset-inline-start: -80px !important;
        }

    }

    .atbd-main-layout{
        margin-inline-start: ${({ theme }) => (theme.topMenu || theme.isMobile ? 'initial' : '80px')};
        margin-block-start: ${({ theme }) => (theme.topMenu ? 'initial' : 'var(--min-height-offset)')};
        min-height: calc(100dvh - var(--min-height-offset));

        @media print {
          width: 100%;
          margin-inline-start: 0;
          margin-inline-end: 0;
        }
    }

    /* Mobile Actions */
    .mobile-action{
        position: absolute;
        inset-inline-end: 20px;
        top: 50%;
        transform: translateY(-50%);
        display: inline-flex;
        align-items: center;
        @media only screen and (max-width: 767px){
            inset-inline-end: 0;
        }
        a{
            display: inline-flex;
            color: ${({ theme }) => theme['light-color']};
            &.btn-search{
                margin-inline-end: 18px;
            }
            svg{
                width: 20px;
                height: 20px;
            }
        }
    }
    .admin-footer{
        @media print {
            display: none;
        }
        .admin-footer__copyright{
            display: inline-block;
            width: 100%;
            color: ${({ theme }) => theme['light-color']};
            @media only screen and (max-width: 767px){
                text-align: center;
            }
        }
        .admin-footer__links{
            text-align: end;
            @media only screen and (max-width: 767px){
                text-align: center;
            }
            a{
                color: ${({ theme }) => theme['light-color']};
                &:not(:last-child){
                    margin-inline-end: 15px;
                }
                &:hover{
                    color: ${({ theme }) => theme['primary-color']};
                }
            }
        }
    }
`;

export { Div };
