import tenantApi from '@api';
import store from '../store';
import actions from './actions';

const {
  SET_QUOTA_CREDITS_DATA,
  SET_LISTINGS_TABLE_DATA,
  SET_ANALYTICS_DATA,
  UPDATE_LISTINGS_DATA,
  updateListDashboard,
  SET_CURRENT_USER,
  SET_ANALYTICS_FILTER_OBJECT,
  SET_QUOTA_CREDITS_ERROR_LOADING,
  SET_EXPIRY_DATA,
  setUpdatedCredits,
  setUpdatedQuota,
  setListingsDashboardData,
  setListingsDashboardLoading,
  setSelectedPlatform,
  setLeadsFilter,
  leadsDataBegin,
  leadsDataError,
  leadsDataSuccess,
  setLeadsSummaryPlatformFilterDashboard,
  setSelectedUser,
} = actions;

const selectUser = (userId) => (dispatch) => {
  dispatch(setSelectedUser(userId));
};

const setListingsData = (user, params = {}, shouldSetLoading = true) => {
  return async (dispatch) => {
    Object?.keys(user?.products?.platforms)?.forEach(async (e) => {
      shouldSetLoading && dispatch(setListingsDashboardLoading(e));
      const response = await tenantApi.dashboardListingWidgetApi(user, params, e);
      if (response) {
        if (response.error) {
          dispatch(setListingsDashboardData({ data: null, loading: false, error: response.error }, e));
        } else {
          dispatch(setListingsDashboardData({ data: response, loading: false, error: null }, e));
        }
      }
    });
  };
};

const setListingsTableData = (userId, params = {}, shouldSetLoading = true, isMobile) => {
  return async (dispatch) => {
    shouldSetLoading && dispatch({ type: SET_LISTINGS_TABLE_DATA, payload: { loading: true } });
    const response = await tenantApi.fetchListingPageData(userId, params);
    if (response.error) {
      dispatch({ type: SET_LISTINGS_TABLE_DATA, payload: { error: response.error, loading: false } });
    } else {
      dispatch({ type: SET_LISTINGS_TABLE_DATA, payload: { data: response.data, error: null, loading: false } });
      const user = store.getState().loginUser?.user;

      if ((user?.products?.platforms['olx'] || user?.products?.platforms['ksa']) && response?.data?.list?.length) {
        const olxStats = await tenantApi.olxListingPerformanceApi(userId, response.data.list, user, params);
        if (olxStats) {
          dispatch({ type: UPDATE_LISTINGS_DATA, payload: olxStats });
        }
      }
    }
  };
};

const setAnalyticsFilter = (filterObj, platformKey) => async (dispatch) => {
  dispatch({
    type: SET_ANALYTICS_FILTER_OBJECT,
    payload: {
      platformKey,
      data: {
        ...filterObj,
      },
    },
  });
};

const updateListForDashboard = (data) => (dispatch) => {
  return dispatch(updateListDashboard(data));
};

const fetchQuotaCreditsData =
  (id, platformSlug, user, cb = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_QUOTA_CREDITS_ERROR_LOADING,
      payload: {
        loading: true,
      },
    });
    const response = await tenantApi.agencyQuotaCredit(id);
    if (response) {
      if (response.error) {
        dispatch({
          type: SET_QUOTA_CREDITS_ERROR_LOADING,
          payload: {
            error: response.error,
            loading: false,
          },
        });
      } else {
        dispatch({
          type: SET_QUOTA_CREDITS_DATA,
          payload: {
            error: null,
            loading: false,
            data: platformSlug ? { [platformSlug]: response?.[platformSlug] } : response,
          },
        });
        cb();
      }
    }
  };

const fetchExpiryData = (id, platformKey, user) => async (dispatch) => {
  const response = user?.type !== 'free' && (await tenantApi.getProductExpiryData(id, user));
  if (!!response) {
    if (response.error) {
    } else {
      dispatch({
        type: SET_EXPIRY_DATA,
        payload: {
          platformKey,
          data: response,
        },
      });
    }
  }
};

const setPlatformCurrentUser = (userId, platformKey) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: {
      platformKey,
      userId,
    },
  });
};

const setUpdatedCreditsDashboard = (data) => {
  return async (dispatch) => {
    dispatch(setUpdatedCredits(data));
  };
};

const setUpdatedQuotaDashboard = (data) => {
  return async (dispatch) => {
    dispatch(setUpdatedQuota(data));
  };
};
const setLeadsDateFilter = (dateRange, platformKey) => (dispatch) => {
  dispatch(setLeadsFilter(dateRange, platformKey));
};
const getLeadsData = (params, platformKey, initalize, user) => {
  return async (dispatch) => {
    dispatch(leadsDataBegin(platformKey, initalize));
    const response = await tenantApi.leadsSummaryApi(params, 'widget', platformKey, user);
    if (response.error) {
      dispatch(leadsDataError(platformKey, response.error));
    } else {
      dispatch(leadsDataSuccess(platformKey, response));
    }
  };
};
const setLeadsSummaryFilterObj = (filterObj, platformKey) => (dispatch) => {
  dispatch(setLeadsSummaryPlatformFilterDashboard(filterObj, platformKey));
};
export {
  fetchExpiryData,
  fetchQuotaCreditsData,
  getLeadsData,
  setAnalyticsFilter,
  setLeadsDateFilter,
  setLeadsSummaryFilterObj,
  setListingsData,
  setListingsTableData,
  setPlatformCurrentUser,
  setSelectedPlatform,
  setUpdatedCreditsDashboard,
  setUpdatedQuotaDashboard,
  updateListForDashboard,
  selectUser,
};
