import tenantUtils from '@utils';
import { imageStateObject } from '../../../../../helpers/imageHelpers/imageStateObject';
import { formatMobile } from '../../../../../utility/utility';

export const getLoggedInUserValues = (values) => {
  if (!values) return null;
  return {
    ...values,
    id: values?.id,
    agencyId: values?.agency?.id,
    fALLicenseNumber: values?.license?.number
      ? values?.license?.number
      : values?.license?.license_owner_type === 'Agency'
        ? values?.agency_attributes?.license_attributes?.number
        : values?.license_attributes?.number,
    fallid: values?.license?.id,
    fALLicenseExpiryDate: values?.license?.end_date,
    commercialRegistrationNumber: values?.commercial_registration_number,
    typeofBuisness: values?.broker_type,
    buisnessName: values?.agency?.name,
    nationalShortAddress: values?.national_short_address,
    is_nafaz_verified: values?.is_nafaz_verified,
    is_rega_verified: values?.is_rega_verified,
    license_type: values?.license?.license_owner_type,
    falLicenseURL: values?.license?.certificate_link
      ? values?.license?.certificate_link
      : values?.license?.license_owner_type === 'Agency'
        ? values?.agency_attributes?.license_attributes?.certificate_link
        : values?.license_attributes?.certificate_link,
    nameArabic: values?.name_l1,
    name: values?.name,
    email: values?.email,
    is_email_verified: values?.is_email_verified,
    mobile: values?.mobile ? formatMobile(values?.mobile, 'singleNumber') : '',
    is_mobile_verified: values?.is_mobile_verified,
    phone: values?.phone ? formatMobile(values?.phone, 'singleNumber') : '',
    address: tenantUtils.getLocalisedString(values, 'address'),
    country: values?.country_id || 155,
    location: {
      city: values?.city_id && { location_id: values?.city_id, name: tenantUtils.getLocalisedString(values, 'city') },
    },
    ...(values?.profile_image && {
      profile_image: [{ gallerythumb: values?.profile_image, ...imageStateObject() }],
    }),
    whatsapp: values?.whatsapp ? formatMobile(values?.whatsapp, 'singleNumber') : '',
  };
};
