import tenantUtils from '@utils';
import { NetworkService } from '../../../../services/networkService';
import { DATE_BEFORE_TIME_FORMAT } from '../../../../constants/formats';
const mailDetailMapper = item => {
  return {
    enquiry_about: {
      id: item?.listing?.id,
      ...(item?.listing?.listing_purpose && { purpose: item?.listing?.listing_purpose }),
      type: item?.listing?.listing_type,
      area: { value: item?.listing?.area_unit?.value, unit: item?.listing?.area_unit?.id || 2 },
      location: { ...item?.listing?.location, breadcrumb: item?.listing?.location?.breadcrumb?.reverse() },
      image: item?.listing?.image,
      price: { value: item?.listing?.price, currency: 'SAR' },
      details: {
        ...item?.listing?.rega_details?.property_specs,
        beds: item?.listing?.beds,
        baths: item?.listing?.baths,
        listing_type: item?.listing?.listing_type,
        regaId: item?.listing?.rega_details?.license_info?.ad_license_number,
        regaExpiryDate: item?.listing?.platforms?.rega_expiry_date,
      },
      ...(item?.listing?.health && {
        health: item?.listing?.health,
      }),
      // productsInfo: item?.listing?.platforms?.ksa?.products_information, //Pending
    },
    details: {
      name: item?.user_name,
      email: item?.user_email,
      phone: item?.user_phone,
    },
    date: { value: item?.datetime , format: DATE_BEFORE_TIME_FORMAT},
    emailBody: { message: item?.email_body, isExpandable: false },
  };
};

export const getMailLeads = async (userId, page) => {
  const response = await NetworkService.get(`/api/dashboard/email_leads?page=${page || 1}`, {
  });
  if (response?.error) {
    return response;
  } else {
    return {
      table: [
        {
          title: 'Property',
          component: 'ListingPurpose',
          dataIndex: 'enquiry_about',
          key: 'enquiry_about',
          width: 400,
        },
        {
          title: 'Details',
          dataIndex: 'details',
          key: 'details',
          component: 'UserDetail',
          width: 280,
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          component: 'Date',
          width: 180,
        },
        {
          title: 'Message',
          dataIndex: 'emailBody',
          key: 'emailBody',
          component: 'ExpandableMessage',
        },
      ],
      list: response?.data?.email_leads?.length ? response?.data?.email_leads?.map(item => mailDetailMapper(item)) : [],

      pagination: tenantUtils.getPaginationObject(response?.data?.pagination),
    };
  }
};
