import { NetworkService } from "../../../../services/networkService";
const getInviteInformation=async (values)=>{
    let response=await NetworkService.get(`/api/invitations?token=${values.token}`);
    if (response.error) {
      return {
        error: response.error,
      };
    } else {
      return response;
    }
}
export default getInviteInformation;