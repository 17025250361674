import { checkRequiredKeys } from '../../tenant/checkRequiredKeys';
import { TENANT_KEY } from '../../utility/env';
import BayutFilters from './bayut';
import ZameenFilters from './zameen';

const configKeys = [];

export const tenants = Object.freeze({
  ['zameen']: checkRequiredKeys(ZameenFilters, configKeys),
  ['bayut']: checkRequiredKeys(BayutFilters, configKeys),
});

export default tenants[TENANT_KEY];
