import { getVariousDates } from '../../utility/date';
import actions from './actions';

const { SET_LISTINGS_TABLE_DATA, SET_LISTINGS_WIDGET_DATA, SET_LISTINGS_TABLE_FILTER, SET_LISTINGS_WIDGET_FILTER } =
  actions;

const initialState = {
  widgetData: {
    data: null,
    date_between: getVariousDates(29),
    loading: true,
    error: null,
  },
  tableData: {
    error: null,
    loading: true,
    list: null,
    pagination: null,
  },
};

export const ListingsSummaryReducer = (state = initialState, action) => {
  const { type, payload, err } = action;

  switch (type) {
    case SET_LISTINGS_TABLE_DATA:
      return { ...state, tableData: { ...state.tableData, loading: false, ...payload } };
    case SET_LISTINGS_WIDGET_DATA:
      return { ...state, widgetData: { ...state.widgetData, loading: false, ...payload } };
    case SET_LISTINGS_TABLE_FILTER:
      return { ...state, tableData: { ...state.tableData, ...payload } };
    case SET_LISTINGS_WIDGET_FILTER:
      return { ...state, widgetData: { ...state.widgetData, ...payload } };
    default:
      return state;
  }
};
