import tenantConstants from '@constants';
import { Tooltip } from 'antd';
import cx from 'clsx';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Flex, Modal } from '../../../../components/common';
import { IconArabicBold, IconArabicfont } from '../../../../components/utilities/icons';
import { useAppAuthentication, useRouteNavigate } from '../../../../hooks';
import { saveUserSettings } from './apis';
import { DeskTopNavBar } from './desktopNavbar';
import { MobileNavBar } from './mobileNavBar';
import { FAB } from './styled';
import { setCookieUnits } from './utils';
import tenantRoutes from '@routes';

export const NavBar = () => {
  const { t } = useTranslation();
  const { isMobile, rtl } = useSelector((state) => state.AppConfig);
  const userData = useSelector((state) => state?.loginUser?.user);
  const locale = useSelector((state) => state.AppConfig.locale);

  const [newLocale, setNewLocale] = useState(locale);
  const [showLocaleModal, setShowLocaleModal] = useState(false);
  const [showChangeCurrencyModal, setShowChangeCurrencyModal] = useState(false);
  const [showChangeAreaUnitModal, setShowChangeAreaUnitModal] = useState(false);
  const [noFavPropModal, setNoFavPropModal] = useState(false);
  const { onLogout } = useAppAuthentication();
  const navigate = useRouteNavigate();

  const pathLocale = locale == 'ar' ? '' : `/${locale}`;

  const countryList = useMemo(
    () => [
      {
        title: 'United Arab Emirates',
        title_l1: 'الإمارات العربية المتحدة',
        href: 'https://www.bayut.com/',
        id: 'UAE',
        icon: 'AE',
      },
      {
        title: 'Pakistan',
        title_l1: 'باكستان',
        href: 'https://www.zameen.com/',
        id: 'PK',
        icon: 'PK',
      },
      {
        title: 'Jordan',
        title_l1: 'الأردن',
        href: 'https://www.bayut.jo/',
        id: 'JO',
        icon: 'JO',
      },
      {
        title: 'Philippines',
        title_l1: 'الفلبين',
        href: 'https://www.lamudi.com.ph/',
        id: 'PH',
        icon: 'PH',
      },
      {
        title: 'Indonedia',
        title_l1: 'إندونيسيا',
        href: 'https://www.lamudi.co.id/',
        id: 'ID',
        icon: 'ID',
      },
      { title: 'Egypt', title_l1: 'مَصر', href: 'https://www.bayut.eg/', id: 'EG', icon: 'EG' },
    ],
    [],
  );

  const localeData = useMemo(
    () => [
      { label: 'English', value: 'en' },
      { label: newLocale === 'ar' ? <IconArabicBold size={52} /> : <IconArabicfont size={52} />, value: 'ar' },
    ],
    [newLocale],
  );

  const logoutUser = async () => {
    onLogout();
  };

  const saveSettings = async (payload) => {
    setCookieUnits(payload);
    await saveUserSettings(payload);
  };

  const toggleLanguage = (loc) => {
    const currentUrl = window.location.pathname;
    let newUrl;
    if (currentUrl.includes(`/${locale}/`)) {
      newUrl = currentUrl.replace(`/${locale}/`, `${loc == 'ar' ? '' : `/${loc}`}/`);
    } else {
      newUrl = `/${loc}${window.location.pathname}`;
    }
    return newUrl;
  };

  const languageObjectToSwitch = useMemo(
    () => tenantConstants?.languages?.length > 1 && tenantConstants?.languages?.find((e) => e.alternate === locale),
    [locale],
  );

  const getNoFavouritePropertyModalUi = () => {
    return (
      <Modal
        visible={noFavPropModal}
        style={{ overlay: { zIndex: 1000 } }}
        footer={null}
        className="favouritePropertiesModal"
        onCancel={() => setNoFavPropModal(false)}
      >
        <Flex vertical align="center" gap="24px" className="text-center">
          <div className="favouritePropertiesPopup" />
          <div>
            <p className="fw-700 fz-20 u-mb16" style={{ lineHeight: '30px' }}>
              {t("You haven't added any favourite properties yet")}
            </p>
            <p className={cx('text-muted fz-16')} style={{ lineHeight: '24px' }}>
              {t('Simply tap on the HEART icon to bookmark your favourite properties and explore them later')}
            </p>
          </div>
        </Flex>
      </Modal>
    );
  };

  return (
    <>
      {getNoFavouritePropertyModalUi()}
      <Tooltip {...(isMobile && { open: false })} title={t('Post an Ad')} placement={rtl ? 'left' : 'right'}>
        <FAB
          type="success"
          icon="MdAddLocationAlt"
          shape="circle"
          size="large"
          onClick={() => navigate(tenantRoutes.app('', false, userData).post_listing.path)}
        />
      </Tooltip>
      {isMobile ? (
        <MobileNavBar
          isMobile={isMobile}
          userData={userData}
          logoutUser={logoutUser}
          localeData={localeData}
          locale={locale}
          countryList={countryList}
          setShowLocaleModal={setShowLocaleModal}
          saveSettings={saveSettings}
          pathLocale={pathLocale}
          toggleLanguage={toggleLanguage}
          languageObj={languageObjectToSwitch}
        />
      ) : (
        <DeskTopNavBar
          userData={userData}
          logoutUser={logoutUser}
          setNewLocale={setNewLocale}
          locale={locale}
          setShowLocaleModal={setShowLocaleModal}
          showChangeCurrencyModal={showChangeCurrencyModal}
          setShowChangeCurrencyModal={setShowChangeCurrencyModal}
          showChangeAreaUnitModal={showChangeAreaUnitModal}
          setShowChangeAreaUnitModal={setShowChangeAreaUnitModal}
          setNoFavPropModal={setNoFavPropModal}
          saveSettings={saveSettings}
          pathLocale={pathLocale}
          toggleLanguage={toggleLanguage}
          languageObj={languageObjectToSwitch}
        />
      )}
    </>
  );
};
