import { NetworkService } from "../../../../services/networkService";

const deleteAgencyUser = async (agencyId, userId) => {
  let response = await NetworkService.delete(`/api/agencies/${agencyId}/remove_staff`, {
    user_id: userId,
  });

  if (response.error) {
    return {
      error: response.error,
    };
  } else if (response.data)
    return {
      user: response.data?.data,
    };
};
export default deleteAgencyUser;
