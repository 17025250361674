import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TextInput } from '..';
import { DATE_FORMAT } from '../../../constants/formats';
import { getTimeDateString } from '../../../utility/date';
import { SkeletonBody } from '../../skeleton/Skeleton';
import { DateRangePickerOne } from '../datePicker/datePicker';
import { Popover } from '../popup/popup';

function DateFilter(props) {
  const { t } = useTranslation();
  const {
    placeholder,
    queryStartDate,
    queryEndDate,
    label,
    labelProps,
    loading,
    error,
    skeletonLoading,
    disabled,
    accentColor,
    ...rest
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const isMobile = useSelector((state) => state.AppConfig.isMobile);

  const onClick = (e) => {
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    setIsOpen(true);
  };
  const getPlaceHolderForSingleDate = (selectedDate, currentDate) => {
    const start = new Date(currentDate);
    const end = new Date(selectedDate);
    const dateDiff = Math.abs(start - end);
    const diffFromToday = Math.floor(dateDiff / (1000 * 60 * 60 * 24));

    if (diffFromToday === 0) {
      return t('Today');
    } else if (diffFromToday === 1) {
      return t('Yesterday');
    } else {
      return `${getTimeDateString(selectedDate, DATE_FORMAT, false, true)} `;
    }
  };

  const content = (
    <>
      <DateRangePickerOne
        {...props}
        setIsOpen={setIsOpen}
        dateRange={dateRange}
        setDateRange={setDateRange}
        accentColor={accentColor}
      />
    </>
  );

  function getPlaceHolder(dateString1, dateString2) {
    // Parse date strings into Date objects
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);

    const timeDifference = Math.abs(date2 - date1);
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    switch (daysDifference) {
      case 0:
        return getPlaceHolderForSingleDate(dateString2, new Date());
      case 6:
        return t('Last Week');
      case 14:
        return t('Last 15 Days');
      case 29:
        return t('Last Month');
      case 89:
        return t('Last 3 Months');
      case 179:
        return t('Last 6 Months');
      case 364:
        return t('Last Year');
      case 729:
        return t('Last 2 Years');
      default:
        return `${getTimeDateString(queryStartDate, DATE_FORMAT, false, true)} – ${getTimeDateString(
          queryEndDate,
          DATE_FORMAT,
          false,
          true,
        )}`;
    }
    return daysDifference;
  }

  return skeletonLoading ? (
    <SkeletonBody type="input" />
  ) : (
    <>
      <Popover
        visible={isOpen}
        onVisibleChange={() => {
          setIsOpen(!isOpen);
        }}
        placement="bottomRight"
        title={t('Search by Calendar')}
        content={content}
        action="click"
        zIndex={1111}
        getTooltipContainer={null}
        getPopupContainer={null}
      >
        <TextInput
          label={label}
          labelProps={labelProps}
          suffixIcon="FiCalendar"
          iconColor={accentColor}
          placeholder={placeholder}
          loading={loading}
          skeletonLoading={skeletonLoading}
          onClick={onClick}
          value={queryStartDate && queryEndDate ? getPlaceHolder(queryStartDate, queryEndDate) : ''}
          className="dateFilter"
          style={{ minWidth: !isMobile && 250, caretColor: 'transparent', '--border-color': accentColor }}
          disabled={disabled}
          fieldSize={rest.fieldSize}
          accentColor={accentColor}
          {...rest}
        />
      </Popover>
    </>
  );
}

export default DateFilter;
