import tenantData from '@data';
import { getVariousDates } from '../../utility/date';
import actions from './actions';

const {
  SET_LISTINGS_FILTER,
  SET_LEADS_FILTER,
  SET_TRAFFIC_FILTER,
  SET_LISTINGS_DATA,
  LEADS_DATA_BEGIN,
  LEADS_DATA_ERROR,
  LEADS_DATA_SUCCESS,
  TRAFFIC_DATA_BEGIN,
  TRAFFIC_DATA_ERROR,
  TRAFFIC_DATA_SUCCESS,
  SET_TRAFFIC_SUMMARY_PLATFORM_FILTER,
  SET_LEADS_SUMMARY_PLATFORM_FILTER,
} = actions;
const initialState = {
  trafficData: {
    date_between: getVariousDates(29),
  },
  leadsData: {},
  listingsData: {
    data: null,
    loading: false,
    error: null,
    date_between: getVariousDates(29),
  },
};

export const ReportsSummaryReducer = (state = initialState, action) => {
  const { type, payload } = action;

  const leadsFilterObj = state.leadsData[payload?.platformKey]?.filterObj;

  const handleLeadsFilterObj = (platformKey) => {
    if (!!platformKey) {
      return {
        [payload.platformKey]: {
          ...state.leadsData[payload.platformKey],
          filterObj: { ...leadsFilterObj, ...payload.data },
        },
      };
    }

    const platformObj = {};
    tenantData.platformList.forEach((platform) => {
      platformObj[platform.slug] = {
        ...state.leadsData[platform.slug],
        filterObj: { ...state.leadsData[platform.slug]?.filterObj, ...payload.data },
      };
    });

    return platformObj;
  };

  const trafficFilterObj = state.trafficData[payload?.platformKey]?.filterObj;

  const handleTrafficFilterObj = (platformKey) => {
    if (!!platformKey) {
      return {
        [payload.platformKey]: {
          ...state.trafficData[payload.platformKey],
          filterObj: { ...trafficFilterObj, ...payload.data },
        },
      };
    }

    const platformObj = {};
    tenantData.platformList.forEach((platform) => {
      platformObj[platform.slug] = {
        ...state.trafficData[platform.slug],
        filterObj: { ...state.leadsData[platform.slug]?.filterObj, ...payload.data },
      };
    });

    return platformObj;
  };

  switch (type) {
    case SET_LISTINGS_FILTER:
      return {
        ...state,
        listingsData: {
          ...state.listingsData,
          date_between: payload.dateRange,
        },
      };
    case SET_LEADS_FILTER:
      return {
        ...state,
        leadsData: {
          ...state.leadsData,
          [payload.platformKey]: {
            ...state.leadsData.filterObj,
            date_between: payload.dateRange,
          },
        },
      };
    case SET_TRAFFIC_FILTER:
      return {
        ...state,
        trafficData: {
          ...state.trafficData,
          date_between: payload.dateRange,
        },
      };

    case SET_LISTINGS_DATA:
      return {
        ...state,
        listingsData: {
          ...state.listingsData,
          ...payload,
        },
      };

    case LEADS_DATA_BEGIN:
      return {
        ...state,
        leadsData: {
          ...state.leadsData,
          [payload.platformKey]: {
            ...state.leadsData[payload.platformKey],
            ...payload.data,
          },
        },
      };

    case LEADS_DATA_ERROR:
      return {
        ...state,
        leadsData: {
          ...state.leadsData,
          [payload.platformKey]: {
            ...state.leadsData[payload.platformKey],
            ...payload.data,
          },
        },
      };
    case LEADS_DATA_SUCCESS:
      return {
        ...state,
        leadsData: {
          ...state.leadsData,
          [payload.platformKey]: {
            ...state.leadsData[payload.platformKey],
            ...payload.data,
          },
        },
      };

    case TRAFFIC_DATA_BEGIN:
      return {
        ...state,
        trafficData: {
          ...state.trafficData,
          [payload.platformKey]: {
            ...state.trafficData[payload.platformKey],
            ...payload.data,
          },
        },
      };

    case TRAFFIC_DATA_ERROR:
      return {
        ...state,
        trafficData: {
          ...state.trafficData,
          [payload.platformKey]: {
            ...state.trafficData[payload.platformKey],
            ...payload.data,
          },
        },
      };
    case TRAFFIC_DATA_SUCCESS:
      return {
        ...state,
        trafficData: {
          ...state.trafficData,
          [payload.platformKey]: {
            ...state.trafficData[payload.platformKey],
            ...payload.data,
          },
        },
      };
    case SET_TRAFFIC_SUMMARY_PLATFORM_FILTER:
      return {
        ...state,
        trafficData: {
          ...state.trafficData,
          ...handleTrafficFilterObj(payload.platformKey),
        },
      };
    case SET_LEADS_SUMMARY_PLATFORM_FILTER:
      return {
        ...state,
        leadsData: {
          ...state.leadsData,
          ...handleLeadsFilterObj(payload.platformKey),
        },
      };
    default:
      return state;
  }
};
