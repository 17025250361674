import tenantApi from '@api';
import actions from './actions';
const { SET_APPLICABLE_LICENSE_LIST } = actions;
const setApplicableLicenseList = (userId) => {
  return async (dispatch) => {
    dispatch({ type: SET_APPLICABLE_LICENSE_LIST, payload: { licenseLoading: true } });
    const response = await tenantApi.fetchActiveLicenses(userId);

    if (response) {
      if (response.error) {
        dispatch({
          type: SET_APPLICABLE_LICENSE_LIST,
          payload: { error: response.error, licenseLoading: false },
        });
      } else {
        dispatch({
          type: SET_APPLICABLE_LICENSE_LIST,
          payload: {
            applicableLicenses: response?.data?.licenses?.length
              ? response?.data?.licenses?.map((e) => ({ ...e, key: e?.id }))
              : [],
            error: null,
            licenseLoading: false,
          },
        });
      }
    }
  };
};
export default setApplicableLicenseList;
